import { preventAction } from "@util/helpers"
import { useCallback } from "react"

export const useCleanBilling = ({ onCleanBilling = () => null }) => {

    const cleanBilling = useCallback(() => {
        preventAction({
            text: "¿Desea limpiar el area de facturación?",
            onSubmit: () => {
                onCleanBilling()
            }
        })
    }, [onCleanBilling])

    return {
        cleanBilling
    }
}