import Autocomplete from "@component/Autocomplete"
import { usePaymentScheduleMenu } from "../hooks/usePaymentScheduleMenu"
import { useURLParams } from "@hook/useURLParams"

const PurchaseSchedulingFilter = () => {
    const { primaryMenu, value } = usePaymentScheduleMenu()
    const { addQuery, delQueries } = useURLParams()

    const handleChange = (id) => {
        if( Boolean(id) ){
            addQuery({ organizeProgrammingBy: id })
        }else{
            delQueries(["organizeProgrammingBy"])
        }
    }

    return(
        <>
            <Autocomplete
                fullWidth
                value={value}
                size="small"
                label="Organizar por"
                options={primaryMenu()}
                onChange={(_, param) => handleChange((param?._id??null))}
            />
        </>
    )
}

export default PurchaseSchedulingFilter