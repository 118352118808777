import { createTheme } from '@component/UIComponents'

const theme = createTheme({
    palette:{
        primary:{ main: "#0069e9", light: "#3387ed", dark: "#0049a3", contrastText: "#FFFFFF" },
    },
    shape:{
        borderRadius: 8
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides:{
                '*::-webkit-scrollbar': {
                    width: '0.5em',
                    height: '0.5em',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.2)',
                    borderRadius : "50px"
                },
                '& #swal2-title': {
                    fontWeight: 'bold',
                    fontSize: "1.5rem"
                },
                '& #swal2-html-container': {
                    fontWeight: '500',
                    marginTop: 0,
                    fontSize: "1rem"
                },
                '& .swal2-confirm': {
                    backgroundColor: "#0069e9 !important"
                },
                '& .MuiTabPanel-root' : {
                    padding: '0px !important'
                }
            },
        },
    },
});

export default theme