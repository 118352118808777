import { Box } from '@component/UIComponents'
import { usePanel } from "@hook/usePanel"
import dayjs from "dayjs"
import { useMemo } from "react"

const TXDate = ({ startDate="", endDate="", format="MM-DD-YYYY" }) => {
    const { getDateFormats } = usePanel()
    const date = useMemo(() => {
        let resp = ""
        const isValidStartDate = dayjs(startDate, format).isValid()
        const isValidEndDate = dayjs(endDate, format).isValid()

        if( isValidStartDate && isValidEndDate ){
            if( (startDate === endDate) ){
                resp = getDateFormats(startDate, format).fullDate
            }else{
                resp = `${getDateFormats(startDate, format).fullDate} al ${getDateFormats(endDate, format).fullDate}`
            }
        }else if( isValidStartDate ){
            resp = getDateFormats(startDate, format).fullDate
        }

        return resp
    }, [startDate, endDate, format, getDateFormats])
    return(
        <Box fontWeight={'bold'} fontSize={'.875rem'} >
            {date}
        </Box>
    )
}

export default TXDate