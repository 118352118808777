import QuickList from "@component/QuickList"
import SuperDataGrid from "@component/SuperDataGrid"
import { useTranslation } from "react-i18next"
import { calculatePurchases } from "../hooks/usePurchase"
import { usePanel } from "@hook/usePanel"
import { memo, useState } from "react"
import { useGridApiRef } from "@mui/x-data-grid"

const PurchasesList = ({ onRowSelectionChange=undefined, title="", loading=false, columns=[], list=[] }) => {
    const { t } = useTranslation()
    let [currentList, setCurrentList] = useState([])
    const { updateStatusWithoutRepeating } = usePanel()
    const { quickList } = calculatePurchases(currentList)
    const dataGridRef = useGridApiRef()
    const api = dataGridRef.current

    return(
        <>
            <SuperDataGrid
                dataGridRef={dataGridRef}
                onRowsDataMutation={(mutation) => updateStatusWithoutRepeating(currentList, mutation, setCurrentList)}
                title={title}
                onRowSelectionModelChange={() => onRowSelectionChange(api)}
                checkboxSelection
                fixedCells
                toolbar={() =>  <QuickList list={quickList} /> }
                loading={loading}
                columns={columns}
                rows={list}
                filterBy={(params) => `${(params?.provider?.name??"")} ${(params?.invoiceNumber??"")} ${(params?.consecutive??"")} ${t((params?.typePayment??""))}`}
            />
        </>
    )
}

export default memo(PurchasesList)