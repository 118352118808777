import { useGetSales } from "@feature/Reports/Sales/hooks/useSales"
import { currencyFormat } from "@util/currencyFormat"
import { groupArrangement, roundDecimals } from "@util/helpers"
import { useCallback, useMemo, useState } from "react"
import { Box } from '@component/UIComponents'
import { getInvoicePaymentType } from "@feature/Reports/Sales/utils/getInvoicePaymentType"
import { usePanel } from "@hook/usePanel"
import dayjs from "dayjs"
import ActionsList from "@component/ActionsList"
import { useSellerReportOptions } from "./useSellerReportOptions"

export const useSellerReportController = () => {
    const [componentProps, setComponentProps] = useState({
        sales: [],
        dataGrid: "seller_list", //seller_list - sales_list
        filtersGetSale: {
            startDate: dayjs().format("MM-DD-YYYY"),
            endDate: dayjs().format("MM-DD-YYYY"),
            invoiceNumber: "",
            identificationNumber: "",
            customer: "",
            city: "",
            neighborhood: "",
            businessType: "",
            branch: "",
            zone: "",
        },
        filtersActive: {
            startDate: dayjs().format("MM-DD-YYYY"),
            endDate: dayjs().format("MM-DD-YYYY"),
        },
        selectedInvoice: "",
    })

    const filtersActive = useMemo(() => ((componentProps?.filtersActive??{})), [componentProps])

    const { results } = useGetSales({ variant: "salesReport", invoiceType: "sale", ...filtersActive })
    
    const salesBySellers = useMemo(() => {
        let r = groupArrangement({
            array: results,
            groupOuting: (n) => (n?.seller?._id??""),
            firstExit: (n) => ({
                groupName: (n?.seller?._id??""),
                seller: `${(n?.seller?.name??"")} ${(n?.seller?.lastName??"")}`,
                total: (n?.total??0),
                totalProfitCurrency: (n?.totalProfitCurrency??0),
                totalProfitPercentage: (n?.totalProfitPercentage??0),
                results: [n]
            }),
            secondExit: (group, index, n) => {
                group[index].total += (n?.total??0)
                group[index].totalProfitCurrency += (n?.totalProfitCurrency??0)
                group[index].totalProfitPercentage = roundDecimals(((group[index].totalProfitCurrency/group[index].total)*100))
                group[index].results.push(n)
            }
        })
        r = r.map((n, index) => ({...n, _id: (index+1)}))
        return r
    }, [results])

    const uploadListWithSales = useCallback((ls) => {
        return setComponentProps((prevState) => {
            return {
                ...prevState,
                sales: ls,
                dataGrid: (ls.length) >= 1 ? "sales_list" : "seller_list"
            }
        })
    }, [])

    const updateFilters = useCallback((field, value) => {
        return setComponentProps((prevState) => {
            return {
                ...prevState,
                filtersGetSale: {
                    ...prevState.filtersGetSale,
                    [field]: value
                }
            }
        })
    }, [])

    const updateActiveFilters = useCallback((filters) => {
        return setComponentProps((prevState) => {
            return {
                ...prevState,
                filtersActive: filters
            }
        })
    }, [])
    
    const updateSelectedInvoice = useCallback((invoiceId="") => {
        return setComponentProps((prevState) => {
            return {
                ...prevState,
                selectedInvoice: invoiceId
            }
        })
    }, [])

    return {
        sales: results,
        salesBySellers,
        componentProps,
        setComponentProps,
        uploadListWithSales,
        updateFilters,
        updateActiveFilters,
        updateSelectedInvoice,
    }
}

export const useSellerReportDataGridController = ({ dataGrid }) => {
    const { getDateFormats } = usePanel()
    const { secondaryMenu } = useSellerReportOptions({})

    const Buttons = (props={}) => {
        const { onViewInvoice=()=>null, _id } = props
        return(
            <ActionsList
                variant="iconButton"
                options={secondaryMenu({
                    onViewInvoice: () => onViewInvoice(_id),
                })}
            />
        )
    }

    const columns = useCallback(({ onSellerClick=()=>null, onViewInvoice=()=>null }) => {
        let r = []
        if( dataGrid === "seller_list" ){
            r = [
                {flex: 1, maxWidth: 250, minWidth: 150, field: "seller", headerName: "Vendedor", renderCell: ({row}) => <Box component={'span'} sx={{ cursor: "pointer" }} onClick={() => onSellerClick(row)} > {`${(row?.seller??"")}`} </Box>},
                {minWidth: 80, maxWidth: 80, align: "center", field: "results", headerName: "Cantidad", renderCell: ({row}) => `${(row?.results?.length??0)}`},
                {minWidth: 150, maxWidth: 150, field: "total", headerName: "Venta", renderCell: ({row}) => `${currencyFormat((row?.total??0))}`},
                {minWidth: 150, maxWidth: 150, field: "totalProfitCurrency", headerName: "$", renderCell: ({row}) => `${currencyFormat((row?.totalProfitCurrency??0))}`},
                {minWidth: 80, maxWidth: 80, field: "totalProfitPercentage", headerName: "%", renderCell: ({row}) => `${(row?.totalProfitPercentage??"")}%`},
            ]
        }
        if(dataGrid === "sales_list"){
            r = [
                { maxWidth: 160, minWidth: 160, field: "seller", headerName: "Vendedor", renderCell: ({row}) => <> <Buttons {...row} onViewInvoice={onViewInvoice} /> <Box component={'span'} ml={1} >{`${(row?.seller?.name??"")} ${(row?.seller?.lastName??"")}`}</Box> </> },
                { flex: 1, minWidth: 220, maxWidth: 350, field: "customer", headerName: "Cliente", renderCell: ({row}) => `${(row?.customer?.name??"VARIOS")}` },
                { maxWidth: 80, minWidth: 80, align: "center", field: "consecutive", headerName: "#FAC", },
                { maxWidth: 120, minWidth: 120, field: "total", headerName: "Venta", renderCell: ({row}) => `${currencyFormat((row?.total??0))}`},
                { maxWidth: 120, minWidth: 120, field: "totalProfitCurrency", headerName: "$", renderCell: ({row}) => `${currencyFormat((row?.totalProfitCurrency??0))}`},
                { maxWidth: 60, minWidth: 60, align: "center", field: "totalProfitPercentage", headerName: "%", renderCell: ({row}) => `${(row?.totalProfitPercentage??0)} %`},
                {
                    maxWidth: 120,
                    minWidth: 120,
                    align: "center",
                    field: "typePayment",
                    headerName: "Tipo de pago",
                    renderCell: ({row}) => {
                        return `${getInvoicePaymentType((row??null))}`
                    }},
                { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de facturación", renderCell: ({row}) => `${getDateFormats((row?.createdAt??"")).mmddyyyyhhmm}`},
            ]
        }

        return r
    }, [dataGrid, getDateFormats])

    const quickList = useCallback((n) => {
        let total = n.reduce((acc, val) => ((val?.total??0)+acc),0)
        let totalProfitCurrency = n.reduce((acc, val) => ((val?.totalProfitCurrency??0)+acc),0)
        let totalProfitPercentage = roundDecimals(((totalProfitCurrency/total)*100))
        return [{
            primary: `${currencyFormat(total)} | ${currencyFormat(totalProfitCurrency)} | ${totalProfitPercentage}%`,
            secondary: "Total",
        }]
    }, [])

    return {
        columns,
        rows: [],
        quickList,
    }
}