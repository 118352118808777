import { List, ListItem, ListItemText, ListItemIcon, Avatar, styled, ClearIconRounded, Box } from '@component/UIComponents'
import { useState } from 'react'
import InvoicesOwedClient from './InvoicesOwedClient'

const ListItemTextMUI = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary' : {
        fontWeight: "bold",
        fontSize: ".875rem",
        lineHeight: 1,
        color: "#FFFFFF"
    },
    '& .MuiListItemText-secondary' : {
        fontWeight: "bold",
        fontSize: ".775rem",
        color: "#FFFFFF",
    },
}))

const CustomerItem = ({ customer, onRemoveCustomer=()=>null }) => {
    const [removeClient, setRemoveClient] = useState(false)
    const handleMouseEnterIcon = () => setRemoveClient(true)
    const handleMouseOutIcon = () => setRemoveClient(false)

    const handleRemoveCustomer = () => {
        setRemoveClient(false)
        onRemoveCustomer()
    }

    return(
        <>
            {Boolean(customer?._id) &&
                <List sx={{ padding: 0 }} >
                    <ListItem sx={{ paddingBottom: 0 }} >
                        <ListItemIcon sx={{ minWidth: 50 }} >
                            <Avatar
                                onClick={() => (removeClient === false) ? undefined : handleRemoveCustomer() }
                                sx={{
                                    bgcolor: (removeClient === false) ? undefined : (theme) => theme.palette.error.main,
                                    cursor: (removeClient === false) ? undefined : "pointer",
                                }}
                                onMouseOver={handleMouseEnterIcon}
                                onMouseOut={handleMouseOutIcon}
                            >
                                {removeClient === false ?
                                    (customer?.name[0]??"")
                                    :
                                    <ClearIconRounded />
                                }
                            </Avatar>
                        </ListItemIcon>
                        <ListItemTextMUI
                            primary={
                                <Box component={'span'} > {(customer?.name??"")} </Box>
                            }
                            secondary={
                                <>
                                    {(customer?.address??"")} <br/>
                                    <InvoicesOwedClient customerId={(customer?._id??null)} />
                                </>
                            }
                        />
                    </ListItem>
                </List>
            }
        </>
    )
}

export default CustomerItem