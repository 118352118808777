import { useIncludeOrdersInSalesReportsMutation } from "@query/BusinessSetting"
import { Toast } from "@util/swal"
import { useCallback } from "react"

export const useReportSetting = () => {
    const [ IncludeOrdersInSalesReportsMutation, { isLoading } ] = useIncludeOrdersInSalesReportsMutation()

    const onUpdateViewOrderInSalesReport = useCallback( async (payload) => {
        try {
            const data = await IncludeOrdersInSalesReportsMutation({reports: payload}).unwrap()
            const resp = (data?.payload??{})
            const { reports } = (resp??{})
            if( (reports?.showOrderInSalesReport) === true ) {
                Toast.fire({
                    icon: "success",
                    text: "Los pedidos se mostraran con las ventas en la tabla de Reporte de venta."
                })
            }else if((reports?.showOrderInSalesReport) === false){
                Toast.fire({
                    icon: "success",
                    text: "Los pedidos NO se mostraran con las ventas en la tabla de Reporte de venta."
                })
            }else{
                Toast.fire({
                    icon: "error",
                    text: "Los pedidos NO se mostraran con las ventas en la tabla de Reporte de venta."
                })
            }
        } catch (error) {
            const message = (error?.message??error?.data??error)
            Toast.fire({
                icon: "error",
                text: message
            })
            console.log(error)
        }
    }, [IncludeOrdersInSalesReportsMutation])

    return {
        onUpdateViewOrderInSalesReport,
        isLoadingViewOrderInSalesReport: isLoading,
    }
}