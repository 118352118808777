import { Box, Card, CardContent, DataGrid, Dialog, DialogContent, Divider, Grid, Typography, styled } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { Fragment, useMemo, useRef } from 'react'
import { useArchivePurchase, useGetPurchase, usePurchaseMenu } from '../hooks/usePurchase'
import { currencyFormat } from '@util/currencyFormat'
import { calculateTax, calculateTotal } from '@util/helpers'
import ActionsList from '@component/ActionsList'
import { useTranslation } from 'react-i18next'

const DataGridStyled = styled(DataGrid)(() => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "30px",
        maxHeight: "30px",
        lineHeight: "30px",
        border: "none",
    },
    '& .MuiDataGrid-cell--withRenderer': {
        fontSize: '.875rem'
    }
}))

const DataGridStyledTotalize = styled(DataGrid)(() => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "30px",
        maxHeight: "30px",
        lineHeight: "30px",
        border: "none",
        display: 'none'
    },
    '& .MuiDataGrid-cell--withRenderer': {
        fontSize: '.875rem'
    }
}))

const Invoice = ({ onUpdateDueDate=()=>null, viewInvoicePayments=()=>null, open=false, onClose=()=>null, purchaseId="", purchaseDefault=null }) => {
    const invoiceRef = useRef(null)
    let { purchase, isFetching } = useGetPurchase({ ref: purchaseId })
    purchase = Boolean(purchaseId) ? purchase : purchaseDefault
    purchase = Boolean(purchase) ? purchase : {}
    const { products=[], payments=[], provider=null, createdAt=undefined, consecutive=0, dateReceived=undefined, dueDate=undefined, billingDate=undefined, invoiceNumber=0, reteFuente=0, subTotal=0, tax=0, total=0, typePayment="", isArchived=false, haulier=null, freight=0, whoReceives="", user=null, totalInPayments=0, balance=0 } = purchase
    const { invoiceActions } = usePurchaseMenu({ invoiceRef })
    const { archivePurchase } = useArchivePurchase()
    const { t } = useTranslation()

    const { getDateFormats } = usePanel()
    const columns = useMemo(() => [
        {flex: 1, field: "name", headerName: "Producto", renderCell: ({ row }) => `${(row?.name??"")}` },
        {minWidth: 80, maxWidth: 80, align: "center", field: "quantity", headerName: "Cantidad", renderCell: ({ row }) => `x${(row?.quantity??0)}`},
        {minWidth: 110, maxWidth: 110, field: "purchaseCostUnit", headerName: "Cst. Unitario", renderCell: ({ row }) => currencyFormat((row?.purchaseCost??0)) },
        {minWidth: 120, maxWidth: 120, field: "purchaseCost", headerName: "Costo total", renderCell: ({ row }) => currencyFormat((row?.purchaseCost??0)*(row?.quantity??0)) },
        {minWidth: 120, maxWidth: 120, field: "tax", headerName: "IVA", renderCell: ({ row }) => currencyFormat( calculateTax({ purchaseCost : (row?.purchaseCost??0), quantity: (row?.quantity??0), tax: (row?.tax?.tax??0) }) )},
        {minWidth: 120, maxWidth: 120, field: "total", headerName: "Total", renderCell: ({ row }) => currencyFormat( calculateTotal({ purchaseCost : (row?.purchaseCost??0), quantity: (row?.quantity??0), tax: (row?.tax?.tax??0) }) )},
    ], [])
    
    const columnsTotalize = useMemo(() => [
        { flex: 1, field: "field" },
        { flex: 1, field: "value", align: "right" },
    ], [])
    
    const totalizeRows = useMemo(() => {
        let row = [
            {field: "Sub total", value: currencyFormat(subTotal)},
            {field: "IVA", value: currencyFormat(tax)},
            {field: "Retención en la fuente", value: currencyFormat(reteFuente)},
            {field: "Total", value: currencyFormat(total)},
        ]
        row = row.map((n, index) => ({...n, _id: index}))
        return row
    }, [reteFuente, subTotal, tax, total])

    const archivedPurchase = (props) => archivePurchase({archived: !(props?.isArchived??false), purchases: [(props?._id??null)]})

    const InformationInvoice = ({ primary, secondary }) => {
        return(
            <Typography display={'flex'} justifyContent={'space-between'} fontSize={'.875rem'} fontWeight={'500'} >
                <Box component={'span'} > {primary} </Box>
                <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > {secondary} </Box>
            </Typography>
        )
    }

    return(
        <>
            <Dialog PaperProps={{ elevation: 0 }} fullWidth open={open} onClose={onClose} maxWidth="lg" >
                <DialogContent>
                    <Grid container >
                        <Grid xs={9} >
                            <Box display={'flex'} justifyContent={'end'} >
                                <ActionsList
                                    variant='mobileMenu'
                                    options={invoiceActions(purchase, { seeInvoice: false, print: true, download: true, archivedPurchase, viewInvoicePayments, onUpdateDueDate, })}
                                    componentProps={{
                                        menuMobile: {
                                            props: {
                                                variant: "button",
                                            }
                                        }
                                    }}
                                />
                            </Box>
                            <Card elevation={0} variant='outlined' ref={invoiceRef} >
                                <CardContent>
                                    <Grid container spacing={1} >
                                        <Grid xs={12} >
                                            <Box display={'flex'} justifyContent={'space-between'} >
                                                <Typography fontSize={'.875rem'} fontWeight={'bold'} lineHeight={1} > Proveedor: <br /><Box component={'span'} > {(provider?.name??"")} </Box></Typography>
                                                <Typography fontSize={'.875rem'} fontWeight={'bold'} lineHeight={1} textAlign={'center'} > Fecha de registro del sistema: <br /><Box component={'span'} >{getDateFormats(createdAt).ddmmyyyyhhmm}</Box></Typography>
                                                <Typography fontSize={'.875rem'} fontWeight={'bold'} lineHeight={1} > Consecutivo: <br />#C<Box component={'span'} >{consecutive}</Box></Typography>
                                            </Box>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid xs={12} >
                                            <Box display={'flex'} justifyContent={'space-between'} >
                                                <Typography textAlign={'center'} fontWeight={'bold'} lineHeight={1} fontSize={'.875rem'} > Fecha de facturación: <br /><Box component={'span'} >{getDateFormats(billingDate).ddmmyyyy}</Box></Typography>
                                                <Typography textAlign={'center'} fontWeight={'bold'} lineHeight={1} fontSize={'.875rem'} > Fecha de recibido: <br /><Box component={'span'} >{getDateFormats(dateReceived).ddmmyyyy}</Box></Typography>
                                                <Typography textAlign={'center'} fontWeight={'bold'} lineHeight={1} fontSize={'.875rem'} > Fecha de vencimiento: <br /><Box component={'span'} >{getDateFormats(dueDate).ddmmyyyy}</Box></Typography>
                                                <Typography textAlign={'center'} fontWeight={'bold'} lineHeight={1} fontSize={'.875rem'} > Numero de factura: <br />#FAC<Box component={'span'} >{invoiceNumber}</Box></Typography>
                                            </Box>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid xs={12} >
                                            <DataGridStyled
                                                rowHeight={25}
                                                getRowId={(param) => param.productId}
                                                loading={isFetching}
                                                disableColumnMenu
                                                localeText={{
                                                    noRowsLabel: ""
                                                }}
                                                hideFooter
                                                autoHeight
                                                columns={columns}
                                                rows={products}
                                            />
                                        </Grid>
                                        <Grid xs={7} />
                                        <Grid xs={5} >
                                            <DataGridStyledTotalize
                                                rowHeight={25}
                                                getRowId={(param) => param._id}
                                                loading={isFetching}
                                                disableColumnMenu
                                                localeText={{
                                                    noRowsLabel: ""
                                                }}
                                                hideFooter
                                                autoHeight
                                                columns={columnsTotalize}
                                                rows={totalizeRows}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid xs={3} >
                            <Box px={1} >
                                <InformationInvoice primary={'Registrado por:'} secondary={`${user?.name??""} ${user?.lastName??""}`} />
                                { typePayment === "credit" &&
                                    <InformationInvoice primary={'Tipo de pago:'} secondary={'Crédito'} />
                                }
                                <InformationInvoice primary={'Estado de la factura:'} secondary={isArchived ? "Archivada" : "Disponible"} />
                                <InformationInvoice primary={'Transportista:'} secondary={(haulier?.name??"No asignado")} />
                                <InformationInvoice primary={'Flete:'} secondary={currencyFormat(freight??0)} />
                                <InformationInvoice primary={'Recibido por:'} secondary={whoReceives??"N/A"} />

                                <Typography mt={2} mb={1} lineHeight={1} fontSize={'.875rem'} fontWeight={'500'} textTransform={'uppercase'} >Pagos:</Typography>
                                {(payments.length <= 0) ?
                                    <>
                                        No se han realizado pagos
                                    </>
                                    :
                                    <>
                                        { payments.map((item, index) => {
                                            return(
                                                <Fragment key={index} >
                                                    <Box mb={1} >
                                                        <Typography display={'flex'} justifyContent={'space-between'} lineHeight={1} fontSize={'.875rem'} fontWeight={'500'} >
                                                            <Box component={'span'} > {t((item?.box??""))} </Box>
                                                            <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > {currencyFormat((item?.amount??0))} </Box>
                                                        </Typography>
                                                        <Typography display={'flex'} justifyContent={'space-between'} fontSize={'.875rem'} fontWeight={'500'} >
                                                            <Box component={'span'} > {getDateFormats((item?.createdAt??"")).ddmmyyyyhhmm} </Box>
                                                            <Box component={'span'} > {(item?.user?.name??"")} {(item?.user?.lastName??"")} </Box>
                                                        </Typography>
                                                        <Divider />
                                                    </Box>
                                                </Fragment>
                                            )
                                        }) }
                                        <InformationInvoice primary={'Pagos total:'} secondary={currencyFormat((totalInPayments??0))} />
                                        <InformationInvoice primary={'Saldo de la factura:'} secondary={currencyFormat((balance??0))} />
                                    </>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Invoice