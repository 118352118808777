import { CategoryIconRounded, Grid } from "@component/UIComponents"
import VerticalCustomList from "@component/VerticalCustomList"
import { useGetCategories } from "./hooks/useCategories"
import ActionsList from "@component/ActionsList"
import ListOfProductsByCategory from "./components/ListOfProductsByCategory"
import { useControllerCategories } from "./hooks/useControllerCategories"
import { useMenusCategories } from "./hooks/useMenusCategories"
import RegisterCategory from "./components/RegisterCategory"
import ComponentTitle from "@component/ComponentTitle"

const Categories = () => {
    const { primaryMenu } = useMenusCategories()
    const { setSelectedCategory, selectedCategory, showCreateCategoryForm, closeCategoryRegistrationForm, setShowCreateCategoryForm } = useControllerCategories()
    const { categories } = useGetCategories()

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Categorías" />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        options={primaryMenu({
                            hideMenus: ['editCategory', 'archiveCategory'],
                            onCreateCategory: () => setShowCreateCategoryForm({ category: null, open: true })
                        })}
                    />
                </Grid>
                <Grid xs={3} >
                    <VerticalCustomList
                        emptyListText="No se encontraron categorías para mostrar"
                        itemIcon={<CategoryIconRounded />}
                        list={categories}
                        onClick={(category) => setSelectedCategory(category)}
                    />
                </Grid>
                <Grid xs={9} >
                    <ListOfProductsByCategory
                        category={selectedCategory}
                        onEditCategory={() => setShowCreateCategoryForm({ category: selectedCategory, open: true })}
                    />
                </Grid>
            </Grid>
            <RegisterCategory open={showCreateCategoryForm.open} category={showCreateCategoryForm.category} onClose={() => closeCategoryRegistrationForm()} />
        </>
    )
}

export default Categories