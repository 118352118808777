import { Box, Dialog, DialogContent } from '@component/UIComponents'
import { useMemo } from 'react'
import { useGetPurchaseHistoryOfAProduct } from '../hooks/useSales'
import { groupValuesByDates, removeArrayRepeats, roundNumber } from '@util/helpers'
import { currencyFormat } from '@util/currencyFormat'
import SuperDataGrid from '@component/SuperDataGrid'
import dayjs from 'dayjs'

const HistoryOfSellingProductsToCustomers = ({ titleComplement="", open=false, onClose=()=>null, filters={}, variant="dialog", title="Historial de venta de productos" }) => {
    const { customerId=null, startDate="", endDate="" } = filters
    const { history, isFetching } = useGetPurchaseHistoryOfAProduct({ ...filters, variant: "historyOfSellingProductsToCustomers", }, {skip: !Boolean(customerId)})
    const { columnsDate, result, diffDates } = useMemo(() => {
        let n = groupValuesByDates(history, {
            sd: startDate,
            ed: endDate,
            itemName: (n) => (n?.name??""),
            itemKey: (n) => `${(n?.productId??"")}`,
            defaultValues: { quantity: 0, salePrice: 0},
            callback: (n, data) => {
                n.quantity = (data?.quantity??0)
                n.salePrice = (data?.salePrice??0)
                return n
            }
        })
        let validDates = (n?.result??[]).map((x) => {
            let b = {...x}
            delete b.name
            delete b._id
            let c = Object.keys(b)
            return c
        })
        validDates = validDates.reduce((acc, val) => [...acc, ...val,], [])
        validDates = removeArrayRepeats(validDates, { type: "single" })
        validDates = validDates.sort((a, b) => dayjs(b, "DD-MM-YYYY").toDate() - dayjs(a, "DD-MM-YYYY").toDate() )
        n.columnsDate = validDates
        n.diffDates = ([...(n?.columnsDate??[])]?.slice(1)??[]).map((date, index) => {
            const lastDate = (n?.columnsDate??[])[index]
            let diff = dayjs(date, "DD-MM-YYYY").diff(dayjs(lastDate, "DD-MM-YYYY"), 'day');
            diff = Math.abs(diff)
            return Number.isNaN(diff) ? 0 : diff
        })
        
        n.result = (n?.result??[]).map((p) => {
            let x = {...p}
            const dates = Object.keys(x).filter((d) => dayjs(d, "DD-MM-YYYY").isValid())
            let diffDatesByProduct = dates.slice(1).map((date, index) => {
                const lastDate = (n?.columnsDate??[])[index]
                let diff = dayjs(date, "DD-MM-YYYY").diff(dayjs(lastDate, "DD-MM-YYYY"), 'day');
                diff = Math.abs(diff)
                return Number.isNaN(diff) ? 0 : diff
            }).reduce((diff, acc) => (acc+diff),0)
            diffDatesByProduct = (diffDatesByProduct/dates.length)
            x.purchaseAverage = roundNumber(diffDatesByProduct)
            const mostRecentDate = (dates[0]??"")
            const daysOfAbsenceSinceLastPurchase = dayjs().diff(dayjs(mostRecentDate, "DD-MM-YYYY"), 'day')
            x.daysOfAbsence = roundNumber(daysOfAbsenceSinceLastPurchase)
            return x
        })
        

        return n
    }, [history, startDate, endDate])


    const columns = useMemo(() => {
        let position = 0
        let r = [
            { flex: 1, minWidth: 280, field: "name", headerName: "Producto"},
            // { maxWidth: 80, minWidth: 80, align: "center", field: "purchaseAverage", headerName: "Promedio de compra"},
            {
                maxWidth: 80,
                minWidth: 80,
                align: "center",
                field: "daysOfAbsence",
                headerName: "Dias de ausencia",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} >
                            {(row?.daysOfAbsence??0)}
                        </Box>
                    )
                }},
        ]
        for( let column of columnsDate ){
            r.push({
                maxWidth: 130,
                minWidth: 130,
                field: column,
                headerName: `${column} - ${Boolean(diffDates[position]) ? diffDates[position] : 0}`,
                renderCell: ({row}) => {
                    const quantity = (row[column]?.quantity??0)
                    return(
                        <Box fontWeight={'500'} component={'span'} sx={{ color: (theme) => (quantity >= 1) ? theme.palette.success.main : undefined }} >
                            {`x${quantity} | ${currencyFormat((row[column]?.salePrice??0))}`}
                        </Box>
                    )
                }
            })
            position = (position+1)
        }
        return r
    }, [columnsDate, diffDates])

    if( variant === "dialog" ){
        return(
            <>
                <Dialog maxWidth="lg" transitionDuration={0} PaperProps={{ elevation: 0 }} fullWidth open={open} onClose={onClose} >
                    <DialogContent>
                        <SuperDataGrid
                            pageSize={20}
                            title={`${title} ${Boolean(titleComplement) ? `- ${titleComplement}` : ""}`}
                            columnBuffer={((columns?.length??0)*2)}
                            fixedCells
                            columns={columns}
                            rows={isFetching ? [] : result}
                            loading={isFetching}
                            filterBy={(param) => `${(param?.name??"")}`}
                        />
                    </DialogContent>
                </Dialog>
            </>
        )
    }else{
        return(
            <>
                <SuperDataGrid
                    pageSize={20}
                    title={title}
                    columnBuffer={((columns?.length??0)*2)}
                    fixedCells
                    columns={columns}
                    rows={isFetching ? [] : result}
                    loading={isFetching}
                    filterBy={(param) => `${(param?.name??"")}`}
                />
            </>
        )
    }
}

export default HistoryOfSellingProductsToCustomers