import SuperDataGrid from "@component/SuperDataGrid"
import { Box, Grid, Stack } from "@component/UIComponents"
import { currencyFormat } from "@util/currencyFormat"
import { useNavigate } from "react-router-dom"
import { routes } from "@util/routes"
import { useBestSeller } from "./hooks/useBestSeller"
import ExportData from "@component/ExportData"
import ComponentTitle from "@component/ComponentTitle"
const { productHistory } = routes

const BestSeller = () => {
    const { FinalDate, InitialDate, TotalizeRows, toExport } = useBestSeller()
    const navigate = useNavigate()
    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Mas Vendidos" />
            <Grid container >
                <Grid xs={12} >
                    <SuperDataGrid
                        toolbar={() => <Stack direction={'row'} >
                            <ExportData {...toExport} />
                        </Stack> }
                        columns={[
                            {
                                minWidth: 250,
                                field: "name",
                                headerName: "Producto",
                                renderCell: ({ row }) => {
                                    const { name="", cellType="" } = (row??{})
                                    const value = name
                                    const Value = () => (cellType === "totalize") ? <Box fontWeight={'bold'} sx={{ WebkitTextStroke: .5 }} >{value}</Box> : <>{value}</>
                                    return <Value />
                                }},
                            {
                                minWidth: 100,
                                field: "InitialStock",
                                headerName: "Stock",
                                renderCell: ({ row }) => {
                                    const { InitialStock=0, cellType="" } = (row??{})
                                    const value = currencyFormat(InitialStock, { applyCurrencyFormat: false })
                                    const Value = () => (cellType === "totalize") ? <Box fontWeight={'bold'} sx={{ WebkitTextStroke: .5 }} >{value}</Box> : <>{value}</>
                                    return <Value />
                                }
                            },
                            {
                                minWidth: 100,
                                field: "shopping",
                                headerName: "Compro",
                                renderCell: ({ row }) => {
                                    const { shopping=0, cellType="" } = (row??{})
                                    const value = currencyFormat(shopping, { applyCurrencyFormat: false })
                                    const Value = () => (cellType === "totalize") ? <Box fontWeight={'bold'} sx={{ WebkitTextStroke: .5 }} >{value}</Box> : <>{value}</>
                                    return <Value />
                                }
                            },
                            {
                                minWidth: 100,
                                field: "sold",
                                headerName: "Vendió",
                                renderCell: ({ row }) => {
                                    const { sold=0, cellType="" } = (row??{})
                                    const value = currencyFormat(sold, { applyCurrencyFormat: false })
                                    const Value = () => (cellType === "totalize") ? <Box fontWeight={'bold'} sx={{ WebkitTextStroke: .5 }} >{value}</Box> : <>{value}</>
                                    return <Value />
                                }
                            },
                            {
                                minWidth: 100,
                                field: "remaining",
                                headerName: "Quedo",
                                renderCell: ({ row }) => {
                                    const { remaining=0, cellType="" } = (row??{})
                                    const value = currencyFormat(remaining, { applyCurrencyFormat: false })
                                    const Value = () => (cellType === "totalize") ? <Box fontWeight={'bold'} sx={{ WebkitTextStroke: .5 }} >{value}</Box> : <>{value}</>
                                    return <Value />
                                }
                            },
                            {
                                minWidth: 150, 
                                field: "cost", 
                                headerName: "Costo", 
                                renderCell: ({ row }) => {
                                    const { cost=0, cellType="" } = (row??{})
                                    const value = currencyFormat(cost)
                                    const Value = () => (cellType === "totalize") ? <Box fontWeight={'bold'} sx={{ WebkitTextStroke: .5 }} >{value}</Box> : <>{value}</>
                                    return <Value />
                                }
                            },
                            {
                                minWidth: 150, 
                                field: "sale", 
                                headerName: "Venta", 
                                renderCell: ({ row }) => {
                                    const { sale=0, cellType="" } = (row??{})
                                    const value = currencyFormat(sale)
                                    const Value = () => (cellType === "totalize") ? <Box fontWeight={'bold'} sx={{ WebkitTextStroke: .5 }} >{value}</Box> : <>{value}</>
                                    return <Value />
                                }
                            },
                            {
                                minWidth: 150, 
                                field: "profit", 
                                headerName: "Ganancia", 
                                renderCell: ({ row }) => {
                                    const { profit=0, cellType="" } = (row??{})
                                    const value = currencyFormat(profit)
                                    const Value = () => (cellType === "totalize") ? <Box fontWeight={'bold'} sx={{ WebkitTextStroke: .5 }} >{value}</Box> : <>{value}</>
                                    return <Value />
                                }
                            },
                        ]}
                        rows={TotalizeRows}
                        filterBy={(param) => `${(param?.name??"")}`}
                        onRowClick={({ row }) => (row?.cellType === "totalize") ? null : navigate(`${productHistory()}?InitialDate=${InitialDate}&FinalDate=${FinalDate}&ItemID=${row.ProductID}`)}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default BestSeller