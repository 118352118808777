import { groupArrangement, objectGetValue } from "@util/helpers"
import { useCallback } from "react"

export const useCustomersController = () => {}

export const useCustomersUtils = () => {

    const groupClients = useCallback(({ array=[], field="", firstExit=()=>({}), secondExit }) => {
        let resp = null
        if( Boolean(field) ){
            resp = groupArrangement({
                array,
                groupOuting:(n) => objectGetValue(n, field),
                firstExit: (n) => ({
                    groupName: objectGetValue(n, field),
                    ...firstExit(n)
                }),
                secondExit
            })
            resp = resp.filter((b) => Boolean(b?.groupName))
            resp = resp.map((n, index) => ({...n, _id: (index+1)}))
        }else{
            resp = array
        }
        return resp
    }, [])

    return {
        groupClients
    }
}