import { Checkbox, DatePicker, InsertLinkRoundedIcon, LinkOffRoundedIcon, Tooltip } from "@component/UIComponents"
import styled from "@emotion/styled"

const DatePickerStyled = styled(DatePicker)(({ theme }) => ({
    width: '100%',
    minWidth: "200px",
    maxWidth: "200px",
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none"
    },
    '& .MuiInputBase-input': {
        fontWeight: 'bold'
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main
    },
}))

const DateRange = ({ sd=undefined, ed=undefined, linkDate=false, onChange=()=>null, setLinkDate=()=>null }) => {

    const handleDateRangeChange = (field="", value=null) => {
        if( Boolean(onChange) ){
            onChange(field, value)
        }
    }

    return(
        <>
            <DatePickerStyled
                format='DD/MM/YYYY'
                value={sd}
                label="Desde"
                slotProps={{ textField: { size: "small", } }}
                onChange={(date) => handleDateRangeChange("sd", date) }
            />
            <DatePickerStyled
                format='DD/MM/YYYY'
                value={ed}
                label="Hasta"
                slotProps={{ textField: { size: "small", } }}
                onChange={(date) => handleDateRangeChange("ed", date) }
            />
            <Tooltip title="Enlazar fechas" arrow >
                <Checkbox
                    onChange={({ target }) => setLinkDate(target.checked) }
                    size='small'
                    icon={<InsertLinkRoundedIcon />}
                    checkedIcon={<LinkOffRoundedIcon />}
                    checked={linkDate}
                />
            </Tooltip>
        </>
    )
}

export default DateRange