import { useCallback } from "react"
import { useCreditsOptions } from "./useCreditsOptions"
import { useSalesController } from "@feature/Reports/Sales/hooks/useSalesController"
import { arrayReducer, populateArrayFromQuery, validateExpiredDate } from "@util/helpers"
import { useMemo } from "react"
import { currencyFormat } from "@util/currencyFormat"

export const useCreditsController = () => {
    const { activeFilters } = useSalesController()
    const { typesOfCredit:typesOfCreditList } = useCreditsOptions()

    const typesOfCreditActive = useMemo(() => {
        const { typesOfCredit=null } = activeFilters
        const r = populateArrayFromQuery(typesOfCreditList(), { query: typesOfCredit, separator: ",", compare: (n) => (n?.name??"") })
        return r
    },[activeFilters, typesOfCreditList])

    const filterByExpirationDate = useCallback((credits=[]) => {
        let resp = [...credits]
        if( Array.isArray(typesOfCreditActive) && typesOfCreditActive.length >= 1 ){
            const defeat = typesOfCreditActive.some((n) => (n?.value??"") === "defeated")
            const active = typesOfCreditActive.some((n) => (n?.value??"") === "active")
            if( defeat && !active ){
                resp = credits.filter((n) => validateExpiredDate({ date: (n?.creditExpirationDate??"") }) )
            }
            if( !defeat && active ){
                resp = credits.filter((n) => !validateExpiredDate({ date: (n?.creditExpirationDate??"") }))
            }
        }
        return resp
    }, [typesOfCreditActive])

    const invoiceBalanceFilter = useCallback((credits=[]) => {
        let resp = [...credits]
        if( Array.isArray(typesOfCreditActive) && typesOfCreditActive.length >= 1 ){
            const pending = typesOfCreditActive.some((n) => (n?.value??"") === "pending")
            const payment = typesOfCreditActive.some((n) => (n?.value??"") === "payment")
            if( pending && !payment ){
                resp = credits.filter((n) => (n?.balance??0) >= 1 )
            }
            if( !pending && payment ){
                resp = credits.filter((n) => (n?.balance??0) <= 0 )
            }
        }
        return resp
    }, [typesOfCreditActive])

    const filtersCredit = useCallback((credits=[]) => {
        let resp = []
        resp = filterByExpirationDate(credits)
        resp = invoiceBalanceFilter(resp)
        return resp
    }, [filterByExpirationDate, invoiceBalanceFilter])

    return {
        filtersCredit
    }
}


export const useCreditsControllerDataGrid = () => {

    const quickList = useCallback((rows=[]) => {
        const sx = {
            minWidth: 230,
            '& .MuiListItemText-secondary': {
                '&:hover': {
                    textDecoration: "underline",
                    cursor: "pointer"
                }
            }
        }
        const total = arrayReducer({ list: rows, reducer: (n) => (n?.total??0) })
        const pendingCredits = arrayReducer({ list: rows, reducer: (n) => (n?.balance??0) })
        const creditsPaid = arrayReducer({ list: rows, reducer: (n) => (n?.totalPayments??0) })
        const expiredCredits = arrayReducer({ list: rows, reducer: (n) => validateExpiredDate({ date: (n?.creditExpirationDate??"") }) ? 1 : 0 })
        const currentCredits = arrayReducer({ list: rows, reducer: (n) => !validateExpiredDate({ date: (n?.creditExpirationDate??"") }) ? 1 : 0 })

        let results = [
            { primary: `${currencyFormat(total)}`, secondary: "Total créditos", sx },
            { primary: `${currencyFormat(creditsPaid)}`, secondary: "Total abonos realizados", sx },
            { primary: `${currencyFormat(pendingCredits)}`, secondary: "Total pendiente por cobrar", sx },
            { primary: `${(expiredCredits+currentCredits)}`, secondary: "Créditos registrados", sx },
            { primary: `${expiredCredits}`, secondary: "Créditos vencidos", sx },
            { primary: `${currentCredits}`, secondary: "Créditos vigentes", sx },
        ]

        return results
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        quickList
    }
}