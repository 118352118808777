import ActionsList from "@component/ActionsList"
import SuperDataGrid from "@component/SuperDataGrid"
import { Grid } from "@component/UIComponents"

const Branches = () => {
    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <ActionsList

                    />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        columns={[]}
                        rows={[]}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Branches