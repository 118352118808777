import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useMemo } from "react"

export const useUserInformationController = () => {

}
export const useUserInformationFormDataController = (config={}) => {
    const { defaultValues=undefined } = config
    const initialValues = useMemo(() => ({ name: "", lastName: "", email: "", phone: "", password: "" }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre del usuario es un campo obligatorio."),
            email: yup.string().required("El correo del usuario es un campo obligatorio."),
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}