import Autocomplete from '@component/Autocomplete'
import NumericFormat from '@component/NumericFormat'
import { Alert, Button, Dialog, DialogActions, DialogContent, Grid } from '@component/UIComponents'
import DeliveryFinder from '@feature/Deliveries/components/DeliveryFinder'
import { useDeliveryInformationFormController } from './hooks/useDeliveryInformationFormController'
import { useDeliveryOptions } from './hooks/useDeliveryOptions'
import { useDeliveryInformationController } from './hooks/useDeliveryInformationController'
import Form from '@component/Form'
import { useRegisterHomeDeliveryInvoice } from './hooks/useRegisterHomeDeliveryInvoice'
import { useGetHomeDelivery } from '@feature/Deliveries/hooks/useGetHomeDelivery'

const FormAddDeliveryInformation = ({ invoiceRef=null, open=false, onClose=()=>null }) => {
    const { delivery:reqDelivery } = useGetHomeDelivery({ invoiceRef }, { skip: !Boolean(invoiceRef) })
    const { registerHomeDeliveryInvoice, isLoading } = useRegisterHomeDeliveryInvoice()
    const { headerInformationAboutPayment, amountInputConfig } = useDeliveryInformationController()
    const { deliveryPaymentMethods } = useDeliveryOptions()
    const { values, setFieldValue, submitValues } = useDeliveryInformationFormController({ onSubmit: registerHomeDeliveryInvoice, invoiceRef })
    const { delivery, deliveryPaymentMethod, deliveryAmount } = values
    const infoPaymentAlert = headerInformationAboutPayment((deliveryPaymentMethod?.value??""))
    const amountConfig = amountInputConfig((deliveryPaymentMethod?.value??""))

    return(
        <>
            <Dialog maxWidth="xs" fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Form onSubmit={submitValues} >
                    { Boolean(reqDelivery?._id) ?
                        <>
                            <DialogContent>
                                <Alert severity='warning' > La factura ya cuenta con un domicilio registrado. </Alert>
                            </DialogContent>
                        </>
                        :
                        <>
                            <DialogContent>
                                <Grid container spacing={1} >
                                    { Boolean(infoPaymentAlert) &&
                                        <Grid xs={12} >
                                            <Alert severity='info' > {infoPaymentAlert} </Alert>
                                        </Grid>
                                    }
                                    <Grid xs={12} >
                                        <DeliveryFinder
                                            value={delivery}
                                            onChange={(_, param) => setFieldValue("delivery", param)}
                                        />
                                    </Grid>
                                    <Grid xs={12} >
                                        <Autocomplete
                                            label="Modo de pago del domicilio"
                                            options={deliveryPaymentMethods()}
                                            value={deliveryPaymentMethod}
                                            onChange={(_, param) => setFieldValue("deliveryPaymentMethod", param)}
                                        />
                                    </Grid>
                                    <Grid xs={12} >
                                        <NumericFormat
                                            label="Monto"
                                            fullWidth
                                            value={deliveryAmount}
                                            onChange={({ floatValue }) => setFieldValue("deliveryAmount", floatValue)}
                                            maxVal={amountConfig.maxAmount}
                                            helperText={amountConfig.amountHelperText}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button type='submit' loading={isLoading} >
                                    Guardar
                                </Button>
                            </DialogActions>
                        </>
                    }
                </Form>
            </Dialog>
        </>
    )
}

export default FormAddDeliveryInformation