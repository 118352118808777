import { useState } from "react";

export const usePreSelectionTimeOut = () => {
    const [timeoutId, setTimeoutId] = useState(null);

    const clearTimeOutFunction = () => {
        if ( Boolean(timeoutId) ) {
            clearTimeout(timeoutId);
        }
    }
    const activeTimeOut = (onTimeOut=undefined) => {
        if( Boolean(onTimeOut) ){
            const newTimeoutId = setTimeout(onTimeOut, 1000);
            setTimeoutId(newTimeoutId);
        }
    }

    return {
        clearTimeOutFunction,
        activeTimeOut
    }
}

export const useNumericFormat = () => {
    const [triggerEraser, setTriggerEraser] = useState(true)
    const { activeTimeOut, clearTimeOutFunction } = usePreSelectionTimeOut()

    const getCursorPositionInInput = (inputValue="", event=null) => {
        const cursorPosition = (event?.target?.selectionStart??null)
        const countStringValue = [...inputValue].length
        const isLastPosition = (countStringValue===cursorPosition)

        return { cursorPosition, countStringValue, isLastPosition }
    }

    const replaceInputValue = ({value, lastPosition}) => {
        if( triggerEraser === true ){
            setTriggerEraser(false)
            // setFieldValue(inputName, "")
            if( lastPosition ){
                value = [...String(value)]
                value = [value[value.length-1]]
                value = parseFloat(value.join(""))
                value = Boolean(value) ? value : 0
                return value
            }
            return value
        }else{
            return value
        }
    }

    const handleInputChange = ({values={}, event=null}) => {
        clearTimeOutFunction()
        const { formattedValue, floatValue } = values
        const { isLastPosition } = getCursorPositionInInput(formattedValue, event)
        const r = replaceInputValue({ lastPosition: isLastPosition, value: floatValue })
        activeTimeOut(() => {
            setTriggerEraser(true)
        })
        return r
    }

    return {
        handleInputChange
    }
}