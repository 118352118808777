import { Dialog, DialogContent, Divider, List, ListItemButton, ListItemIcon, ListItemText, ReceiptLongRoundedIcon, Typography, styled } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'
import { Fragment } from 'react'

const ListStyled = styled(List)(({ theme }) => ({
    '& .MuiButtonBase-root': {
        padding: 0
    },
    '& .MuiListItemIcon-root': {
        minWidth: 40
    },
    '& .MuiListItemText-primary': {
        fontSize: "1rem",
        fontWeight: "bold",
    },
    '& .MuiListItemText-secondary': {
        fontSize: ".875rem",
        fontWeight: "500",
    },
    '& .MuiSvgIcon-root': {
        fontSize: "2rem",
        color: theme.palette.primary.main
    },
}))

const CustomerInvoices = ({ open=false, onClose=()=>null, invoices=[], onSelectedInvoice=()=>null }) => {
    const { getDateFormats } = usePanel()
    const customer = (invoices[0]?.customer?.name??"")
    return(
        <>
            <Dialog maxWidth="xs" fullWidth transitionDuration={0} PaperProps={{ elevation: 0 }} open={open} onClose={onClose} >
                <DialogContent>
                    <Typography fontWeight={'bold'} fontSize={'.875rem'} >{ customer }</Typography>
                    <ListStyled>
                        { invoices.map((invoice, index) => {
                            return(
                                <Fragment key={index} >
                                    <ListItemButton onClick={()=>onSelectedInvoice((invoice?._id??null))} >
                                        <ListItemIcon>
                                            <ReceiptLongRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${currencyFormat((invoice?.total??0))}`}
                                            secondary={`#FAC ${(invoice?.consecutive??0)} - ${getDateFormats((invoice?.createdAt??"")).fullDate} - ${getDateFormats((invoice?.createdAt??"")).hhmm}`}
                                        />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            )
                        }) }
                    </ListStyled>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CustomerInvoices