import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useMemo } from "react"

export const usePurchaseOrderFormData = (props = {}) => {
    const { defaultValues = undefined } = props
    const { schema } = useProductPreSelectionFormData()
    const initialValues = useMemo(() => ({ provider: null, products: [], total: 0 }), [])

    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            provider: yup.object().shape({
                _id: yup.string().required("El embalaje es requerido.")
            }),
            products: yup.array().min(1, "Añada al menos un producto a la lista antes de continuar.").of(yup.object().shape(schema)).required("Añada al menos un producto a la lista antes de continuar."),
            total: yup.number().min(1, "Añada al menos un producto a la lista antes de continuar.")
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}

export const useProductPreSelectionFormData = (props = {}) => {
    const { defaultValues = undefined } = props

    const initialValues = useMemo(() => ({ packaging: null, purchaseCost: 0, quantity: 1 }), [])
    const schema = useMemo(() => {
        return {
            productId: yup.string().required("No fue posible cargar el identificador del producto, cierre la ventana he intente cargar el producto de nuevo."),
            packaging: yup.object().shape({
                _id: yup.string().required("El embalaje es requerido.")
            }),
            purchaseCost: yup.number().min(1, "El costo es requerido.").required("El costo es requerido."),
            quantity: yup.number().min(1, "La cantidad es requerida.").required("La cantidad es requerida.")
        }
    }, [])

    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema
    })
    return { values, setFieldValue, resetValues, validateValues, schema }
}