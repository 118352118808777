import { arrayReducer, roundNumber } from "@util/helpers"
import { useCallback } from "react"

export const useInvoiceController = () => {

    const calculateProfit = useCallback(({ sale=0, cost=0 }) => {
        let profit = (sale-cost)
        return profit
    }, [])

    const calculateMargin = useCallback(({ sale=0, profit=0 }) => {
        const margin = ((profit/sale)*100)
        return margin
    }, [])

    const calculateTotalSalesInvoice = useCallback(({ products=[] }) => {
        const totalCostPrice = arrayReducer({ list: products, reducer: (val) => ((val?.costPrice??0)*(val?.quantity??0)) })
        const totalSalePrice = arrayReducer({ list: products, reducer: (val) => ((val?.salePrice??0)*(val?.quantity??0)) })
        const totalProfitCurrency = calculateProfit({ cost: totalCostPrice, sale: totalSalePrice })
        return {
            total: roundNumber(totalSalePrice),
            totalProfitCurrency: roundNumber(totalProfitCurrency),
            totalProfitPercentage: roundNumber(calculateMargin({ profit: totalProfitCurrency, sale: totalSalePrice })),
        }
    }, [calculateProfit, calculateMargin])

    return {
        calculateProfit,
        calculateMargin,
        calculateTotalSalesInvoice,
    }
}