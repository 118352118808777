import { Box, Typography } from '@component/UIComponents'
import clsx from 'clsx'

const Title = ({ text="", children }) => {
    return(
        <Box py={1} mx={2} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2} >
            <Typography fontSize={'1.2rem'} fontWeight={500} >
                {text}
            </Typography>
            {children}
        </Box>
    )
}

export default Title

export const Text = ({ children=<></>, variant="", sx={} }) => {
    const variants = clsx({
        "Typography-FormTitle": (variant === "form-title")
    })

    return(
        <>
            <Typography className={variants} pt={1} px={2} sx={{...sx}} >
                {children}
            </Typography>
        </>
    )
}