import Autocomplete from '@component/Autocomplete'
import { Button, Dialog, DialogActions, DialogContent } from '@component/UIComponents'
import { useProductOptions } from '../hooks/useProducts'
import { useState } from 'react'
import Form from '@component/Form'
import { useTranslation } from 'react-i18next'
import { createBook } from '@util/xlsx'

const defaultFields = ["consecutive", "product", "category", "packaging", "stock", "skuCode", "cost"]

const DownloadProductUploadTemplate = ({ open=false, onClose=()=>null }) => {
    const { t } = useTranslation()
    const [fieldSelected, setFieldSelected] = useState("")
    const { fourthOption } = useProductOptions()
    const handleChange = (props=[]) => {
        let query = (props?.map((n) => (n?.value??""))).join(",")
        setFieldSelected(query)
    }

    const handleDownload = () => {
        let currentDefaultFields = fieldSelected.split(",")
        currentDefaultFields = currentDefaultFields.filter((n) => Boolean(n))
        let fields = [...defaultFields, ...currentDefaultFields]
        fields = fields.map((n) => t(n))
        createBook({ columns: fields, fileName: "Plantilla de carga masiva de productos.xlsx" })
        onClose()
    }

    return(
        <>
            <Dialog maxWidth="xs" transitionDuration={0} PaperProps={{ elevation: 0 }} fullWidth open={open} onClose={onClose} >
                <Form onSubmit={handleDownload} >
                    <DialogContent>
                        <Autocomplete
                            multiple
                            queryValue={fieldSelected}
                            label="Campos"
                            options={fourthOption}
                            onChange={(_, val) => handleChange(val)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' >
                            Descargar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default DownloadProductUploadTemplate