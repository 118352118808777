import { useGetReceivedSalesInvoicesQuery } from "@query/Sales"
import { useCallback, useMemo } from "react"

export const useGetReceivedSalesInvoices = (props={}, config={}) => {
    const { data, isFetching } = useGetReceivedSalesInvoicesQuery(props, config)
    const receiptSalesInvoices = useMemo(() => (data?.payload??[]),[data])

    const getReceiptSaleInvoice = useCallback(({ findBy=()=>"", val }) => {
        let receiptSaleInvoice = receiptSalesInvoices.find((n) => findBy(n) === val ) || null
        return receiptSaleInvoice
    }, [receiptSalesInvoices])

    return {
        receiptSalesInvoices,
        isFetching,
        getReceiptSaleInvoice
    }
}