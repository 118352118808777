import { Box, Divider, ReceiptLongRoundedIcon, Typography, createFilterOptions } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '@util/currencyFormat'
import { createCookie, getCookie } from '@util/helpers'
import { usePanel } from '@hook/usePanel'
import { Toast } from '@util/swal'

const SavedSales = ({ onChangeTab=()=>null, onRecoverSavedInvoice=()=>null }) => {
    const { t } = useTranslation()
    const savedProductList = (getCookie("savedProductList", { convertJSONstringify: true })??[])
    const { getDateFormats } = usePanel()

    const filterOptions = createFilterOptions({
        stringify: (option) => `${t((option?.customer?.name??"some"))} ${option.total}`,
    });

    const handleKeyDown = (event) => {
        const { code } = event
        if( code === "Tab" ){
            event.preventDefault()
            onChangeTab("undefinedSale")
        }
    }

    const handleRecoverSavedInvoice = (param) => {
        let currentSavedProductList = [...savedProductList]
        const index = currentSavedProductList.findIndex((n) => n?._id === param?._id)
        onRecoverSavedInvoice("customer", (param?.customer??null))
        onRecoverSavedInvoice("products", (param?.products??[]))
        currentSavedProductList.splice(index, 1)
        currentSavedProductList = currentSavedProductList.map((n, index) => ({...n, _id: (index+1)}))
        createCookie({ name: "savedProductList", value: currentSavedProductList, convertJSONstringify: true })
        Toast.fire({
            icon: "success",
            text: "La factura se recupero exitosamente."
        })
    }

    return(
        <>
        <Box mt={1} >
            <Autocomplete
                onChange={(_, param) => handleRecoverSavedInvoice(param)}
                onKeyDown={handleKeyDown}
                open={true}
                slotProps={
                    {paper: {
                        elevation: 0
                    }}
                }
                filterOptions={filterOptions}
                placeholder="Guardadas..."
                options={savedProductList}
                renderOption={(props, option) => {
                    return(
                        <li {...props} key={option._id} >
                            <Box width={'100%'} >
                                <Box display={'flex'} alignItems={'center'} >
                                    <Box width={'100%'} >
                                        <Typography sx={{ color: (theme) => theme.palette.primary.main }} lineHeight={1.1} fontSize={'.875rem'} fontWeight={'bold'} > Cliente: <Box component={'span'} sx={{ color: "#000" }} > {t((option?.customer?.name??"some"))} </Box> </Typography>
                                        <Typography sx={{ color: (theme) => theme.palette.primary.main }} lineHeight={1.1} fontSize={'.875rem'} fontWeight={'bold'} > Total: <Box component={'span'} sx={{ color: "#000" }} > {currencyFormat((option?.total??0))} </Box> </Typography>
                                        <Typography lineHeight={1.3} fontSize={'.875rem'} fontWeight={'bold'} > Hora de guardado: <Box component={'span'} sx={{ color: "#000" }} > { getDateFormats(option?.createdAt??"").hhmm } </Box> </Typography>
                                    </Box>
                                    <Box>
                                        <ReceiptLongRoundedIcon />
                                    </Box>
                                </Box>
                                <Divider />
                            </Box>
                        </li>
                    )
                }}
            />
        </Box>
        </>
    )
}

export default SavedSales