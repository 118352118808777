import { Box, Card, CardContent, DataGrid, Dialog, DialogContent, Divider, Grid, Typography, styled } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { useMemo, useRef } from 'react'
import { currencyFormat } from '@util/currencyFormat'
import ActionsList from '@component/ActionsList'
import { useGetOrderSupplier } from '../OrderEntry/hooks/useGetOrderSupplier'
import { useOrderInvoiceMenus } from './hooks/useOrderInvoiceMenus'
import PurchaseOrderSummary from './components/PurchaseOrderSummary'

const DataGridStyled = styled(DataGrid)(() => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "30px",
        maxHeight: "30px",
        lineHeight: "30px",
        border: "none",
    },
    '& .MuiDataGrid-cell--withRenderer': {
        fontSize: '.875rem'
    }
}))

const DataGridStyledTotalize = styled(DataGrid)(() => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "30px",
        maxHeight: "30px",
        lineHeight: "30px",
        border: "none",
        display: 'none'
    },
    '& .MuiDataGrid-cell--withRenderer': {
        fontSize: '.875rem'
    }
}))

const OrderInvoice = ({ open=false, onClose=()=>null, purchaseOrderId="" }) => {
    const { order, isFetchingPurchaseOrder } = useGetOrderSupplier({ refId: purchaseOrderId })
    const invoiceRef = useRef(null)
    const purchaseOrderSummaryRef = useRef(null)
    const { products=[], provider=null, createdAt=undefined, consecutive=0, total=0, user=null } = (order??{})
    const { primaryMenu } = useOrderInvoiceMenus({ invoiceRef, purchaseOrderSummaryRef })

    const { getDateFormats } = usePanel()
    const columns = useMemo(() => [
        {flex: 1, field: "name", headerName: "Producto", renderCell: ({ row }) => `${(row?.name??"")}` },
        {minWidth: 100, maxWidth: 100, field: "packaging", headerName: "Embalaje", renderCell: ({ row }) => `${(row?.packaging?.name??"")}`},
        {minWidth: 100, maxWidth: 100, align: "center", field: "quantity", headerName: "Cantidad", renderCell: ({ row }) => `x${(row?.quantity??0)}`},
        {minWidth: 110, maxWidth: 110, field: "purchaseCost", headerName: "Costo", renderCell: ({ row }) => currencyFormat((row?.purchaseCost??0)) },
        {minWidth: 120, maxWidth: 120, field: "purchaseCostTotal", headerName: "Total", renderCell: ({ row }) => currencyFormat((row?.purchaseCost??0)*(row?.quantity??0)) },
    ], [])

    const columnsTotalize = useMemo(() => [
        { flex: 1, field: "field" },
        { flex: 1, field: "value", align: "right" },
    ], [])

    const totalizeRows = useMemo(() => {
        let row = [
            {field: "Total", value: currencyFormat(total)},
        ]
        row = row.map((n, index) => ({...n, _id: index}))
        return row
    }, [total])

    return(
        <>
            <Dialog PaperProps={{ elevation: 0 }} fullWidth open={open} onClose={onClose} maxWidth="lg" >
                <DialogContent>
                    <Grid container >
                        <Grid xs={12} >
                            <ActionsList options={primaryMenu(order)} />
                            <Card elevation={0} variant='outlined' ref={invoiceRef} >
                                <CardContent>
                                    <Grid container spacing={1} >
                                        <Grid xs={12} >
                                            <Box>
                                                <Grid container >
                                                    <Grid xs={4} >
                                                        <Typography fontSize={'.875rem'} fontWeight={'bold'} lineHeight={1} > Proveedor: <br /><Box component={'span'} > {(provider?.name??"")} </Box></Typography>
                                                    </Grid>
                                                    <Grid xs={4} >
                                                        <Typography fontSize={'.875rem'} fontWeight={'bold'} lineHeight={1} textAlign={'center'} > Fecha de registro: <br /><Box component={'span'} >{getDateFormats(createdAt).ddmmyyyyhhmm}</Box></Typography>
                                                    </Grid>
                                                    <Grid xs={4} >
                                                        <Typography textAlign={'right'} fontSize={'.875rem'} fontWeight={'bold'} lineHeight={1} > Consecutivo: <br />#P<Box component={'span'} >{consecutive}</Box></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid xs={12} >
                                            <Typography textAlign={'center'} fontWeight={'bold'} lineHeight={1} fontSize={'1.2rem'} textTransform={'uppercase'} > Orden de pedido </Typography>
                                            <Typography fontWeight={'bold'} lineHeight={1} fontSize={'.775rem'} textTransform={'uppercase'} > registrado por: {(user?.name??"")} {(user?.lastName??"")} </Typography>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid xs={12} >
                                            <DataGridStyled
                                                rowHeight={25}
                                                getRowId={(param) => param.productId}
                                                loading={isFetchingPurchaseOrder}
                                                disableColumnMenu
                                                localeText={{
                                                    noRowsLabel: ""
                                                }}
                                                hideFooter
                                                autoHeight
                                                columns={columns}
                                                rows={products}
                                            />
                                        </Grid>
                                        <Grid xs={9} />
                                        <Grid xs={3} >
                                            <DataGridStyledTotalize
                                                rowHeight={25}
                                                getRowId={(param) => param._id}
                                                loading={isFetchingPurchaseOrder}
                                                disableColumnMenu
                                                localeText={{
                                                    noRowsLabel: ""
                                                }}
                                                hideFooter
                                                autoHeight
                                                columns={columnsTotalize}
                                                rows={totalizeRows}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <PurchaseOrderSummary ref={purchaseOrderSummaryRef} products={products} provider={provider} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default OrderInvoice