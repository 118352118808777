import Dropzone from '@component/Dropzone'
import { CircularProgress, UploadFileRoundedIcon, styled } from '@component/UIComponents'
import { useState } from 'react'
import { useLoadElectronicInvoice } from '../hooks/useEnterPurchase'
import { useEffect } from 'react'
import { useCallback } from 'react'

const CircularProgressStyled = styled(CircularProgress)(() => ({
    width: "20px !important",
    height: "20px !important"
}))

const UploadElectronicInvoice = ({ onChange=()=>null, defaultFiles=null, getFile=()=>null }) => {

    const [files, setFiles] = useState([])
    const { readElectronicInvoice, prepared } = useLoadElectronicInvoice({ files })

    const onChangeMemo = useCallback(() =>  {
        readElectronicInvoice((provider, products, invoiceNumber) => onChange(provider, products, invoiceNumber))
    },[onChange, readElectronicInvoice])

    const handleFile = (value) => {
        setFiles(value)
        getFile(value)
    }

    useEffect(() => {
        onChangeMemo()
    }, [onChangeMemo])

    useEffect(() => {
        if( Boolean(defaultFiles) && Array.isArray(defaultFiles) && defaultFiles.length >= 1 ){
            if( !prepared ){
                setFiles(defaultFiles)
                getFile(defaultFiles)
            }
        }
    }, [defaultFiles, prepared, getFile])

    return(
        <>
            <Dropzone
                title='Importar factura electronica'
                loading={prepared}
                disabled={prepared}
                variant='iconButton'
                icon={ prepared ? <CircularProgressStyled /> : <UploadFileRoundedIcon />}
                multiple={false}
                accept='.zip'
                onChange={ !prepared ? (file) => handleFile(file) : () => null}
            />
        </>
    )
}

export default UploadElectronicInvoice