import { Box, Button, DatePicker, Grid, TextField, Typography } from "@component/UIComponents"
import ProductList from "./components/ProductList"
import ProductFinder from "@feature/Inventory/Products/components/ProductFinder"
import ProviderFinder from "@feature/Providers/components/ProviderFinder"
import { useEnterPurchaseActions, useEnterPurchaseDefaultValue, useEnterPurchaseFormData, useGenerateAdvanceFormData, useRecordPurchase } from "./hooks/useEnterPurchase"
import { currencyFormat } from "@util/currencyFormat"
import dayjs from "dayjs"
import { useState } from "react"
import Form from "@component/Form"
import PreSelectionOfProduct from "./components/PreSelectionOfProduct"
import SelectionOfPaymentMethod from "@component/SelectionOfPaymentMethod"
import { elementFocus } from "@util/helpers"
import ActionWindow from "./components/ActionWindow"
import { useRegisterAdvanceToSupplier } from "@feature/Providers/hooks/useProviders"
import HauliersFinder from "@feature/Hauliers/components/HauliersFinder"
import ActionsList from "@component/ActionsList"
import { useGetSupplierPurchases } from "@feature/PurchaseReport/Purchases/hooks/usePurchase"
import { useMemo } from "react"
import { useEffect } from "react"
import { Swal } from "@util/swal"
import UploadElectronicInvoice from "./components/UploadElectronicInvoice"
import { usePanel } from "@hook/usePanel"
import ComponentTitle from "@component/ComponentTitle"
import AutoSaveList from "./components/AutoSaveList"

const EnterPurchase = () => {
    usePanel({ appTitle: "Compras" })
    const defaultValues = useEnterPurchaseDefaultValue()
    const [ XMLTrigger, setXMLTrigger ] = useState({ waitingFile: null, fileAction: null })

    const [totalAmountOfPurchasesOfTheDay, setTotalAmountOfPurchasesOfTheDay] = useState(0)
    const [selectedProvider, setSelectedProvider] = useState(null)
    const [paymentWindow, setPaymentWindow] = useState(false)
    const [preSelectedProduct, setPreSelectedProduct] = useState(null)

    const { values, setFieldValue, validateValues, purchaseTotals, purchaseTaxes, removeProductFromList, resetValues } = useEnterPurchaseFormData({ totalAmountOfPurchasesOfTheDay, defaultValues })
    const { _id, provider, billingDate, dateReceived, whoReceives, invoiceNumber, products, dueDate, isCredit, payments, total, haulier } = values
    const { recordPurchase, isLoading } = useRecordPurchase()
    const providerAdvance = (provider?.balance??0)

    const { values:AdvanceValues, setFieldValue:setFieldAdvanceValue, resetValues:resetAdvanceValues, validateValues:validateAdvanceValues } = useGenerateAdvanceFormData()
    const { payments:paymentsAdvance, providerId:providerIdAdvance } = AdvanceValues
    const { registerAdvanceToSupplier, isLoading:isLoadingRegisterAdvanceToSupplier } = useRegisterAdvanceToSupplier()

    const { purchases, isFetching } = useGetSupplierPurchases({ ref: (provider?._id??""), variant: "supplierPurchases" }, { skip: !Boolean(provider?._id) })
    const totalPurchasesOfTheDay = useMemo(() => purchases.reduce((acc, params) => acc+(params?.subTotal??0),0), [purchases])

    const { actions } = useEnterPurchaseActions()

    useEffect(() => {
        setTotalAmountOfPurchasesOfTheDay(totalPurchasesOfTheDay)
        if( (provider?.tax?.name??"") === "retefuente" ){
            if( totalPurchasesOfTheDay >= (provider?.tax?.minimumAmount??"") ){
                Swal.fire({
                    icon: "warning",
                    text: `Este proveedor aplica para retención en la fuente con un monto en compras el dia de hoy por ${currencyFormat(totalPurchasesOfTheDay)} antes de IVA`
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPurchasesOfTheDay])

    const showPaymentWindow = () => {
        const validate = validateValues(values)
        if( validate.type === "errors" ) return
        setPaymentWindow(true)
    }

    const handleSubmit = () => {
        const validate = validateValues(values)
        if( validate.type === "errors" ) return
        recordPurchase(values, { clearForm: () => resetValues(), closeForm: () => setPaymentWindow(false) })
    }

    const handleSubmitAdvance = () => {
        const { type } = validateAdvanceValues(AdvanceValues)
        if( type === "errors" ) return
        registerAdvanceToSupplier(AdvanceValues, {
            clearForm: () => resetAdvanceValues(),
            elementFocus: setTimeout(() => elementFocus("providerFinder"), 500),
        })
    }

    const handleChangeProductList = (newValue) => {
        let currentProducts = [...products]
        let productIndex = currentProducts.findIndex((n) => ((n?.productId??"") === (newValue?.productId??"")))
        if( productIndex >= 0 ){
            currentProducts[productIndex] = newValue
        }else{
            currentProducts.push(newValue)
        }
        setFieldValue("products",currentProducts)
    }

    const handlePaymentMethodChanges = (field, value, newListPayments) => {
        if( field !== "payment" ){
            setFieldValue(field, value)
        }else{
            setFieldValue("payments", newListPayments)
        }
    }

    const handleAction = (action, defaultProvider=null) => {
        if( action === "purchase" ){
            setSelectedProvider(null)
            setTimeout(() => {
                if( Boolean(defaultProvider) ){
                    setFieldValue("provider", {...defaultProvider})
                }else{
                    setFieldValue("provider", {...selectedProvider})
                }
            },100)
            setTimeout(() => {
                elementFocus("billingDate")
            },150)
        }else{
            setTimeout(() => {
                setFieldAdvanceValue("providerId", (selectedProvider?._id??""))
            }, 100)
            setSelectedProvider(null)
        }
    }

    const handleCloseAdvanceWindow = () => {
        resetAdvanceValues()
        elementFocus("providerFinder")
    }

    const handleSelectedProvider = (provider) => {
        if( Boolean(provider?._id) ){
            setSelectedProvider(provider)
        }else{
            setFieldValue("provider", null)
        }
    }

    const prepareBillingAreaByElectronicInvoice = (invoiceProvider=null, invoiceProducts=[], invoiceElectronicNumber="") => {
        if( !Boolean(provider?._id) && (products.length <= 0) ){
            let currentProducts = [...invoiceProducts]
            handleAction("purchase", invoiceProvider)
            setTimeout(() => {
                setFieldValue("invoiceNumber", invoiceElectronicNumber)
                setFieldValue("products", currentProducts)
            }, 150)
        }
    }

    return(
        <Box px={1} >
            <Form onSubmit={ Boolean(_id) ? handleSubmit : showPaymentWindow} >
                <Grid container spacing={1} >
                    <Grid xs={ !Boolean(provider?._id) ? 9 : 11 } >
                        <Box display={'flex'} gap={2} alignItems={'center'} >
                            <ProviderFinder sx={{ '& .MuiInputBase-input': { fontWeight: "bold" } }} fullWidth id={"providerFinder"} autoFocus={true} filters={{ variant: "purchases" }} value={provider} size="small" onChange={(_, params) => handleSelectedProvider(params)} />
                            {Boolean(provider) &&
                                <Typography width={200} lineHeight={1} fontWeight={'bold'} fontSize=".875rem" textTransform={'uppercase'} >
                                    Anticipo disponible <br />
                                    <Box component={'span'} fontSize={'1rem'} sx={{ color: (theme) => theme.palette.primary.main }} >
                                        {currencyFormat(providerAdvance)}
                                    </Box>
                                </Typography>
                            }
                        </Box>
                        <ComponentTitle title="Ingreso de compras:" />
                    </Grid>

                    <Grid xs={!Boolean(provider?._id) ? 3 : 1} sx={{ display: "flex", justifyContent: "end" }} >
                        {!Boolean(provider?._id) &&
                            <>
                                <AutoSaveList onChange={(val) => {
                                    let keys = Object.keys(val)
                                    for( let key of keys ){
                                        setFieldValue(key, val[key])
                                    }
                                }} />
                            </>
                        }
                        <UploadElectronicInvoice onChange={prepareBillingAreaByElectronicInvoice} defaultFiles={XMLTrigger.fileAction} getFile={(file) => setXMLTrigger((n) => ({...n, waitingFile: file, fileAction: null}))} />
                        <ActionsList
                            variant="iconButton"
                            options={actions({ purchases, loading: isFetching, billInformation: values }, {
                                disabled: !Boolean(provider?._id),
                                clearForm: () => resetValues()
                            })}
                        />
                    </Grid>
                    <Grid xs={2} >
                        <DatePicker format="DD/MM/YYYY" disabled={!Boolean(provider?._id)} value={dayjs(billingDate)} onChange={(date) => setFieldValue("billingDate", dayjs(date).format("MM-DD-YYYY"))} label="Fecha de facturación" slotProps={{ textField: { fullWidth: true, size: "small", id: "billingDate" } }} sx={{ '& .MuiSvgIcon-root': { color: (theme) => theme.palette.primary.main } }} />
                    </Grid>
                    <Grid xs={2} >
                        <DatePicker format="DD/MM/YYYY" disabled={!Boolean(provider?._id)} value={dayjs(dateReceived)} onChange={(date) => setFieldValue("dateReceived", dayjs(date).format("MM-DD-YYYY"))} label="Fecha de recibido" slotProps={{ textField: { fullWidth: true, size: "small" } }} sx={{ '& .MuiSvgIcon-root': { color: (theme) => theme.palette.primary.main } }} />
                    </Grid>
                    <Grid xs={3} >
                        <TextField disabled={!Boolean(provider?._id)} value={whoReceives} onChange={({ target }) => setFieldValue("whoReceives", (target?.value??""))} placeholder="Recibido por" fullWidth size="small" />
                    </Grid>
                    <Grid xs={2} >
                        <TextField disabled={!Boolean(provider?._id)} value={invoiceNumber} onChange={({ target }) => setFieldValue("invoiceNumber", (target?.value??""))} placeholder="Numero de factura" fullWidth size="small" />
                    </Grid>
                    <Grid xs={3} >
                        <HauliersFinder disabled={!Boolean(provider?._id)} fullWidth size="small" value={haulier} onChange={(_, params) => setFieldValue("haulier", params)} />
                    </Grid>
                    <Grid xs={12} >
                        <ProductFinder
                            disabled={!Boolean(provider?._id)}
                            value={null}
                            variant={'purchases'}
                            onChange={(_, params) => setPreSelectedProduct(params)} />
                    </Grid>
                    <Grid xs={12} >
                        <ProductList
                            haulier={haulier}
                            list={products}
                            tableTotalize={purchaseTotals}
                            tableTaxes={purchaseTaxes}
                            removeProductFromList={removeProductFromList}
                            selectProductFromList={(product) => setPreSelectedProduct({...product, _id: (product?.productId??"")})}
                            onAfterSaving={() => {
                                handleSelectedProvider(null)
                                setFieldValue("products", [])
                                setTimeout(() => {
                                    setXMLTrigger((n) => ({...n, fileAction: n.waitingFile, waitingFile: null,}))
                                }, 150)
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sx={{ display: "flex", justifyContent: "end" }} >
                        <Button disabled={!Boolean(provider?._id)} type="submit" size="large" variant="outlined" > {Boolean(_id) ? "Guardar cambios" : "Completar compra"}  </Button>
                    </Grid>
                </Grid>
            </Form>
            <PreSelectionOfProduct
                list={products}
                value={preSelectedProduct}
                onClose={() => setPreSelectedProduct(null)}
                onChange={(params) => handleChangeProductList(params)}
            />
            <SelectionOfPaymentMethod
                name1={'Caja remitente'}
                name2={'Valor a pagar'}
                showMaxVal={false}
                onSubmit={handleSubmit}
                open={paymentWindow}
                onClose={() => setPaymentWindow(false)}
                dueDate={dueDate}
                isCredit={isCredit}
                onChange={handlePaymentMethodChanges}
                payments={payments}
                totalToPay={total}
                onDeletePayment={(_, index, newList) => setFieldValue("payments", newList)}
                paymentReference={(provider?._id??"")}
                isLoading={isLoading}
            />
            <SelectionOfPaymentMethod
                title="Generar anticipo"
                onSubmit={handleSubmitAdvance}
                open={Boolean(providerIdAdvance)}
                onClose={handleCloseAdvanceWindow}
                onChange={(_, payment, newList) => setFieldAdvanceValue("payments",newList)}
                payments={paymentsAdvance}
                onDeletePayment={(_, index, newList) => setFieldAdvanceValue("payments",newList)}
                noPayoutLimit
                paymentMethodsAvailable={{
                    credit: false,
                    advance: false,
                }}
                hideTabs
                isLoading={isLoadingRegisterAdvanceToSupplier}
            />
            <ActionWindow
                provider={selectedProvider}
                onClose={() => setSelectedProvider(null)}
                onSubmit={handleAction}
            />
        </Box>
    )
}

export default EnterPurchase