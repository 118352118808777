import DetailsWindow from "@component/DetailsWindow"
import SelectionOfPaymentMethod from "@component/SelectionOfPaymentMethod"
import { Box, Button, IconButton, ListItemIcon, MenuItem, PaymentsRoundedIcon, Tooltip, Typography } from "@component/UIComponents"
import { useRecordVariableExpense, useVariableExpensesFormData } from "@feature/Expenses/hooks/useExpenses"
import { useState } from "react"

const VariableExpense = ({ variantBox, fixedBox=undefined, variantListButton="button", onOpen=false, handleOpen=()=>{} }) => {
    const [ showVariableSpendingWindow, setShowVariableSpendingWindow ] = useState(false)
    const [ showVariableExpenseDetailWindow, setShowVariableExpenseDetailWindow ] = useState(false)


    const { values, setFieldValue, validateValues } = useVariableExpensesFormData({ openModal: showVariableSpendingWindow })
    const { payments, observation } = values
    const { recordVariableExpense } = useRecordVariableExpense()

    const handleSubmitVariableExpense = () => {
        const { type } = validateValues(values)
        if(type === "errors") return
        recordVariableExpense(values, {
            closeForm: () => {
                setShowVariableSpendingWindow(false)
                handleOpen(false)
            }
        })
    }

    const openDetailsVariableExpense = () => setShowVariableExpenseDetailWindow(true)

    const handleCreateVariableExpense = (value) => {
        setShowVariableSpendingWindow(true)
        setFieldValue("observation", value)
    }

    return(
        <>
            {variantListButton === "button" &&
                <Button startIcon={<PaymentsRoundedIcon />} onClick={openDetailsVariableExpense} >
                    Crear gasto variable
                </Button>
            }
            {variantListButton === "iconButton" &&
                <Tooltip title="Crear gasto variable" arrow >
                    <IconButton onClick={openDetailsVariableExpense} >
                        <PaymentsRoundedIcon />
                    </IconButton>
                </Tooltip>
            }
            {variantListButton === "mobileMenu" &&
                <MenuItem onClick={openDetailsVariableExpense} >
                    <ListItemIcon>
                        <PaymentsRoundedIcon />
                    </ListItemIcon>
                    Crear gasto variable
                </MenuItem>
            }
            <SelectionOfPaymentMethod
                title={`Gasto variable`}
                fixedBox={fixedBox}
                open={showVariableSpendingWindow || onOpen}
                onClose={() => {
                    setShowVariableSpendingWindow(false)
                    handleOpen(false)
                }}
                hideTabs
                noPayoutLimit
                paymentMethodsAvailable={{ cash: (!(variantBox === "bank") && !(variantBox === "cashier")) , consignment: (variantBox === "bank"), cashier: (variantBox === "cashier"), advance: false, credit: false }}
                onChange={(n, x, list) => setFieldValue("payments", list)}
                onDeletePayment={(n, x, list) => setFieldValue("payments", list)}
                payments={payments}
                onSubmit={handleSubmitVariableExpense}
                topComponent={<>
                    <Typography fontSize={'.875rem'} fontWeight={'bold'} > Observación: <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > {observation}. </Box> </Typography>
                </>}
            />
            <DetailsWindow
                title="Ingresa detalles del gasto antes de continuar"
                open={showVariableExpenseDetailWindow}
                onClose={() => setShowVariableExpenseDetailWindow(false)}
                onSubmit={handleCreateVariableExpense}
            />
        </>
    )
}

export default VariableExpense