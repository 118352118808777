import { useMassiveProductUpdateMutation } from "@query/Products"
import { asynchronousResponseHandler } from "@util/helpers"

export const useMassiveProductUpdate = () => {
    const [ massiveProductUpdateMutation, { isLoading } ] = useMassiveProductUpdateMutation()

    const massiveProductUpdate = async (payload=null, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await massiveProductUpdateMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "Los productos se actualizaron exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        massiveProductUpdate,
        isLoadingMassiveProductUpdate: isLoading
    }
}