import Menu from '@component/Menu'
import { Box, Button, Divider, IconButton, ListItemIcon, MenuItem, MoreVertRoundedIcon, Tooltip, Typography } from '@component/UIComponents'
import { useState } from 'react'

const MenuVariant = ({ children=undefined, variant="iconButton", icon=<MoreVertRoundedIcon />, title="Opciones", menuTitle="", filters=<></>, options=[], setPopoverProps=()=>null }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    return(
        <>
            {variant === "iconButton" &&
                <Tooltip title={title} >
                    <IconButton color='primary' onClick={(event) => setAnchorEl(event.currentTarget)} >
                        {icon}
                    </IconButton>
                </Tooltip>
            }
            {variant === "button" &&
                <Button startIcon={icon} color='primary' onClick={(event) => setAnchorEl(event.currentTarget)} >
                    {title}
                </Button>
            }
            <Menu positionArrow="right" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} >
                { Boolean(menuTitle) &&
                    <Box component={'span'} >
                        <Typography px={1.5} fontSize={'.900rem'} fontWeight={'500'} sx={{ color: (theme) => theme.palette.grey[700] }} > {menuTitle} </Typography>
                        <Divider />
                    </Box>
                }
                {filters}
                {options?.map((item, index) => {
                    const { button=null, buttonProps={}, icon=<></>, title:menuTitle="", onClick=()=>null, onPopover=undefined } = (item??{})
                    return(
                        <Box component={'span'} key={index} >
                            {Boolean(button) ?
                                <Box component={'span'}>
                                    {(button??<></>)}
                                </Box>
                                :
                                <MenuItem onClick={(event) => Boolean(onPopover) ? onPopover(event, setPopoverProps) : onClick()} {...(buttonProps??{})} >
                                    {Boolean(icon) &&
                                        <ListItemIcon>
                                            {icon}
                                        </ListItemIcon>
                                    }
                                    {(menuTitle??"")}
                                </MenuItem>
                            }
                        </Box>
                    )
                })}
                {children}
            </Menu>
        </>
    )
}

export default MenuVariant