import { Grid } from "@component/UIComponents"
import { Box, Card, CardContent, Table, TableBody, TableCell, TableRow } from "@mui/material"
import { currencyFormat } from "@util/currencyFormat"

const ClosingSummaryCard = ({ title="", minWidth=0, maxWidth=0, totalValue=0, extendsSummary=undefined }) => {
    return (
        <Card variant="outlined" sx={{ width: '100%', minWidth: minWidth, maxWidth: maxWidth, height: "100%" }} >
            <CardContent>
                <Grid container >
                    <Grid xs={12} >
                        <Box sx={{ fontSize: '.875rem', textTransform: 'uppercase', fontWeight: 'bold', }} className="truncate-text" >
                            {title}
                        </Box>
                    </Grid>
                    <Grid xs={12} >
                        <Table size="small" >
                            <TableBody sx={{
                                '& tr': {
                                    '& td': {
                                        padding: 0,
                                        py: 0.3,
                                        border: 'none'
                                    }
                                }
                            }} >
                                {/* {!(disableCash === true) &&
                                    <TableRow >
                                        <TableCell>Efectivo</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat(cashValue)}</TableCell>
                                    </TableRow>
                                }
                                {!(disableCredit === true) &&
                                    <TableRow >
                                        <TableCell>Crédito</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat(creditValue)}</TableCell>
                                    </TableRow>
                                } */}
                                {extendsSummary}
                                <TableRow sx={{
                                    '& td': {
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        fontSize: "1rem",
                                        WebkitTextStroke: .5
                                    }
                                }} >
                                    <TableCell>Total</TableCell>
                                    <TableCell align="right">{currencyFormat(totalValue)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ClosingSummaryCard