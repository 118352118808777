import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useCallback, useMemo } from "react"

export const useBusinessFormController = (props={}) => {
    const { defaultValues = undefined, onSubmit=()=>null } = props
    const initialValues = useMemo(() => ({ address: "", email: "", name: "", nit: "", phone: "" }), [])

    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre del negocio (Razón social) es requerido."),
            nit: yup.string(),
            email: yup.string().email(),
            phone: yup.string(),
            address: yup.string(),
        }
    })

    const submitValues = useCallback(() => {
        const { type } = validateValues(values)
        if(type === "errors") return
        onSubmit(values)
    }, [onSubmit, validateValues, values])

    return { values, setFieldValue, resetValues, validateValues, submitValues }
}