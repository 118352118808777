import TabAppContext from "@component/TabAppContext"
import { Grid } from "@component/UIComponents"
import { useGetDynamicBoxes } from "./hooks/useBoxes"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useEffect } from "react"
import BoxesTemplate from "@feature/MasterBox/components/BoxesTemplate"
import { useCallback } from "react"
import ComponentTitle from "@component/ComponentTitle"

const Boxes = () => {
    const [tab, setTab] = useState(undefined)
    const { t } = useTranslation()
    const { resp, isLoading } = useGetDynamicBoxes()
    const box = useMemo(() => ((resp?.find((n) => n._id === tab)??null)), [tab, resp])

    let tabs = useMemo(() => {
        return resp.map((n) => ({ label: t(n?.name??""), value: n._id, component: <BoxesTemplate variant="pocket" boxName={(box?.name??"")} refId={(box?._id??null)} boxBalance={(box?.amount??0)} /> }))
    },[t, resp, box])

    const loadDefaultTab = useCallback(() =>{
        if( tabs.length >= 1 ){
            if( !Boolean(tab) || tab === "0" ){
                setTab((tabs[0]?.value??undefined))
            }
        }else{
            setTab("0")
        }
    }, [tabs, tab])

    useEffect(() => {
        loadDefaultTab()
    }, [loadDefaultTab])
    return(
        <>
            {isLoading ?
                <></>
                :
                <>
                    <ComponentTitle activeNavigatorTabTitle={true} title="Bolsillos" />
                    <Grid container >
                        <Grid xs={12} >
                            {(Boolean(tab) && (tabs.length >= 1)) ?
                                <TabAppContext
                                    tabs={tabs}
                                    tab={tab}
                                    onChange={(event, value) => setTab(value)}
                                />
                                :
                                <>
                                    <TabAppContext
                                        tabs={[{ label: "No hay bolsillos creados", value: "0", component: <BoxesTemplate variant="pocket" boxName={""} refId={null} boxBalance={0} /> }]}
                                        tab={tab}
                                        onChange={(event, value) => setTab(value)}
                                    />
                                </>
                            }
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}

export default Boxes