import { Button, CloseRoundedIcon, Grid, Typography, Dialog, DialogActions, DialogContent, TextField } from '@component/UIComponents'
import { usePocketFormData, useRegisterNewPocket } from '../hooks/useBoxes'
import Form from '@component/Form'

const BoxesForm = ({ open=false, onClose=()=>null }) => {
    const { values, validateValues, setFieldValue } = usePocketFormData({ openModal: open })
    const { name="" } = values
    const { registerNewPocket, isLoading } = useRegisterNewPocket()
    const handleSubmit = () => {
        let { type } = validateValues(values)
        if( type === "errors" ) return
        registerNewPocket(values, {
            closeForm: () => onClose()
        })
    }
    return(
        <>
            <Dialog maxWidth="xs" fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Grid
                container
                direction="row"
                alignContent='center'
                justifyContent='space-between'
                >
                    <Typography px={2} textTransform={'uppercase'} lineHeight={1.2} pt={2} pb={0} fontWeight={'bold'} fontSize={'1rem'} display={'flex'} flexDirection={'column'} >
                        Crear Bolsillo
                    </Typography>
                    <Button
                    onClick={onClose}
                    >
                        <CloseRoundedIcon sx={{ color: 'red'}}/>
                    </Button>
                </Grid>
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="Nombre del bolsillo"
                            value={name}
                            onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button loading={isLoading} type='submit' > Guardar </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default BoxesForm