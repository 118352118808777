import  { TextField, styled } from '@component/UIComponents'
import NumericFormat from "@component/NumericFormat"
import { useCallback, useEffect, useRef } from 'react'
import { currencyFormat } from '@util/currencyFormat'

const TextFieldStyle = styled(TextField)(() => ({
    '& .MuiFormHelperText-root': {
        textTransform: "uppercase",
        fontSize: "1rem",
        fontWeight: "bold",
        marginTop: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none"
    }
}))

const Cash = ({ onChange=()=>null, value=0, returned=0, balance=0 }) => {
    const inputRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus()
        }, 100)
    }, [inputRef])

    const handleChange = (val) => {
        if( Boolean(onChange) ){
            onChange({ value: val, boxName: "cashier" })
        }
    }

    const loadBalanceInInput = useCallback(() => {
        if( balance >= 1 && value <= 0 ){
            setTimeout(() => {
                onChange({ value: balance, boxName: "cashier" })
            }, 50)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balance, value])

    useEffect(() => {
        loadBalanceInInput()
    }, [loadBalanceInInput])

    return(
        <>
            <NumericFormat
                onChange={({ floatValue=0 }) => handleChange(floatValue)}
                ref={inputRef}
                value={value}
                helperText={`Devuelve: ${currencyFormat(returned)}`}
                TextFieldDefault={TextFieldStyle}
                fullWidth
                prefix="RECIBE: $"
                style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "2rem",
                    padding: 0
                }}
            />
        </>
    )
}

export default Cash