import { usePayPurchasesMutation } from "@query/Purchases"
import { Toast } from "@util/swal"
import { useCallback, useMemo, useState } from "react"

export const useSendPayment = ({ onResetPayments=()=>null }) => {

    const [ payPurchasesMutation,  { isLoading } ] = usePayPurchasesMutation()
    const [ payments, setPayments ] = useState([])

    const totalPayments = useMemo(() => {
        let CurrentPayments = [...(payments??[])]
        CurrentPayments = CurrentPayments.reduce((accPayment, item) => {
            const { payments=[] } = (item??{})
            const totalizePayments = payments.reduce((acc, payment) => (acc+(payment?.amount??0)), 0)
            return (accPayment+totalizePayments)
        }, 0)
        return CurrentPayments
    }, [payments])

    const ConvertPayment = useCallback((payment=null) => {
        const box = ((payment?.box?.type??"") === "bank") ? "bank" : (payment?.box?.name??"")
        const bank = ((payment?.box?.type??"") === "bank") ? (payment?.box?.name??"") : null
        return {
            box,
            amount: (payment?.amount??""),
            bank,
            boxRef: (payment?.box?._id??null)
        }
    }, [])

    const UpdatePaymentList = useCallback(({payment={}, ProviderID=null, InvoiceID=null }) => {
        const CurrentPayments = [...payments]
        const findIndex = CurrentPayments.findIndex((item) => ((item?.invoiceId) === InvoiceID) && ((item?.providerId) === ProviderID) )
        let payload = { providerId: ProviderID, invoiceId: InvoiceID }

        if( findIndex >= 0 ){
            const PaymentPayload = ConvertPayment(payment)
            CurrentPayments[findIndex] = {
                ...CurrentPayments[findIndex],
                payments: [PaymentPayload]
            }
            setPayments(CurrentPayments)
        }else{
            const PaymentPayload = ConvertPayment(payment)
            payload.payments = [PaymentPayload]
            CurrentPayments.push(payload)
            setPayments(CurrentPayments)
        }

    }, [payments, ConvertPayment])

    const CleanPaymentsCero = useCallback((payments=[]) => {
        let currentPayments = [...(payments??[])]
        currentPayments = currentPayments.map((item) => {
            let ssss = {...(item??{})}
            ssss.total = (ssss?.payments??[]).reduce((acc, item) => (acc+(item?.amount??0)),0)
            return ssss
        })
        currentPayments = currentPayments.filter((item) => ((item?.total??0) > 0) )
        currentPayments.forEach((item) => {
            delete item.total
        })
        return currentPayments
    }, [])

    const SendPayment = useCallback( async () => {
        try {
            let currentPayments = [...(payments??[])]
            currentPayments = CleanPaymentsCero(currentPayments)
    
            if( currentPayments.length <= 0 ){
                Toast.fire({ icon: "error", text: "Añada al menos un pago a la lista." })
                return
            }

            const data = await payPurchasesMutation({ payments: currentPayments }).unwrap()
            const { status=0 } = (data??{})
            if(status === 1){
                Toast.fire({
                    icon: "success",
                    text: "Los pagos se realizaron exitosamente."
                })
                onResetPayments()
                setPayments([])
            }
        } catch (error) {
            Toast.fire({
                icon: "error",
                text: `${(error?.message??error)}`
            })
            console.log(error)
        }

    }, [payments, CleanPaymentsCero, payPurchasesMutation, onResetPayments])

    return {
        SendPayment,
        UpdatePaymentList,
        totalPayments,
        payPurchasesMutation,
        isLoadingSendPayments: isLoading,
    }
}