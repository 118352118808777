import Autocomplete from "@component/Autocomplete"
import { Box, Grid } from "@component/UIComponents"
import CategoriesFinder from "@feature/Inventory/Categories/components/CategoriesFinder"
import ProviderFinder from "@feature/Providers/components/ProviderFinder"
import { useProductOptions } from "../hooks/useProducts"
import { transformArrayIntoString } from "@util/helpers"


const Filters = ({ filters={}, setFiltersData=()=>null }) => {
    const { primaryOptions, secondaryOptions, thirdOption } = useProductOptions()
    const { listType="", showProduct="", categories="", providers="", groupBy="" } = filters
    const transformArray = (value, callback=(n) => (n?.name??"")) => transformArrayIntoString(value, { mapFunction: callback, separator: "," })

    return(
        <>
        <Box>
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <Autocomplete
                        queryValue={listType}
                        fullWidth
                        label="Lista"
                        options={thirdOption}
                        onChange={(_, value) => setFiltersData("listType", (value?.value??""))}
                        sx={{
                            '& .MuiInputBase-input': {
                                fontWeight: "bold"
                            }
                        }}
                    />
                </Grid>
                <Grid xs={12} >
                    <Autocomplete
                        queryValue={showProduct}
                        multiple
                        fullWidth
                        label="Mostrar producto"
                        options={primaryOptions}
                        onChange={(_, value) => setFiltersData("showProduct", transformArray(value, (n) => (n?.value??"")))}
                    />
                </Grid>
                <Grid xs={12} >
                    <CategoriesFinder
                        queryValue={categories}
                        multiple
                        onChange={(_, value) => setFiltersData("categories", transformArray(value))}
                    />
                </Grid>
                <Grid xs={12} >
                    <ProviderFinder
                        queryValue={providers}
                        multiple
                        onChange={(_, value) => setFiltersData("providers", transformArray(value))}
                    />
                </Grid>
                <Grid xs={12} >
                    <Autocomplete
                        queryValue={groupBy}
                        fullWidth
                        label="Agrupar por"
                        options={secondaryOptions}
                        onChange={(_, value) => setFiltersData("groupBy", (value?.value??""))}
                    />
                </Grid>
            </Grid>
        </Box>
        </>
    )
}

export default Filters