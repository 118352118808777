import { List, ListItem, ListItemIcon, ListItemText, styled } from '@component/UIComponents'

const ListStyled = styled(List)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
}))

const ListItemStyled = styled(ListItem, {
    shouldForwardProp: (prop) => prop !== "type"
})(({ type, theme }) => ({
    padding: 0,
    '& .MuiListItemText-root': {
        margin: 0
    },
    '& .MuiListItemText-primary': {
        fontWeight: "bold",
        ...( !Boolean(type) ? {} : ( type === "active" ? {
            color: theme.palette.success.main
        } : {
            color: theme.palette.error.main
        } ) )
    },
    '& .MuiListItemText-secondary': {
        fontWeight: "500",
    },
}))


const QuickList = ({ list=[], sx={} }) => {
    return(
        <>
            <ListStyled sx={{...sx, overflow: 'auto'}} >
                {list.map((item, index) => {
                    return(
                        <ListItemStyled type={(item?.type??"")} key={index} sx={{...(item?.sx??{})}} >
                            {Boolean((item?.icon??false)) &&
                                <ListItemIcon>
                                    {item?.icon}
                                </ListItemIcon>
                            }
                            <ListItemText
                                primary={(item?.primary??"")}
                                secondary={(item?.secondary??"")}
                                onClick={(event) => Boolean(item?.onListItemTextClick) ? item.onListItemTextClick(event) : null }
                            />
                        </ListItemStyled>
                    )
                })}
            </ListStyled>
        </>
    )
}

export default QuickList