import { Box } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ShowColumns = ({ onChange=()=>null }) => {
    const [selection, setSelection] = useState([])
    const [columns] = useState(["name","lowInventory","lowStockAlert","consecutive","productParent","category","packaging","provider","cost","activePOS","activePurchase","skuCode","stock","totalStock","tax", "sellPrice1", "sellPrice2", "sellPrice3"])
    const { t } = useTranslation()

    const options = useMemo(() => {
        return [...columns].map((c, index) => {
            let r = {
                _id: (index+1),
                name: t(c),
                value: c,
            }
            return r
        })
    }, [columns, t])

    const handleOnChange = (arr=[]) => {
        const currentValues = [...arr]
        let columnVisibilityModel = {}
        if( currentValues.length >= 1 ){
            for( let n of columns ){
                const currentVal = currentValues.find((x) => (x?.value??"") === n)
                if( Boolean(currentVal?._id) ){
                    columnVisibilityModel[n] = true
                }else{
                    columnVisibilityModel[n] = false
                }
            }
        }
        onChange(columnVisibilityModel)
        setSelection(currentValues)
    }

    return(
        <Box width={'100%'} >
            <Autocomplete
                limitTags={1}
                size="small"
                label="Mostrar solo"
                options={options}
                multiple
                onChange={(_, values) => handleOnChange(values)}
                value={selection}
            />
        </Box>
    )
}

export default ShowColumns