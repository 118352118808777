import AutocompleteCreable from "@component/AutocompleteCreable"
import { useGetBranches } from "../hooks/useCustomers"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"

const BranchFinder = ({ size=undefined, value=null, onChange=undefined, multiple=undefined, queryValue=undefined }) => {
    const { branches, isFetching } = useGetBranches()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: branches, queryValue })
    const val = getAutocompleteValue()

    return(
        <>
            <AutocompleteCreable
                size={size}
                multiple={multiple}
                value={val}
                onChange={onChange}
                loading={isFetching}
                label="Sucursal"
                options={branches}
            />
        </>
    )
}

export default BranchFinder