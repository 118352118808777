import AdvancedFilters from "@component/AdvancedFilters"
import PanelContainer from "@component/PanelContainer"
import { advanceFilters } from "@util/helpers"
import { useFixedCostsOptions } from "../hooks/useFixedCosts"
import { useURLParams } from "@hook/useURLParams"

const Filters = ({ list=[] }) => {
    const { delQueries } = useURLParams()
    const { groupByOptions } = useFixedCostsOptions()
    return(
        <>
            <PanelContainer onClose={() => delQueries(['panel'])} >
                <AdvancedFilters
                    options={advanceFilters({
                        groupByOptions,
                        showOnlyOptions: list,
                        filters: {
                            showOnly: true,
                            groupBy: true,
                            viewArchived: true,
                        }
                    })}
                />
            </PanelContainer>
        </>
    )
}

export default Filters