import { Box, Typography } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useGetTaxes } from "../hooks/useBusiness"
import { useTranslation } from "react-i18next"
import { currencyFormat } from '@util/currencyFormat'

const TaxesFinder = ({ fullWidth=undefined, size=undefined, onChange=undefined, value=null, inputRef=undefined, sx=undefined, label="Impuestos", filters={} }) => {
    const { t } = useTranslation()
    const { taxes, isFetching } = useGetTaxes(filters)
    return(
        <>
            <Autocomplete
                sx={sx}
                ref={inputRef}
                getOptionLabel={(param) => `${t((param?.name??""))} ${(param?.tax??0)}%`}
                onChange={onChange}
                value={value}
                fullWidth={fullWidth}
                size={size}
                label={label}
                options={taxes}
                loading={isFetching}
                renderOption={(props, option) => {
                    if( option.name === "retefuente" ){
                        return(
                            <li {...props} key={option._id} >
                                <Box component={'span'} display={'flex'} flexDirection={'column'} >
                                    <Typography variant='body2' > Imp: {t((option?.name??""))} {(option?.tax??0)}% </Typography>
                                    <Typography variant='body2' > Base: {currencyFormat((option?.minimumAmount??0))} </Typography>
                                </Box>
                            </li>
                        )
                    }else{
                        return(
                            <li {...props} key={option._id} >
                                <Typography variant='body2' > {option.name} {(option?.tax??0)}% </Typography>
                            </li>
                        )
                    }
                }}
            />
        </>
    )
}

export default TaxesFinder