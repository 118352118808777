import { useEffect } from "react"
import { useCallback } from "react"
import { useUpdateInvoiceDueDate } from "./useUpdateInvoiceDueDate"
import { useUpdateCreditExpirationDateForm } from "./useUpdateCreditExpirationDateForm"

const useControllerUpdateCreditExpirationDate = (props={}) => {
    const { dataHandler=()=>null, saleRef=null, dueDate=null, closeForm=()=>null } = props
    const { updateInvoiceDueDate, isLoadingUpdateInvoiceDueDate } = useUpdateInvoiceDueDate()
    const { validateValues } = useUpdateCreditExpirationDateForm()

    const onUpdateExpirationDate = useCallback((payload) => {
        const { type } = validateValues(payload)
        if(type === "errors") return
        updateInvoiceDueDate(payload, { closeForm, })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateInvoiceDueDate])

    const uploadSalesInvoiceReference = useCallback(() => {
        dataHandler("saleRef", saleRef)
    }, [dataHandler, saleRef])

    const loadCreditExpirationDate = useCallback(() => {
        if( Boolean(dueDate) ){
            dataHandler("creditExpirationDate", dueDate)
        }
    }, [dataHandler, dueDate])

    useEffect(() => {
        uploadSalesInvoiceReference()
    }, [uploadSalesInvoiceReference])
    
    useEffect(() => {
        loadCreditExpirationDate()
    }, [loadCreditExpirationDate])

    return {
        onUpdateExpirationDate,
        isLoadingUpdateInvoiceDueDate
    }
}

export default useControllerUpdateCreditExpirationDate