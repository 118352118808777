import Autocomplete from "@component/Autocomplete"
import { Grid } from "@component/UIComponents"
import { useGetEmployeeBillingModeQuery } from "@query/Employees"
import { useMemo } from "react"

const EmployeeBillingSettings = ({ billingMode=null, onChange=()=>null }) => {
    const { data } = useGetEmployeeBillingModeQuery()
    const EmployeeBillingMode = useMemo(() => (data?.payload??[]), [data])

    return (
        <Grid container >
            <Grid xs={12} >
                <Autocomplete
                    size="small"
                    label="Modo de facturación"
                    options={EmployeeBillingMode}
                    value={billingMode}
                    onChange={(_, option) => onChange("billingMode", option)}
                />
            </Grid>
        </Grid>
    )
}

export default EmployeeBillingSettings