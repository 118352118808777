import { ArchiveRoundedIcon, ControlPointIconRounded, EditIconRounded, ManageSearchRoundedIcon } from "@component/UIComponents"
import { useGetDeliveriesQuery, useGetDeliveriesRealizedQuery } from "@query/Deliveries"

export const useGetDeliveries = () =>{
    const { data, isFetching } = useGetDeliveriesQuery()
    const deliveries = (data?.payload??[])

    return {
        deliveries,
        isFetching
    }
}

export const useGetDeliveriesRealized = (filters={}, config={}) =>{
    const { data, isFetching } = useGetDeliveriesRealizedQuery(filters, config)
    const deliveriesRealized = (data?.payload[0]??[])

    return {
        deliveriesRealized,
        isFetching
    }
}

export const useDeliveriesMenu = () => {
    const primaryMenu = (props={}) => {
        const { onDeliveryRealized=()=>null } = props
        let r = [
            {
                title: "Editar",
                onClick: () => null,
                icon: <EditIconRounded />,
            },
            {
                title: "Domicilios realizados",
                onClick: () => onDeliveryRealized(),
                icon: <ManageSearchRoundedIcon />,
            },
            {
                title: "Archivar",
                onClick: () => null,
                icon: <ArchiveRoundedIcon />,
            },
        ]
        return r
    }
    
    const secondaryMenu = (props={}) => {
        let r = [
            {
                title: "Crear domiciliario",
                onClick: () => null,
                icon: <ControlPointIconRounded />,
            },
        ]
        return r
    }

    return {
        primaryMenu,
        secondaryMenu
    }
}