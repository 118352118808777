import { Button, Drawer } from "@component/UIComponents"
import { useState } from "react"

const RightDrawer = ({ children, keepMounted=undefined, ButtonProps={} }) => {
    const { name="Abrir", ...ButtonPropsRest } = (ButtonProps??{})
    const [ open, setOpen ] = useState(false)

    return (
        <>
            <Button disableElevation size="small" {...ButtonPropsRest} onClick={() => setOpen(true)}>
                {name}
            </Button>
            <Drawer slotProps={{ backdrop: { id: "BackdropID" } }} sx={{ zIndex: 1250 }} PaperProps={{ sx: { width: 350 } }} ModalProps={{ keepMounted }} anchor={"right"} open={open} onClose={() => setOpen(false)} >
                {children}
            </Drawer>
        </>
    )
}

export default RightDrawer