import { Box } from '@component/UIComponents'
import { delToken, getToken } from "@util/helpers"
import { useCallback, useEffect, useMemo, useState } from "react"
import { routes } from "@util/routes"
import { useLocation, useNavigate } from "react-router-dom"
import LoadProgress from "./LoadProgress"
import { useGetBusinessInformation } from '@feature/Settings/BusinessInformation/hooks/useBusiness'
import { Toast } from '@util/swal'
import { useTranslation } from 'react-i18next'
const { welcome, login, singIn, report } = routes

const SafeRedirect = ({ children }) => {
    const { business, isSuccess, isError } = useGetBusinessInformation()
    console.log("🚀 ~ SafeRedirect ~ isError:", isError)
    const { t } = useTranslation()
    const [showPreload, setShowPreload] = useState(true)
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tk = useMemo(() => getToken(), [])
    const redirect = useCallback(() => {
        if( !Boolean(tk) ){
            if( pathname !== login() && pathname !== singIn() && pathname !== welcome() ){
                navigate(login())
            }
            setShowPreload(false)
        }else{
            if( isSuccess || isError ){
                if( isError || ["noValidUserFoundForToken", "authenticationHeaderNotFound", "tokenDoesNotExist", "userBlocked", "expiredToken"].some((n) => (n === business) || (n === (business?.dictionaryKey??"")) ) ){
                    Toast.fire({
                        icon: "warning",
                        text: `Ocurrió un problema al intentar iniciar la sesión, ingrese nuevamente. (${t(business??(business?.dictionaryKey??""))})`,
                        timer: 2000
                    })
                    setTimeout(() => {
                        delToken()
                        window.location.reload(true)
                    }, 2100)
                }
                if( Boolean(business?._id) ){
                    if( pathname === login() || pathname === singIn() || pathname === welcome() ){
                        navigate(report())
                    }
                    setShowPreload(false)
                }
            }
        }
    }, [tk, pathname, navigate, business, isSuccess, t, isError])

    useEffect(() => {
        redirect()
    }, [redirect])

    if( showPreload ){
        return(
            <Box width={'100vw'} height={'100vh'} display={'flex'} alignItems={'center'} >
                <LoadProgress />
            </Box>
        )
    }else{
        return(
            <>
                {children}
            </>
        )
    }
}

export default SafeRedirect