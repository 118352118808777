import React from 'react'
import { NumericFormat as NumericFormatMUI, TextField, styled } from '@component/UIComponents'
import { forwardRef } from 'react';
import { useNumericFormat } from './hooks/useNumericFormat';

const TextFieldStyled = styled(TextField)(() => ({
    '& input[type="number"]': {
        MozAppearance: 'textfield',
        WebkitAppearance: 'none',
        margin: 0,
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
}));

const CustomNumericFormat = React.forwardRef((props, ref) => {
    const { handleInputChange } = useNumericFormat()

    const { onChange, automaticErase=false, ...other } = props;
    const handleOnChange = (params, eventSrc) => {
        if (onChange) {
            const { event } = eventSrc
            let values = {...params}
            const n = handleInputChange({ values, event })
            values.floatValue = n
            let resp = automaticErase ? values : params
            onChange(resp, event);
        }
    }
    return (
        <NumericFormatMUI
            getInputRef={ref}
            onValueChange={(params, event) => handleOnChange(params, event)}
            {...other}
        />
    );
});

const NumericFormat = forwardRef(({ automaticErase=false, defaultInputValue=undefined, sx=undefined, disabled=false, endAdornment=undefined, onKeyDown=undefined, margin=undefined, TextFieldDefault=undefined, maxVal=undefined, defaultValue=undefined,dataIndex=undefined, className=undefined, onKeyUp=undefined, id=undefined, autoFocus=undefined, variant="outlined", size=undefined, prefix = "$ ", format=undefined, suffix = undefined, thousandSeparator = " ", onMouseEnter=undefined, onMouseOut=undefined, onFocus=undefined, onBlur=undefined, name=undefined, value, label=undefined, helperText=undefined, error=undefined, fullWidth=undefined, ...rest }, ref) => {

    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (maxVal !== undefined) {
            if( Boolean(defaultValue) ){
                if (defaultValue <= maxVal) return true;
                return false;
            }else{
                if (value <= maxVal) return true;
                return false;
            }
        }
        return true;
    };

    const Element = Boolean(TextFieldDefault) ? TextFieldDefault : TextFieldStyled

    return (
        <Element
            defaultValue={defaultInputValue}
            sx={sx}
            disabled={disabled}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            id={id}
            autoFocus={autoFocus}
            variant={variant}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseEnter={onMouseEnter}
            onMouseOut={onMouseOut}
            name={name}
            fullWidth={fullWidth}
            helperText={helperText}
            error={error}
            label={label}
            margin={margin}
            size={size}
            value={value}
            InputProps={{
                inputComponent: CustomNumericFormat,
                endAdornment,
            }}
            inputProps={{ ref, prefix, value, thousandSeparator, className, suffix, isAllowed: withValueCap, automaticErase, ...rest }}
        />
    );
})

export default React.memo(NumericFormat)