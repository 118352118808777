import { ArchiveRoundedIcon, EditIconRounded, LocalPrintshopRoundedIcon, SimCardDownloadRoundedIcon } from "@component/UIComponents"
import { routes } from "@util/routes";
import { useCallback } from "react"
import { exportComponentAsPNG } from "react-component-export-image";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

export const useOrderInvoiceMenus = (cProps={}) => {
    const { invoiceRef, purchaseOrderSummaryRef } = cProps
    const navigate = useNavigate()

    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
    });

    const downloadInvoiceImage = useCallback((order) => exportComponentAsPNG(invoiceRef, { fileName: `#P${(order?.consecutive??"")}` }), [invoiceRef])
    const downloadPurchaseOrderSummaryImage = useCallback((order) => exportComponentAsPNG(purchaseOrderSummaryRef, { fileName: `#P${(order?.consecutive??"")}` }), [purchaseOrderSummaryRef])

    const primaryMenu = useCallback((props={}, config={}) => {
        const { ordersToSuppliers } = routes
        return [
            { title: "Editar", onClick: () => navigate(ordersToSuppliers({ orderPurchaseId: (props?._id??null) })), icon: <EditIconRounded /> },
            { title: "Descargar cotización de pedido", onClick: () => downloadInvoiceImage(props), icon: <SimCardDownloadRoundedIcon /> },
            { title: "Descargar resumen de pedido", onClick: () => downloadPurchaseOrderSummaryImage(props), icon: <SimCardDownloadRoundedIcon /> },
            { title: "Imprimir", onClick: () => handlePrint(), icon: <LocalPrintshopRoundedIcon /> },
            { title: "Archivar", onClick: () => null, icon: <ArchiveRoundedIcon /> },
        ]
    }, [handlePrint, downloadInvoiceImage, navigate, downloadPurchaseOrderSummaryImage])

    return {
        primaryMenu,
    }
}