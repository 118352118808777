import { Box } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"
import { useGetFixedExpensesToPaymentList } from "../hooks/useFixedCosts"
import { currencyFormat } from '@util/currencyFormat'

const FixedExpenseFinder = ({ label="Gastos fijos", getOptionDisabled=undefined, multiple=undefined, fullWidth=undefined, size=undefined, onChange=undefined, value=null, sx=undefined, inputRef=undefined, queryValue=undefined, compare=undefined }) => {
    const { fixedExpenses, isFetching } = useGetFixedExpensesToPaymentList({ variant: "paymentList" })
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: fixedExpenses, queryValue, compare })
    let val = getAutocompleteValue()
    return(
        <>
            <Autocomplete
                getOptionDisabled={getOptionDisabled}
                getOptionLabel={(param) => `${(param?.name??"")} - ${currencyFormat((param?.balance??0))}`}
                multiple={multiple}
                ref={inputRef}
                sx={sx}
                onChange={onChange}
                value={val}
                fullWidth={fullWidth}
                size={size}
                label={label}
                options={fixedExpenses}
                loading={isFetching}
                renderOption={(props, option) => {
                    return(
                        <li {...props} key={option._id} >
                            <Box component={'span'} display={'flex'} flexDirection={'column'} >
                                <Box lineHeight={1.2} fontSize={'.875rem'} fontWeight={'bold'} > {(option?.name??"")} </Box>
                                <Box lineHeight={1.2} fontSize={'.875rem'} fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }} > {currencyFormat((option?.balance??0))} </Box>
                            </Box>
                        </li>
                    )
                }}
            />
        </>
    )
}

export default FixedExpenseFinder