import { createFilterOptions } from '@component/UIComponents'
import Autocomplete from "./Autocomplete";

const filter = createFilterOptions();

const AutocompleteCreable = ({ onChange=undefined, getOptionLabel=undefined, multiple=undefined, sx=undefined, disabled=undefined, options=[], fullWidth=undefined, size=undefined, loading=undefined, value=undefined, label="", inputRef=undefined }) => {
    const handleOnChange = (type, value) => {
        if( Boolean(onChange) ){
            onChange(type, value)
        }
    }

    return (
        <>
            <Autocomplete
                multiple={multiple}
                ref={inputRef}
                sx={sx}
                disabled={disabled}
                loading={loading}
                size={size}
                value={value}
                label={label}
                fullWidth={fullWidth}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        handleOnChange("newValue", {
                            _id: 0,
                            name: (newValue?.inputValue??"")
                        })
                    } else if (newValue && newValue.inputValue) {
                        handleOnChange("newValue", {
                            _id: 0,
                            name: (newValue?.inputValue??"")
                        })
                    } else {
                        handleOnChange("oldValue", newValue)
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            name: `Añadir "${inputValue}"`,
                        });
                    }
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={options}
                getOptionLabel={ Boolean(getOptionLabel) ? (option) => getOptionLabel(option) : (option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                }}
                renderOption={(props, option) => <li {...props} key={(option?._id??"")} >{option.name}</li>}
                freeSolo
            />
        </>
    )
}

export default AutocompleteCreable