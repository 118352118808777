import { useNavigate } from "react-router-dom"
import { IconButton, PointOfSaleRoundedIcon, Tooltip } from "./UIComponents"
import { routes } from "@util/routes"
const { sales } = routes

const POSModuleButton = () => {
    const navigate = useNavigate()
    return(
        <>
            <Tooltip arrow title="VENDER" placement="bottom" >
                <IconButton onClick={() => navigate(sales({ saleId: "" }))} >
                    <PointOfSaleRoundedIcon className="iconNotificationSize" sx={{ color: (theme) => theme.palette.primary.contrastText }} />
                </IconButton>
            </Tooltip>
        </>
    )
}

export default POSModuleButton