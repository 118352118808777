import { Box, Card, Container, Step, StepLabel, Stepper } from '@component/UIComponents'
import { useRegisterController } from './hooks/useRegisterController'
import { useEffect } from 'react'

const SingIn = () => {
    const { steps, activeStep, stepValue } = useRegisterController()
    useEffect(() => {
        const elem = document.getElementById("root")
        if( Boolean(elem) ){
            elem.style.backgroundColor = "#d8eaff"
        }
    }, [])
    return(
        <Container sx={{ width: "100%", height: "100%" }} >
            <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} >
                <Stepper sx={{ width: "100%", mb: (theme) => theme.spacing(3) }} activeStep={activeStep} alternativeLabel >
                    {steps.map((val, index) => {
                        return(
                            <Step key={index} >
                                <StepLabel>
                                    {(val?.label??"")}
                                </StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                <Container maxWidth="sm" >
                    <Card elevation={0} variant='outlined' sx={{ padding: (theme) => theme.spacing(3) }} >
                        {(stepValue?.component??<></>)}
                    </Card>
                </Container>
            </Box>
        </Container>
    )
}

export default SingIn