import { Box, Dialog, DialogContent, Grid, TableCell, TableRow, useMediaQuery, useTheme } from "@component/UIComponents"
import SuperDataGrid from "@component/SuperDataGrid"
import ClosingSummaryCard from "./components/DetailsCard"
import { useGetGeneralBalanceOfTheBusinessQuery } from "@query/Business"
import { useEffect, useMemo, useState } from "react"
import { currencyFormat } from "@util/currencyFormat"
import dayjs from "dayjs"
import ComponentTitle from "@component/ComponentTitle"
import { useTranslation } from "react-i18next"
import { useGetUserSessionInformation } from "@feature/Users"

const Panel = () => {
    const { userData } = useGetUserSessionInformation()
    const { t } = useTranslation()
    const [generalBalance, setGeneralBalance] = useState(null)
    const { data } = useGetGeneralBalanceOfTheBusinessQuery()
    const { summary=[] } = useMemo(() => (data?.payload??{}), [data])
    const { assets={}, liabilities={}, profitOfTheDay={}, createdAt=null } = (generalBalance??{})
    const [widths, setWidths] = useState({})
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    useEffect(()=>{
        if(isMobile){
            setWidths({
                minWidth: 160,
                maxWidth: 280
            })
        }
        else{
            setWidths({
                minWidth: 360,
                maxWidth: 400
            })
        }
    },[isMobile])
    if( ((userData?.rol?.internalName??"") === "admin") ){
        return(
            <>
                <Box p={1} >
                    <ComponentTitle title="Panel" />
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <SuperDataGrid
                                columns={[
                                    {minWidth: 150, maxWidth: 150, field: "assets", headerName: "Activo", renderCell: ({ row }) => currencyFormat((row?.assets?.totalAssets??0)) },
                                    {minWidth: 150, maxWidth: 150, field: "liabilities", headerName: "Pasivo", renderCell: ({ row }) => currencyFormat((row?.liabilities?.totalLiabilities??0)) },
                                    {minWidth: 150, maxWidth: 150, field: "capital", headerName: "Capital", renderCell: ({ row }) => currencyFormat((row?.capital??0)) },
                                    {minWidth: 130, maxWidth: 130, field: "profit", headerName: "Utilidad", renderCell: ({ row }) => currencyFormat((row?.profitOfTheDay?.profit?.amount??0)) },
                                    {minWidth: 130, maxWidth: 130, field: "variableExpenses", headerName: "Gastos", renderCell: ({ row }) => currencyFormat((row?.profitOfTheDay?.variableExpenses?.amount??0)) },
                                    {minWidth: 130, maxWidth: 130, field: "profitOfTheDay", headerName: "Gano", renderCell: ({ row }) => currencyFormat((row?.profitOfTheDay?.total??0)) },
                                    {minWidth: 150, maxWidth: 150, field: "newCapital", headerName: "Nuevo capital", renderCell: ({ row }) => currencyFormat(((row?.capital??0)+(row?.profitOfTheDay?.total??0))) },
                                    {minWidth: 160, maxWidth: 160, field: "createdAt", headerName: "Fecha", renderCell: ({ row }) => dayjs(row?.createdAt).format("DD-MM-YYYY") },
                                ]}
                                rows={summary}
                                useSearchBar={false}
                                onRowClick={({ row }) => setGeneralBalance(row) }
                            />
                        </Grid>
                        <Dialog open={Boolean((generalBalance?._id))} onClose={() => setGeneralBalance(null)} maxWidth="lg" >
                            <DialogContent>
                                <Grid container spacing={2} >
                                    <Grid xs={12} >
                                        <Box fontWeight={'bold'} textTransform={'uppercase'} > balance del {dayjs(createdAt).format("DD-MM-YYYY")} </Box>
                                    </Grid>
                                    <Grid xs={`${isMobile ? 12 : 4}`} >
                                        <ClosingSummaryCard
                                            title="Activos"
                                            minWidth={widths.minWidth}
                                            maxWidth={widths.maxWidth}
                                            totalValue={(assets?.totalAssets??0)}
                                            extendsSummary={
                                                <>
                                                    <TableRow >
                                                        <TableCell>Inventario</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((assets?.inventoryValue?.amount??0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>Caja principal</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((assets?.mainCashBalance?.amount??0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>Caja POS</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((assets?.cashiers?.amount??0))}</TableCell>
                                                    </TableRow>
                                                    { (assets?.banksBalance?.results??[]).map((bank) => {
                                                        return(
                                                            <TableRow key={(bank?._id??null)} >
                                                                <TableCell>{(bank?.name??"")}</TableCell>
                                                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((bank?.balance??0))}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }) }
                                                    { (assets?.pocketsBalance?.results??[]).map((pocket) => {
                                                        return(
                                                            <TableRow key={(pocket?._id??null)} >
                                                                <TableCell>{t((pocket?.name??""))}</TableCell>
                                                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((pocket?.amount??0))}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }) }
                                                    <TableRow >
                                                        <TableCell>Anticipo proveedores</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((assets?.supplierAdvance?.amount??0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>CxC</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((assets?.accountsReceivable?.amount??0))}</TableCell>
                                                    </TableRow>
                                                </>
                                            }
                                        />
                                    </Grid>
                                    <Grid xs={`${isMobile ? 12 : 4}`} >
                                        <ClosingSummaryCard
                                            title="Pasivos"
                                            minWidth={widths.minWidth}
                                            maxWidth={widths.maxWidth}
                                            totalValue={(liabilities?.totalLiabilities??0)}
                                            extendsSummary={
                                                <>
                                                    <TableRow >
                                                        <TableCell>Deuda proveedores</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((liabilities?.debtsFromSuppliers?.amount??0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>Gastos fijos</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((liabilities?.fixedExpends?.amount??0))}</TableCell>
                                                    </TableRow>
                                                </>
                                            }
                                        />
                                    </Grid>
                                    <Grid xs={`${isMobile ? 12 : 4}`} >
                                        <ClosingSummaryCard
                                            title="Ganancia"
                                            minWidth={widths.minWidth}
                                            maxWidth={widths.maxWidth}
                                            totalValue={(profitOfTheDay?.total??0)}
                                            extendsSummary={
                                                <>
                                                    <TableRow >
                                                        <TableCell>Ganancia</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((profitOfTheDay?.profit?.amount??0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>Gastos variables</TableCell>
                                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currencyFormat((profitOfTheDay?.variableExpenses?.amount??0))}</TableCell>
                                                    </TableRow>
                                                </>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Box>
            </>
        )
    }else{
        return(
            <></>
        )
    }
}

export default Panel