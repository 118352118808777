import { usePanel } from "@hook/usePanel"
import { useState } from "react"

export const useProductsController = () => {
    const { getActiveFilters } = usePanel()
    const [filters, setFilters] = useState({ showProduct: "", groupBy: "", categories: "", providers: "", listType: "" })
    const handlerSetFilters = (field, value) => setFilters(prevState => ({...prevState, [field]: value}))
    const activeFilters = getActiveFilters({ currentFilters: Object.keys(filters) })

    return {
        activeFilters,
        filters,
        handlerSetFilters
    }
}