import { Box, Typography } from '@component/UIComponents'

const EmptyList = ({ icon=<></>, text="" }) => {
    return(
        <>
            <Box textAlign={'center'} >
                <Box sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: "3rem",
                        color: (theme) => theme.palette.grey[500]
                    }
                }} >
                    {icon}
                </Box>
                <Typography sx={{ color: (theme) => theme.palette.grey[500] }} >
                    {text}
                </Typography>
            </Box>
        </>
    )
}

export default EmptyList