import { useInvoiceController } from "@feature/Reports/Invoice/hooks/useInvoiceController"
import { getRandomColor, preventAction, roundNumber } from "@util/helpers"
import { Toast } from "@util/swal"
import { useCallback, useState } from "react"
import { useRegisterCreditNote } from "./useRegisterCreditNote"

export const useCreditNoteController = () => {
    const { registerCreditNote } = useRegisterCreditNote()
    const { calculateTotalSalesInvoice } = useInvoiceController()
    const [Identity, setIdentity] = useState([])
    const [affectedProducts, setAffectedProducts] = useState([])
    const [dialogs, setDialogs] = useState({
        displayModalToAddADescriptionToTheCreditNote: false
    })

    const addAffectedProductToTheList = useCallback((product=null) => {
        if( Boolean((product?.productId??"")) ){
            const currentIdentity = [...Identity]
            const currentList = [...affectedProducts]

            let index = currentList.findIndex((n) => (n?.productId??"") === (product?.productId??""))
            if( index >= 0 ){
                currentList[index] = product
            }else{
                let color = getRandomColor()
                currentIdentity.push({
                    _id: (product?.productId??""),
                    color,
                })
                currentList.push(product)
            }
            setAffectedProducts(currentList)
            setIdentity(currentIdentity)
        }
    }, [affectedProducts, Identity])

    const getColorIdentity = useCallback((id) => {
        const color = Identity.find((n) => id === (n?._id??""))
        return (color?.color??"")
    }, [Identity])

    const removeAffectedProduct = useCallback((id) => {
        const currentList = [...affectedProducts]
        const currentIdentity = [...Identity]
        const index = currentList.findIndex((n) => (n?.productId??"") === id)
        const indexIdentity = currentIdentity.findIndex((n) => (n?._id??"") === id)

        if( index >= 0 ){
            currentList.splice(index, 1)
        }
        if( indexIdentity >= 0 ){
            currentIdentity.splice(indexIdentity, 1)
        }

        setIdentity(currentIdentity)
        setAffectedProducts(currentList)
    }, [Identity, affectedProducts])

    const deleteProductFromTheInvoice = useCallback((product=null) => {
        if( Boolean((product?.productId??"")) ){
            const currentIdentity = [...Identity]
            const currentList = [...affectedProducts]

            let index = currentList.findIndex((n) => (n?.productId??"") === (product?.productId??""))
            if( index >= 0 ){
                currentList[index].affect = "remove"
            }else{
                let color = getRandomColor()
                currentIdentity.push({
                    _id: (product?.productId??""),
                    color,
                })
                let payload = {...product}
                payload.affect = "remove"
                currentList.push(payload)
            }
            setAffectedProducts(currentList)
            setIdentity(currentIdentity)
        }
    }, [affectedProducts, Identity])

    const getRemovedProduct = useCallback((id) => {
        const currentList = [...affectedProducts]
        let p = currentList.find((n) => ( ((n?.productId??"") === id) && ((n?.affect??"") === "remove")) ) || null
        return p
    }, [affectedProducts])

    const areYouRemovingAllTheProducts = useCallback((allProducts=[]) => {
        const currentList = [...affectedProducts]
        const removeProducts = currentList.filter((n) => (n?.affect??"") === "remove")
        return ((removeProducts?.length??0) === (allProducts?.length??0))
    }, [affectedProducts])

    const creditNoteDispatchHandler = useCallback((allProducts=[]) => {
        const removeAll = areYouRemovingAllTheProducts(allProducts)
        if( removeAll ){
            preventAction({
                icon: "warning",
                text: "Esta removiendo todos los productos de la lista, la nota crédito sera asumida como una nota crédito de anulación total. ¿Desea continuar con la operación?",
                onSubmit: () => setDialogs((prevState) => ({...prevState, displayModalToAddADescriptionToTheCreditNote: true}))
            })
        } else {
            setDialogs((prevState) => ({...prevState, displayModalToAddADescriptionToTheCreditNote: true}))
        }
    }, [areYouRemovingAllTheProducts])

    const dialogWindowManager = useCallback((dialog, value) => {
        setDialogs((prevState) => ({...prevState, [dialog]: value}))
    }, [])

    const mergeInvoiceWithCreditNote = useCallback(({invoice={}, creditNoteProducts=[]}) => {
        let currentInvoice = {...invoice}
        let invoiceProducts = (currentInvoice?.products??[])

        const newProductsList = invoiceProducts.map((n) => {
            let r = {}
            const productIndex = creditNoteProducts.findIndex((x) => ((x?.productId??"") === (n?.productId??"")))
            r = n
            if( productIndex >= 0 ){
                let creditNoteProduct = creditNoteProducts[productIndex]
                creditNoteProduct = {...creditNoteProduct}

                if( (creditNoteProduct?.affect??"") === "remove" ) r = null
                if( (creditNoteProduct?.affect??"") !== "remove" ) {
                    let invoiceProduct = invoiceProducts.find((x) => (x?.productId??"") === (creditNoteProduct?.productId??""))
                    invoiceProduct = {...invoiceProduct}
                    creditNoteProduct.quantity = (invoiceProduct?.quantity??0)-(creditNoteProduct?.quantity??0)
                    r = creditNoteProduct
                }
            }
            return r
        }).filter((n) => Boolean(n?._id))

        const totalizeInvoice = calculateTotalSalesInvoice({ products: newProductsList })
        const newInvoice = {...currentInvoice, ...totalizeInvoice, products: newProductsList}

        const totalCreditNote = calculateTotalSalesInvoice({ products: creditNoteProducts }).total
        const differenceBetweenTheTotalOfTheInvoices = ((currentInvoice?.total??0)-(newInvoice?.total??0))
        const differenceInProfitBetweenTheInvoices = roundNumber(((currentInvoice?.totalProfitCurrency??0)-(newInvoice?.totalProfitCurrency??0)))

        const invoiceCreditNote = {...currentInvoice, products: creditNoteProducts, total: totalCreditNote}

        return {
            newInvoice,
            oldInvoice: currentInvoice,
            differenceBetweenTheTotalOfTheInvoices,
            differenceInProfitBetweenTheInvoices,
            totalCreditNote,
            invoiceCreditNote,
        }
    }, [calculateTotalSalesInvoice])

    const recordCreditNote = useCallback(({allProducts=[], description="", invoiceId, invoice={}}) => {
        const currentList = [...affectedProducts]
        const typeOfCreditNote = areYouRemovingAllTheProducts(allProducts) ? "totalCancellation" : "partialCancellation"
        const invoiceMerging = mergeInvoiceWithCreditNote({invoice, creditNoteProducts: currentList})
        let payload = {
            invoiceId,
            products: currentList,
            typeOfCreditNote,
            description,
            differenceInTotal: invoiceMerging.differenceBetweenTheTotalOfTheInvoices,
            differenceInProfit: invoiceMerging.differenceInProfitBetweenTheInvoices,
            total: invoiceMerging.totalCreditNote
        }
        if( currentList.length <= 0 ){
            Toast.fire({
                icon: "warning",
                text: "Añada al menos un producto a la lista de afectados."
            })
            return
        }

        if( !Boolean(invoiceId) ){
            Toast.fire({
                icon: "error",
                text: "No fue posible cargar el identificador de la factura cierre la ventana he intente nuevamente."
            })
            return
        }
        registerCreditNote(payload)
    }, [affectedProducts, areYouRemovingAllTheProducts, mergeInvoiceWithCreditNote, registerCreditNote])

    return {
        affectedProducts,
        setAffectedProducts,
        addAffectedProductToTheList,
        getColorIdentity,
        removeAffectedProduct,
        deleteProductFromTheInvoice,
        getRemovedProduct,
        areYouRemovingAllTheProducts,
        creditNoteDispatchHandler,
        dialogs,
        dialogWindowManager,
        recordCreditNote,
        mergeInvoiceWithCreditNote,
    }
}