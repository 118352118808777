import Form from "@component/Form"
import { Box, Button, Grid } from "@component/UIComponents"
import ProviderFinder from "@feature/Providers/components/ProviderFinder"
import { useURLParams } from "@hook/useURLParams"
import { transformArrayIntoString } from "@util/helpers"
import DateFilter from "@component/Date"
import { useFiltersOrderList } from "../hooks/useFiltersOrderList"
import dayjs from "dayjs"


const Filters = () => {
    const { addQuery, delAllQueries } = useURLParams()
    const { filters, setDates, setSupplier } = useFiltersOrderList()
    const { startDate, endDate, providers } = filters

    const transformArray = (value, callback=(n) => (n?._id??"")) => transformArrayIntoString(value, { mapFunction: callback, separator: "," })

    const handleSubmitFilters = () => {
        let f = {}
        let currentFilters = {...filters}
        delAllQueries()
        Object.keys(currentFilters).forEach((item) => {
            if( Boolean(currentFilters[item]) ){
                f[item] = currentFilters[item]
            }
        })
        addQuery(f)
    }

    return(
        <>
        <Box>
            <Form onSubmit={handleSubmitFilters} >
                <Grid container spacing={1} >
                    <Grid xs={12} >
                        <DateFilter
                            startDate={dayjs(startDate)}
                            endDate={dayjs(endDate)}
                            onChange={(dates) => setDates(prevState => ({...prevState, ...dates}))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <ProviderFinder
                            queryValue={providers}
                            compare={(n) => (n?._id??"")}
                            multiple
                            onChange={(_, value) => setSupplier(transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <Box display={'flex'} justifyContent={'space-between'} >
                            <Button color='error' onClick={() => null} >
                                Limpiar filtros
                            </Button>
                            <Button type='submit' >
                                Filtrar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Form>
        </Box>
        </>
    )
}

export default Filters