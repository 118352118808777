export const currencyFormat = (value=0, config={}) => {
    const { applyCurrencyFormat=true, ...rest } = config
    let format = value.toLocaleString('es-CO', {
        ...(applyCurrencyFormat ? {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        } : {}),
        ...rest
    });
    return format.replace(/\.00$/, '')
}

export const calcDecimals = ({number=0, decimals=2}) => {
    let val = parseInt(((number).toFixed(decimals)))
    val = (val <= 0) ? 0 : val
    return val
}

export const calculateProfitMargin = (sale=0, profit=0) => {
    const percentage = (profit/sale)*100
    let toFixedNumber = Number.parseFloat(String(percentage)).toFixed(2)
    toFixedNumber = parseFloat(toFixedNumber)
    toFixedNumber = (toFixedNumber <= 0) ? 0 : toFixedNumber
    toFixedNumber = Number.isNaN(toFixedNumber) ? 0 : toFixedNumber
    return toFixedNumber
}

export const calculateProfitAndBaseMarginSale = (cost=0, sale=0) => {
    let profit = calcDecimals({ number: (sale-cost) })
    let margin = calculateProfitMargin(sale, profit)
    return { profit, margin }
}

export const calculateSalesAndMarginBasedProfit = (cost, profit) => {
    let sale = calcDecimals({ number: (cost+profit) })
    let margin = calculateProfitMargin(sale, profit)
    return { sale, margin }
}

export const calculateProfitAndSaleBasedMargin = (cost, margin) => {
    let sale = calcDecimals({ number: (cost/((100-margin)/100)) })
    const profit = calcDecimals({ number: (sale-cost) })
    return { sale, profit }
}
