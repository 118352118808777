import { useCallback, useState } from "react"

export const useControllerPackages = () => {
    const [selectedPackaging, setSelectedPackaging] = useState(null)
    const [showCreatePackageForm, setShowCreatePackageForm] = useState({ package: null, open: false })

    const closePackageRegistrationForm = useCallback(() => {
        setShowCreatePackageForm({ package: null, open: false })
    }, [])

    return {
        selectedPackaging,
        setSelectedPackaging,
        showCreatePackageForm,
        setShowCreatePackageForm,
        closePackageRegistrationForm
    }
}