import Form from '@component/Form'
import NumericFormat from '@component/NumericFormat'
import { Button, Checkbox, CloseRoundedIcon, Dialog, DialogActions, DialogContent, FormControlLabel, FormHelperText, Grid, TextField, Typography } from '@component/UIComponents'
import { useFixedCostsFormData, useRegisterFixedExpense } from '../hooks/useFixedCosts'
import AutocompleteCreable from '@component/AutocompleteCreable'
import { useURLParams } from '@hook/useURLParams'
import { currencyFormat } from '@util/currencyFormat'
import dayjs from 'dayjs'
import { useEffect } from 'react'

const FixedExpenseForm = ({ defaultValues={} }) => {
    const { getQuery, delQueries } = useURLParams()
    const open = getQuery("form") === "record-fixed-expense"
    const { values, setFieldValue, validateValues, resetValues } = useFixedCostsFormData({ defaultValues })
    const { name, amount, each, autoTracking, tag, adjustCurrentMonth, adjustBiweekly } = values
    const { registerFixedExpense, isLoading } = useRegisterFixedExpense()

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if( type === "errors" ) return
        registerFixedExpense(values, {
            clearForm: () => resetValues(),
            closeForm: () => delQueries(['form'])
        })
    }

    useEffect(() => {
        if( open === false ){
            resetValues()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return(
        <>
            <Dialog fullWidth transitionDuration={0} open={open} onClose={() => delQueries(['form'])} PaperProps={{ elevation: 0 }} >
                <Form onSubmit={handleSubmit} >
                    <Grid
                    container
                    direction="row"
                    alignContent='center'
                    justifyContent='space-between'
                    >
                        <Typography px={2} textTransform={'uppercase'} lineHeight={1.2} pt={2} pb={0} fontWeight={'bold'} fontSize={'1rem'} display={'flex'} flexDirection={'column'} >
                            Crear Gasto fijo
                        </Typography>
                        <Button
                        onClick={()=>delQueries(['form'])}
                        >
                            <CloseRoundedIcon sx={{ color: 'red'}}/>
                        </Button>
                    </Grid>
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={12} >
                                <TextField
                                    inputProps={{
                                        style:{
                                            fontWeight: 'bold'
                                        }
                                    }}
                                    fullWidth
                                    placeholder="Gasto"
                                    value={name}
                                    onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat label="Monto" value={amount} onChange={({ floatValue }) => setFieldValue("amount", (floatValue??0))} />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat disabled={adjustCurrentMonth || adjustBiweekly} label="Vence cada" prefix='' suffix={ ((each === 0) || (each >= 2)) ? ' días' : ' dia'} thousandSeparator='' onChange={({ floatValue }) => setFieldValue("each", (floatValue??0))} maxVal={31} value={each} />
                            </Grid>
                            <Grid xs={4} >
                                <AutocompleteCreable value={tag} label="Etiqueta" onChange={(_, params) => setFieldValue("tag", params)} />
                            </Grid>
                            <Grid xs={12}>
                                <FormControlLabel
                                    label="Seguimiento automático"
                                    control={<Checkbox sx={{ paddingTop: 0, paddingBottom: 0 }} checked={autoTracking} onChange={({ target }) => setFieldValue("autoTracking", (target?.checked??false))} />}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <FormControlLabel
                                    label="Ajustar al mes"
                                    control={<Checkbox sx={{ paddingTop: 0, paddingBottom: 0 }} checked={adjustCurrentMonth} onChange={({ target }) => {
                                        setFieldValue("adjustCurrentMonth", (target?.checked??false))
                                        setFieldValue("adjustBiweekly", false)
                                    }} />}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <FormControlLabel
                                    label="Ajustar a la quincena"
                                    control={<Checkbox sx={{ paddingTop: 0, paddingBottom: 0 }} checked={adjustBiweekly} onChange={({ target }) => {
                                        setFieldValue("adjustBiweekly", (target?.checked??false))
                                        setFieldValue("adjustCurrentMonth", false)
                                    }} />}
                                />
                            </Grid>
                            <Grid xs={12}>
                                {adjustCurrentMonth &&
                                    <>
                                        <FormHelperText> El monto diario correspondiente al mes es de {currencyFormat((amount/30))}.</FormHelperText>
                                        <FormHelperText> Se generara un corte mensual de {currencyFormat(amount)} </FormHelperText>
                                    </>
                                }
                                {adjustBiweekly &&
                                    <>
                                        <FormHelperText> El monto diario correspondiente a la quincena del mes es de {currencyFormat((amount/30))}.</FormHelperText>
                                        <FormHelperText> Se generara un corte quincenal de {currencyFormat((amount/2))} </FormHelperText>
                                    </>
                                }
                                {!autoTracking &&
                                    <FormHelperText> El seguimiento automático se encuentra desactivado deberá generar manualmente los cortes de este gasto. </FormHelperText>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} > Guardar </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default FixedExpenseForm