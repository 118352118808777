import { useRegisterOrderMutation } from "@query/billing"
import { useCallback, useMemo } from "react"
import _ from 'lodash'
import { Toast } from "@util/swal"
import { usePrintInvoice } from "@feature/Sales/hooks/usePrintInvoice"

export const useRegisterOrder = ({ products=[], customer=null, total=0, totalProfitCurrency=0, totalProfitPercentage=0, onCleanBilling=()=>null, onPayBill=()=>null, user=null }) => {
    const { cashier=false, rol="" } = (user??{})
    const [ registerOrderMutation, { isLoading } ] = useRegisterOrderMutation()
    const { PrintInvoice } = usePrintInvoice()

    const payload = useMemo(() => {
        return {
            products,
            customer,
            total,
            totalProfitCurrency,
            totalProfitPercentage,
        }
    }, [products, customer, total, totalProfitCurrency, totalProfitPercentage])

    const validateOrder = useCallback(() => {
        try {
            if( !_.isArray(products) ) throw "Se esperaba una lista con los productos."
            if( products.length <= 0 ) throw "Añade al menos un producto antes de registrar el pedido."
            return true
        } catch (error) {
            throw error
        }
    }, [products])

    const registerOrder = useCallback(async (config={ print: false }) => {
        try {
            const isValid = validateOrder()
            if((isValid === true)){
                const { print=false } = (config??{})
                const resp = await registerOrderMutation(payload).unwrap()
                const order = (resp.payload??{})
                const { _id, orderNumber, customer=null } = (order??{})
                if( Boolean(_id) ){
                    Toast.fire({
                        icon: "success",
                        text: `El pedido numero #P-${orderNumber} registrado a ${customer?.name??"VARIOS"} se grabo exitosamente.`
                    })
                    onCleanBilling()
                    if(print === true){
                        PrintInvoice({ BillID: _id, invoiceType: "order" })
                    }
                    if((rol?.internalName) === "admin" || cashier === true) {
                        onPayBill(_id)
                    }
                }
            }
        } catch (error) {
            Toast.fire({ icon: "error", text: error })
        }
    }, [validateOrder, payload, registerOrderMutation, onCleanBilling, PrintInvoice, cashier, onPayBill, rol])

    return {
        registerOrder,
        WaitingForSavedOrder: isLoading
    }
}