import Form from '@component/Form'
import NumericFormat from '@component/NumericFormat'
import { AddShoppingCartRoundedIcon, Box, CloseRoundedIcon, Dialog, DialogContent, Grid, IconButton, Stack, TextField, styled } from '@component/UIComponents'
import { elementFocus } from '@util/helpers'
import { useEffect } from 'react'
import { useState } from 'react'

const StackStyled = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "editable"
})(({ theme, editable }) => ({
    padding: theme.spacing(.5),
    ...(editable ? {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.error.light,
        '& .MuiSvgIcon-root' : {
            color: theme.palette.background.paper
        },
    } : {})
}))

const ServiceRegistration = ({ productList=[], onAddService=()=>null, onAddCustomer=()=>null, open=false, onClose=()=>null }) => {
    const [serviceModel, setServiceModel] = useState({ costPrice: 1, name: "", productId: null, quantity: 1, salePrice: 0, typePrice: "manual", pricePosition: 0, productType: "service" })
    const [customerName, setCustomerName] = useState("")

    const handleSubmitService = () => {
        let currentProductList = [...productList]
        let currentService = {...serviceModel}
        currentService.productId = ((currentProductList?.length??0)+1)
        currentProductList.unshift(currentService)
        if( Boolean(onAddService) ){
            onAddService(currentProductList)
        }
        if( Boolean(onAddCustomer) && Boolean(customerName) ){
            onAddCustomer({ _id: 1, typeCustomer: "service", name: customerName })
        }
        onClose()
    }

    const handleChange = (field="", value) => setServiceModel((prevState) => ({...prevState, [field]: value}))

    useEffect(() => {
        if( open ){
            setTimeout(() => {
                elementFocus("customerName")
            }, 150)
        }
    }, [open])

    return(
        <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
            <DialogContent>
                <Form onSubmit={handleSubmitService} >
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <StackStyled direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                                <Box>
                                    <IconButton size="small" color='success' type='submit' >
                                        <AddShoppingCartRoundedIcon />
                                    </IconButton>
                                </Box>
                                <Box textAlign={'center'} fontWeight={'bold'} textTransform={'uppercase'} > Registrar servicio </Box>
                                <Box>
                                    <IconButton size="small" color='error' onClick={onClose} >
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Box>
                            </StackStyled>
                        </Grid>
                        <Grid xs={12} >
                            <TextField
                                id='customerName'
                                fullWidth
                                placeholder='Nombre del cliente'
                                onChange={({ target }) => setCustomerName((target?.value??""))}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontWeight: "bold"
                                    }
                                }}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <TextField
                                id='serviceName'
                                fullWidth
                                placeholder='Tipo de servicio'
                                onChange={({ target }) => handleChange("name", (target?.value??""))}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontWeight: "bold"
                                    }
                                }}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <NumericFormat
                                fullWidth
                                placeholder="Valor del servicio"
                                onChange={({ floatValue }) => handleChange("salePrice", (floatValue??0))}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontWeight: "bold"
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default ServiceRegistration