import { api, mutationConfig } from "../splitApi";
import { BoxTag, EmployeeTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerEmployee: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/employee", body };
            },
            invalidatesTags: EmployeeTag
        }),
        archiveEmployee: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/employee", body };
            },
            invalidatesTags: EmployeeTag
        }),
        updateEmployeeSettings: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/update/employee/settings", body };
            },
            invalidatesTags: EmployeeTag
        }),
        getEmployees: build.query({
            query: (params=undefined) => ({
                url: `/get/employees`,
                params
            }),
            providesTags: EmployeeTag
        }),
        getEmployee: build.query({
            query: (params=undefined) => ({
                url: `/get/employee`,
                params
            }),
            providesTags: EmployeeTag
        }),
        getCashiersEmployed: build.query({
            query: (params=undefined) => ({
                url: `/get/cashiers/employed`,
                params
            }),
            providesTags: [...EmployeeTag, ...BoxTag]
        }),
        GetEmployeeBillingMode: build.query({
            query: (params=undefined) => ({
                url: `/get/employee/billing/mode`,
                params
            }),
            providesTags: [...EmployeeTag, ...BoxTag]
        }),
    }),
    overrideExisting: false,
});

export const {
    useArchiveEmployeeMutation,
    useRegisterEmployeeMutation,
    useGetEmployeesQuery,
    useGetEmployeeQuery,
    useUpdateEmployeeSettingsMutation,
    useGetCashiersEmployedQuery,
    useGetEmployeeBillingModeQuery
} = Endpoints;