import { Box, Portal, Typography } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { getElement } from '@util/helpers'

const ComponentTitle = ({ title="", activeNavigatorTabTitle=undefined }) => {
    usePanel({ appTitle: (activeNavigatorTabTitle === true) ? title : "" })
    return(
        <>
            <Portal container={getElement({ attr: "auxiliary-portal" })} >
                <Box display={'flex'} alignItems={'center'} gap={2} >
                    <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1.2rem'} sx={{ color: (theme) => theme.palette.grey[200] }} >
                        {title}
                    </Typography>
                </Box>
            </Portal>
        </>
    )
}

export default ComponentTitle