import NumericFormat from '@component/NumericFormat'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@component/UIComponents'
import PackagingFinder from '@feature/Inventory/Packaging/components/PackagingFinder'
import { useProductPreSelectionFormData } from '../hooks/useControlPurchaseOrderForms'
import { useEffect } from 'react'
import Form from '@component/Form'
import { useControllerPreSelectedProduct } from '../hooks/useControllerOrderEntry'

const PreSelectProduct = ({ product=null, open=false, onClose=()=>null, onSubmitProduct=()=>null }) => {
    const { name="" } = (product??{})
    const { values, resetValues, setFieldValue } = useProductPreSelectionFormData({ defaultValues: product })
    const { packaging, purchaseCost, quantity } = values
    const { productShippingHandler } = useControllerPreSelectedProduct({ onSubmitProduct, values, currentProduct: product, closeForm: onClose })

    useEffect(() => {
        if( open === false ) resetValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return(
        <>
            <Dialog fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
                <Form onSubmit={productShippingHandler} >
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={12} >
                                <Typography fontWeight={'bold'} fontSize={'.875rem'} >
                                    {name}
                                </Typography>
                            </Grid>
                            <Grid xs={4} >
                                <PackagingFinder
                                    value={packaging}
                                    label=''
                                    placeholder="Embalaje"
                                    onChange={(_, params) => setFieldValue("packaging", params)}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat
                                    value={quantity}
                                    fullWidth
                                    placeholder="Cantidad"
                                    prefix="x"
                                    onChange={({ floatValue }) => setFieldValue("quantity", floatValue)}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat
                                    value={purchaseCost}
                                    fullWidth
                                    placeholder="Costo"
                                    onChange={({ floatValue }) => setFieldValue("purchaseCost", floatValue)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default PreSelectProduct