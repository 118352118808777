import { RemoveRoundedIcon, Box, Collapse, Divider, ExpandMoreRoundedIcon, KeyboardArrowUpRoundedIcon, List, ListItemButton, ListItemIcon, ListItemText, Popover, Tooltip, Typography, styled, useMediaQuery, useTheme } from '@component/UIComponents'
import { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    minWidth: '40px',
    '& svg': {
        fontSize: ""
    },
    color: 'black'
}))
const ListItemTitleStyled = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.grey[700]
    }
}));
const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: '.875rem',
        color: 'black'
    }
}));
const ListItemButtonStyled = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== "active"
})(({ active, theme }) => ({
    ...(active ? {
        paddingTop: '5px',
        paddingBottom: '5px',
        backgroundColor: 'rgb(51 135 237 / 13%)',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0px',
            transform: 'translateX(-50%)',
            width: '.7rem',
            height: '100%',
            backgroundColor: theme.palette.primary.main
        },
        '& .MuiListItemText-root': {
            '& .MuiTypography-root': {
                color: `${theme.palette.primary.main} !important`,
            }
        },
        '& .MuiListItemIcon-root': {
            '& svg': {
                color: `${theme.palette.primary.main} !important`,
            }
        },
    } : {})
}))


const DrawerListItemMenu = ({ list=[], open=false, setOpen=()=>null }) => {
    let [openMenu, setOpenMenu] = useState([])
    const [currentList, setCurrentList] = useState([])
    const [toggles, setToggles] = useState([])
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const onClosePopoverMenu = () => {
        let currentOpenMenu = [...openMenu]
        currentOpenMenu = currentOpenMenu.map(() => false)
        setOpenMenu(currentOpenMenu)
    }

    const handleMainListClick = (item, index, event) => {
        let currentToggle = [...toggles]
        if(item.name === 'Inventarios'){
            handleSecondaryListClick(item)
        }
        if (item.children.length >= 1) {
            if( open ){
                const currentValue = currentToggle[index]
                currentToggle = currentToggle.map((n) => {
                    return n === true ? false : n
                })
                currentToggle[index] = (currentValue === true) ? false : true
                setToggles(currentToggle)
            }else{
                let currentMenu = [...openMenu]
                onClosePopoverMenu()
                currentMenu[index] = event.currentTarget
                setOpenMenu(currentMenu)
            }

        } else {
            navigate(item.pathName)
            if( isMobile ){
                setOpen(false)
            }
        }
    }

    const handleSecondaryListClick = (item) => {
        if(typeof item.name === 'string'){
            navigate(item.pathName)
        }
        console.log(typeof item.name)
        if( isMobile ){
            setTimeout(() => {
                setOpen(false)
            }, 50)
        }
    }

    useEffect(() => {
        let currentToggles = [...toggles]
        for (let i in list) {
            const item = list[i]
            if (item.children.length >= 1) {
                currentToggles[i] = false
                setToggles(currentToggles)
            }
        }
        setCurrentList(list)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list])

    useEffect(() => {
        const _currentList = [...list]
        let currentToggles = [...toggles]
        for (let i in _currentList) {
            let found = false
            let item = _currentList[i]
            if( Boolean(item.prefix??"") ){
                const pathnamePrefix = pathname.split("/")[1]
                if (item.prefix === pathnamePrefix) {
                    item.active = true
                    break
                }
            }else{
                if (item.pathName === pathname) {
                    item.active = true
                    break
                }
            }
            for (let b in item.children) {
                let itemChildren = item.children[b]
                if( Boolean(itemChildren.prefix??"") ){
                    const pathnamePrefix = pathname.split("/")[1]
                    if (itemChildren.prefix === pathnamePrefix) {
                        itemChildren.active = true
                        currentToggles[i] = true
                        break
                    }
                }else if (itemChildren.pathName === pathname) {
                    itemChildren.active = true
                    currentToggles[i] = true
                    found = true
                    break
                }
            }
            if (found === true) break
        }
        setCurrentList(_currentList)
        setToggles(currentToggles)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, pathname])

    return (
        <>
            <List sx={{ position: "relative" }} >
                {currentList.map((item, index) => {
                    const useCollapse = (item.children.length >= 1)
                    const isOpenCollapse = useCollapse ? toggles[index] : false
                    return (
                        <Fragment key={index} >
                            <ListItemButtonStyled active={item.active} onClick={(event) => handleMainListClick(item, index, event)} >
                                <Tooltip title={(item?.name ?? "")} placement='left' arrow >
                                    <ListItemIconStyled>
                                        {item.icon}
                                    </ListItemIconStyled>
                                </Tooltip>
                                { open && <ListItemTitleStyled primary={(item?.name ?? "")} /> }
                                {(useCollapse && open) &&
                                    <ListItemIconStyled sx={{ minWidth: '24px !important' }} >
                                        {isOpenCollapse ? <ExpandMoreRoundedIcon /> : <KeyboardArrowUpRoundedIcon />}
                                    </ListItemIconStyled>
                                }
                            </ListItemButtonStyled>
                            {(useCollapse && open) &&
                                <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.children.map((collapseItem, collapseIndex) => {
                                            return (
                                                <Fragment key={collapseIndex} >

                                                    <ListItemButtonStyled active={collapseItem.active} sx={{ padding: 0.9 , pl: 4, margin: 0 }} onClick={() => handleSecondaryListClick(collapseItem)} >
                                                        <RemoveRoundedIcon sx={{ color: 'gray' }}/>
                                                        <ListItemTextStyled primary={collapseItem.name} />
                                                    </ListItemButtonStyled>
                                                </Fragment>
                                            )
                                        })}
                                    </List>
                                </Collapse>
                            }
                            {(useCollapse && !open) &&
                                <Popover open={Boolean(openMenu[index])} anchorEl={openMenu[index]} onClose={onClosePopoverMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Box display={'flex'} alignItems={'center'} p={1} >
                                        <ListItemIconStyled sx={{ minWidth: 30 }} >
                                            {item.icon}
                                        </ListItemIconStyled>
                                        <Typography fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > {(item?.name ?? "")} </Typography>
                                    </Box>
                                    <Divider sx={{ mb: 1 }} />
                                    <List component="div" disablePadding>
                                        {item.children.map((collapseItem, collapseIndex) => {
                                            return (
                                                <Fragment key={collapseIndex} >
                                                    <ListItemButtonStyled active={collapseItem.active} onClick={() => handleSecondaryListClick(collapseItem)} >
                                                        <ListItemTextStyled primary={collapseItem.name} />
                                                    </ListItemButtonStyled>
                                                </Fragment>
                                            )
                                        })}
                                    </List>
                                </Popover>
                            }
                        </Fragment>
                    )
                })}
            </List>
        </>
    )
}

export default DrawerListItemMenu