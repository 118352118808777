import Autocomplete from "@component/Autocomplete"
import { Button, Dialog, DialogActions, DialogContent, FileDownloadIconRounded } from "@component/UIComponents"
import { memo } from "react"
import { useSelectionsFields } from "../hooks/useSuperDataGrid"

const FieldSelection = ({ rows=[], columns=[], excludeFieldsToFile=[], fileName=undefined, open=false, onClose=()=>null, onDownloadBook=null }) => {
    const { options, selectedFields, setSelectedFields, downloadBook } = useSelectionsFields({ columns, excludeFieldsToFile, rows })
    const handleDownload = () => {
        if( Boolean(onDownloadBook) ){
            onDownloadBook({ selectedFields, excludedFields: excludeFieldsToFile, rows })
        }else{
            downloadBook({fileName})
        }
    }

    return(
        <>
            <Dialog PaperProps={{ elevation: 0 }} transitionDuration={0} maxWidth='xs' fullWidth open={open} onClose={onClose} >
                <DialogContent>
                    <Autocomplete
                        multiple
                        options={options}
                        value={selectedFields}
                        label="Campos"
                        onChange={(_, params) => setSelectedFields(params)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDownload} startIcon={<FileDownloadIconRounded />} >
                        Descargar archivo
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default memo(FieldSelection)