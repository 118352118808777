import AdvancedFilters from "@component/AdvancedFilters"
import { advanceFilters } from "@util/helpers"

const Filters = () => {
    const filters = advanceFilters({
        showOnlyOptions: [
            {name: "Aplica retención", value: "withRetention"},
            {name: "No aplica retención", value: "notApplyRetention"},
            {name: "Con anticipo", value: "inAdvance"},
            {name: "Sin anticipo", value: "withoutAdvance"},
            {name: "Archivados", value: "archived"},
        ].map((n, index) => ({...n, _id: (index+1)})),
        showOnlyMultiple: false,
        filters: {
            showOnly: true,
        }
    })
    return(
        <AdvancedFilters options={filters} />
    )
}

export default Filters