import { useState, useCallback } from "react"
import { useLazyRetrieveReceivedSaleInvoice } from "./useLazyRetrieveReceivedSaleInvoice"
import { useLazyGetProvider } from "@feature/Providers/hooks/useLazyGetProvider"
import { preventAction } from "@util/helpers"
import { useRegisterProvider } from "@feature/Providers/ProviderForm/hooks/useProviderForm"
import { useLazyGetProducts } from "@feature/Inventory/Products/hooks/useLazyGetProducts"
import { useNavigate } from "react-router-dom"
import { routes } from '@util/routes'
const { enterPurchase } = routes

export const useReceiptOfSalesInvoicesController = () => {
    const navigate = useNavigate()
    const { lazyGetProductsQuery } = useLazyGetProducts()
    const { registerProvider } = useRegisterProvider()
    const { lazyGetProviderQuery } = useLazyGetProvider()
    const { retrieveReceivedSaleInvoice } = useLazyRetrieveReceivedSaleInvoice()
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [popUpWindows, setPopUpWindows] = useState({ previewSalesInvoice: false })
    const handlePopUpWindows = useCallback((field="", value="") => setPopUpWindows((prevState) => ({...prevState, [field]: value})),[])

    const openPreviewInvoice = useCallback((invoiceId) => {
        setSelectedInvoice(invoiceId)
        handlePopUpWindows("previewSalesInvoice", true)
    }, [handlePopUpWindows])

    const closePreviewInvoice = useCallback(() => {
        setSelectedInvoice(null)
        handlePopUpWindows("previewSalesInvoice", false)
    }, [handlePopUpWindows])

    const enterReceivedInvoicePurchase = useCallback( async ({ receivedSale }) => {
        const { data } = await retrieveReceivedSaleInvoice({ receivedSale })
        const invoice = (data?.payload??null)
        const provider = ((await lazyGetProviderQuery({ name: (invoice?.business?.name??null) }))?.data?.payload??null)
        const isItASupplier = Boolean((provider?._id??null))

        if( !isItASupplier ){
            preventAction({
                text: `El negocio ${(invoice?.business?.name??"")} no se encuentra registrado en la lista de proveedores, para continuar con el proceso de ingresar la factura como una compra debe registrar este negocio como proveedor, ¿desea que registre el negocio como proveedor?.`,
                onSubmit: () => registerProvider({ ...(invoice?.business), _id: null }, {
                    closeForm: (provider) => {
                        preventAction({
                            icon: "success",
                            text: `El negocio ${(provider?.name??"")} ahora es un proveedor. ¿desea continuar con el proceso de registra la factura como una compra?`,
                            onSubmit: () => enterReceivedInvoicePurchase({ receivedSale })
                        })
                    }
                })
            })
        }else{

            const productsList = ((await lazyGetProductsQuery({ variant: "purchases" }))?.data?.payload??[])
            const productAlreadyExist = (code) => (productsList.find(n => (n?.purchaseCodes??[]).some((x) => x === code) )?._id??null)
            let products = (invoice?.products??[])
            products = products.map((n) => ({
                productId: (n?.productId??null),
                name: (n?.name??""),
                quantity: (n?.quantity??0),
                purchaseCost: (n?.costPrice??0),
                freight: 0,
                tax: null,
                packaging: null,
                new: !Boolean(productAlreadyExist(`${(n?.consecutive??0)}`)),
                codes: Boolean((n?.consecutive??0)) ? [`${n?.consecutive??0}`] : []
            }))
            navigate(enterPurchase(" "), {state: { order: {
                provider,
                invoiceNumber: (invoice?.consecutive??""),
                products,
                new: true
            } }})
        }

    }, [retrieveReceivedSaleInvoice, lazyGetProviderQuery, registerProvider, lazyGetProductsQuery, navigate])

    return {
        popUpWindows,
        handlePopUpWindows,
        setSelectedInvoice,
        selectedInvoice,
        openPreviewInvoice,
        closePreviewInvoice,
        enterReceivedInvoicePurchase
    }
}

export default useReceiptOfSalesInvoicesController