import { api, mutationConfig } from "../splitApi";
import { BoxTag, FixedExpenseTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerFixedExpense: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/fixed/expense", body };
            },
            invalidatesTags: FixedExpenseTag,
        }),
        archiveFixedExpenses: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/fixed/expenses", body };
            },
            invalidatesTags: FixedExpenseTag,
        }),
        recordPaymentToFixedCost: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/record/payment/to/fixed/cost", body };
            },
            invalidatesTags: [...FixedExpenseTag, ...BoxTag],
        }),
        generateNewExpenseAmount: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/generate/new/expense/amount", body };
            },
            invalidatesTags: FixedExpenseTag,
        }),
        getFixedExpenses: build.query({
            query: (params=undefined) => ({
                url: `/get/fixed/expenses`,
                params,
            }),
            providesTags: FixedExpenseTag
        }),
        getFixedExpensesToPaymentList: build.query({
            query: (params=undefined) => ({
                url: `/get/fixed/expenses`,
                params,
            }),
            providesTags: FixedExpenseTag
        }),
        getHistoryOfFixedExpenseRecords: build.query({
            query: (params=undefined) => ({
                url: `/get/history/of/fixed/expense/records`,
                params
            }),
            providesTags: FixedExpenseTag
        }),
        getHistoryOfPaymentsToAccountsPayable: build.query({
            query: (params=undefined) => ({
                url: `/get/history/of/payments/to/accounts/payable`,
                params
            }),
            providesTags: FixedExpenseTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useRegisterFixedExpenseMutation,
    useGetFixedExpensesQuery,
    useGetHistoryOfFixedExpenseRecordsQuery,
    useGetHistoryOfPaymentsToAccountsPayableQuery,
    useArchiveFixedExpensesMutation,
    useRecordPaymentToFixedCostMutation,
    useGenerateNewExpenseAmountMutation,
    useGetFixedExpensesToPaymentListQuery
} = Endpoints;
