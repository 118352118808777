import SingleDataGrid from "@component/SingleDataGrid"
import { useGetProductFamily } from "@feature/Inventory/Products/hooks/useProducts"
import { currencyFormat } from "@util/currencyFormat"
import { calculateTotal } from "@util/helpers"
import { useMemo } from "react"

const ProductFamilyCosts = ({ productId, freight, packaging, purchaseCost, quantity, tax }) => {
    const total = calculateTotal({ purchaseCost, quantity, tax:(tax?.tax??0) })
    let costUnit = (total/(packaging?.quantity??0))
    let freightUnit = (freight/(packaging?.quantity??0))
    const { productFamily, isFetching } = useGetProductFamily({ variant: "purchase", productId })
    let rows = useMemo(() => {
        return [...productFamily].reduce((acc, params) => {
        let currentAcc = [...(acc??[])]
        if( !Boolean((params?.compound??false)) ){
            currentAcc.push({
                name: (params?.name??""),
                cost: costUnit,
                freight: freightUnit,
                costWithFreight: (costUnit+freightUnit),
            })
        }else{
            currentAcc.push({
                name: (params?.name??""),
                cost: (costUnit*(params?.packaging?.quantity??0)),
                freight: (freightUnit*(params?.packaging?.quantity??0)),
                costWithFreight: ((costUnit*(params?.packaging?.quantity??0))+(freightUnit*(params?.packaging?.quantity??0))),
            })
        }
        return currentAcc
    }, [])
    }, [costUnit, freightUnit, productFamily])
    rows = rows.map((n, index) => ({...n, _id: index}))
    const columns = [
        {minWidth: 250, maxWidth: 250, field: "name", headerName : "Producto"},
        {minWidth: 160, maxWidth: 160, field: "cost", headerName : "Costo", renderCell: ({row}) => ` ${currencyFormat((row?.cost??0))} `},
        {minWidth: 160, maxWidth: 160, field: "freight", headerName : "Flete", renderCell: ({row}) => ` ${currencyFormat((row?.freight??0))} `},
        {minWidth: 160, maxWidth: 160, field: "costWithFreight", headerName : "Costo con flete", renderCell: ({row}) => ` ${currencyFormat((row?.costWithFreight??0))} `},
    ]

    return(
        <>
            <SingleDataGrid
                columns={columns}
                rows={rows}
                isFetching={isFetching}
            />
        </>
    )
}

export default ProductFamilyCosts