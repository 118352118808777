import { useGetOrderSupplierQuery } from "@query/PurchasesOrder"
import { useMemo } from "react"

export const useGetOrderSupplier = (props={}, config={}) => {
    const { refId=null } = props
    const { data, isFetching } = useGetOrderSupplierQuery(props, config)
    const order = useMemo(() => (data?.payload??null), [data])

    return {
        order: Boolean(refId) ? order : null,
        isFetchingPurchaseOrder: isFetching
    }
}