import TextField from '@component/TextField'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@component/UIComponents'
import { useTranslation } from 'react-i18next'
import { useRecoveryFormValues, useSendPasswordRecoveryEmail, useSendValidationEmail } from '../hooks/useLogin'
import { getErrorsParams } from '@hook/useFormik'
import Form from '@component/Form'

const RecoveryDialog = ({ setRecoverData, open, form }) => {
    const { t } = useTranslation()
    const { isLoadingSendPasswordRecoveryEmailMutation, sendPasswordRecoveryEmail } = useSendPasswordRecoveryEmail()
    const { isLoadingSendValidationEmailMutation, sendValidationEmail } = useSendValidationEmail()

    const handleSendPasswordRecoveryEmail = (params) => {
        if( form === "recover-password" ) sendPasswordRecoveryEmail(params)
        if( form === "resend-validation-email" ) sendValidationEmail(params)
        if( form === "close-open-sessions" ){

        }
        setRecoverData({open: false, form: null})
    }
    
    const { errors, handleSubmit, setFieldValue, touched, values } = useRecoveryFormValues({ onSubmit: handleSendPasswordRecoveryEmail })
    const { email } = values
    const error = (name) => getErrorsParams({ errors, field: name, touched })

    return(
        <>
            <Dialog maxWidth="xs" fullWidth open={open} onClose={() => setRecoverData((n) => ({open: false, form: null}))} >
                <Form onSubmit={() => handleSubmit()} >
                    <DialogContent>
                        <Box mb={3} >
                            { form === "recover-password" &&
                                <Typography color={'GrayText'} fontSize={'.875rem'} lineHeight={1} >
                                    Te enviaremos un correo de recuperación de contraseña para tu cuenta, ingresa el correo con el que estas registrado.
                                </Typography>
                            }
                            { form === "resend-validation-email" &&
                                <Typography color={'GrayText'} fontSize={'.875rem'} lineHeight={1} >
                                    Volveremos a enviarte el correo de validación, ingresa el correo con el que estas registrado
                                </Typography>
                            }
                            { form === "close-open-sessions" &&
                                <Typography color={'GrayText'} fontSize={'.875rem'} lineHeight={1} >
                                    Si tienes sesiones abiertas te enviaremos un correo para que puedas cerrarlas, ingresa el correo con el que estas registrado.
                                </Typography>
                            }
                        </Box>
                        <TextField
                            value={email}
                            InputFontWeight={500}
                            label={t('email')}
                            fullWidth
                            {...error("email")}
                            onChange={({ target }) => setFieldValue("email", (target?.value??""))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoadingSendPasswordRecoveryEmailMutation || isLoadingSendValidationEmailMutation} >
                            {(form === "recover-password" || form === "close-open-sessions") && "Enviar correo de recuperación"}
                            {form === "resend-validation-email" && "Enviar correo de validación"}
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default RecoveryDialog