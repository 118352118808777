import { AccountBalanceRoundedIcon, CloseRoundedIcon, Box, Button, CurrencyExchangeRoundedIcon, DataGrid, DatePicker, DeleteIconRounded, Dialog, DialogActions, DialogContent, Grid, IconButton, LocalAtmRoundedIcon, PointOfSaleRoundedIcon, SavingsRoundedIcon, Typography, styled } from '@component/UIComponents'
import ConfirmWindow from "@component/ConfirmWindow"
import TabAppContext from './TabAppContext'
import { useMemo, useState } from 'react'
import PayFromCashier from './PayFromCashier'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '@util/currencyFormat'
import { actionOnElement, elementFocus } from '@util/helpers'
import Form from './Form'
import { Toast } from '@util/swal'
import { useRef } from 'react'
import { useImperativeHandle } from 'react'
import { forwardRef } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import TextField from "@component/TextField"


const DataGridStyled = styled(DataGrid)(() => ({
    border: "none",
    '& [role="cell"]': {
        outline: 'none',
        userSelect: 'none',
        tabIndex: -1,
    },
    '& .MuiDataGrid-columnHeaders' : {
        display: "none"
    }
}))

const PaymentsList = forwardRef(({ name1='', name2='', showMaxVal=true, getOptionDisabled=true, skipOptions=[], fixedBox=undefined, maxAmount=-1, index=0, blockFCommand=false, hidePaymentList=false, noPayoutLimit=false, typePayment="", dueDate=undefined, onChange=undefined, payments=[], totalToPay=0, onDeletePayment=undefined, paymentReference="" }, ref) => {
    const { t } = useTranslation()
    const paymentRef = useRef(null)

    const { boxToEnterAmountRef=null, cashToPayInputRef=null } = (paymentRef?.current??{})
    useImperativeHandle(ref, () => ({ boxToEnterAmountRef, cashToPayInputRef }))
    totalToPay = useMemo(() => parseFloat((totalToPay??0).toFixed(2)), [totalToPay])
    let currentPayments = useMemo(() => {
        let currentPayments = [...payments]
        let totalPayments = currentPayments.reduce((acc, param) => acc+(param?.amount??0),0)
        currentPayments.push({ box: "Total pagos realizados", amount: totalPayments, bank: null, boxRef: "" })
        if( noPayoutLimit === false ){
            currentPayments.push({ box: "Saldo", amount: (totalToPay-totalPayments), bank: null, boxRef: "" })
        }
        currentPayments = currentPayments.map((n, index) => ({...n, _id: index}))
        return currentPayments
    }, [payments, totalToPay, noPayoutLimit])
    const balance = (currentPayments.find((n) => (n?.box??"") === "Saldo")?.amount??0)

    const handleSubmit = (field, value) => {
        if( Boolean(onChange) ){
            const currentPayments = [...payments]
            currentPayments.push(value)
            if( noPayoutLimit === false ){
                let totalPayments = currentPayments.reduce((acc, params) => (acc+(params?.amount??0)),0)
                if( totalPayments > totalToPay ){
                    Toast.fire({
                        icon: 'warning',
                        text: `La suma total de los pagos no pueden superar el monto a pagar. Verifique los pagos o intente con otro monto`
                    })
                    return
                }
            }
            onChange(field, value, currentPayments)
            setTimeout(() => {
                if( typePayment !== "advance" ){
                    elementFocus("_cashToPay", null, { by: "className", elemIndex: index })
                }else{
                    elementFocus("_boxToEnterAmount", null, { by: "className", elemIndex: index })
                }
            },150)
        }
    }



    const handleDeletePayment = (param, index) => {
        if( Boolean(onDeletePayment) ){
            let currentPayments = [...payments]
            currentPayments.splice(index, 1)
            onDeletePayment(param, index, currentPayments)
            elementFocus(null, cashToPayInputRef)
        }
    }
    const columns = [
        {flex: 1, field: "box", renderCell: ({row}) => `${t((row?.box??""))}`},
        {flex: 1, field: "bank", renderCell: ({row}) => `${t((row?.bank??""))}`},
        {flex: 1, field: "amount", align: "right", renderCell: ({row}) => `${currencyFormat((row?.amount??""))}`},
        {maxWidth: 70, minWidth: 70, field: " ", renderCell: ({ row, id }) => {
            return(
                <>
                    { Boolean(row.boxRef??"") &&
                        <>
                            <IconButton id={`payment_${id}`} tabIndex={-1} color='error' size='small' onClick={() => handleDeletePayment(row, id)} >
                                <DeleteIconRounded sx={{ fontSize: '1rem' }} />
                            </IconButton>
                            { blockFCommand === false && <>{`(F${id+1})`}</> }
                        </>
                    }
                </>
            )
        }},
    ]

    return(
        <Box my={2} >
            <Grid container spacing={1} >
                { (typePayment === "credit") &&
                    <>
                        <Grid xs={12} >
                            <DatePicker format='DD/MM/YYYY' onChange={(date) => handleSubmit("dueDate", dayjs(date).format("MM-DD-YYYY") )} label="Fecha de vencimiento" value={dayjs(dueDate)} slotProps={{ textField: { fullWidth: true, size: "small", id: "dueDate" } }} />
                        </Grid>
                    </>
                }
            </Grid>
            { (typePayment !== "credit") &&
                <>
                <PayFromCashier
                    getOptionDisabled={getOptionDisabled}
                    skipOptions={skipOptions}
                    fixedBox={fixedBox}
                    maxAmount={maxAmount}
                    ref={paymentRef}
                    balance={(noPayoutLimit === true) ? undefined : balance}
                    typePayment={typePayment}
                    payments={payments}
                    onSubmit={(params) => handleSubmit("payment", params)}
                    paymentReference={paymentReference}
                    name1={name1}
                    name2={name2}
                    showMaxVal={showMaxVal}
                 />
                </>
            }
            { !hidePaymentList &&
                <DataGridStyled
                    rowHeight={25}
                    getRowId={(param) => (param?._id??"")}
                    localeText={{
                        noRowsLabel: "No hay pagos realizados"
                    }}
                    hideFooter
                    autoHeight
                    columns={columns}
                    rows={currentPayments}

                 />

            }


        </Box>
    )
})

let currentPaymentMethodsAvailable = {
    cash: true,
    consignment: true,
    advance: true,
    credit: true,
    cashier: false,
}

const SelectionOfPaymentMethod = ({ getOptionDisabled=true, skipOptions=[], topComponent=<></>, bottomComponent=<></>, children, fixedBox=undefined, maxAmount=-1, index=0, blockFCommand=false, hidePaymentList=false, useDialog=true, noPayoutLimit=false, isLoading=false, title="", name1='', name2='', showMaxVal=true, hideTabs=false, paymentMethodsAvailable=currentPaymentMethodsAvailable, onSubmit=undefined, dueDate=dayjs().add(1, "day").format("MM-DD-YYYY"), payments=[], onChange=undefined, open=false, onClose=undefined, totalToPay=0, onDeletePayment=undefined, paymentReference="" }) => {
    const [ selectedPaymentType, setSelectedPaymentType ] = useState("cash")
    const [showConfirm, setShowConfirm] = useState(false)
    const paymentsListRef = useRef(null)
    const { cashToPayInputRef=null } = (paymentsListRef?.current??{})
    const Component = useMemo(() => () => <PaymentsList
        getOptionDisabled={getOptionDisabled}    
        skipOptions={skipOptions}
        fixedBox={fixedBox}
        maxAmount={maxAmount}
        index={index}
        blockFCommand={blockFCommand}
        ref={paymentsListRef}
        hidePaymentList={hidePaymentList}
        noPayoutLimit={noPayoutLimit}
        onChange={(field, value, newList) => Boolean(onChange) ? onChange(field, value, newList) : null}
        typePayment={selectedPaymentType}
        dueDate={dueDate}
        payments={payments}
        totalToPay={totalToPay}
        onDeletePayment={onDeletePayment}
        paymentReference={paymentReference}
        name1={name1}
        name2={name2}
        showMaxVal={showMaxVal}
    />, [index, getOptionDisabled, skipOptions, fixedBox, maxAmount, noPayoutLimit, onChange, selectedPaymentType, dueDate, payments, totalToPay, onDeletePayment, paymentReference, hidePaymentList, blockFCommand ])

    const tabs = useMemo(() => {
        let r = [
            ...((paymentMethodsAvailable?.cash??true) ? [{ label: "Efectivo", value: "cash", component: <Component />, icon: <LocalAtmRoundedIcon /> }] : []),
            ...((paymentMethodsAvailable?.consignment??true) ? [{ label: "Consignación", value: "consignment", component: <Component />, icon: <AccountBalanceRoundedIcon /> }] : []),
            ...((paymentMethodsAvailable?.advance??true) ? [{ label: "Anticipo", value: "advance", component: <Component />, icon: <SavingsRoundedIcon /> }] : []),
            ...((paymentMethodsAvailable?.credit??true) ? [{ label: "Crédito", value: "credit", component: <Component />, icon: <CurrencyExchangeRoundedIcon /> }] : []),
            ...((paymentMethodsAvailable?.cashier??false) ? [{ label: "Caja", value: "cashier", component: <Component />, icon: <PointOfSaleRoundedIcon /> }] : []),
        ]
        return r
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethodsAvailable, selectedPaymentType, Component]) 
    const loadFirstTab = useCallback(() => {
        let defaultTab = tabs[0].value
        setSelectedPaymentType(defaultTab)
    }, [tabs])
    const handleKeyBoartEvents = (event) => {
        const { code } = event
        let fCode = [1, 2, 3, 4]
        if( ['ControlLeft', 'ControlRight', 'AltLeft', ...fCode.map((n) => `F${n}`)].some((n) => n === code) ){
            event.preventDefault()
            event.stopPropagation()
        }
        if( code === 'ControlLeft' || code === 'ControlRight' ){
            let indexTab = tabs.findIndex((n) => n.value === selectedPaymentType)
            const newTab = tabs[indexTab+1]
            const newTabValue = (newTab?.value??"")
            if( Boolean(newTab) ){
                setSelectedPaymentType(newTabValue)
            }else{
                loadFirstTab()
            }
            setTimeout(() => {
                if( newTabValue !== "advance" ){
                    elementFocus("_cashToPay", null, { by: "className", elemIndex: index })
                }else{
                    elementFocus("_boxToEnterAmount", null, { by: "className", elemIndex: index })
                }
            },150)
        }

        if( fCode.map((n) => `F${n}`).some((b) => b === code) ){
            if( blockFCommand === false ){
                const fIndex = fCode.findIndex((n) => `F${n}` === code)
                let currentPayments = [...payments]
                currentPayments.splice(fIndex, 1)
                onDeletePayment(null, fIndex, currentPayments)
                elementFocus(null, cashToPayInputRef)
                actionOnElement(`payment_${fIndex}`)
            }
        }
    }

    const ConfirmationDialog = (onSubmit) =>{
            <Grid>
                <Typography>
                    Confirmar
                </Typography>
                <Button onClick={onSubmit}>Confirmar</Button>
            </Grid>
    }

    const Title = () => (
        <>
            { Boolean(title) &&
                <Typography px={2} textTransform={'uppercase'} lineHeight={1.2} pt={2} pb={0} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'column'} >
                    {title}
                    { (noPayoutLimit === false) &&
                        <Box component={'span'} fontWeight={'bold'} fontSize={'.775rem'} sx={{ color: (theme) => theme.palette.primary.main }} >
                            <Box component={'span'} color={'#000'} >total a pagar:</Box> {currencyFormat(totalToPay)}
                        </Box>
                    }
                </Typography>
            }
        </>
    )

    const CloseButton = () => (
        <>
            <Button
            onClick={onClose}
            >
                <CloseRoundedIcon sx={{ color: 'red'}}/>
            </Button>
        </>
    )

    useEffect(() => {
        loadFirstTab()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (payments.length > 0) {

        }
    }, [payments, totalToPay, noPayoutLimit]);

    const openConfirm = () =>{
        setShowConfirm(true)
    }

    const closeConfirm = () =>{
        setShowConfirm(false)
    }

    if( useDialog ){
        return(
            <>
                <Dialog transitionDuration={0} onKeyDown={handleKeyBoartEvents} maxWidth="sm" fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} >
                    <Form onSubmit={onSubmit} >
                        <Grid
                        container
                        direction="row"
                        alignContent='center'
                        justifyContent='space-between'
                        >
                            <Title />
                            <CloseButton/>
                        </Grid>
                        <DialogContent>
                            {topComponent}
                            <TabAppContext hideTabs={hideTabs} variant={'scrollable'} tab={selectedPaymentType} tabs={tabs} onChange={(_, value) => setSelectedPaymentType(value)} />
                            {bottomComponent}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={openConfirm} loading={isLoading} > Guardar </Button>
                        </DialogActions>
                        <ConfirmWindow
                        title="Confirmar operacion"
                        open={showConfirm}
                        onClose={closeConfirm}
                        onSubmit={onSubmit}
                        />
                    </Form>
                </Dialog>
            </>
        )
    }else{
        return(
            <>
                <Title />
                <TabAppContext onKeyDown={handleKeyBoartEvents} hideTabs={hideTabs} variant={'scrollable'} tab={selectedPaymentType} tabs={tabs} onChange={(_, value) => setSelectedPaymentType(value)} />
            </>
        )
    }
}

export default SelectionOfPaymentMethod