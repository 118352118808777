import AdvancedFilters from "@component/AdvancedFilters"
import { advanceFilters } from "@util/helpers"

const Filters = () => {
    const filters = advanceFilters({

        showOnlyOptions: [
            {name: "Con anticipo", value: "inAdvance"},
            {name: "Sin anticipo", value: "withoutAdvance"},
            {name: "Archivados", value: "archived"},
        ].map((n, index) => ({...n, _id: (index+1)})),

        groupByOptions: [
            {name: "Tipo de negocio", value: "businessType"},
            {name: "Sucursal", value: "branch"},
            {name: "Ciudad", value: "City"},
            {name: "Barrio", value: "neighborhood"},
            {name: "Zona", value: "zone"},
        ].map((n, index) => ({...n, _id: (index+1)})),
        showOnlyMultiple: false,
        filters: {
            showOnly: true,
            groupBy: true,
        }
    })
    return(
        <AdvancedFilters options={filters} />
    )
}

export default Filters