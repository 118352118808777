import { Box } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useAutomaticSavingPurchaseInvoices } from "../hooks/useAutomaticSavingPurchaseInvoices"
import dayjs from 'dayjs'

const AutoSaveList = ({ onChange=()=>null }) => {
    const { storedPurchases=[], retrieveTemporarilyStoredPurchase } = useAutomaticSavingPurchaseInvoices()
    const handleChange = (id) => {
        const currentStoredPurchases = [...storedPurchases]
        const index = currentStoredPurchases.findIndex((n) => n._id === id)
        const val = retrieveTemporarilyStoredPurchase(index)
        onChange(val)
    }

    return(
        <>
            <Autocomplete
                fullWidth
                label="Guardadas"
                size="small"
                options={storedPurchases}
                onChange={(_, param) => handleChange((param?._id??0))}
                renderOption={(props, option) => {
                    return(
                        (<li {...props} key={option._id} >
                            <Box fontSize={'.875rem'} fontWeight={'bold'} lineHeight={1} >
                                {(option?.providerName??"")}<br />
                                <Box component={'span'} > {dayjs((option?.createdAt??"")).format("hh:mm A")} </Box>
                            </Box>
                        </li>)
                    )
                }}
            />
        </>
    )
}

export default AutoSaveList