import { Box, GroupsIconRounded, IconButton, List, ListItem, ListItemText, MoreVertRoundedIcon, Tooltip } from '@component/UIComponents'
import ActionsList from '@component/ActionsList'
import { usePOSMenu } from '../hooks/usePOS'
import { useState } from 'react'
import AddClientMobile from './AddClientMobile'
import CashierActions from './CashierActions'
import { useGetUserSessionInformation } from '@feature/Users'

const SalesMenu = ({ isMobile=false, setFieldValue=()=>null, customer=null, cashierLists=<></> }) => {
    const [showForms, setShowForms] = useState({ registerClient: false, registerProduct: false })
    const [showAddCustomerMobile, setShowAddCustomerMobile] = useState(false)
    const { userData, balance } = useGetUserSessionInformation()

    return(
        <>
            <Box display={'flex'} gap={1} sx={{
                '& .MuiSvgIcon-root': {
                    fontSize: "1.5rem"
                }
            }} zIndex={1000} >
                { isMobile &&
                    <>
                        {Boolean(customer) ?
                            <>
                                <List sx={{ padding: 0 }} >
                                    <ListItem sx={{ paddingBottom: 0 }} onClick={() => setFieldValue("customer", null)} >
                                        <ListItemText
                                            sx={{
                                                '& .MuiListItemText-primary': {
                                                    fontSize: ".875rem",
                                                    fontWeight: "bold",
                                                    lineHeight: 1,
                                                    textAlign: "right",
                                                    color: "#FFFFFF"
                                                },
                                                '& .MuiListItemText-secondary': {
                                                    fontSize: ".775rem",
                                                    fontWeight: "bold",
                                                    textAlign: "right",
                                                    color: "#FFFFFF"
                                                },
                                            }}
                                            primary={(customer?.name??"")}
                                            secondary={
                                                <>
                                                    {(customer?.address??"")} <br/>
                                                </>
                                            }
                                         />
                                    </ListItem>
                                </List>
                            </>
                            :
                            <Tooltip arrow title={"Añadir cliente"} >
                                <IconButton color="primary" onClick={() => setShowAddCustomerMobile(true)} >
                                    <Box lineHeight={.8} >
                                        <GroupsIconRounded sx={{ color: "#FFFFFF" }} />
                                    </Box>
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                }
                {isMobile &&
                    <ActionsList
                        variant={"mobileMenu"}
                        sxButton={{
                            fontSize: ".875rem !important",
                            fontWeight: "bold",
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            color: (theme) => theme.palette.grey[200]
                        }}
                        componentProps={{
                            menuMobile: {
                                props: {
                                    icon: <MoreVertRoundedIcon sx={{ color: "#FFFFFF"  }} />
                                }
                            }
                        }}
                     >
                        {isMobile &&
                            <>
                                {cashierLists}
                            </>
                        }
                    </ActionsList>
                }
                <CashierActions variantListButton='mobileMenu' balance={balance} setFieldValue={setFieldValue} refUser={(userData?._id??null)} />
            </Box>
            <AddClientMobile
                open={showAddCustomerMobile}
                onClose={() => setShowAddCustomerMobile(false)}
                onAddCustomer={(customerSelected) => {
                    setFieldValue("customer", customerSelected)
                    setShowAddCustomerMobile(false)
                }}
                onCreateCustomer={() => setShowForms((prevState) => ({...prevState, registerClient: true}))}
            />
        </>
    )
}

export default SalesMenu