import { api, mutationConfig } from "../splitApi";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerOrder: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/newBillingProcess/register/order", body };
            },
            invalidatesTags:  ["PendingInvoices", "GetOrder", "MyLastBill", "Sale"]
        }),
        editOrder: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/newBillingProcess/edit/order", body };
            },
            invalidatesTags: ["PendingInvoices", "GetOrder", "MyLastBill", "Sale"]
        }),
        getPendingInvoices: build.query({
            query: (params=undefined) => ({
                url: `/newBillingProcess/get/pending/invoices`,
                params,
            }),
            providesTags: ["PendingInvoices"]
        }),
        getOrder: build.query({
            query: (params=undefined) => ({
                url: `/newBillingProcess/get/order`,
                params,
            }),
            providesTags: ["GetOrder"]
        }),
        getMyLastBill: build.query({
            query: (params=undefined) => ({
                url: `/newBillingProcess/get/my/last/bill`,
                params,
            }),
            providesTags: ["MyLastBill"]
        }),
        GetMySalesPointOfSale: build.query({
            query: (params=undefined) => ({
                url: `/get/my/sales/point/of/sale`,
                params,
            }),
            providesTags: ["MyLastBill", "Sale", "PendingInvoices"]
        }),
    }),
    overrideExisting: false,
});

export const {
    useRegisterOrderMutation,
    useEditOrderMutation,
    useGetPendingInvoicesQuery,
    useLazyGetOrderQuery,
    useLazyGetMyLastBillQuery,
    useGetMySalesPointOfSaleQuery,
} = Endpoints;
