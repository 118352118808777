import { useArchiveProductMutation } from "@query/Products"
import { asynchronousResponseHandler } from "@util/helpers"

export const useArchiveProduct = () => {
    const [ archiveProductMutation, { isLoading } ] = useArchiveProductMutation()

    const archiveProduct = async (payload=null, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await archiveProductMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: (resp?.payload?.isArchived??false) === true ?
                    "El producto se archivo exitosamente"
                    :
                    "El producto se recupero exitosamente",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        archiveProduct,
        isLoadingArchiveProduct: isLoading,
    }
}