import { api, mutationConfig } from "../splitApi";
import { Business, CityTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        updateBusinessInformation: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/update/business/information", body };
            },
            invalidatesTags: Business
        }),
        getTaxes: build.query({
            query: (params=undefined) => ({
                url: `/get/taxes`,
                params
            }),
        }),
        getCities: build.query({
            query: (params=undefined) => ({
                url: `/get/cities`,
                params
            }),
            providesTags: CityTag
        }),
        getCloseOfDay: build.query({
            query: (params=undefined) => ({
                url: `/get/close/of/day`,
                params
            }),
            providesTags: ["Box", "Sale", "Order"]
        }),
        getBusinessInformation: build.query({
            query: (params=undefined) => ({
                url: `/get/business/information`,
                params
            }),
            providesTags: Business
        }),
        getInformationAboutBusiness: build.query({
            query: (params=undefined) => ({
                url: `/get/information/about/business`,
                params
            }),
            providesTags: Business
        }),
        getBusinessAssets: build.query({
            query: (params=undefined) => ({
                url: `/get/business/assets`,
                params
            }),
            providesTags: Business
        }),
        getBusinessLiabilities: build.query({
            query: (params=undefined) => ({
                url: `/get/business/liabilities`,
                params
            }),
            providesTags: Business
        }),
        validateBusinessCode: build.query({
            query: (params=undefined) => ({
                url: `/validate/business/code`,
                params
            }),
            providesTags: Business
        }),
        GetGeneralBalanceOfTheBusiness: build.query({
            query: (params=undefined) => ({
                url: `/get/general/balance/of/the/business`,
                params
            }),
            providesTags: Business
        }),
        GetSavedClosures: build.query({
            query: (params=undefined) => ({
                url: `/get/saved/closures`,
                params
            }),
            providesTags: Business
        }),
        SaveClosingOfTheDay: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/save/closing/of/the/day", body };
            },
            invalidatesTags: Business
        }),
    }),
    overrideExisting: false,
});

export const {
    useLazyValidateBusinessCodeQuery,
    useGetTaxesQuery,
    useGetCitiesQuery,
    useGetCloseOfDayQuery,
    useGetBusinessInformationQuery,
    useUpdateBusinessInformationMutation,
    useGetBusinessAssetsQuery,
    useGetBusinessLiabilitiesQuery,
    useGetInformationAboutBusinessQuery,
    useGetGeneralBalanceOfTheBusinessQuery,
    useGetSavedClosuresQuery,
    useSaveClosingOfTheDayMutation
} = Endpoints;