import { useLazyGetMyLastBillQuery, useLazyGetOrderQuery } from "@query/billing"
import { useLazyGetSaleQuery } from "@query/Sales"
import { useCallback } from "react"
import ReactDOM from "react-dom/client"
import { useReactToPrint } from "react-to-print"

export const usePrintInvoice = () => {
    const [GetOrderQuery] = useLazyGetOrderQuery()
    const [GetSaleQuery] = useLazyGetSaleQuery()
    const [ GetMyLastBillQuery ] = useLazyGetMyLastBillQuery()

    const handlePrint = useReactToPrint({
        content: () => document.getElementById("InvoiceAnchor"),
    });

    const getOrderToPrint = useCallback(async (OrderID) => {
        const data = await GetOrderQuery({ OrderID }).unwrap()
        const { payload = null } = (data ?? {})
        return payload
    }, [GetOrderQuery])
    
    const getSaleToPrint = useCallback(async (BillID) => {
        const data = await GetSaleQuery({ ref: BillID }).unwrap()
        const { payload = null } = (data ?? {})
        return payload
    }, [GetSaleQuery])

    const getAnchor = useCallback(() => {
        const anchor = document.getElementById("InvoiceAnchor")
        if (!Boolean(anchor)) {
            alert("No se encontró un ancla para la factura.")
            return
        }
        return anchor
    }, [])

    const getInvoiceTemplate = useCallback(async () => {
        try {
            const GraphicInvoiceModule = await import('../../Reports/Invoice')
            const GraphicInvoiceComponent = GraphicInvoiceModule.default
            return GraphicInvoiceComponent
        } catch (error) {
            throw error
        }
    }, [])

    const PrintMyLastBill = useCallback(async ({ InitDate=null, EndDate=null }) => {
        const data = await GetMyLastBillQuery({ InitDate, EndDate }).unwrap()
        const { payload=null } = (data??{})
        if( !Boolean(payload) ){
            alert("No se encontró una factura para imprimir.")
            return
        }
        const GraphicInvoice = await getInvoiceTemplate()
        const anchor = getAnchor()
        const root = ReactDOM.createRoot(anchor)
        root.render(<GraphicInvoice {...(payload??{})} />);
        setTimeout(() => {
            handlePrint();
        }, 100);
    }, [GetMyLastBillQuery, getInvoiceTemplate, getAnchor, handlePrint])

    const PrintInvoice = useCallback(async ({ BillID = null, invoiceType = "" }) => {
        if (!Boolean(invoiceType)) {
            alert("El tipo de factura no fue definido.")
            return
        }
        let bill = null
        if (invoiceType === "order") {
            bill = await getOrderToPrint(BillID)
        }else if( invoiceType === "sale" ){
            bill = await getSaleToPrint(BillID)
        }
        if (!Boolean(bill)) {
            alert("No se encontró una factura para imprimir.")
            return
        } else {
            const GraphicInvoiceComponent = await getInvoiceTemplate()
            const anchor = getAnchor()
            const root = ReactDOM.createRoot(anchor)
            root.render(<GraphicInvoiceComponent {...bill} />);
            setTimeout(() => {
                handlePrint();
            }, 100);
        }
    }, [getOrderToPrint, handlePrint, getInvoiceTemplate, getAnchor, getSaleToPrint])

    return {
        PrintInvoice,
        PrintMyLastBill,
    }
}