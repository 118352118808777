import { DataGrid, IconButton, Tooltip, styled } from '@component/UIComponents'
import DataGridFooter from './DatagridFooter'
import { currencyFormat } from '@util/currencyFormat'
import { calculateSubtotal, calculateTax, calculateTotal, calculateTotalFreight } from '@util/helpers'
import ActionsList from '@component/ActionsList'
import { useEnterPurchaseActions } from '../hooks/useEnterPurchase'
import Img from '@component/Img'
import productFound from '@style/images/terminado.png'
import productNotFound from '@style/images/rechazado.png'
import ProductForm from '@feature/Inventory/Products/ProductForm'
import { useState } from 'react'
import { memo } from 'react'

const DataGridStyled = styled(DataGrid)(({ theme }) => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "35px !important",
        maxHeight: "35px !important",
    },
    '& .MuiDataGrid-columnSeparator': {
        visibility: 'visible'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        textTransform: "uppercase",
        color: theme.palette.primary.main,
    },
    '& .MuiDataGrid-cell:first-of-type': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: theme.palette.background.paper,
    },
    '& .MuiDataGrid-row': {
        cursor: "pointer",
    },
    '& .super-class_isNew': {
        backgroundColor: "rgba(239,83,80,0.55)",
    },
}))

const ProductList = ({ haulier=null, removeProductFromList=()=>null, selectProductFromList=()=>null, list=[], tableTotalize=[], tableTaxes=[], onAfterSaving=()=>null }) => {
    const [newProductForm, setNewProductForm] = useState({ open: false, product:null, })
    const { dataGridActions } = useEnterPurchaseActions()
    const Buttons = (props={}) => <ActionsList
        options={dataGridActions(props, {
            deleteItemList : ({ productId=null }) => removeProductFromList(productId)
        })}
        variant='iconButton'
    />


    const columns = [
        {flex: 1, minWidth: 200, field: "name", headerName: "Producto"},
        {
            maxWidth: 40,
            minWidth: 40,
            field: "productStatus",
            headerName: "  ",
            align: "center",
            cellClassName: ({ row }) => {
                if( Boolean(row?.new) ){
                    return "super-class_isNew"
                }else{
                    return ""
                }
            },
            renderCell: ({row}) => {
                let currentNewProduct = {...row}
                currentNewProduct = { name: (row?.name??""), cost: (row?.purchaseCost??0), packaging: (row?.packaging??null), tax: (row?.tax??null), purchaseCodes: (row?.codes??[]), new: Boolean(row?.new) }
                return(
                    <>
                        { Boolean(row?.new) ?
                            <>
                                <Tooltip title="Producto no encontrado - haga click para registrar" arrow >
                                    <IconButton size='small' onClick={() => setNewProductForm(n => ({...n, open: true, product: currentNewProduct}))} >
                                        <Img src={productNotFound} style={{ maxWidth: 20 }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                            :
                            <Tooltip title="Producto cargado exitosamente" arrow >
                                <IconButton size='small' >
                                    <Img src={productFound} style={{ maxWidth: 20 }} />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {minWidth: 80, maxWidth: 80, align: "center", field: "quantity", headerName: "Cant."},
        {minWidth: 120, maxWidth: 120, align: "center", field: "packaging", headerName: "Embalaje", renderCell: ({ row }) => Boolean(row?.packaging?._id) ? `${row?.packaging?.name}` : ``},
        {minWidth: 150, maxWidth: 150, field: "purchaseCost", headerName: "Costo", renderCell: ({ row }) => `${currencyFormat((row?.purchaseCost??0))}` },
        {minWidth: 100, maxWidth: 100, field: "freight", headerName: "Flete", renderCell: ({ row }) => `${currencyFormat((row?.freight??0))}` },
        {minWidth: 110, maxWidth: 110, field: "totalFreight", headerName: "Total flete", renderCell: ({row}) => `${currencyFormat(calculateTotalFreight(row))}`},
        {minWidth: 100, maxWidth: 100, align: "center", field: "tax", headerName: "Impuesto", renderCell: ({ row }) => Boolean(row?.tax?._id) ? `${(row?.tax?.name??"")} ${(row?.tax?.tax??0)}%` : ``},
        {minWidth: 120, maxWidth: 120, field: "iva", headerName: "Iva", renderCell: ({ row }) => `${currencyFormat(calculateTax({...row, tax: (row?.tax?.tax??0), roundOff: true}))}`},
        {minWidth: 150, maxWidth: 150, field: "subTotal", headerName: "Sub total", renderCell: ({ row }) => `${currencyFormat( calculateSubtotal({...row, roundOff: true}) )}`},
        {minWidth: 150, maxWidth: 150, field: "total", headerName: "Total", renderCell: ({row}) => `${currencyFormat( calculateTotal({...row, tax: (row?.tax?.tax??0), roundOff: true}) )}`},
        {minWidth: (40*dataGridActions().length), maxWidth: (40*dataGridActions().length), align: "center", field: "actions", headerName: " ", renderCell: ({row}) => <Buttons {...row} />},
    ]

    const handleCellClick = ({ field, row }) => (field !== "actions" && field !== "productStatus") ? selectProductFromList(row) : null

    return(
        <>
            <DataGridStyled
                onCellClick={handleCellClick}
                disableVirtualization
                rowHeight={25}
                autoHeight
                getRowId={(param) => (param?.productId??"")}
                columns={columns}
                rows={list}
                localeText={{
                    noRowsLabel: "No hay productos seleccionados"
                }}
                slots={{
                    footer: () => <DataGridFooter tableTaxes={tableTaxes} tableTotalize={tableTotalize} />
                }}
                columnVisibilityModel={{
                    name: true,
                    productStatus: true,
                    quantity: true,
                    purchaseCost: true,
                    freight: Boolean(haulier?.name),
                    totalFreight: Boolean(haulier?.name),
                    tax: true,
                    iva: true,
                    packaging: true,
                    subTotal: true,
                    total: true,
                    actions: true,
                }}
            />
            <ProductForm
                defaultValues={newProductForm.product}
                open={newProductForm.open}
                onClose={() => setNewProductForm(n => ({...n, open: false, product: null}))}
                onAfterSaving={() => onAfterSaving()}
            />
        </>
    )
}

export default memo(ProductList)