import { useRef, } from 'react'
import { Box, Typography, styled, DeleteIconRounded, Card, CardContent, IconButton, AddRoundedIcon, Button, Tooltip } from '@component/UIComponents'
import dropfile from './images/dropfile.png'
import { useDropzone } from './hooks/useDropzone';

const DropZoneContainer = styled(Card)(({ theme }) => ({
    cursor: 'pointer',
    height: 130,
    overflow: 'none',
    '&:hover': {
        backgroundColor: theme.palette.grey[100]
    }
}))

const ImageContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    cursor: 'pointer',
    border: "1px solid",
    borderColor: theme.palette.grey[400],
    borderRadius: theme.spacing(1),
    maxWidth: 80,
    height: 80,
    flexGrow: 1,
    flexBasis: 80,
    overflow: 'hidden',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:hover #mask': {
        display: 'flex'
    },
}))

const ImageMask = styled(Box)(({ theme }) => ({
    position: "absolute",
    display: 'none',
    width: 80,
    height: 80,
    backgroundColor: "#00000063",
    justifyContent: "center",
    alignItems: "center",
    '& svg': {
        color: "#FFFFFF",
        fontSize: "3rem",
    }
}))

const Dropzone = ({ disabled=undefined, imageLimit=5, onChange=()=>null, defaultImages=[], variant="dropzone", icon=<></>, accept="image/*", multiple=true, title="", loading=false }) => {
    let fileInputRef = useRef(null)

    const { processFiles, previewImages, removeImage } = useDropzone({ fileInputRef, imageLimit, onChange, defaultImages, multiple })
    const handleDropZoneClick = (event) => {
        const elem = event.target
        if( Boolean(elem) ){
            if(variant === "dropzone"){
                if( elem.classList.contains("imagesContent") ){
                    if( Boolean(fileInputRef.current) ){
                        fileInputRef.current.click()
                    }
                }
            }
            if(variant === "iconButton" || variant === "button"){
                if( Boolean(fileInputRef.current) ){
                    fileInputRef.current.click()
                }
            }
        }
        
    }
    const handleChangeInputFile = (event) => {
        const data = event.target.files
        processFiles(data)
    }
    const handlerDrop = (evt) => {
        evt.preventDefault()
        evt.stopPropagation()
        const files = evt.dataTransfer.files
        processFiles(files)
    }
    const handlerDrag = (evt) => {
        evt.preventDefault()
        evt.stopPropagation()
    }

    if(variant === "dropzone"){
        return(
            <>
                <input ref={fileInputRef} type="file" multiple={multiple} accept={accept} onChange={handleChangeInputFile} style={{ display: 'none' }} />
                <DropZoneContainer className="imagesContent" elevation={0} variant='outlined' onClick={handleDropZoneClick} onDrop={handlerDrop} onDragOver={handlerDrag} >
                    <CardContent className="imagesContent" sx={{ display: "flex", flexWrap: "wrap" }} >
                        {(previewImages.length <= 0) ?
                            <>
                            <Box className="imagesContent" display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ gap: 2 }} >
                                <Box className="imagesContent" maxWidth={100} >
                                    <img className="imagesContent" width={'100%'} src={dropfile} alt='load images' />
                                </Box>
                                <Box className="imagesContent" textAlign={'center'} >
                                    <Typography className="imagesContent" fontWeight="bold" fontSize={20} color="grey.800" > Soltar o Seleccionar archivos. </Typography>
                                    <Typography className="imagesContent" fontSize={14} color="grey.800" > Arrastre las imágenes o haga click para cargarlas. </Typography>
                                    <Typography className="imagesContent" fontSize={12} sx={{ color: (theme) => theme.palette.warning.main }} > Este campo solo permite imágenes. </Typography>
                                </Box>
                            </Box>
                            </>
                            :
                            <>
                                {previewImages.map((image, index) => {
                                    return(
                                        <ImageContainer key={index} >
                                            <ImageMask id="mask" onClick={() => removeImage(index)} >
                                                <DeleteIconRounded />
                                            </ImageMask>
                                            <img width={'100%'} height={'100%'} src={image} alt={`selected images ${index+1}`} style={{ objectFit: "cover" }} />
                                        </ImageContainer>
                                    )
                                })}
                                {(previewImages.length < imageLimit) &&
                                    <ImageContainer className="imagesContent" sx={{ backgroundColor: "#FFFFFF" }} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                        <AddRoundedIcon className="imagesContent" sx={{ fontSize: "2.5rem", color: (theme) => theme.palette.grey[500] }} />
                                    </ImageContainer>
                                }
                            </>
                        }
                    </CardContent>
                </DropZoneContainer>
            </>
        )
    }else if(variant === "iconButton"){
        return(
            <>
                <input ref={fileInputRef} type="file" multiple={multiple} accept={accept} onChange={handleChangeInputFile} style={{ display: 'none' }} />
                <Tooltip title={title} arrow >
                    <IconButton disabled={disabled} onClick={handleDropZoneClick} color='primary' >
                        {icon}
                    </IconButton>
                </Tooltip>
            </>
        )
    }else if(variant === "button"){
        return(
            <>
                <input ref={fileInputRef} type="file" multiple={multiple} accept={accept} onChange={handleChangeInputFile} style={{ display: 'none' }} />
                <Button startIcon={icon} disabled={disabled} onClick={handleDropZoneClick} color='primary' loading={loading} >
                    {title}
                </Button>
            </>
        )
    }

}

export default Dropzone