import ActionsList from '@component/ActionsList'
import { Dialog, DialogContent, Grid } from '@component/UIComponents'
import Invoice from '@feature/Reports/Invoice'
import { useInvoiceMenu } from '@feature/Reports/Invoice/hooks/useInvoice'
import { useLazyGetSale } from '@feature/Reports/Sales/hooks/useLazyGetSale'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLazyRetrieveReceivedSaleInvoice } from '../hooks/useLazyRetrieveReceivedSaleInvoice'

const PreviewSalesInvoice = ({ saleRef, open=false, onClose=()=>null, isReceived=false }) => {
    const [currentSale, setCurrentSale] = useState(null)
    const { retrieveReceivedSaleInvoice } = useLazyRetrieveReceivedSaleInvoice()
    const { lazyGetSaleQuery } = useLazyGetSale()
    const invoiceRef = useRef(null)
    const { invoiceMenu } = useInvoiceMenu({ invoiceRef })

    const getInvoice = useCallback( async () => {
        let resp = null
        if( isReceived ){
            resp = ((await retrieveReceivedSaleInvoice({ receivedSale: saleRef }))?.data?.payload??{})
        }else{
            resp = ((await lazyGetSaleQuery({ ref: saleRef, otherBusiness: true }))?.data?.payload??{})
            // console.log("lazyGetSaleQuery", resp)
        }
        return resp
    }, [isReceived, lazyGetSaleQuery, saleRef, retrieveReceivedSaleInvoice])
    // const sale = useMemo(() => getInvoice(), [getInvoice])
    // console.log("🚀 ~ sale:", sale)
    // const currentSale = useMemo(() => sale, [sale])

    useEffect(() => {
        (async () => {
            let r = await getInvoice()
            setCurrentSale(r)
        })()
    }, [getInvoice])

    return(
        <Dialog fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
            <DialogContent>
                <Grid container >
                    { Boolean(isReceived) &&
                        <Grid xs={12} >
                            <ActionsList
                                options={invoiceMenu({}, { includingMenus: ['printInvoice'] })}
                            />
                        </Grid>
                    }
                    <Grid xs={12} >
                        <Invoice
                            ref={invoiceRef}
                            {...currentSale}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default PreviewSalesInvoice