import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@component/UIComponents'
import Form from './Form'
import { useRef, useState } from 'react'

const ConfirmWindow = ({ title="", validateDetail=true, onSubmit=()=>null, onClose=()=>null, open=false }) => {
    const [detail, setDetail] = useState("")
    const buttonRef = useRef(null)

    const handleSubmit = () => {
        onClose()
        onSubmit(detail)
    }

    const handleKeyDown = (event) => {
        const { code="" } = event
        if( code === "Tab" ){
            event.preventDefault()
            event.stopPropagation()
            if( Boolean(buttonRef.current) ){
                buttonRef.current.focus()
            }
        }
    }
    return(
        <>
            <Dialog onKeyDown={handleKeyDown} maxWidth="xs" fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Typography fontWeight={'bold'} fontSize={'1rem'} textTransform={'uppercase'} >{title}</Typography>
                    </DialogContent>
                    <Grid container>
                        <DialogActions>
                            <Button ref={buttonRef} onClick={onClose}>
                                Cancelar
                            </Button>
                        </DialogActions>
                        <DialogActions>
                            <Button ref={buttonRef} type='submit' >
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Grid>
                </Form>
            </Dialog>
        </>
    )
}

export default ConfirmWindow