import useQueryParams from "@hook/useQueryParams"
import { useGetBestSellingProductsQuery } from "@query/Products"
import { useMemo } from "react"

export const useBestSeller = () => {
    const { getQueryParams } = useQueryParams()
    const queries = useMemo(() => getQueryParams(["InitialDate", "FinalDate"]), [getQueryParams])
    const { FinalDate=null, InitialDate=null } = (queries??{})
    const { data } = useGetBestSellingProductsQuery({ InitialDate, FinalDate })
    const bestSellers = useMemo(() => (data?.payload??[]), [data])

    const TotalizeRows = useMemo(() => {
        let currentRows = [...(bestSellers??[])]
        const InitialStock = currentRows.reduce((acc, item) => (acc+(item?.InitialStock??0)),0)
        const shopping = currentRows.reduce((acc, item) => (acc+(item?.shopping??0)),0)
        const totalSold = currentRows.reduce((acc, item) => (acc+(item?.sold??0)),0)
        const remaining = currentRows.reduce((acc, item) => (acc+(item?.remaining??0)),0)
        const totalSale = currentRows.reduce((acc, item) => (acc+(item?.sale??0)),0)
        const totalCost = currentRows.reduce((acc, item) => (acc+(item?.cost??0)),0)
        const totalProfit = currentRows.reduce((acc, item) => (acc+(item?.profit??0)),0)
        currentRows.unshift({
            _id: "001",
            name: "TOTAL",
            InitialStock,
            shopping,
            sold: totalSold,
            remaining,
            sale: totalSale,
            cost: totalCost,
            profit: totalProfit,
            cellType: "totalize"
        })
        return currentRows
    }, [bestSellers])

    const toExport = useMemo(() => {
        const currentRows = [...bestSellers]
        const sss = [
            { name: "Producto", field: "name" },
            { name: "Stock", field: "InitialStock" },
            { name: "Compro", field: "shopping" },
            { name: "Vendió", field: "sold" },
            { name: "Quedo", field: "remaining" },
            { name: "Costo", field: "cost" },
            { name: "Venta", field: "sale" },
            { name: "Ganancia", field: "profit" },
        ]
        let organizedRows = []
        currentRows.forEach((item) => {
            let result = {}
            sss.forEach((organized) => {
                result[organized.field] = item[organized.field]
            })
            organizedRows.push(result)
        })
        const headerNames = sss.map((item) => item.name )
        return {
            sheetName: "Mas vendidos",
            headerNames,
            rows: organizedRows,
        }
    }, [bestSellers])

    return {
        bestSellers,
        FinalDate,
        InitialDate,
        TotalizeRows,
        toExport,
    }
}