import { Autocomplete as AutocompleteMUI, InputAdornment, TextField, styled } from '@component/UIComponents'
import { forwardRef } from 'react'
import PropTypes from 'prop-types';
import { useAutocompleteManipulateValue } from '@hook/useAutocompleteManipulateValue';
import { useTranslation } from 'react-i18next';

const AutocompleteStyled = styled(AutocompleteMUI, {
    shouldForwardProp: (prop) => prop !== "borderNone"
})(({ borderNone }) => ({
    ...(Boolean(borderNone) ? {
        '& .MuiOutlinedInput-notchedOutline' : {
            border: 'none',
        }
    } : {})
}))

const Autocomplete = forwardRef(({ limitTags=3, defaultValue=undefined, InputProps=undefined, renderTags=undefined, readOnly=undefined, freeSolo=undefined, disableCloseOnSelect=undefined, queryValue=undefined, filterOptions=undefined, className=undefined, borderNone=undefined, autoFocus=undefined, getOptionDisabled=undefined, onKeyUp=undefined, onKeyDown=undefined, id=undefined, inputValue=undefined, slotProps=undefined, open=undefined, placeholder=undefined, variant="outlined", size="medium", getOptionLabel=undefined, renderOption=(props, option) => (<li {...props} key={option._id} > {option.name} </li>), label="", multiple=undefined, sx=undefined, fullWidth=undefined, options=[], loading=undefined, onChange=undefined, name="", error=undefined, helperText=undefined, value=undefined, compare=(n) => (n?.value??""), ...rest }, ref) => {
    const { t } = useTranslation()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: options, queryValue, compare })
    const val = getAutocompleteValue()
    return(
        <>
            <AutocompleteStyled
                defaultValue={defaultValue}
                renderTags={renderTags}
                readOnly={readOnly}
                freeSolo={freeSolo}
                disableCloseOnSelect={disableCloseOnSelect}
                filterOptions={filterOptions}
                getOptionDisabled={getOptionDisabled}
                borderNone={borderNone}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                id={id}
                inputValue={inputValue}
                slotProps={slotProps}
                open={open}
                isOptionEqualToValue={(option, value) => (option?._id??"") === (value?._id??"")}
                noOptionsText="No hay opciones"
                limitTags={limitTags}
                ChipProps={{
                    size: 'small',
                    color: 'primary',
                }}
                multiple={multiple}
                sx={sx}
                fullWidth={fullWidth}
                value={val}
                onChange={onChange}
                getOptionLabel={ Boolean(getOptionLabel) ? (params) => getOptionLabel(params) : (params) => t(`${params?.name??""}`) }
                options={options}
                renderInput={(params) => <TextField
                    {...params}
                    inputRef={ref}
                    autoFocus={autoFocus}
                    variant={variant}
                    size={size}
                    label={label}
                    name={name}
                    error={error}
                    helperText={helperText}
                    placeholder={placeholder}
                    inputProps={{
                        ...(params?.inputProps??{}),
                        className: `${((params?.inputProps??{})?.className??"")} ${className}`
                    }}
                />}
                loading={loading}
                renderOption={renderOption}
                {...rest}
            />
        </>
    )
})

Autocomplete.propTypes = {
    borderNone: PropTypes.bool,
    autoFocus: PropTypes.bool,
    getOptionDisabled: PropTypes.any,
    onKeyUp: PropTypes.any,
    onKeyDown: PropTypes.any,
    id: PropTypes.string,
    inputValue: PropTypes.string,
    slotProps: PropTypes.any,
    open: PropTypes.bool,
    placeholder: PropTypes.string,
    variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
    size: PropTypes.oneOf(["medium", "small"]),
    getOptionLabel: PropTypes.any,
    renderOption: PropTypes.any,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    sx: PropTypes.any,
    fullWidth: PropTypes.bool,
    options: PropTypes.array,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    value: PropTypes.any,
}

export default Autocomplete