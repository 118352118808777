import NumericFormat from '@component/NumericFormat'
import { Box, Button, Dialog, DialogContent, Divider, Grid, Typography } from '@component/UIComponents'
import { useEnterPurchasePreSelectionProductFormData } from '../hooks/useEnterPurchase'
import Form from '@component/Form'
import PackagingFinder from '@feature/Inventory/Packaging/components/PackagingFinder'
import TaxesFinder from '@feature/Business/components/TaxesFinder'
import { useEffect } from 'react'
import { elementFocus } from '@util/helpers'
import { currencyFormat } from '@util/currencyFormat'

const PreSelectionOfProduct = ({ list=[], value=null, onClose=undefined, onChange=undefined }) => {
    const defaultValue = [...list].find((n) => (n?.productId??"") === (value?._id??"")) || null
    const { values, setFieldValue, validateValues, resetValues } = useEnterPurchasePreSelectionProductFormData({ defaultValue, selectedProduct: value })
    const { freight, purchaseCost, quantity, packaging, tax } = values
    const open = Boolean((value?._id))

    const handleSubmit = () => {
        const errors = validateValues(values)
        if( errors.type === "errors" ) return
        if( Boolean(onChange) ) onChange(values)
        onClose()
    }

    useEffect(() => {
        if( !open ){
            resetValues()
        }else{
            setTimeout(() => {
                elementFocus("preSelectionProductQuantity")
            }, 100)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])
    return(
        <>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose} transitionDuration={0} PaperProps={{ elevation: 0, sx: { borderRadius: 0 } }} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={12} >
                                <Box sx={{ '& p': { lineHeight: 1.3 } }} >
                                    <Typography fontSize={'1rem'} fontWeight={'bold'} color="primary" > {(value?.name??"")} </Typography>
                                    <Typography fontSize={'.875rem'} fontWeight={'500'} > Embalaje: {(value?.packaging?.name??"")} - x{(value?.packaging?.quantity??"")} </Typography>
                                    <Typography fontSize={'.875rem'} fontWeight={'500'} >
                                        Stock disponible:{" "}
                                        <Box component={'span'} >
                                            { currencyFormat((value?.stock??0), { applyCurrencyFormat: false })}
                                        </Box>
                                        {" "}UND
                                    </Typography>
                                </Box>
                                <Divider sx={{ my: .5 }} />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat id={'preSelectionProductQuantity'} value={quantity} fullWidth size={'small'} label="Cantidad" prefix='' onChange={({ floatValue }) => setFieldValue("quantity", floatValue)} />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat value={purchaseCost} fullWidth size={'small'} label="Costo de compra" onChange={({ floatValue }) => setFieldValue("purchaseCost", floatValue)} />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat value={freight} fullWidth size={'small'} label="Flete" onChange={({ floatValue }) => setFieldValue("freight", floatValue)} />
                            </Grid>
                            <Grid xs={6} >
                                <PackagingFinder onChange={(_, value) => setFieldValue("packaging", value)} value={packaging} fullWidth size={'small'} />
                            </Grid>
                            <Grid xs={6} >
                                <TaxesFinder onChange={(_, value) => setFieldValue("tax", value)} value={tax} fullWidth size={'small'} filters={{ name: "IVA" }} />
                            </Grid>
                            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'end' }} >
                                <Button type='submit' > Añadir a la lista </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Form>
            </Dialog>
        </>
    )
}

export default PreSelectionOfProduct