import NumericFormat from '@component/NumericFormat'
import { Box, Button, CloseRoundedIcon, Dialog, DialogActions, DialogContent, Divider, Grid, TextField, Typography } from '@component/UIComponents'
import Dropzone from '@component/Dropzone'
import ProviderFinder from '@feature/Providers/components/ProviderFinder'
import { useGetProduct, useProductFormData, useProductOptions, useRegisterProduct } from '../hooks/useProducts'
import PriceListForm from '../components/PriceListForm'
import CategoriesFinder from '@feature/Inventory/Categories/components/CategoriesFinder'
import Form from '@component/Form'
import { convertFilesToBase64 } from '@util/helpers'
import Autocomplete from '@component/Autocomplete'
import ProductFinder from '../components/ProductFinder'
import PackagingFinder from '@feature/Inventory/Packaging/components/PackagingFinder'
import TaxesFinder from '../../../Business/components/TaxesFinder'
import { useState } from 'react'

const ProductForm = ({ open=false, onClose=()=>null, productId=null, defaultValues=null, onAfterSaving=()=>null }) => {
    const [typeProduct, setTypeProduct] = useState(true)
    const { product } = useGetProduct({ productId }, { skip : !Boolean(productId) })
    const { values, setFieldValue, updatePrices, validateValues, resetValues, updateCost } = useProductFormData({ defaultValues: Boolean(defaultValues) ? defaultValues : product, openModal: open })
    const { name="", activePOS=true, activePurchase=true, productParent=null, category=null, provider=null, stock=0, skuCode="", cost=0, prices=[], images=[], packaging=null, lowStockAlert=0, tax=null, barCode="" } = values
    const { registerProduct, isLoading } = useRegisterProduct()
    const { fifthOptions } = useProductOptions()
    const handleSubmit = () => {
        let currentValues = {...values}
        let currentImages = (currentValues?.images??[])
        convertFilesToBase64(currentImages)
        .then((img) => {
            currentValues.images = img.map((n, index) => ({name: (currentImages[index]?.name??""), base: n}))
            const { type } = validateValues(currentValues)
            if(type === "errors") return
            registerProduct(currentValues, {
                closeForm: () => {
                    resetValues()
                    onClose()
                    onAfterSaving()
                }
            })
        })
        .catch((err) => {
            console.log(err)
        })
    }

    return(
        <>
            <Dialog fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Grid 
                container
                direction={'row'}
                justifyContent={'center'}
                alignContent={'center'}                
                >
                    <Box
                    container
                    gap={1}
                    sx={{
                        padding: 0.5,
                        paddingLeft: 5,
                        paddingRight: 5,
                        background: '#efefef',
                        margin: 1,
                        borderRadius: 10,
                    }}
                    >
                        <Button
                        onClick={()=>setTypeProduct(true)}
                        sx={{
                            color: `${typeProduct ? 'white' : '#6a6a6a'}`,
                            background: `${typeProduct ? 'blue' : '#efefef'}`,
                            paddingLeft: 2,
                            paddingRight: 2,
                            borderRadius: 10,
                            '&:hover': {
                                backgroundColor: `${typeProduct ? 'blue' : '#efefef'}`, 
                                boxShadow: 'none', 
                            },
                        }}
                        >
                            Producto simple
                        </Button>
                        <Button
                        onClick={()=>setTypeProduct(false)}
                        sx={{
                            color: `${typeProduct ? '#6a6a6a' : 'white'}`,
                            background: `${typeProduct ? '#efefef' : 'blue'}`,
                            paddingLeft: 2,
                            paddingRight: 2,
                            borderRadius: 10,
                            '&:hover': {
                                backgroundColor: `${typeProduct ? '#efefef' : 'blue'}`, 
                                boxShadow: 'none', 
                            },
                        }}
                        >
                            Producto compuesto
                        </Button>
                    </Box>
                    
                </Grid>
                { typeProduct ?
                <Form onSubmit={handleSubmit} >
                    <Grid
                    container
                    direction="row"
                    alignContent='center'
                    justifyContent='space-between'
                    >
                        <Typography px={2} textTransform={'uppercase'} lineHeight={1.2} pt={2} pb={0} fontWeight={'bold'} fontSize={'1rem'} display={'flex'} flexDirection={'column'} >
                            Crear Producto
                        </Typography>
                        <Button
                        onClick={onClose}
                        >
                            <CloseRoundedIcon sx={{ color: 'red'}}/>
                        </Button>
                    </Grid>
                    <DialogContent>
                        <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color:'#566573'
                        }}
                        variant='body2'
                        >
                            Informacion del Producto:
                        </Typography>
                        <Divider
                        sx={{
                            marginBottom: 1
                        }}
                        />
                        <Grid container spacing={1} sx={{ maxHeight: '80vh' }} >
                            <Grid xs={6} >
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    value={name}
                                    onChange={({target}) => setFieldValue("name", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <ProductFinder
                                    value={productParent}
                                    placeholder=''
                                    inputVariant="outlined"
                                    fullWidth
                                    label="Compuesto"
                                    variant={'single'}
                                    typeProduct={'single'}
                                    onChange={(_, value) => setFieldValue("productParent", value)}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <CategoriesFinder
                                    value={category}
                                    fullWidth
                                    label="Categoría"
                                    onChange={(_, value) => setFieldValue("category", value)}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <PackagingFinder
                                    value={packaging}
                                    onChange={(_, value) => setFieldValue("packaging", value)}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <ProviderFinder
                                    placeholder={""}
                                    label={"Proveedor"}
                                    value={provider}
                                    onChange={(_, value) => setFieldValue("provider", value)}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat
                                    prefix={""}
                                    suffix=""
                                    thousandSeparator=" "
                                    fullWidth
                                    label="Stock"
                                    value={stock}
                                    onChange={({ floatValue }) => setFieldValue("stock", (floatValue??0))}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <TextField
                                    fullWidth
                                    label="SKU"
                                    value={skuCode}
                                    onChange={({target}) => setFieldValue("skuCode", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat
                                    prefix={""}
                                    suffix=""
                                    thousandSeparator=" "
                                    fullWidth
                                    label="Alertar stock bajo"
                                    value={lowStockAlert}
                                    onChange={({ floatValue }) => setFieldValue("lowStockAlert", (floatValue??0))}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <TextField
                                    fullWidth
                                    label="Código de barra"
                                    value={barCode}
                                    onChange={({target}) => setFieldValue("barCode", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <TaxesFinder
                                    filters={{ name: "IVA" }}
                                    value={tax}
                                    onChange={(_, value) => setFieldValue("tax", value)}
                                />
                            </Grid>
                            {/* <Grid xs={12} >
                                <TagInput
                                    label='Códigos de compra'
                                    value={ (purchaseCodes??[]).map((n) => ({name: n})) }
                                    onChange={(n) => setFieldValue("purchaseCodes", ((n??[]).map((x) => (x?.name??""))??[]))}
                                />
                            </Grid> */}
                            <Grid xs={12} >
                                <Autocomplete
                                    multiple
                                    options={fifthOptions}
                                    renderInput={(params) => <TextField {...params} label="Habilitar en" />}
                                    value={ fifthOptions.filter((n) => ((activePOS === true) ? n.value === "pointOfSale" : undefined) || ((activePurchase === true) ? n.value === "purchase" : undefined) ) }
                                    onChange={(_, value) => {
                                        let currentActivePOS = Boolean((value.find((n) => n?.value === "pointOfSale")))
                                        let currentActivePurchase = Boolean((value.find((n) => n?.value === "purchase")))
                                        setFieldValue("activePOS", currentActivePOS)
                                        setFieldValue("activePurchase", currentActivePurchase)
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    color:'#566573'
                                }}
                                variant='body2'
                                >
                                    Informacion de precios:
                                </Typography>
                                <Divider
                                sx={{
                                    marginBottom: 1
                                }}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <NumericFormat
                                    disabled={Boolean(productParent)}
                                    fullWidth
                                    label="Costo"
                                    value={cost}
                                    onChange={({ floatValue }) => updateCost((floatValue??0))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                {prices.map((item, index) => {
                                    return(
                                        <PriceListForm key={index} cost={cost} {...item} onChange={(price) => updatePrices(price, index)} />
                                    )
                                })}
                            </Grid>
                            {/* <Grid xs={12} >
                                <AdvancedProductSettings />
                            </Grid> */}
                            <Grid xs={12} >
                                <Dropzone
                                    onChange={(currentImages) => setFieldValue("images", currentImages)}
                                    defaultImages={images}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
                :
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color:'#566573'
                        }}
                        variant='body2'
                        >
                            Informacion del Producto:
                        </Typography>
                        <Divider
                        sx={{
                            marginBottom: 1
                        }}
                        />
                        <Grid container spacing={1} sx={{ maxHeight: '80vh' }} >
                            <Grid xs={6} >
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    value={name}
                                    onChange={({target}) => setFieldValue("name", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <ProductFinder
                                    value={productParent}
                                    placeholder=''
                                    inputVariant="outlined"
                                    fullWidth
                                    label="Compuesto"
                                    variant={'single'}
                                    typeProduct={'single'}
                                    onChange={(_, value) => setFieldValue("productParent", value)}
                                />
                            </Grid>
                            
                            <Grid xs={6} >
                                <PackagingFinder
                                    value={packaging}
                                    onChange={(_, value) => setFieldValue("packaging", value)}
                                />
                            </Grid>
                            
                            
                            <Grid xs={6} >
                                <TextField
                                    fullWidth
                                    label="SKU"
                                    value={skuCode}
                                    onChange={({target}) => setFieldValue("skuCode", (target?.value??""))}
                                />
                            </Grid>
                            
                            <Grid xs={6} >
                                <TextField
                                    fullWidth
                                    label="Código de barra"
                                    value={barCode}
                                    onChange={({target}) => setFieldValue("barCode", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <TaxesFinder
                                    filters={{ name: "IVA" }}
                                    value={tax}
                                    onChange={(_, value) => setFieldValue("tax", value)}
                                />
                            </Grid>
                            {/* <Grid xs={12} >
                                <TagInput
                                    label='Códigos de compra'
                                    value={ (purchaseCodes??[]).map((n) => ({name: n})) }
                                    onChange={(n) => setFieldValue("purchaseCodes", ((n??[]).map((x) => (x?.name??""))??[]))}
                                />
                            </Grid> */}
                            <Grid xs={12} >
                                <Autocomplete
                                    multiple
                                    options={fifthOptions}
                                    renderInput={(params) => <TextField {...params} label="Habilitar en" />}
                                    value={ fifthOptions.filter((n) => ((activePOS === true) ? n.value === "pointOfSale" : undefined) || ((activePurchase === true) ? n.value === "purchase" : undefined) ) }
                                    onChange={(_, value) => {
                                        let currentActivePOS = Boolean((value.find((n) => n?.value === "pointOfSale")))
                                        let currentActivePurchase = Boolean((value.find((n) => n?.value === "purchase")))
                                        setFieldValue("activePOS", currentActivePOS)
                                        setFieldValue("activePurchase", currentActivePurchase)
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    color:'#566573'
                                }}
                                variant='body2'
                                >
                                    Informacion de precios:
                                </Typography>
                                <Divider
                                sx={{
                                    marginBottom: 1
                                }}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <NumericFormat
                                    disabled={Boolean(productParent)}
                                    fullWidth
                                    label="Costo"
                                    value={cost}
                                    onChange={({ floatValue }) => updateCost((floatValue??0))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                {prices.map((item, index) => {
                                    return(
                                        <PriceListForm key={index} cost={cost} {...item} onChange={(price) => updatePrices(price, index)} />
                                    )
                                })}
                            </Grid>
                            {/* <Grid xs={12} >
                                <AdvancedProductSettings />
                            </Grid> */}
                            <Grid xs={12} >
                                <Dropzone
                                    onChange={(currentImages) => setFieldValue("images", currentImages)}
                                    defaultImages={images}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
                }
            </Dialog>
        </>
    )
}

export default ProductForm