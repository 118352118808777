import { DataGrid, styled } from '@component/UIComponents'

const DataGridStyled = styled(DataGrid, {
    shouldForwardProp: (prop) => prop !== "hideHeader"
})(({ hideHeader }) => ({
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "25px !important",
        maxHeight: "25px !important",
        ...(hideHeader ? {
            display: "none"
        } : {})
    }
}))

const SingleDataGrid = ({ isFetching=false, columns=[], rows=[], localeText=undefined, hideHeader=false, numRows=50, hideFooter=true }) => {
    return(
        <>
            <DataGridStyled
                pageSizeOptions={[10, 20, 30, 40, 50]}
                initialState={{
                    pagination: { paginationModel: { pageSize: numRows } },
                }}
                hideHeader={hideHeader}
                localeText={localeText}
                rowHeight={25}
                hideFooter={hideFooter}
                getRowId={(param) => (param?._id??"")}
                autoHeight
                loading={isFetching}
                columns={columns}
                rows={rows}
            />
        </>
    )
}

export default SingleDataGrid