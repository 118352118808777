import { useGetCustomerPurchaseTrackingQuery, useLazyGetCustomerPurchaseTrackingQuery } from "@query/Customers"

export const useGetCustomerPurchaseTracking = (filters, { skip=undefined, refetchOnMountOrArgChange=undefined }) => {
    const { data, isLoading, isFetching } = useGetCustomerPurchaseTrackingQuery(filters, { skip, refetchOnMountOrArgChange, })
    const customerPurchaseTracking = (data?.payload??[])

    return {
        customerPurchaseTracking,
        isLoadingGetCustomerPurchaseTracking: isLoading,
        isFetchingGetCustomerPurchaseTracking: isFetching,
    }
}

export const useLazyGetCustomerPurchaseTracking = () => {
    const [ lazyGetCustomerPurchaseTrackingQuery, { isLoading, isFetching } ] = useLazyGetCustomerPurchaseTrackingQuery()

    return {
        lazyGetCustomerPurchaseTrackingQuery,
        isLoadingLazyGetCustomerPurchaseTrackingQuery: isLoading,
        isFetchingLazyGetCustomerPurchaseTrackingQuery: isFetching,
    }
}