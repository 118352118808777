import { DataGrid, styled } from "@component/UIComponents"
import { useGetHistoryOfFixedExpenseRecords } from "../FixedCosts/hooks/useFixedCosts"
import { currencyFormat } from "@util/currencyFormat"
import { usePanel } from "@hook/usePanel"
import { useTranslation } from "react-i18next"

const DataGridStyled = styled(DataGrid)(() => ({
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "25px !important",
        maxHeight: "25px !important",
    }
}))

const FixedExpenseRecordHistory = ({ _id }) => {
    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const { historyFixedCosts, isFetching } = useGetHistoryOfFixedExpenseRecords({ ref: _id, limit: 5 }, {skip: !Boolean(_id)})
    const columns = [
        {maxWidth: 150, minWidth: 150, field: "user", headerName: "Registrado por", renderCell: ({ row }) => `${t((row?.user?.name??""))}`},
        {maxWidth: 200, minWidth: 200, field: "tag", headerName: "Etiqueta", renderCell: ({ row }) => `${(row?.tag?.name??"")}`},
        {minWidth: 200, flex: 1, field: "name", headerName: "Gasto"},
        {maxWidth: 150, minWidth: 150, field: "amount", headerName: "Monto", renderCell: ({ row }) => `${currencyFormat((row?.amount??0))}`},
        {maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({ row }) => `${getDateFormats((row?.createdAt??undefined)).ddmmyyyyhhmm}`},
    ]
    return(
        <>
            <DataGridStyled
                rowHeight={25}
                hideFooter
                getRowId={(param) => (param?._id??"")}
                autoHeight
                loading={isFetching}
                columns={columns}
                rows={historyFixedCosts}
            />
        </>
    )
}

export default FixedExpenseRecordHistory