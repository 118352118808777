import Menu from '@component/Menu'
import { Avatar, Box, Button, Divider, ListItemIcon, ListItemText, MenuItem, SettingsRoundedIcon, Stack, StoreRoundedIcon, Typography, styled } from '@component/UIComponents'
import { useGetBusinessInformation } from '@feature/Settings/BusinessInformation/hooks/useBusiness';
import { useGetUserSessionInformation } from '@feature/Users';
import { usePanel } from '@hook/usePanel';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '@util/routes';

const MenuItemStyled = styled(MenuItem)(() => ({
    '& .MuiListItemText-primary': {
        fontWeight: "500",
        fontSize: "1rem",
        padding: 5,
    }
}))

const AppBarUserMenu = ({ sx=undefined }) => {
    const { getDateFormats } = usePanel()
    const { fullName, email, lastConnection } = useGetUserSessionInformation()
    const { business } = useGetBusinessInformation()
    const navigate = useNavigate()
    // const theme = useTheme()
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => setAnchorEl(null);

    const logout = () => {
        const token = localStorage.getItem("access_token")
        if( Boolean(token) ){
            localStorage.removeItem("access_token")
            window.location.reload(true)
        }
    }

    return(
        <>
            <Box sx={sx} >
                <Button onClick={(event) => setAnchorEl(event.currentTarget)} startIcon={<StoreRoundedIcon />} >
                    { (business?.name??"") }
                    {/* <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }} >
                        {fullName[0]}
                    </Avatar> */}
                </Button>
            </Box>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClick={handleClose} onClose={handleClose} >
                <Box >
                    <Stack p={1} direction={'row'} gap={1} alignItems={'center'} >
                        <Avatar sx={{ width: 40, height: 40 }} />
                        <Box>
                            <Typography lineHeight={1} fontWeight={'bold'} fontSize={'1rem'} > {fullName} </Typography>
                            <Typography lineHeight={1.5} fontSize={'.875rem'} sx={{ color: (theme) => theme.palette.grey[500] }} > {email} </Typography>
                        </Box>
                    </Stack>
                    <Divider sx={{ margin: "0px !important" }} />
                    <MenuItemStyled onClick={() => navigate( routes.updateUserInformation() )} >
                        <ListItemIcon>
                            <SettingsRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Configuraciones"} />
                    </MenuItemStyled>
                    <Divider sx={{ margin: "0px !important" }} />
                    {/* <Box p={1} display={'flex'} width={'100%'}  >
                        <Box textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.975rem'} >Código de negocio:
                            <Box sx={{ color: (theme) => theme.palette.primary.main }} component={'span'} ml={1} >
                                {(business?.businessCode??"")}
                            </Box>
                        </Box>
                    </Box> */}
                    <Box p={1} display={'flex'} justifyContent={'end'} width={'100%'}  >
                        <Button variant='outlined' size='small' onClick={logout} > Salir </Button>
                    </Box>
                    <Typography px={1} fontSize={'.800rem'} fontWeight={'500'} color={'grey'} > sesión iniciada: {getDateFormats(lastConnection).fullDate} {getDateFormats(lastConnection).hhmmss} </Typography>
                </Box>
            </Menu>
        </>
    )
}

export default AppBarUserMenu