import { useCallback } from "react"

export const useCreditsOptions = () => {
    const typesOfCredit = useCallback(() => {
        const r = [
            {name: "Pendientes", value: "pending"},
            {name: "Pagados", value: "payment"},
            {name: "Vencidos", value: "defeated"},
            {name: "Vigentes", value: "active"},
        ].map((n, index) => ({...n, _id: (index+1) }))
        return r
    }, [])

    return {
        typesOfCredit
    }
}