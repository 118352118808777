import { ArticleRoundedIcon, CurrencyExchangeRoundedIcon, FilterListRoundedIcon, PaymentsRoundedIcon, ProductionQuantityLimitsRoundedIcon } from "@component/UIComponents"
import { useURLParams } from "@hook/useURLParams"
import { useGetCashMovementsQuery, useGetMasterBoxQuery } from "@query/Movements"
import { currencyFormat } from "@util/currencyFormat"
import { useCallback, useMemo } from "react"

export const useGetMasterBox = (filters={}) => {
    const { data, isFetching } = useGetMasterBoxQuery(filters)
    const resp = (data?.payload??{})

    return {
        resp,
        isFetching
    }
}

export const useGetCashMovements = (filters={}) => {
    const { data, isFetching } = useGetCashMovementsQuery(filters)
    const cashMovements = (data?.payload??[])

    const quickList = useCallback((movements=[]) => {
        let incomingTransfers = movements.filter((n) => n?.typeOfTransfer === "incoming" && n?.movement === "transfer" ).reduce((acc, val) => (val?.amount??0)+acc,0)
        let outgoingTransfers = movements.filter((n) => n?.typeOfTransfer === "outgoing" && n?.movement === "transfer" ).reduce((acc, val) => (val?.amount??0)+acc,0)
        let customerAdvance = movements.filter((n) => n?.box === "advanceToCustomer" ).reduce((acc, val) => (val?.amount??0)+acc,0)
        let providerAdvance = movements.filter((n) => n?.movement === "advanceToSupplier" ).reduce((acc, val) => (val?.amount??0)+acc,0)
        let fixedCosts = movements.filter((n) => n?.movement === "fixedExpense" ).reduce((acc, val) => (val?.amount??0)+acc,0)
        let variableExpense = movements.filter((n) => n?.movement === "variableExpense" ).reduce((acc, val) => (val?.amount??0)+acc,0)
    
        return [
            { primary: currencyFormat(incomingTransfers), secondary: "Traslados entrantes" },
            { primary: currencyFormat(outgoingTransfers), secondary: "Traslados salientes" },
            { primary: currencyFormat(customerAdvance), secondary: "Anticipos de cliente" },
            { primary: currencyFormat(providerAdvance), secondary: "Anticipos de proveedores" },
            { primary: currencyFormat(fixedCosts), secondary: "Pagos a gastos fijos" },
            { primary: currencyFormat(variableExpense), secondary: "Pagos a gastos variables" },
        ]
    }, [])

    return {
        cashMovements,
        isFetching,
        quickList
    }
}

export const useMasterBoxMenu = (params={}) => {
    const { addQuery } = useURLParams()
    const { leftPanel=false, rightPanel=false } = params
    const masterBoxActions = [
        {
            title: "Filtros",
            onClick: () => addQuery({ panel: "filters" }),
            icon: <FilterListRoundedIcon />
        },
        {
            title: "Resumen de caja",
            onClick: () => addQuery({ panel2: "cash_summary" }),
            icon: <ArticleRoundedIcon />
        },
        {
            title: "Registrar gasto variable",
            onClick: () => null,
            icon: <ProductionQuantityLimitsRoundedIcon />
        },
        {
            title: "Registrar pago a gasto fijo",
            onClick: () => null,
            icon: <PaymentsRoundedIcon />
        },
        {
            title: "Registrar traslado de caja",
            onClick: () => null,
            icon: <CurrencyExchangeRoundedIcon />
        },
    ]

    // const advanceFilters = [
    //     {
    //         component: "patternFormat",
    //         name: "invoiceNumber",
    //         componentProps: {
    //             fullWidth: true,
    //             label: "Numero de factura",
    //             variant: "standard",
    //             size: "small",
    //             format: "FAC-####################",
    //             InputLabelProps: { shrink: true }
    //         },
    //         gridProps: {
    //             xs: 12
    //         }
    //     },
    //     {
    //         component: "autocomplete",
    //         name: "purchaseStatus",
    //         componentProps: {
    //             fullWidth: true,
    //             label: "Estado de la compra",
    //             variant: "standard",
    //             size: "small",
    //             options: purchaseStatus
    //         },
    //         gridProps: {
    //             xs: 12
    //         }
    //     },
    //     {
    //         component: "autocomplete",
    //         name: "groupBy",
    //         componentProps: {
    //             fullWidth: true,
    //             label: "Agrupar por",
    //             variant: "standard",
    //             size: "small",
    //             options: groupPurchases
    //         },
    //         gridProps: {
    //             xs: 12
    //         }
    //     },
    //     {
    //         component: "autocomplete",
    //         name: "paymentTypes",
    //         componentProps: {
    //             fullWidth: true,
    //             multiple: true,
    //             label: "Tipos de pago",
    //             variant: "standard",
    //             size: "small",
    //             options: paymentTypes
    //         },
    //         gridProps: {
    //             xs: 12
    //         }
    //     },
    //     {
    //         component: "checkbox",
    //         name: "omitDateRange",
    //         componentProps: {
    //             label: "Omitir rango de fecha",
    //         },
    //         gridProps: {
    //             xs: 12
    //         }
    //     },
    //     {
    //         component: "checkbox",
    //         name: "useDueDate",
    //         componentProps: {
    //             label: "Filtrar por fecha de vencimiento",
    //         },
    //         gridProps: {
    //             xs: 12
    //         }
    //     },
    // ]

    // const actionsPurchase = [
    //     {
    //         title: "Filtros",
    //         onClick: () => addQuery({ panel: "filters" }),
    //         icon: <FilterListRoundedIcon />
    //     },
    //     {
    //         title: "Aplicar descuento",
    //         onClick: () => null,
    //         icon: <MonetizationOnRoundedIcon />,
    //     },
    //     {
    //         title: "Pagar facturas",
    //         onClick: () => null,
    //         icon: <AddShoppingCartRoundedIcon />,
    //     },
    //     {
    //         title: "Generar programación de pago",
    //         onClick: () => null,
    //         icon: <ArticleRoundedIcon />,
    //     },
    // ]

    const gridSizes = useMemo(() => {
        const sizes = { leftPanel: 3, centerPanel: 12, rightPanel: 3 }
        if( leftPanel && rightPanel){
            sizes.centerPanel = 6
        }
        if( leftPanel && !rightPanel){
            sizes.centerPanel = 9
        }
        if( !leftPanel && rightPanel){
            sizes.centerPanel = 9
        }
        if( !leftPanel && !rightPanel){
            sizes.centerPanel = 12
        }
        return sizes
    }, [leftPanel, rightPanel])

    return {
        gridSizes,
        masterBoxActions
    }
}