import { Avatar, Box, Typography } from '@component/UIComponents'
import SuperDataGrid from "@component/SuperDataGrid"
import ActionsList from '@component/ActionsList'
import { useGetProducts } from '@feature/Inventory/Products/hooks/useProducts'
import { useMemo } from 'react'
import { currencyFormat } from '@util/currencyFormat'
import spent from '@style/images/agotado.png'
import available from '@style/images/en-stock.png'
import { useMenusPackages } from '../hooks/useMenusPackages'

const ListOfProductsByPackaging = ({ packaging, onEditPackaging=()=>null }) => {
    const { getProductsByPackaging } = useGetProducts({ variant: "inventory" }, { skip: !Boolean((packaging?._id??null)) })

    const products = useMemo(() => {
        let currentPackages = [(packaging?._id??"")]
        return getProductsByPackaging(currentPackages)
    }, [getProductsByPackaging, packaging])

    const { primaryMenu } = useMenusPackages()

    const columns = useMemo(() => {
        let r = [
            { flex: 1, minWidth: 300, field: "name", headerName: "Producto", renderCell: ({row}) => {
                return(
                    <>
                        <Box display={'flex'} gap={1} alignItems={'center'} >
                            {(row?.stock??0) <= 0 ?
                                <Avatar src={spent} sx={{ width: 20, height: 20 }} />
                                :
                                <Avatar src={available} sx={{ width: 20, height: 20 }} />
                            }
                            <Typography fontSize={'.875rem'} >
                                {row?.name??""}
                            </Typography>
                        </Box>
                    </>
                )
            }},
            { maxWidth: 150, minWidth: 150, field: "packaging", headerName: "Embalaje", renderCell: ({ row }) => Boolean(row?.packaging) ? `${(row?.packaging?.name??"")} - x${(row?.packaging?.quantity??0)}` : "" },
            { flex: 1, minWidth: 250, field: "provider", headerName: "Proveedor", renderCell: ({ row }) => (row?.provider?.name??"") },
            { maxWidth: 130, minWidth: 130, field: "cost", headerName: "Costo", renderCell: ({ row }) => `${currencyFormat((row?.cost??0))}` },
            { maxWidth: 120, minWidth: 120, align: "center", field: "skuCode", headerName: "Código SKU" },
            { maxWidth: 120, minWidth: 120, field: "stock", headerName: "Stock", renderCell: ({ row }) => `${currencyFormat((row?.stock??0), {applyCurrencyFormat: false})}`},
        ]
        return r 
    }, [])
    return(
        <>
            <Box>
                { Boolean((packaging?._id)) ?
                    <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'} >
                        <Box fontSize={'1.2rem'} fontWeight={'bold'} >
                            {(packaging?.name??"")}
                        </Box>
                        <Box>
                            <ActionsList options={primaryMenu({
                                    hideMenus: ['createPackage'],
                                    onEditPackaging,
                                    packaging
                                })}
                            />
                        </Box>
                    </Box>
                    :
                    <></>
                }
                <SuperDataGrid
                    columns={columns}
                    rows={products}
                    filterBy={(item) => `${(item?.name??"")}`}
                />
            </Box>
        </>
    )
}

export default ListOfProductsByPackaging