import NumericFormat from '@component/NumericFormat'
import { Box, CloseRoundedIcon, Typography, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@component/UIComponents'
import { useBankFormData, useRegisterNewBank } from '../hooks/useBanks'
import Form from '@component/Form'

const BankForm = ({ open=false, onClose=()=>null }) => {
    const { values, setFieldValue, validateValues } = useBankFormData({ openModal: open })
    const { name="", accountNumber="", account="", accountType="", enabledPaymentsPurchases=false, enabledPaymentsSales=false, enabledTransfers=false } = values
    const { isLoading, registerNewBank } = useRegisterNewBank()
    const handleSubmitValue = () => {
        const { type } = validateValues(values)
        if( type === "errors" ) return
        registerNewBank(values, { closeForm: () => onClose() })
    }

    return(
        <>
            <Dialog fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Form onSubmit={handleSubmitValue} >
                    <Grid
                    container
                    direction="row"
                    alignContent='center'
                    justifyContent='space-between'
                    >
                        <Typography px={2} textTransform={'uppercase'} lineHeight={1.2} pt={2} pb={0} fontWeight={'bold'} fontSize={'1rem'} display={'flex'} flexDirection={'column'} >
                            Crear Banco
                        </Typography>
                        <Button
                        onClick={onClose}
                        >
                            <CloseRoundedIcon sx={{ color: 'red'}}/>
                        </Button>
                    </Grid>
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={6} >
                                <TextField
                                    value={name}
                                    fullWidth
                                    label="Banco"
                                    onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <NumericFormat
                                    thousandSeparator=""
                                    prefix=""
                                    value={accountNumber}
                                    fullWidth
                                    label="Numero de cuenta"
                                    onChange={({ floatValue }) => setFieldValue("accountNumber", (floatValue??0))}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <FormLabel>Tipo de persona</FormLabel>
                                <RadioGroup row value={account} onChange={({ target }) => setFieldValue("account", (target?.value??""))} >
                                    <FormControlLabel label="Natural" value={"personal"} control={<Radio />} />
                                    <FormControlLabel label="Jurídica" value={"legal"} control={<Radio />} />
                                </RadioGroup>
                            </Grid>
                            <Grid xs={6} >
                                <FormLabel>Tipo de cuenta</FormLabel>
                                <RadioGroup row value={accountType} onChange={({ target }) => setFieldValue("accountType", (target?.value??""))} >
                                    <FormControlLabel label="Ahorro" value={"savings"} control={<Radio />} />
                                    <FormControlLabel label="Corriente" value={"checking"} control={<Radio />} />
                                </RadioGroup>
                            </Grid>
                            <Grid xs={12} >
                                <Box display={'flex'} flexDirection={'column'} sx={{ gap: 1 }} >
                                    <FormControlLabel
                                        label="Habilitar para pagos de compras"
                                        control={<Checkbox checked={enabledPaymentsPurchases} sx={{ padding: 0 }} onChange={({ target }) => setFieldValue("enabledPaymentsPurchases", (target?.checked??false))} />}
                                    />
                                    <FormControlLabel
                                        label="Habilitar para pagos de ventas"
                                        control={<Checkbox checked={enabledPaymentsSales} sx={{ padding: 0 }} onChange={({ target }) => setFieldValue("enabledPaymentsSales", (target?.checked??false))} />}
                                    />
                                    <FormControlLabel
                                        label="Habilitar para traslados"
                                        control={<Checkbox checked={enabledTransfers} sx={{ padding: 0 }} onChange={({ target }) => setFieldValue("enabledTransfers", (target?.checked??false))} />}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} > Guardar </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default BankForm