import { api, mutationConfig } from "../splitApi";
import { RoleTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        recordRole: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/record/role", body };
            },
            invalidatesTags: RoleTag
        }),
        getRole: build.query({
            query: (params=undefined) => ({
                url: `/get/role`,
                params
            }),
            providesTags: RoleTag
        }),
        getRoles: build.query({
            query: (params=undefined) => ({
                url: `/get/roles`,
                params
            }),
            providesTags: RoleTag
        }),
        getPermissions: build.query({
            query: (params=undefined) => ({
                url: `/get/permissions`,
                params
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetRoleQuery,
    useGetRolesQuery,
    useGetPermissionsQuery,
    useRecordRoleMutation,
} = Endpoints;