import LineChart from '@component/LineChart'
import { Dialog, DialogContent, useTheme } from '@component/UIComponents'
import { groupArrangement, roundNumber } from '@util/helpers'
import dayjs from 'dayjs'
import { useMemo } from 'react'

const SalesGraph = ({ salesGraph=null, open=false, onClose=()=>null }) => {
    const theme = useTheme()
    const { data, dates } = useMemo(() => {
        let salesGroup = groupArrangement({
            array: (salesGraph?.sales??[]),
            groupOuting: (sale) => dayjs(sale.createdAt).format("MM-DD-YYYY"),
            firstExit: (sale) => ({
                groupName: dayjs(sale.createdAt).format("MM-DD-YYYY"),
                total: sale.total,
                totalProfitCurrency: roundNumber(sale.totalProfitCurrency),
                totalProfitPercentage: roundNumber(((sale.totalProfitCurrency/sale.total)*100)),
            }),
            secondExit: (group, index, n) => {
                group[index].total += n.total
                group[index].totalProfitCurrency += roundNumber(n.totalProfitCurrency)
                group[index].totalProfitPercentage = ((group[index].totalProfitCurrency/group[index].total)*100)
                group[index].totalProfitPercentage = roundNumber(group[index].totalProfitPercentage)
            }
        })
        salesGroup = salesGroup.sort((a,b) => dayjs(b.groupName, "MM-DD-YYYY").toDate()-dayjs(a.groupName, "MM-DD-YYYY").toDate())
        
        return {
            dates: salesGroup.map((n) => (n?.groupName??"")),
            data: salesGroup.map((n) => (n?.total??0))
        }
    }, [salesGraph])

    const charConstructor = useMemo(() => {
        return {
            labels: dates.reverse(),
            datasets: [{
                label: (salesGraph?.name??""),
                data: data.reverse(),
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.main,
                pointRadius: 6
            }],
        }
    }, [data, dates, theme, salesGraph])

    return(
        <>
            <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
                <DialogContent>
                    <LineChart data={charConstructor} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SalesGraph