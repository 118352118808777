import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'

const TableCellStyled = styled(TableCell)(() => ({
    textTransform: 'uppercase',
    padding: 0,
    fontWeight: 'bold',
}))

const DataGridFooter = ({ tableTotalize=[], tableTaxes=[] }) => {
    return (
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} mt={3} >
            <TableContainer sx={{ maxWidth: 500 }} >
                {tableTaxes.length >= 1 &&
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ padding: 0, maxWidth: 200, }} >Impuesto</TableCell>
                                <TableCell sx={{ padding: 0 }} >Base</TableCell>
                                <TableCell sx={{ padding: 0 }} >Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableTaxes.map((item, index) => {
                                return(
                                    <TableRow key={index}>
                                        <TableCell sx={{ padding: 0 }} > { (item?.name??"") } </TableCell>
                                        <TableCell sx={{ padding: 0 }} > { currencyFormat((item?.base??0)) } </TableCell>
                                        <TableCell sx={{ padding: 0 }} > { currencyFormat((item?.total??0)) } </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                }
            </TableContainer>

            <TableContainer sx={{ maxWidth: 400 }} >
                {tableTotalize.length >= 1 &&
                    <Table>
                        <TableBody>
                            {tableTotalize.map((item, index) => {
                                return(
                                    <TableRow key={index} >
                                        <TableCellStyled colSpan={2}>{ (item?.name??"") }</TableCellStyled>
                                        <TableCellStyled align="right">{ currencyFormat((item?.amount??0)) }</TableCellStyled>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                }
            </TableContainer>
        </Box>
    )
}

export default DataGridFooter