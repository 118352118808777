import { formErrorList } from "@hook/useFormTools"
import { yup } from "@hook/useFormik"
import { useRegisterProviderMutation } from "@query/Providers"
import { Toast } from "@util/swal"
import { useCallback, useEffect, useMemo, useState } from "react"

export const useProviderFormData = (config={}) => {
    const { defaultValues=undefined } = config
    const schema = yup.object().shape({
        name: yup.string().required("El nombre del proveedor es un campo obligatorio."),
    })

    let val = useMemo(() => ({ address: "", bank: "", bankAccountNumber: "", typeOfBankAccount: "", bankAgreementNumber: "", city: null, code: "", name: "", nit: "", phone: "", representative: "", tax: null }), [])

    const [values, setValue] = useState({...val})

    const resetValues = useCallback(() => setValue({...val}), [val])

    const validateValues = (params={}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            console.log(error)
            formErrorList((error?.errors??[]))
            return {
                type: "errors",
                errors: (error?.errors??[])
            }
        }
    }

    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))

    const loadDefaultValues = useCallback(() => {
        if( Boolean(defaultValues?._id) ){
            setValue(defaultValues)
        }
    }, [defaultValues])

    useEffect(() => {
        loadDefaultValues()
    }, [loadDefaultValues])

    return { values, setFieldValue, resetValues, validateValues }
}

export const useRegisterProvider = () => {
    const [ registerProviderMutation, {isLoading} ] = useRegisterProviderMutation()

    const registerProvider = async (payload={}, callback={}) => {
        if( isLoading === false ){
            const { clearForm=()=>null, closeForm=()=>null } = callback
            const resp = await registerProviderMutation(payload).unwrap()
            if( resp?.status === 1 ){
                let r = (resp?.payload??null)
                Toast.fire({
                    icon: 'success',
                    text: Boolean(payload?._id) ? "El proveedor se actualizo exitosamente." : "El proveedor se registro exitosamente."
                })
                clearForm(r)
                closeForm(r)
            }else{
                Toast.fire({
                    icon: 'error',
                    text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
                })
            }
        }
    }

    return {
        registerProvider,
        isLoading,
    }
}