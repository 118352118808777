import TabAppContext from "@component/TabAppContext"
import { useMemo, useState } from "react"
import OrderEntry from "./OrderEntry"
import ComponentTitle from "@component/ComponentTitle"

const PurchaseOrders = () => {
    const [tab, setTab] = useState("orders")

    const tabs = useMemo(() => ([
        {label: "Pedidos a proveedores", value: "orders", component: <OrderEntry />},
    ]), [])

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Pedidos" />
            <TabAppContext
                tab={tab}
                tabs={tabs}
                onChange={(a, b) => setTab(b)}
            />
        </>
    )
}

export default PurchaseOrders