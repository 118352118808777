import SuperDataGrid from "@component/SuperDataGrid"
import { useArchivedProvider, useGetProviders, useGetProvidersFilters } from "../hooks/useProviders"
import { usePanel } from "@hook/usePanel"
import { useMemo, useState } from "react"
import { currencyFormat } from "@util/currencyFormat"
import { Grid } from "@component/UIComponents"
import ActionsList from "@component/ActionsList"
import { useProviderListMenu } from "./hooks/useProviderList"
import Filters from "./components/Filters"
import ProviderForm from "../ProviderForm"
import GenerateProviderAdvance from "../components/GenerateProviderAdvance"
import ReturnAdvancePaymentFromSupplierToBox from "../components/ReturnAdvancePaymentFromSupplierToBox"

const ProviderList = () => {

    const [openForm, setOpenForm] = useState(false)
    const { archived } = useGetProvidersFilters()
    const { providers, isFetching } = useGetProviders({ variant: "list", archived })
    const { primaryActions, secondaryActions } = useProviderListMenu()
    const [selection, setSelection] = useState({ editableProvider: null, anticipateProvider: null, returnSupplierAdvance: null })
    const { archivedProvider } = useArchivedProvider()

    const { getDateFormats } = usePanel()

    const Buttons = (props={}) => <ActionsList
        title={`${(props?.name??"")}`}
        variant="mobileMenu"
        options={secondaryActions(props, {
            onEdit: (params) => setSelection((prevState) => ({...prevState, editableProvider: params})),
            onArchived: ({ isArchived=false, _id }) => archivedProvider({ providerId: _id, archived: !isArchived }),
            onAdvance: (params) => setSelection((prevState) => ({...prevState, anticipateProvider: params})),
            onReturnAdvance: (params) => setSelection((prevState) => ({...prevState, returnSupplierAdvance: params})),
        })}
    />

    const columns = useMemo(() => [
        {
            flex: 1,
            minWidth: 250,
            field: "name",
            headerName: "Proveedor",
            renderCell: ({ row }) => {
                return(
                    <>
                        <Buttons {...row} />
                        { (row?.name??"") }
                    </>
                )
            }
        },
        {minWidth: 150, maxWidth: 150, field: "balance", headerName: "Anticipo", renderCell: ({row}) => `${currencyFormat((row?.balance??0))}`},
        {flex: 1, minWidth: 250, field: "address", headerName: "Dirección"},
        {minWidth: 200, maxWidth: 200, field: "representative", headerName: "Representante"},
        {minWidth: 100, maxWidth: 100, field: "retefuente", align: "center", headerName: "Ret. Fuente", renderCell: ({ row }) => Boolean(row?.retefuente??false) ? `${(row?.tax?.tax??0)} %` : `N/A` },
        {minWidth: 130, maxWidth: 130, field: "nit", headerName: "Nit."},
        {minWidth: 100, maxWidth: 100, field: "phone", headerName: "Teléfono"},
        {minWidth: 160, maxWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({ row }) => `${getDateFormats(row?.createdAt??"").ddmmyyyyhhmm}`},
    ], [getDateFormats])

    const handleClose = () => {
        setOpenForm(false)
        setSelection((prevValues) => ({...prevValues, editableProvider: null}))
    }
    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        filterButton={{
                            show: true,
                            component: () => <Filters />
                        }}
                        options={primaryActions(null, { createProvider: () => setOpenForm(true) })}
                    />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        fixedCells
                        loading={isFetching}
                        columns={columns}
                        rows={providers}
                        filterBy={({ name="", representative="", nit="" }) => `${name} ${representative} ${nit}`}
                    />
                </Grid>
            </Grid>
            <ProviderForm defaultValues={(selection?.editableProvider??null)} open={openForm || Boolean((selection?.editableProvider?._id??null))} onClose={handleClose} />
            <GenerateProviderAdvance provider={(selection?.anticipateProvider??null)} onClose={() => setSelection((prevValues) => ({...prevValues, anticipateProvider: null}))} />
            <ReturnAdvancePaymentFromSupplierToBox provider={(selection?.returnSupplierAdvance??null)} onClose={() => setSelection((prevValues) => ({...prevValues, returnSupplierAdvance: null}))} />
        </>
    )
}

export default ProviderList