import ActionsList from "@component/ActionsList"
import { useCashierMenu } from "../hooks/useCashierPOS"
import { MenuIconRounded } from "@component/UIComponents"
import { currencyFormat } from "@util/currencyFormat"
import { usePOSMenu } from '../hooks/usePOS'
import {useState} from 'react'
import CustomerForm from '@feature/Customers/CustomerForm'
import ProductForm from '@feature/Inventory/Products/ProductForm'
import { Grid, Typography } from "@component/UIComponents"

const CashierActions = ({ variantListButton="", refUser=null, balance=0, setFieldValue=()=>null }) => {
    const [showForms, setShowForms] = useState({ registerClient: false, registerProduct: false })
    const { cashShares } = useCashierMenu()
    const optionsCash = cashShares({variantListButton, fixedBox: (refUser??null), boxBalance: balance })
    const { optionActions } = usePOSMenu()
    const optionsActions = optionActions({
                        onRecordCustomer: () => setShowForms((prevState) => ({...prevState, registerClient: true})),
                        onRecordProduct: () => setShowForms((prevState) => ({...prevState, registerProduct: true})),
                    })

    const cajaPOS ={
        button:
        <Grid
         >
            <Typography
                variant="body"
                fontWeight="bold"
                spacing={2}
                style={{padding:'16px'}}
             >
                {`Disponible en caja POS: ${currencyFormat(balance)}`}
            </Typography>
        </Grid>
        }
    const newOptionCash = [cajaPOS, ...optionsCash]
    const finalOptions = [...newOptionCash, ...optionsActions]
    return(
        <>
            <ActionsList
                title={`USAR DINERO - ${currencyFormat(balance)}`}
                variant={variantListButton}
                options={finalOptions}
                componentProps={{
                    menuMobile: {
                        props: {
                            icon: <MenuIconRounded sx={{ color: "#FFFFFF", fontSize: ".875rem" }} />,
                        }
                    }
                }}
            />
            <CustomerForm
                open={showForms.registerClient}
                defaultValues={null}
                onClose={() => setShowForms((prevState) => ({...prevState, registerClient: false}))}
                actionWhenClosingFormWithSuccessfulRegistration={(customer) => setFieldValue("customer", customer)}
            />
            <ProductForm
                open={showForms.registerProduct}
                onClose={() => setShowForms((prevState) => ({...prevState, registerProduct: false}))}
            />
        </>
    )
}

export default CashierActions