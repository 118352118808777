import { useUpdatePurchaseExpirationDateMutation } from "@query/Purchases"
import { asynchronousResponseHandler } from "@util/helpers"

export const useUpdatePurchaseExpirationDate = () => {
    const [updatePurchaseExpirationDateMutation, { isLoading }] = useUpdatePurchaseExpirationDateMutation()

    const updatePurchaseExpirationDate = async (payload, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await updatePurchaseExpirationDateMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "Se actualizo la fecha de vencimiento de la factura satisfactoriamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        updatePurchaseExpirationDate,
        isLoading
    }
}