import TabAppContext from "@component/TabAppContext"
import { useMemo, useState } from "react"
import CustomerList from "./CustomersList"
import ComponentTitle from "@component/ComponentTitle"

const Customers = () => {
    const [tab] = useState("customers")
    const tabs = useMemo(() => ([
        {label: "Clientes", value: "customers", component: <CustomerList />}
    ]), [])
    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Clientes" />
            <TabAppContext
                tab={tab}
                tabs={tabs}
                onChange={(a) => console.log(a)}
            />
        </>
    )
}

export default Customers