import TabAppContext from "@component/TabAppContext"
import { useState } from "react"
import { useMemo } from "react"
import ProviderList from "./ProviderList"
import ComponentTitle from "@component/ComponentTitle"

const Providers = () => {
    const [tab] = useState("providers")
    const tabs = useMemo(() => ([
        {label: "Proveedores", value: "providers", component: <ProviderList />}
    ]), [])
    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Proveedores" />
            <TabAppContext
                tab={tab}
                tabs={tabs}
                onChange={(a) => console.log(a)}
            />
        </>
    )
}

export default Providers