import Autocomplete from "@component/Autocomplete"
import { useGetBanks } from "../hooks/useBanks"
import { useRef } from "react"
import { useEffect } from "react"

const BanksFinder = ({ label="Bancos", autoFocus=undefined, onChange=undefined, queryValue=undefined, compare=undefined }) => {
    let autoCompleteRef = useRef()
    const { resp, isFetching } = useGetBanks()

    useEffect(() => {
        if( Boolean(autoFocus) ){
            setTimeout(() => {
                autoCompleteRef.current?.focus()
            }, 100)
        }
    }, [autoCompleteRef, autoFocus])

    return(
        <>
            <Autocomplete
                queryValue={queryValue}
                compare={compare}
                fullWidth={true}
                onChange={onChange}
                ref={autoCompleteRef}
                loading={isFetching}
                label={label}
                options={resp}
            />
        </>
    )
}

export default BanksFinder