export const filter = {
    active: false,
    props: {
        title: undefined,
        icon: undefined,
        variant: undefined,
        component: undefined,
        filterWindow: undefined,
        filters: undefined,
        onChangeFilters: undefined,
    }
}

export const menuMobile = {
    props: {
        icon: undefined,
        title: undefined,
        menuTitle: undefined,
        variant: undefined,
    }
}

export const portal = {
    active: false
}

export const componentProps = {
    filter,
    menuMobile,
    portal,
}