import Form from "@component/Form"
import TextField from "@component/TextField"
import { Avatar, Box, Button, Grid, Stack } from "@component/UIComponents"
import { useAuthenticateUser, useLoginFormValues } from "@feature/Login/hooks/useLogin"
import { useLoginController } from "@feature/Login/hooks/useLoginController"
import { getErrorsParams } from "@hook/useFormik"
import { useMemo } from "react"
import { useCallback } from "react"
import { useEffect } from "react"

const QuickLogin = () => {
    const { getQuickAccessAccount, deleteQuickAccessAccount, handleShowPassword, showPassword } = useLoginController()
    const user = useMemo(() => getQuickAccessAccount(), [getQuickAccessAccount])
    const { isLoadingAuthenticateUserMutation, authenticateUser } = useAuthenticateUser()

    const { values, errors, touched, handleSubmit, setFieldValue } = useLoginFormValues({ onSubmit: authenticateUser })
    const { password } = values
    const error = (name) => getErrorsParams({ errors, field: name, touched })
    const loadUserEmailInForm = useCallback(() => {
        if( Boolean(((user?.email??""))) ){
            setFieldValue("email", (user?.email??""))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        loadUserEmailInForm()
    }, [loadUserEmailInForm])

    return(
        <>
            <Box padding={1} mx={7} >
                <Form onSubmit={() => handleSubmit()} >
                    <Grid container spacing={2} >
                        <Grid xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }} >
                            <Avatar sx={{ width: 80, height: 80, fontSize: "2.6rem", fontWeight: "bold", bgcolor: (theme) => theme.palette.primary.main }} />
                            <Box lineHeight={1} mt={.5} sx={{ color: (theme) => theme.palette.grey[500], fontWeight: 500 }} >
                                {(user?.name??"")} {(user?.lastName??"")}
                            </Box>
                            <Box sx={{ color: (theme) => theme.palette.grey[500], fontWeight: 500 }} >
                                {(user?.email??"")}
                            </Box>
                        </Grid>
                        <Grid xs={12} >
                            <TextField
                                value={password}
                                showPassword
                                label={"Contraseña"}
                                fullWidth
                                type={showPassword}
                                InputFontWeight={500}
                                {...error("password")}
                                onChange={({target}) => setFieldValue("password", (target?.value??""))}
                                onViewPassword={() => handleShowPassword()}
                            />
                        </Grid>
                        <Grid xs={12} sx={{ mt: 3 }} >
                            <Stack justifyContent={'space-between'} direction={'row'} >
                                <Button onClick={() => deleteQuickAccessAccount()} loading={isLoadingAuthenticateUserMutation} >
                                    usar otra cuenta
                                </Button>
                                <Button variant="contained" disableElevation type="submit" loading={isLoadingAuthenticateUserMutation} >
                                    ingresar
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </Box>
        </>
    )
}

export default QuickLogin