import AuthContainerForm from "@component/AuthContainerForm"
import Form from "@component/Form"
import PatternFormat from "@component/PatternFormat"
import { Button, Grid, Stack, TextField } from "@component/UIComponents"
import { useBusinessFormController } from "@feature/Business/hooks/useBusinessFormController"
import { useUpdateBusinessInformation } from "@feature/Business/hooks/useUpdateBusinessInformation"

const RegisterFirstBusiness = () => {
    const { values, setFieldValue, validateValues, resetValues } = useBusinessFormController()
    const { address="", email="", name="", nit="", phone="" } = values
    const { updateBusinessInformation } = useUpdateBusinessInformation()

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if( type === "errors" ) return
        updateBusinessInformation(values, {
            clearForm: () => {
                resetValues()
                setTimeout(() => {
                    window.location.reload(true)
                }, 2000)
            }
        })
    }

    return(
        <>
            <AuthContainerForm title={"Facill - Mi Negocio"} subTitle="Ya casi terminamos, solo registrar tu negocio y podrás acceder a nuestros servicios." >
                <Form onSubmit={handleSubmit} >
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <TextField
                                value={name}
                                fullWidth
                                placeholder="Razón social / Nombre del negocio"
                                onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <TextField
                                value={nit}
                                fullWidth
                                placeholder="NIT"
                                onChange={({ target }) => setFieldValue("nit", (target?.value??""))}
                            />
                        </Grid>
                        <Grid xs={6} >
                            <TextField
                                value={email}
                                fullWidth
                                placeholder="Correo electrónico"
                                onChange={({ target }) => setFieldValue("email", (target?.value??""))}
                            />
                        </Grid>
                        <Grid xs={6} >
                            <PatternFormat
                                InputLabelProps={{
                                    shrink: true
                                }}
                                format="(+57) - ### ### ## ##"
                                placeholder={"Numero de teléfono"}
                                fullWidth
                                InputFontWeight={500}
                                value={phone}
                                onChange={(val) => setFieldValue("phone", (val??""))}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <TextField
                                value={address}
                                multiline
                                rows={2}
                                fullWidth
                                placeholder="Dirección"
                                onChange={({ target }) => setFieldValue("address", (target?.value??""))}
                            />
                        </Grid>
                        <Grid xs={12} mt={3} >
                            <Stack justifyContent={'space-between'} direction={'row'} >
                                <Button >
                                    Cerrar sesión
                                </Button>
                                <Button variant="contained" disableElevation type="submit" >
                                    Registrar negocio
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </AuthContainerForm>
        </>
    )
}

export default RegisterFirstBusiness