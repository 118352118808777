import Autocomplete from '@component/Autocomplete'
import { Stack } from '@component/UIComponents'


const Consignment = ({ id=undefined, size=undefined }) => {
    return(
        <>
            <Stack direction={'row'}>
                <Autocomplete
                    size={size}
                    id={id}
                    label='Bancos'
                    fullWidth
                    options={[]}
                />
            </Stack>
        </>
    )
}

export default Consignment