import { Box, Checkbox, DatePicker, InsertLinkRoundedIcon, LinkOffRoundedIcon } from '@component/UIComponents'
import dayjs from 'dayjs'
import { useState } from 'react'

const DateFilter = ({ minDate=undefined, startDate=undefined, endDate=undefined, onChange=()=>null, size="medium", stDisableFuture=true, edDisableFuture=true, }) => {
    const [linkDates, setLinkDates] = useState(true)

    const handleChangeDate = (field, date) => {
        if( linkDates ){
            onChange({
                startDate: dayjs(date).format("MM-DD-YYYY"),
                endDate: dayjs(date).format("MM-DD-YYYY")
            })
        }else{
            onChange({
                [field]: dayjs(date).format("MM-DD-YYYY")
            })
        }
    }

    return(
        <>
            <Box display={'flex'} justifyContent={'space-between'} >
                <DatePicker
                    defaultValue={dayjs()}
                    value={dayjs(startDate)}
                    label="Desde"
                    format='DD-MM-YYYY'
                    onChange={(date) => handleChangeDate("startDate", date)}
                    disableFuture={stDisableFuture}
                    minDate={minDate}
                    slotProps={{
                        textField: {
                            size
                        }
                    }}
                />
                <Checkbox
                    size='small'
                    checked={linkDates}
                    icon={<LinkOffRoundedIcon />}
                    checkedIcon={<InsertLinkRoundedIcon />}
                    onChange={({target}) => setLinkDates((target?.checked??false))}
                />
                <DatePicker
                    minDate={minDate}
                    disableFuture={edDisableFuture}
                    defaultValue={dayjs()}
                    value={dayjs(endDate)}
                    label="Hasta"
                    format='DD-MM-YYYY'
                    onChange={(date) => handleChangeDate("endDate", date)}
                    slotProps={{
                        textField: {
                            size
                        }
                    }}
                />
            </Box>
        </>
    )
}

export default DateFilter