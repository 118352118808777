import { Grid, WidgetsRoundedIcon } from "@component/UIComponents"
import { useControllerPackages } from "./hooks/useControllerPackages"
import { useGetPackaging } from "./hooks/usePackaging"
import ActionsList from "@component/ActionsList"
import VerticalCustomList from "@component/VerticalCustomList"
import ListOfProductsByPackaging from "./components/ListOfProductsByPackaging"
import RegisterPackaging from "./components/RegisterPackaging"
import { useMenusPackages } from "./hooks/useMenusPackages"
import { useMemo } from "react"
import ComponentTitle from "@component/ComponentTitle"

const Packaging = () => {
    const { primaryMenu } = useMenusPackages()
    const { closePackageRegistrationForm, selectedPackaging, setSelectedPackaging, setShowCreatePackageForm, showCreatePackageForm } = useControllerPackages()
    const { packages } = useGetPackaging()
    const currentPackages = useMemo(() => {
        return packages.map((n) => ({...n, defaultName: `${(n?.name??"")} = x${(n?.quantity??0)}`}))
    }, [packages])
    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Embalajes" />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        options={primaryMenu({
                            hideMenus: ['editPackage', 'archivePackage'],
                            onCreatePackaging: () => setShowCreatePackageForm({ package: null, open: true })
                        })}
                    />
                </Grid>
                <Grid xs={3} >
                    <VerticalCustomList
                        emptyListText="No se encontraron embalajes para mostrar"
                        itemIcon={<WidgetsRoundedIcon />}
                        list={currentPackages}
                        onClick={(packaging) => setSelectedPackaging(packaging)}
                        getPrimaryLabel={(item) => (item?.defaultName??"")}
                    />
                </Grid>
                <Grid xs={9} >
                    <ListOfProductsByPackaging
                        packaging={selectedPackaging}
                        onEditPackaging={() => setShowCreatePackageForm({ package: selectedPackaging, open: true })}
                    />
                </Grid>
            </Grid>
            <RegisterPackaging open={showCreatePackageForm.open} packaging={showCreatePackageForm.package} onClose={() => closePackageRegistrationForm()} />
        </>
    )
}

export default Packaging