import { AddCircleOutlineIconRounded, EditIconRounded } from "@component/UIComponents"
import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useGetRoleQuery, useGetRolesQuery, useRecordRoleMutation } from "@query/RoleAndPermissions"
import { asynchronousResponseHandler } from "@util/helpers"
import { useCallback } from "react"
import { useMemo } from "react"

export const useGetRole = (filter={}, config={}) => {
    const { data, isFetching } = useGetRoleQuery(filter, config)
    let role = (data?.payload??null)
    role = Boolean(filter?.roleId??null) ? role : null
    return {
        role,
        isFetching
    }
}

export const useGetRoles = () => {
    const { data, isFetching } = useGetRolesQuery()
    const roles = (data?.payload??[])

    return {
        roles,
        isFetching
    }
}

export const useRoleFormData = (props={}) => {
    const { defaultValues=undefined } = props
    const initialValues = useMemo(() => ({ name: "", permissions: [], }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre del rol es requerido."),
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}

export const useRecordRole = () => {
    const [recordRoleMutation, { isLoading }] = useRecordRoleMutation()

    const recordRole = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await recordRoleMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean(payload?._id??null) ?
                    "El rol se actualizo exitosamente."
                    :
                    "El rol se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        recordRole,
        isLoading
    }
}

export const useRoleMenu = () => {

    const primaryMenu = useCallback((props={}) => {
        const { onCreateNewRole=()=>null } = props
        return [
            {
                title: "Crear nuevo rol",
                icon: <AddCircleOutlineIconRounded />,
                onClick: () => onCreateNewRole()
            }
        ].map((n, index) => ({...n, _id: index}))
    }, [])
    
    const secondaryMenu = useCallback((props={}, config={}) => {
        const { _id=null } = props
        const { onEditRole=()=>null } = config
        return [
            {
                title: "Editar",
                icon: <EditIconRounded />,
                onClick: () => onEditRole(_id)
            }
        ].map((n, index) => ({...n, _id: index}))
    }, [])

    return {
        primaryMenu,
        secondaryMenu
    }
}