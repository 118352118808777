import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarChart = ({ data=undefined, title="" }) => {

    const options = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: { display: Boolean(title), text: title },
        },
    };

    return (
        <>
            {Boolean(data) ?
                <Bar data={data} options={options} />
                :
                <></>
            }
        </>
    )
}

export default BarChart