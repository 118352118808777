import Form from '@component/Form'
import { Box, Button, DatePicker, Dialog, DialogActions, DialogContent } from '@component/UIComponents'
import dayjs from 'dayjs'
import { useUpdateCreditExpirationDateForm } from './hooks/useUpdateCreditExpirationDateForm'
import useControllerUpdateCreditExpirationDate from './hooks/useControllerUpdateCreditExpirationDate'
import { usePanel } from '@hook/usePanel'
import { getDetailedDueDate } from '@util/date'

const UpdateCreditExpirationDate = ({ saleRef=null, dueDate=null, open=false, onClose=()=>null }) => {
    const { getDateFormats } = usePanel()
    const { setFieldValue, values } = useUpdateCreditExpirationDateForm()
    const { creditExpirationDate } = values
    const { onUpdateExpirationDate, isLoadingUpdateInvoiceDueDate } = useControllerUpdateCreditExpirationDate({ dataHandler: setFieldValue, saleRef, dueDate, closeForm: onClose })

    return(
        <>
            <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
                <Form onSubmit={() => onUpdateExpirationDate(values)} >
                    <DialogContent>
                        <DatePicker
                            format='DD/MM/YYYY'
                            label="Fecha de vencimiento"
                            value={dayjs(creditExpirationDate)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    helperText: <>
                                        <Box component={'span'} >
                                            <Box> { getDateFormats(dayjs(creditExpirationDate)).fullDate } </Box>
                                            <Box> { getDetailedDueDate(creditExpirationDate) } </Box>
                                        </Box>
                                    </>
                                }
                            }}
                            onChange={(date,) => setFieldValue("creditExpirationDate", dayjs(date).toDate())}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button loading={isLoadingUpdateInvoiceDueDate} type='submit' > Actualizar </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default UpdateCreditExpirationDate