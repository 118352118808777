export class Utils {
    constructor() {}

    static RoundNumber(value, decimals=2){
        const _ = require('lodash')
        const round = _.round(value, decimals)
        return round
    }

    static sumArray(data=[], sumFn=()=>0){
        let result = data.reduce((acc, item) => (acc+sumFn(item)),0)
        result = Utils.RoundNumber(result)
        return result
    }
    
    static multiply(num1, num2){
        let result = (num1*num2)
        result = Utils.RoundNumber(result)
        return result
    }

    static margin(profit=0, sale=0){
        let margin = ((profit/sale)*100)
        margin = Utils.RoundNumber(margin)
        return margin
    }
}