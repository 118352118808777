import NumericFormat from '@component/NumericFormat'
import { Box, Stack } from '@component/UIComponents'
import BanksFinder from "@feature/Banks/components/BanksFinder"
import { useState } from 'react'

const Consignment = ({ onChange=()=>null, maxAmount=0 }) => {
    const [payment, setPayment] = useState({ value: 0, boxName: "", refId: null })

    const handleChange = (field, val) => {
        let currentPayment = {...payment}
        if( field === "bank" ){
            currentPayment = {...currentPayment, boxName: (val?.name??""), refId: (val?._id??null) }
        }else{
            currentPayment = {...currentPayment, value: val }
        }
        setPayment(currentPayment)
        onChange(currentPayment)
    }

    return(
        <>
        <Stack direction={'row'} width={'100%'} spacing={1} >
            <Box width={'100%'} >
                <BanksFinder
                    compare={(bank) => (bank?._id??"")}
                    queryValue={(payment?.refId??"")}
                    onChange={(_, param) => handleChange("bank", param)}
                    autoFocus
                />
            </Box>
            <Box width={'100%'} >
                <NumericFormat
                    maxVal={maxAmount}
                    fullWidth
                    label="Monto"
                    onChange={({ floatValue }) => handleChange("amount", floatValue)}
                />
            </Box>
        </Stack>
        </>
    )
}

export default Consignment