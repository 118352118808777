import { useLazyGetCustomer } from "@feature/Customers/hooks/useLazyGetCustomer"
import { preventAction } from "@util/helpers"
import { Toast } from "@util/swal"
import { useCallback, useState } from "react"
import { useShareSaleAsPurchase } from "./useShareSaleAsPurchase"

const useControllerCodeToShareSalesInvoice = (props={}) => {
    const [updateCustomer, setUpdateCustomer] = useState(null)
    const { dataHandler=()=>null } = props
    const { lazyGetCustomerQuery } = useLazyGetCustomer()
    const { shareSaleAsPurchase } = useShareSaleAsPurchase()

    const uploadSalesInvoiceReference = useCallback((saleRef=null) => {
        dataHandler("saleRef", saleRef)
    }, [dataHandler])

    const closeCustomerEditingForm = useCallback(() => setUpdateCustomer(null), [])

    const validateCustomerBeforeSharingInvoice = useCallback(({ customerId=null, saleRef=null }) => {
        if( !Boolean(customerId) ){
            Toast.fire({
                icon: "error",
                text: "La factura no cuenta con un cliente para poder realizar el proceso."
            })
        }else{
            preventAction({
                text: "¿Desea compartir esta factura de venta con el cliente en la app?",
                onSubmit: async () => {
                    const customer = ((await lazyGetCustomerQuery({ refId: customerId }))?.data?.payload??null)
                    if( !Boolean(customer?.businessCode) ){
                        preventAction({
                            text: "El cliente no tiene asignado un código de negocio, no sabemos a que negocio le quieres compartir esta factura de venta. ¿Quieres registrar un código de negocio a este cliente?",
                            onSubmit: () => setUpdateCustomer(customer)
                        })
                    }else{
                        shareSaleAsPurchase({ saleRef, businessCode: (customer?.businessCode??"") })
                    }
                }
            })
        }
    }, [lazyGetCustomerQuery, shareSaleAsPurchase])


    return {
        uploadSalesInvoiceReference,
        validateCustomerBeforeSharingInvoice,
        updateCustomer,
        closeCustomerEditingForm,
    }
}

export default useControllerCodeToShareSalesInvoice