import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'
import { forwardRef } from 'react'

const MovementReceipt = forwardRef(({ movementId=null, }, ref) => {
    const { getDateFormats } = usePanel()
    let Signing = ({ label="" }) => {
        return(
            <Grid container >
                <Grid xs={1} >
                    {label}:
                </Grid>
                <Grid xs={6} >
                    _____________________________
                </Grid>
            </Grid>
        )
    }

    return(
        <>
            <TableContainer 
                sx={{ display: "none", displayPrint: "block" }}
            ref={ref} >
                <Box padding={2} >
                    <Table>
                        <TableBody sx={{
                            '& .MuiTableCell-root': {
                                border: "none",
                                padding: "2px"
                            }
                        }} >
                            <TableRow>
                                <TableCell >
                                    <Typography textTransform={'uppercase'} textAlign={'center'} fontWeight={'bold'} >
                                        { "Gasto Fijo" }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >
                                    <Box fontWeight={'bold'} > Registrado por: <Box component={'span'} fontWeight={'500'} > { "Cristian Acosta" } </Box> </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >
                                    <Box fontWeight={'bold'} > Fecha de registro: <Box component={'span'} fontWeight={'500'} > { getDateFormats("09-10-2023").fullDate } a las {getDateFormats("09-10-2023").hhmm} </Box> </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >
                                    <Box fontWeight={'bold'} > Monto: <Box component={'span'} fontWeight={'500'} > { currencyFormat(1350000) } </Box> </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >
                                    <Box fontWeight={'bold'} > Desde: <Box component={'span'} fontWeight={'500'} > { "Caja mayor" } </Box> </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >
                                    <Box fontWeight={'bold'} > Detalles: <Box component={'span'} fontWeight={'500'} > { "Se realizo un pago al gasto fijo NOMINA ENRIQUE" } </Box> </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >
                                    <Box fontWeight={'bold'} > Fecha de impresión del recibo: <Box component={'span'} fontWeight={'500'} > { getDateFormats().fullDate } a las {getDateFormats().hhmm} </Box> </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell >
                                    <Box fontWeight={'bold'} > Recibo generado por: <Box component={'span'} fontWeight={'500'} > { "Enrique A. Pacheco" } </Box> </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Grid container >
                                        <Grid xs={12} sx={{ marginBottom: 3 }} >
                                            <Signing label='Nombre' />
                                            <Signing label='I.D.' />
                                            <Signing label='Firma' />
                                        </Grid>
                                        <Grid xs={12} >
                                            <Signing label='Nombre' />
                                            <Signing label='I.D.' />
                                            <Signing label='Firma' />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>
        </>
    )
})

export default MovementReceipt