import ActionsList from "@component/ActionsList"
import { Box, Grid } from "@component/UIComponents"
import { useArchiveFixedExpenses, useFixedCostsActions, useGetFixedExpenses, usePaymentFixedCostFormData, useRecordPaymentToFixedCost } from "./hooks/useFixedCosts"
import FixedExpenseForm from "./components/FixedExpenseForm"
import { useState } from "react"
import { useURLParams } from "@hook/useURLParams"
import Filters from "./components/Filters"
import FixedCostsDataGrid from "./components/FixedCostsDataGrid"
import SelectionOfPaymentMethod from "@component/SelectionOfPaymentMethod"
import GenerateAmount from "./components/GenerateAmount"

const FixedCosts = () => {
    const [editableFixedExpense, setEditableFixedExpense] = useState(null)
    const [fixedExpenseToPay, setFixedExpenseToPay] = useState(null)
    const [fixedExpenseGenerateAmount, setFixedExpenseGenerateAmount] = useState(null)
    const { addQuery, getQuery } = useURLParams()
    let viewArchived = getQuery("viewArchived")
    viewArchived = (viewArchived === true || viewArchived === "true")
    const handleSetEditableFixedExpense = (props=null) => {
        setEditableFixedExpense(props)
        addQuery({ form: "record-fixed-expense" })
    }
    const { fixedCosts, isFetching, quickList, group, tags } = useGetFixedExpenses({ ...(viewArchived ? { archived: true } : {}) })
    const { archiveFixedExpenses } = useArchiveFixedExpenses()
    const { actions, dataGridActions, panels } = useFixedCostsActions()
    const { leftPanelProps, centerPanelProps } = panels
    const groupBy = getQuery("groupBy")
    const { values, setFieldValue, resetValues, validateValues } = usePaymentFixedCostFormData({ fixedCost: fixedExpenseToPay })
    const { payments } = values
    const { recordPaymentToFixedCost, isLoading } = useRecordPaymentToFixedCost()
    const handleClose = () => {
        setFixedExpenseToPay(null)
        resetValues()
    }
    const handleSubmit = () => {
        const { type } = validateValues(values)
        if( (type === "errors") ) return
        recordPaymentToFixedCost(values,{
            clearForm: () => resetValues(),
            closeForm: () => setFixedExpenseToPay(null),
        })
    }

    return(
        <Grid container >
            <Grid xs={12} >
                <ActionsList options={actions} />
            </Grid>
            <Grid {...leftPanelProps} >
                <Filters list={tags} />
            </Grid>
            <Grid {...centerPanelProps} >
                { Boolean(groupBy) ?
                    <>
                        { group.map((item, index) => {
                            return(
                                <Box key={index} >
                                    <FixedCostsDataGrid
                                        setFixedExpenseGenerateAmount={setFixedExpenseGenerateAmount}
                                        setFixedExpenseToPay={setFixedExpenseToPay}
                                        title={(item?.groupName??"")}
                                        quickList={(item?.quickList??[])}
                                        archiveFixedExpenses={archiveFixedExpenses}
                                        dataGridActions={dataGridActions}
                                        handleSetEditableFixedExpense={handleSetEditableFixedExpense}
                                        isFetching={isFetching}
                                        rows={(item?.results??[])}
                                    />
                                </Box>
                            )
                        }) }
                    </>
                    :
                    <>
                        <FixedCostsDataGrid
                            setFixedExpenseToPay={setFixedExpenseToPay}
                            setFixedExpenseGenerateAmount={setFixedExpenseGenerateAmount}
                            quickList={quickList}
                            archiveFixedExpenses={archiveFixedExpenses}
                            dataGridActions={dataGridActions}
                            handleSetEditableFixedExpense={handleSetEditableFixedExpense}
                            isFetching={isFetching}
                            rows={fixedCosts}
                        />
                    </>
                }
            </Grid>
            <FixedExpenseForm
                defaultValues={editableFixedExpense}
            />
            <SelectionOfPaymentMethod
                isLoading={isLoading}
                title={(fixedExpenseToPay?.name??"")}
                hideTabs
                paymentMethodsAvailable={{ credit: false, advance: false }}
                onSubmit={handleSubmit}
                open={Boolean((fixedExpenseToPay?._id??""))}
                onClose={handleClose}
                onChange={(_, value, newPayments) => setFieldValue("payments", newPayments)}
                payments={payments}
                totalToPay={(fixedExpenseToPay?.balance??0)}
                onDeletePayment={(_, index, newList) => setFieldValue("payments", newList)}
            />
            <GenerateAmount
                fixedCost={fixedExpenseGenerateAmount}
                onClose={() => setFixedExpenseGenerateAmount(null)}
            />
        </Grid>
    )
}

export default FixedCosts