import { Box, Checkbox, RadioButtonCheckedRoundedIcon, RadioButtonUncheckedRoundedIcon, Typography } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"
import { useGetPermissions } from "../hooks/usePermissions"

const FinderPermissions = ({ disableCloseOnSelect=true, multiple=undefined, label="Permisos", size=undefined, onChange=undefined, value=null, queryValue="" }) => {
    const { isFetching, permissions } = useGetPermissions()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: permissions, queryValue, compare: (n) => n.permission})
    const val = getAutocompleteValue()
    return(
        <>
            <Autocomplete
                disableCloseOnSelect={disableCloseOnSelect}
                loading={isFetching}
                multiple={multiple}
                size={size}
                label={label}
                options={permissions}
                onChange={onChange}
                value={val}
                renderOption={(props, option, {selected}) => {
                    return(
                        <li {...props} key={option._id} >
                            <Checkbox
                                icon={<RadioButtonUncheckedRoundedIcon />}
                                checkedIcon={<RadioButtonCheckedRoundedIcon />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            <Box display={"flex"} flexDirection={"column"} alignItems="start" >
                                <Typography fontSize={'.875rem'} fontWeight={'bold'} >
                                    Permiso: <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main, textDecoration: "underline" }} > {(option?.name??"")} </Box>
                                </Typography>
                                <Typography lineHeight={1.2} fontSize={'.775rem'} fontWeight={'500'} sx={{ color: (theme) => theme.palette.grey[600] }} >
                                    Descripción: <Box component={'span'} > {(option?.description??"")} </Box>
                                </Typography>
                            </Box>
                        </li>
                    )
                }}
            />
        </>
    )
}

export default FinderPermissions