import { useUpdateUserInformationMutation } from "@query/Users"
import { asynchronousResponseHandler } from "@util/helpers"

export const useUpdateUserInformation = () => {
    const [ updateUserInformationMutation, { isLoading } ] = useUpdateUserInformationMutation()

    const updateUserInformation = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await updateUserInformationMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "La información se actualizo satisfactoriamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        updateUserInformation,
        updateUserInformationIsLoading: isLoading
    }
}