import ActionsList from "@component/ActionsList"
import { Box, PersonIconRounded, RestoreRoundedIcon, ShoppingCartRoundedIcon } from "@component/UIComponents"
import { useGetCustomer } from "@feature/Customers/hooks/useCustomers"
import { useCustomersUtils } from "@feature/Customers/hooks/useCustomersController"
import { useLazyGetCustomerPurchaseTracking } from "@feature/Customers/hooks/useGetCustomerPurchaseTracking"
import { useLazyGetSales } from "@feature/Reports/Sales/hooks/useSales"
import { useSalesController } from "@feature/Reports/Sales/hooks/useSalesController"
import { currencyFormat } from "@util/currencyFormat"
import { roundNumber } from "@util/helpers"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

export const useCustomerTrackingController = () => {
    const [activeFilters, setActiveFilters] = useState({ startDate: dayjs().startOf("month").format("MM-DD-YYYY"), endDate: dayjs().format("MM-DD-YYYY"), })
    const [customerPurchaseTracking, setCustomerPurchaseTracking] = useState([])
    const [trackingDetails, setTrackingDetails] = useState([])
    const { lazyGetCustomerPurchaseTrackingQuery } = useLazyGetCustomerPurchaseTracking()
    const { lazyGetSalesQuery, lazyGetSalesQueryIsFetching } = useLazyGetSales()

    const [componentProps, setComponentProps] = useState({
        dataGrid: "general_customer_history", // customer_purchase_history, general_customer_history
        showCustomerPurchaseHistoryWindow: false,
        filterACustomerPurchaseHistory: {
            customerId: null,
            startDate: "",
            endDate: "",
            customerName: "",
        },
        customerPurchaseTrackingFilters: {
            startDate: "",
            endDate: "",
            variant: "salesReport",
            invoiceType: "sale",
            customer: null,
        },
        customerPurchaseTrackingFilter: {
            startDate: dayjs().subtract(90, "days").format("MM-DD-YYYY"),
            endDate: dayjs().format("MM-DD-YYYY"),
            customer: "",
            customerType: "",
            businessType: "",
            zone: "",
            city: "",
            groupClients: null,
        },
        customerInvoices: [],
        selectedInvoice: null,
        selectedClient: null,
        customerSalesGraph: null,
    })

    const { customer } = useGetCustomer({ refId: (componentProps?.selectedClient??null) })

    const getList = useCallback( async () => {
        let resp = []
        if( componentProps.dataGrid === "general_customer_history" ){
            let { data } = (await lazyGetCustomerPurchaseTrackingQuery({ ...activeFilters }, {}))
            resp = (data?.payload??[])
        }
        if( componentProps.dataGrid === "customer_purchase_history" ){
            let { data } = (await lazyGetSalesQuery(componentProps.customerPurchaseTrackingFilters, {}))
            resp = (lazyGetSalesQueryIsFetching === true) ? [] : (data?.payload??[])
        }
        return resp
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyGetSalesQueryIsFetching, lazyGetCustomerPurchaseTrackingQuery, lazyGetSalesQuery, componentProps.dataGrid, componentProps.customerPurchaseTrackingFilters, activeFilters])

    const handleShowCustomerProductPurchaseHistory = useCallback(({ customerId=null, endDate="", startDate="", customerName="" }) => setComponentProps((prevState) => {
        return {
            ...prevState,
            showCustomerPurchaseHistoryWindow: !prevState.showCustomerPurchaseHistoryWindow,
            ...(!prevState.showCustomerPurchaseHistoryWindow === false ? {
                filterACustomerPurchaseHistory: {
                    ...prevState.filterACustomerPurchaseHistory,
                    customerId: null,
                    startDate: "",
                    endDate: "",
                    customerName: "",
                }
            } : {
                filterACustomerPurchaseHistory: {
                    ...prevState.filterACustomerPurchaseHistory,
                    customerId,
                    startDate,
                    endDate,
                    customerName,
                }
            })
        }
    }), [])

    const toggleInformationTables = useCallback((dataGrid="general_customer_history", { startDate="", endDate="", customer=null }) => {
        return setComponentProps((prevState) => {
            return {
                ...prevState,
                dataGrid,
                ...((dataGrid === "general_customer_history") ? {
                    customerPurchaseTrackingFilters: {
                        ...prevState.customerPurchaseTrackingFilters,
                        startDate: "",
                        endDate: "",
                        customer: null,
                    }
                } : {
                    customerPurchaseTrackingFilters: {
                        ...prevState.customerPurchaseTrackingFilters,
                        ...( Boolean(startDate) ? { startDate } : {} ),
                        ...( Boolean(endDate) ? { endDate } : {} ),
                        ...( Boolean(customer) ? { customer } : {} ),
                    }
                })
            }
        })
    }, [])

    const updateCustomerInvoices = useCallback((customerInvoices) => {
        return setComponentProps((prevState) => ({
            ...prevState,
            customerInvoices,
        }))
    }, [])

    const updateSelectedInvoice = useCallback((invoiceId) => {
        return setComponentProps((prevState) => ({
            ...prevState,
            selectedInvoice: invoiceId,
        }))
    }, [])

    const updateSelectedClient = useCallback((customerId) => {
        return setComponentProps((prevState) => ({
            ...prevState,
            selectedClient: customerId,
        }))
    }, [])

    const updateCustomerPurchaseTrackingFilter = useCallback((field, value) => {
        if( componentProps.dataGrid === "customer_purchase_history" ){
            return toggleInformationTables("customer_purchase_history", {[field]: value})
        }else{
            return setComponentProps((prevState) => {
                return {
                    ...prevState,
                    customerPurchaseTrackingFilter:{
                        ...prevState.customerPurchaseTrackingFilter,
                        [field]: value
                    }
                }
            })
        }
    }, [componentProps, toggleInformationTables])

    const updateCustomerSalesChart = useCallback((val=null) => {
        return setComponentProps((prevState) => {
            return {
                ...prevState,
                customerSalesGraph: val
            }
        })
    }, [])

    useEffect(() => {
        (async () => {
            let list = await getList()
            if( componentProps.dataGrid === "general_customer_history" ) setCustomerPurchaseTracking(list)
            if( componentProps.dataGrid === "customer_purchase_history" ) setTrackingDetails(list)
            
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getList])

    return {
        customerPurchaseTracking,
        componentProps,
        setComponentProps,
        handleShowCustomerProductPurchaseHistory,
        toggleInformationTables,
        updateCustomerInvoices,
        updateSelectedInvoice,
        updateCustomerPurchaseTrackingFilter,
        activeFilters,
        setActiveFilters,
        updateSelectedClient,
        customer,
        updateCustomerSalesChart,
        trackingDetails
    }
}

export const useCustomerControllerOptions = () => {

    const datagridRowOptionsMenu = useCallback(({ onShowCustomerProductPurchaseHistory=()=>null, onShowCustomerPurchaseHistory=()=>null, onEditCustomer=()=>null }) => {
        let r = [
            { title: "Ver productos", onClick: () => onShowCustomerProductPurchaseHistory(), icon: <ShoppingCartRoundedIcon /> },
            { title: "Ver historial", onClick: () => onShowCustomerPurchaseHistory(), icon: <RestoreRoundedIcon /> },
            { title: "Editar", onClick: () => onEditCustomer(), icon: <PersonIconRounded /> },
        ]
        return r
    }, [])

    return {
        datagridRowOptionsMenu,
    }
}

export const useCustomerTrackingControllerDataGrid = ({ trackingDetails=[], groupRows=null, onShowCustomerProductPurchaseHistory=()=>null, onShowCustomerPurchaseHistory=()=>null, onShowCustomerPurchaseInvoices=()=>null, onEditCustomer=()=>null, onShowCustomerSalesGraph=()=>null, customerPurchaseTracking=[], dataGrid="" }) => {
    const { t } = useTranslation()

    const { datagridRowOptionsMenu } = useCustomerControllerOptions()
    const { generateCustomerTracking } = useSalesController()
    const { groupClients } = useCustomersUtils()

    const Buttons = (props) => <ActionsList
        options={datagridRowOptionsMenu({
            onShowCustomerProductPurchaseHistory: () => onShowCustomerProductPurchaseHistory(props),
            onShowCustomerPurchaseHistory: ()=> onShowCustomerPurchaseHistory(props),
            onEditCustomer: ()=> onEditCustomer(props),
        })}
        variant="mobileMenu"
    />

    const { customerTracking, columnsDate } = useMemo(() => {
        const { columnsDate, customerTracking } = (dataGrid === "customer_purchase_history") ? generateCustomerTracking({ sales: [...trackingDetails] }) : {}
        return {
            columnsDate,
            customerTracking
        }
    }, [generateCustomerTracking, trackingDetails, dataGrid])

    const rows = useMemo(() => {
        let r = []
        let f = []
        r = groupClients({
            array: [...customerPurchaseTracking],
            field: Boolean(groupRows) ? `${groupRows}.name` : null,
            firstExit: (params) => ({
                total: (params?.total??0),
                totalProfitCurrency: (params?.totalProfitCurrency??0),
                totalProfitPercentage: (params?.totalProfitPercentage??0),
                customerType: (params?.customerType??{}),
                businessType: (params?.businessType??{}),
                zone: (params?.zone??{}),
                city: (params?.city??{}),
            }),
            secondExit: (group, groupIndex, n) => {
                group[groupIndex].total += (n?.total??0)
                group[groupIndex].totalProfitCurrency += (n?.totalProfitCurrency??0)
                group[groupIndex].totalProfitPercentage = (((group[groupIndex]?.totalProfitCurrency??0)/(group[groupIndex]?.total??0))*100)
            }
        })
        r = r.sort((a, b) => ((b?.daysOfAbsence??0)-(a?.daysOfAbsence??0)))

        if( dataGrid === "customer_purchase_history" ){
            f = [...customerTracking]
        }
        return {
            lsGeneralCustomerHistory: r,
            lsCustomerPurchaseHistory: f
        }
    }, [customerPurchaseTracking, dataGrid, customerTracking, groupClients, groupRows])

    const columns = useMemo(() => {
        let r = []
        let f = []
        r = [
            ...(Boolean(groupRows) ? [
                { flex: 1, minWidth: 300, field: "groupName", headerName: t(groupRows), renderCell: ({ row }) => <> {(row?.groupName??"")} </> },
            ] : [
                { flex: 1, minWidth: 300, field: "name", headerName :"Cliente", renderCell: ({ row }) => <> <Buttons {...row} /> {(row?.name??"")} </> },
            ]),
            { minWidth: 100, maxWidth: 100, field: "purchasePeriod", align: "center", headerName :"Periodo de compra", renderCell: ({row}) => `${(row?.purchasePeriod??0)}` },
            { minWidth: 100, maxWidth: 100, field: "daysOfAbsence", headerName :"Dias de ausencia", renderCell: ({row}) => <Box component={'span'} fontWeight={'bold'} sx={{ color: (theme) => (row?.daysOfAbsence??0) >= 1 ? theme.palette.error.main : theme.palette.success.main }} > {(row?.daysOfAbsence??0)} </Box> },
            {
                minWidth: 150,
                maxWidth: 150,
                field: "total",
                headerName :"Venta",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} sx={{ cursor: "pointer" }} onClick={() => onShowCustomerSalesGraph(row)} >
                            {`${currencyFormat((row?.total??0))}`}
                        </Box>
                    )
                }
            },
            { minWidth: 150, maxWidth: 150, field: "totalProfitCurrency", headerName :"$", renderCell: ({row}) => `${currencyFormat((row?.totalProfitCurrency??0))}` },
            { minWidth: 80, maxWidth: 80, field: "totalProfitPercentage", headerName :"%", renderCell: ({row}) => `${roundNumber((row?.totalProfitPercentage??0))} %` },
            { minWidth: 200, maxWidth: 200, field: "customerType", headerName :"Tipo de cliente", renderCell: ({ row }) => (row?.customerType?.name??"") },
            { minWidth: 200, maxWidth: 200, field: "businessType", headerName :"Tipo de negocio", renderCell: ({ row }) => (row?.businessType?.name??"") },
            { minWidth: 150, maxWidth: 150, field: "zone", headerName :"Zona", renderCell: ({ row }) => (row?.zone?.name??"") },
            { minWidth: 150, maxWidth: 150, field: "city", headerName :"Ciudad", renderCell: ({ row }) => (row?.city?.name??"") },
        ]
        if( dataGrid === "customer_purchase_history" ){
            f = [
                { flex: 1, minWidth: 300, field: "customer", headerName: "Cliente", renderCell: ({row}) => {
                    return(
                        <Box component={'span'} sx={{ cursor: "pointer" }} onClick={() => onShowCustomerProductPurchaseHistory({ _id: (row?._id??null) })} >
                            {`${(row?.customer??"VARIOS")}`}
                        </Box>
                    )
                }},
                { flex: 1, minWidth: 140, field: "sale", headerName: "Venta", renderCell: ({row}) => currencyFormat((row?.sale??0))},
                { flex: 1, minWidth: 140, field: "profit", headerName: "$", renderCell: ({row}) => currencyFormat((row?.profit??0))},
                { flex: 1, minWidth: 100, field: "percentage", headerName: "%", renderCell: ({row}) => `${(row?.percentage??0)}%`},

            ]
            for( let column of columnsDate ){
                r.push({
                    maxWidth: 250,
                    minWidth: 250,
                    field: column,
                    headerName: column,
                    renderCell: ({row}) => {
                        return(
                            <Box component={'span'} sx={{ cursor: "pointer" }} onClick={() => onShowCustomerPurchaseInvoices((row[column]?.results??[]))} >
                                {`${currencyFormat((row[column]?.sale??0))} | ${currencyFormat((row[column]?.profit??0))} | ${(row[column]?.percentage??0)}%`}
                            </Box>
                        )
                    }
                })
            }
        }
        return {
            clGeneralCustomerHistory: r,
            clCustomerPurchaseHistory: f
        }
    }, [dataGrid, columnsDate, onShowCustomerProductPurchaseHistory, onShowCustomerPurchaseInvoices, onShowCustomerSalesGraph, t, groupRows])

    const quickList = useCallback((list) => {
        let currentList = [...list]
        let total = currentList.reduce((acc, val) => ((val?.total??0)+acc),0)
        let totalProfitCurrency = currentList.reduce((acc, val) => ((val?.totalProfitCurrency??0)+acc),0)
        let totalProfitPercentage = ((totalProfitCurrency/total)*100)

        return [
            {primary: `${currencyFormat(currentList.length, { applyCurrencyFormat: false })}`, secondary: "RESULTADOS LISTADOS"},
            {primary: `${currencyFormat(total)} | ${currencyFormat(totalProfitCurrency)} | ${roundNumber(totalProfitPercentage)} %`, secondary: "TOTAL"},
        ]
    }, [])

    return {
        columns,
        rows,
        quickList,
        columnsDate
    }
}