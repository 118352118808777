import dayjs from "dayjs"

export const getTheTimeOfDay = () => {
    let hours = []
    for( let i = 0; i < 24; i++ ){
        let startDate = dayjs().startOf("day")
        if( i <= 0 ){
            hours.push( startDate.format("HH:mm") )
        }else{
            hours.push( startDate.add(i, "hour").format("HH:mm") )
        }
    }

    return hours
}

export const calculateDifferenceOfDays= (date="") => {
    const toDay = dayjs( dayjs().startOf("day").toDate() )
    const currentDate = dayjs(dayjs(date).startOf("day").toDate())
    const diff = (currentDate.diff(toDay, "day"))
    return diff
}

export const getDetailedDueDate = (date="") => {
    const days = calculateDifferenceOfDays(date)
    if( days === 0 ){
        return "Vence hoy"
    }else if( days === 1 ){
        return "Vence mañana"
    }else if( days >= 2 ){
        return `Vence dentro de ${days} días`
    }else if( days === -1 ) {
        return "Vencido hace un dia"
    }else{
        return `Vencido hace ${Math.abs(days)} días`
    }
}