import { PaymentRoundedIcon } from "@component/UIComponents"
import { formErrorList } from "@hook/useFormTools"
import { yup } from "@hook/useFormik"
import { useGetHistoryOfPaymentsToAccountsPayableQuery } from "@query/AccountsPayable"
import { useState } from "react"

export const useAccountPayableFormData = () => {
    const schema = yup.object().shape({
        accountPayableId: yup.string().required("Ocurrió un problema al cargar el identificador de la cuenta por pagar, cierre la ventana he intente nuevamente."),
        payments: yup.array().min(1, "Debe seleccionar al menos un pago.").of(yup.object().shape({
            amount: yup.number().min(1, "Es necesario definir el monto del pago.").required("Es necesario definir el monto del pago."),
            box: yup.string().required("Es necesario definir la caja de donde saldrá el pago."),
            boxRef: yup.string().required("Es necesario definir la caja de donde saldrá el pago."),
        })).required("Debe seleccionar al menos un pago.")
    })
    let defaultValues = {
        accountPayableId: "",
        payments: [],
    }

    const [values, setValue] = useState({...defaultValues})
    const resetValues = () => setValue({...defaultValues})
    const validateValues = (params={}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            formErrorList((error?.errors??[]))
            return {
                type: "errors",
                errors: (error?.errors??[])
            }
        }
    }

    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))


    return { values, setFieldValue, resetValues, validateValues }
}

export const useAccountsPayableActions = (props={}) => {
    const { paymentClick=undefined } = props
    const dataGridActions = [
        {
            title: "Pagar",
            onClick: (_, prop) => Boolean(paymentClick) ? paymentClick(prop) : null,
            id: "payment",
            icon: <PaymentRoundedIcon />,
        },
    ]
    return {
        dataGridActions
    }
}

export const useGetHistoryOfPaymentsToAccountsPayable = (params={}, config={}) => {
    const { data, isFetching } = useGetHistoryOfPaymentsToAccountsPayableQuery(params, config)
    const paymentHistory = (data?.payload??[])

    return {
        paymentHistory,
        isFetching
    }
}