import Menu from '@component/Menu'
import { HelpOutlineRoundedIcon, IconButton, MenuItem, Tooltip } from '@component/UIComponents'
import { useState } from 'react';
import RecoveryDialog from './RecoveryDialog';

const SessionOptions = () => {
    const [recoverData, setRecoverData] = useState({open: false, form: null})
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => setAnchorEl(null);
    return(
        <>
            <Tooltip title="Ayuda" arrow placement='right' >
                <IconButton color='warning' onClick={(event) => setAnchorEl(event.currentTarget)} >
                    <HelpOutlineRoundedIcon sx={{ fontSize: '2rem' }} />
                </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClick={handleClose} onClose={handleClose} >
                <MenuItem onClick={() => setRecoverData((n) => ({...n, open: true, form: "recover-password"}))} >
                    Olvide mi contraseña
                </MenuItem>
                <MenuItem onClick={() => setRecoverData((n) => ({...n, open: true, form: "resend-validation-email"}))} >
                    No llego mi correo de validación
                </MenuItem>
                <MenuItem onClick={() => setRecoverData((n) => ({...n, open: true, form: "close-open-sessions"}))} >
                    Quiero invalidar otras sesiones abiertas
                </MenuItem>
            </Menu>
            <RecoveryDialog {...recoverData} setRecoverData={setRecoverData} />
        </>
    )
}

export default SessionOptions