import TabAppContext from '@component/TabAppContext'
import { Box, Button, CurrencyExchangeRoundedIcon, Dialog, DialogContent, ShoppingCartRoundedIcon, Typography } from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'
import { useEffect, useState } from 'react'

const ActionWindow = ({ provider=null, onClose=()=>null, onSubmit=undefined }) => {
    const [action, setAction] = useState("purchase")

    const tabs = [
        { label: "Compra", value: "purchase", component: <></>, icon: <ShoppingCartRoundedIcon /> },
        { label: "Anticipo", value: "advance", component: <></>, icon: <CurrencyExchangeRoundedIcon /> },
    ]

    const handleSubmitValue = () => {
        if( Boolean(onSubmit) ){
            onSubmit(action)
        }
    }

    const handleKeyboardShortcuts = (event) => {
        const { code } = event
        if( code === "ControlLeft" || code === "ControlRight" ){
            let tabIndex = tabs.findIndex((n) => n.value === action)
            if( tabIndex >= 0 ){
                if( tabs[tabIndex+1] ){
                    setAction(tabs[tabIndex+1].value)
                }else{
                    setAction("purchase")
                }
            }
        }
        if( code === "NumpadEnter" || code === "Enter" ){
            handleSubmitValue()
        }
    }

    useEffect(() => {
        if( !Boolean(provider?._id) && action !== "purchase" ){
            setAction("purchase")
        }
    },[provider, action])

    return(
        <Dialog onKeyDown={handleKeyboardShortcuts} PaperProps={{ elevation: 0 }} transitionDuration={0} maxWidth="xs" fullWidth open={Boolean(provider?._id)} onClose={onClose} >
            <Typography textTransform={'uppercase'} lineHeight={1.2} p={2} pb={0} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'column'} >
                {(provider?.name??"")}
                <Box component={'span'} fontWeight={'bold'} fontSize={'.775rem'} sx={{ color: (theme) => theme.palette.primary.main }} >
                    <Box component={'span'} color={'#000'} >Anticipo disponible:</Box> {currencyFormat((provider?.balance??0))}
                </Box>
            </Typography>
            <DialogContent>
                <TabAppContext
                    onChange={(_, value) => setAction(value)}
                    centered
                    tab={action}
                    tabs={tabs}
                />
                <Box width={'100%'} display={'flex'} justifyContent={'end'} >
                    <Button onClick={handleSubmitValue} >
                        {action === "purchase" && "Crear compra"}
                        {action === "advance" && "Crear anticipo"}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ActionWindow