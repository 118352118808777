import LoginForm from "./components/LoginForm"
import AuthContainerForm from "@component/AuthContainerForm"
import QuickLogin from "./components/QuickLogin"
import { useLoginController } from "./hooks/useLoginController"
import LoadProgress from "@component/LoadProgress"
import { Alert } from '@component/UIComponents'
import { useURLParams } from "@hook/useURLParams"
import { useMemo } from "react"

const Login = () => {
    const { getQuery } = useURLParams()
    const { renderOnLogin } = useLoginController()
    const showAlert = useMemo(() => getQuery("account"), [getQuery])

    return(
        <>
            <AuthContainerForm subTitle="Fácil, rápido, completo y seguro." >
                { showAlert === "failed" && <Alert sx={{ mb: 2 }} severity="error" > Fallo la verificación de la cuenta, probablemente porque la cuenta ya se encuentra verificada o el token a expirado. </Alert> }
                { showAlert === "verified" && <Alert sx={{ mb: 2 }} severity="success" > La cuenta se verifico exitosamente, inicia sesión para finalizar el registro. </Alert> }
                {renderOnLogin === "preload" && <LoadProgress />}
                {renderOnLogin === "loginForm" && <LoginForm />}
                {renderOnLogin === "quickLogin" && <QuickLogin />}
            </AuthContainerForm>
        </>
    )
}

export default Login