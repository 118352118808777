import { Box, CircularProgress, FormControl, FormControlLabel, Switch as MUISwitch, styled } from "@mui/material";

const SwitchStyled = styled((props) => (
    <MUISwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.primary.main,
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const Switch = ({ onChangeValue=()=>null, disabled=undefined, checked=undefined }) => {
    return (
        <SwitchStyled disabled={disabled} onChange={({ target }) => onChangeValue(target.checked) } checked={checked} />
    )
}

export default Switch


export const SwitchDescription = ({ label="", description="", onChangeValue=()=>null, loading=false, checked=false }) => {
    return (
        <FormControl sx={{ position: "relative" }} >
            <FormControlLabel
                sx={{
                    overflow: "hidden",
                    position: "relative",
                    margin: 0,
                    padding: 2,
                    width: "100%",
                    maxWidth: 400,
                    minWidth: 350,
                    height: 100,
                    gap: 4,
                    border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                    borderRadius: ({ spacing }) => `${spacing(1.5)}`,
                    justifyContent: "space-between",
                    transition: ({ transitions }) => transitions.create("all"),
                    '&:hover': {
                        boxShadow: ({ palette }) => `0px 2px 5px -1px ${palette.grey[500]}`
                    }
                }}
                labelPlacement="start"
                label={
                    <>
                        { (loading === true) &&
                            <Box sx={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                top: 0,
                                left: 0,
                                backgroundColor: "rgb(255 255 255 / 80%)"
                            }} >
                                <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                    <CircularProgress />
                                </Box>
                            </Box>
                        }
                        <Box sx={{ userSelect: "none" }} >
                            <Box fontSize={'1rem'} fontWeight={'700'} className="truncate-text" >
                                {label}
                            </Box>
                            <Box fontSize={'.875rem'} lineHeight={1.2} color="grey.500" >
                                {description}
                            </Box>
                        </Box>
                    </>
                }
                control={<Switch onChangeValue={onChangeValue} disabled={loading} checked={checked} />}
            />
        </FormControl>
    )
}