import Autocomplete from "@component/Autocomplete"
import { useCustomerTrackingOptions } from "../hooks/useCustomerTrackingOptions"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"
import { useMemo } from "react"

const GroupClients = ({ label="Agrupar por", size=undefined, onChange=undefined, value=undefined, multiple=undefined, queryValue=undefined, compare=undefined }) => {
    const { customerTrackingGroupOptions } = useCustomerTrackingOptions()
    const options = useMemo(() => customerTrackingGroupOptions({}), [customerTrackingGroupOptions])
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: options, queryValue, compare })
    const val = getAutocompleteValue()

    return(
        <>
            <Autocomplete
                size={size}
                multiple={multiple}
                value={val}
                onChange={onChange}
                label={label}
                options={options}
            />
        </>
    )
}

export default GroupClients