import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useRecordVariableExpenseMutation } from "@query/VariableExpenses"
import { asynchronousResponseHandler } from "@util/helpers"
import { useMemo } from "react"

export const useExpenses = () => {
    
}

export const useVariableExpensesFormData = (config={}) => {
    const { defaultValues=undefined, openModal=false, } = config

    const initialValues = useMemo(() => ({ observation: "", payments: [] }), [])
    const schema = {
        observation: yup.string().required("La observación es requerida"),
        payments: yup.array().min(1, "Añada al menos un pago para continuar.").max(1, "Añada al menos un pago para continuar.").required("Añada al menos un pago para continuar."),
    }
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema,
        openModal,
    })

    return { values, setFieldValue, resetValues, validateValues, schema }
}

export const useRecordVariableExpense = () => {
    const [ recordVariableExpenseMutation, {isLoading} ] = useRecordVariableExpenseMutation()
    const recordVariableExpense = async (payload=null, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await recordVariableExpenseMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "El gasto se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        recordVariableExpense,
        isLoading
    }
}