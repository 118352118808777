import { configureStore } from '@reduxjs/toolkit';
import { api } from '../api/splitApi';
import ReducerClosingOfTheDay from '@feature/ClosingOfTheDay/slice/sliceClosingOfTheDay';

export const store = configureStore({
    reducer: {
        ClosingOfTheDay: ReducerClosingOfTheDay,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(api.middleware),
});