import { useGetUserSessionInformation } from "@feature/Users"
import { useCallback } from "react"

// const SALES = [ "my_sales", "record_delivery_information", "register_sale", "register_quotation", "show_undefined_sales_list", "switch_between_product_and_customer_list", "print_last_sale_invoice", "print_sales_invoice", "show_profit_information", "show_sales_report", "show_credits_report", "register_client", "register_product", "register_variable_expends" ]

export const useValidatePermission = () => {
    const { permissions:userPermissions } = useGetUserSessionInformation()

    const havePermission = useCallback((permission="") => Boolean((userPermissions.find((n) => n === permission))), [userPermissions])

    const hasPermissionGroup = useCallback((permissions=[]) => {
        const getPermissions = (userPermissions.filter((n) => permissions.some((b) => b === n)))
        return getPermissions.length === permissions.length
    }, [userPermissions])

    const validateAtLeastOnePermissions = useCallback((permissions=[]) => {
        const getPermissions = (userPermissions.filter((n) => permissions.some((b) => b === n)))
        return (getPermissions.length >= 1)
    }, [userPermissions])

    const getObjectWithPermission = useCallback((permission, item) => {
        const returnObject = havePermission(permission)
        return returnObject ? item : {}
    }, [havePermission])

    const validateArrayByPermissionOrder = useCallback((permissions=[], array=[]) => {
        let newArray = []
        array.forEach((item, index) => {
            let val = permissions[index]
            let isArray = Array.isArray(val)

            if( isArray ){
                let returnObject = validateAtLeastOnePermissions(val)
                if( returnObject ){
                    newArray.push(item)
                }
            }else{
                let returnObject = havePermission(val)
                if( returnObject ){
                    newArray.push(item)
                }
            }
        })
        return newArray
    }, [havePermission, validateAtLeastOnePermissions])

    const executeFunctionWithPermission = useCallback((permission="", callback=()=>null) => {
        const returnObject = havePermission(permission)
        if( returnObject ){
            return callback()
        }else{
            return () => null
        }
    }, [havePermission])

    return {
        havePermission,
        hasPermissionGroup,
        getObjectWithPermission,
        validateArrayByPermissionOrder,
        executeFunctionWithPermission,
        validateAtLeastOnePermissions,
    }
}