import { useFormik as useFormikValidation } from "formik";
import * as yup from 'yup'

/**
 * @typedef {Object} Formik
 * @property {object} initialValues
 * @property {object} schema
 * @property {undefined} onSubmit
 */

/**
 * @param {Formik} params
 */

export const useFormik = (params={}) => {
    const { initialValues, schema, onSubmit } = params
    const validationSchema = yup.object().shape(schema);
    const formik = useFormikValidation({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if( onSubmit !== undefined ){
                onSubmit(values, {
                    resetForm: formik.resetForm
                })
            }
        },
    });
    const { errors, touched, setFieldValue, handleSubmit, values, resetForm } = formik
    return { formik, errors, touched, setFieldValue, handleSubmit, values, resetForm }
}

export { yup }

export const getRequiredFieldsFromSchema = ({schema={}}) => {
    const currentSchema = {...schema}
    const schemaKeys = Object.keys(currentSchema)
    let requiredFields = schemaKeys.map((n) => {
        if( !Boolean( (currentSchema[n]?.spec?.optional??true) ) ){
            return n
        }else{
            return null
        }
    })
    requiredFields = requiredFields.filter((n) => Boolean(n) )
    return requiredFields
}


/**
 * @typedef {Object} GetErrorsParams
 * @property {object} errors
 * @property {object} touched
 * @property {string} field
 */

/**
 * @param {GetErrorsParams} params
 */

export const getErrorsParams = (params={}) => {
    const { errors, field, touched } = params
    let currentErrors = {...errors}
    let currentTouched = {...touched}
    const errorValue = Boolean(currentTouched[field] && currentErrors[field])
    const touchedValue = currentTouched[field] && currentErrors[field]

    return {
        error: errorValue,
        helperText: touchedValue
    }
}