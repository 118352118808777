import { useNewUserAndBusinessRegistrationMutation } from "@query/Authentication"
import { asynchronousResponseHandler } from "@util/helpers"
import { useState } from "react"

export const useNewUserAndBusinessRegistration = () => {
    const [isError, setIsError] = useState(false)
    const [userName, setUserName] = useState(null)
    const [newUserAndBusinessRegistrationMutation, { isLoading }] = useNewUserAndBusinessRegistrationMutation()

    const newUserAndBusinessRegistration = async (payload={}, callback={}) => {
        if( isLoading === false ){
            setIsError(false)
            const { clearForm=()=>null, closeForm=()=>null } = callback
            const resp = await newUserAndBusinessRegistrationMutation(payload).unwrap()
            const data = (resp?.payload??null)

            asynchronousResponseHandler(resp, {
                successMessage: "Se registro la información satisfactoriamente.",
                clearForm: () => clearForm(),
                closeForm: () => closeForm(data),
            })

            if( (resp?.status??0) === 0 ){
                setIsError(true)
            }

            localStorage.setItem("access_token", (data?.token??""))
            if( Boolean(data?.name) && Boolean(data?.lastName) ){
                setUserName(`${(data?.name??"")} ${(data?.lastName??"")}`)
            }
        }
    }

    return {
        newUserAndBusinessRegistration,
        isLoading,
        userName,
        isError
    }

}