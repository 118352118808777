import ReactDOM from 'react-dom/client';
import { Swal } from "@util/swal"
import { useState } from "react"

export const useFormTools = (params={}) => {
    const { defaultType="" } = params
    const [inputType, setInputType] = useState(defaultType)

    const handleChangeInputType = () => {
        if( inputType === "password" ){
            setInputType("text")
        }else{
            setInputType("password")
        }
    }

    return {
        handleChangeInputType,
        currentType: inputType,
    }
}

export const formErrorList = (errors=[], icon="warning") => {
    const container = document.createElement("div")
    const root = ReactDOM.createRoot(container);
    root.render(
        <ul style={{ textAlign: "left" }} >
            <li> Resuelva los siguientes errores antes de continuar: </li>
            {errors.map((error, index) => {
                return(
                    <li key={index} > {index+1}- {error} </li>
                )
            })}
        </ul>
    )

    Swal.fire({
        icon,
        title: "ADVERTENCIA",
        html: container
    })
}