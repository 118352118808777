import Form from "@component/Form"
import { Box, Button, Grid, Stack, TextField } from "@component/UIComponents"
import { useBusinessFormController } from "../hooks/useBusinessFormController"

const BusinessForm = ({ isNew=false, defaultValues=null, getFormInformation=()=>null, primaryButtonText="Registrar", secondaryButtonText="Volver", onClickSecondaryButton=()=>null }) => {
    const { values, setFieldValue, submitValues } = useBusinessFormController({
        defaultValues,
        onSubmit: (val) => {
            let currentVal = {...val, new: isNew}
            getFormInformation(currentVal)
        }
    })
    const { name="" } = values

    return(
        <Form onSubmit={submitValues} >
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <Box fontWeight={'bold'} textTransform={'uppercase'} textAlign={'center'} mb={2} > Nombre del negocio </Box>
                </Grid>
                <Grid xs={12} >
                    <TextField
                        value={name}
                        fullWidth
                        placeholder="Razón social / Nombre del negocio"
                        onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                    />
                </Grid>
                {/* <Grid xs={12} >
                    <TextField
                        value={nit}
                        fullWidth
                        placeholder="NIT"
                        onChange={({ target }) => setFieldValue("nit", (target?.value??""))}
                    />
                </Grid>
                <Grid xs={6} >
                    <TextField
                        value={email}
                        fullWidth
                        placeholder="Correo electrónico"
                        onChange={({ target }) => setFieldValue("email", (target?.value??""))}
                    />
                </Grid>
                <Grid xs={6} >
                    <PatternFormat
                        InputLabelProps={{
                            shrink: true
                        }}
                        format="(+57) - ### ### ## ##"
                        placeholder={"Numero de teléfono"}
                        fullWidth
                        InputFontWeight={500}
                        value={phone}
                        onChange={(val) => setFieldValue("phone", (val??""))}
                    />
                </Grid>
                <Grid xs={12} >
                    <TextField
                        value={address}
                        multiline
                        rows={2}
                        fullWidth
                        placeholder="Dirección"
                        onChange={({ target }) => setFieldValue("address", (target?.value??""))}
                    />
                </Grid> */}
                <Grid xs={12} mt={3} >
                    <Stack justifyContent={'space-between'} direction={'row'} >
                        <Button onClick={onClickSecondaryButton} >
                            {secondaryButtonText}
                        </Button>
                        <Button variant="contained" disableElevation type="submit" >
                            {primaryButtonText}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Form>
    )
}

export default BusinessForm