import { Box, DataGrid, Grid, SellRoundedIcon, Typography, styled } from "@component/UIComponents"
import ProductFinder from "@feature/Inventory/Products/components/ProductFinder"
import PreSelectProduct from "./components/PreSelectProduct"
import useControllerOrderEntry from "./hooks/useControllerOrderEntry"
import { currencyFormat } from "@util/currencyFormat"
import { replaceBy } from "@util/helpers"
import PurchaseOrderList from "../PurchaseOrderList"
import ProviderFinder from "@feature/Providers/components/ProviderFinder"
import EmptyList from "@component/EmptyList"
import { usePurchaseOrderFormData } from "./hooks/useControlPurchaseOrderForms"
import { useMemo } from "react"
import ActionsList from "@component/ActionsList"
import { useOrderListMenu } from "./hooks/useOrderEntryMenus"
import { useGetOrderSupplier } from "./hooks/useGetOrderSupplier"
import { useParams } from "react-router-dom"
import Filters from "../PurchaseOrderList/components/Filters"

const DataGridStyled = styled(DataGrid)(({ theme }) => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "35px !important",
        maxHeight: "35px !important",
    },
    '& .MuiDataGrid-columnSeparator': {
        visibility: 'visible'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        textTransform: "uppercase",
        color: theme.palette.primary.main,
    },
    '& .MuiDataGrid-cell:first-of-type': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: theme.palette.background.paper,
    },
    '& .MuiDataGrid-row': {
        cursor: "pointer",
    },
    '& .super-class_isNew': {
        backgroundColor: "rgba(239,83,80,0.55)",
    },
}))

const OrderEntry = () => {
    const { orderPurchaseId="" } = useParams()
    const { order } = useGetOrderSupplier({ refId: orderPurchaseId })
    const { values, setFieldValue, resetValues } = usePurchaseOrderFormData()
    const { provider=null } = values
    const { savePurchaseOrder, isLoadingSavePurchaseOrder, selectedProduct, setSelectedProduct, showPreSelectionWindow, addProductToOrderList, productsTable, removeProductToOrderList } = useControllerOrderEntry({ updateOrderForm: setFieldValue, resetForm: resetValues, editableOrder: order })
    const providerActive = useMemo(() => provider?._id, [provider])
    const { primaryMenu, secondaryMenu } = useOrderListMenu()

    const Buttons = (props={}) => <ActionsList variant="iconButton" options={primaryMenu(props, {
        onRemoveProductsFromList: () => removeProductToOrderList((props?.productId??""))
    })} />

    const columns = [
        {flex: 1, minWidth: 300, field: "name", headerName: "Producto"},
        {minWidth: 120, maxWidth: 120, field: "packaging", headerName: "Embalaje", renderCell: ({ row }) => `${(row?.packaging?.name??"")}` },
        {
            minWidth: 120,
            maxWidth: 120,
            align: "center",
            field: "quantity",
            headerName: "Cantidad",
            renderCell: ({ row }) => replaceBy({ condition: Boolean((row?.quantity??"")), value: `x${(row?.quantity??0)}`, replace: "" })
        },
        {
            minWidth: 150,
            maxWidth: 150,
            field: "purchaseCost",
            headerName: "Costo de compra",
            renderCell: ({ row }) => replaceBy({ condition: Boolean((row?.purchaseCost??0)), value: `${currencyFormat((row?.purchaseCost??0))}`, replace: "" })
            
        },
        {
            minWidth: 140,
            maxWidth: 140,
            field: "total",
            headerName: "Total",
            renderCell: ({ row }) => replaceBy({
                condition: Boolean((row?.total??0)),
                value: <Typography fontWeight={'bold'} > {currencyFormat((row?.total??0))} </Typography>,
                replace: `${currencyFormat(((row?.purchaseCost??0)*(row?.quantity??0)))}`
            })
        },
        {
            minWidth: 50,
            maxWidth: 50,
            align: "center",
            field: " ",
            headerName: " ",
            renderCell: ({ row }) => replaceBy({
                condition: (Boolean((row?.total??0)) && !Boolean((row?.name??""))),
                value: <></>,
                replace: <Buttons {...row} />
            })
        },
    ]

    return(
        <>
            <Box p={1} >
                <Grid container spacing={1} >
                    <Grid xs={12} >
                        <ActionsList
                            filterButton={{
                                show: true,
                                component: () => <Filters />
                            }}
                        />
                    </Grid>
                    <Grid xs={3} >
                        <PurchaseOrderList />
                    </Grid>
                    <Grid xs={9} >
                        <Box my={1} >
                            <ProviderFinder
                                value={provider}
                                label="Proveedor"
                                onChange={(_, params) => setFieldValue("provider", params)}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Box>
                        <Box>
                            <Grid container >
                                <Grid xs={12} >
                                    <ProductFinder
                                        value={null}
                                        variant={'purchases'}
                                        onChange={(_, params) => setSelectedProduct(params)}
                                        disabled={!Boolean(providerActive)}
                                    />
                                </Grid>
                                <Grid xs={12} >
                                    {  Boolean(providerActive) ?
                                        <>
                                            <Box display={'flex'} justifyContent={'end'} >
                                                <ActionsList options={secondaryMenu({
                                                    loadingSaveOrder: isLoadingSavePurchaseOrder,
                                                }, {
                                                    onSaveOrder: () => savePurchaseOrder(values)
                                                })} />
                                            </Box>
                                            <DataGridStyled
                                                hideFooter
                                                disableVirtualization
                                                rowHeight={25}
                                                autoHeight
                                                getRowId={(param) => (param?.productId??"")}
                                                columns={columns}
                                                rows={productsTable}
                                                localeText={{
                                                    noRowsLabel: "No hay productos seleccionados"
                                                }}
                                            />
                                        </>
                                        :
                                        <>
                                            <Box mt={2} >
                                                <EmptyList icon={<SellRoundedIcon />} text='Seleccione un proveedor para crear un nuevo pedido.' />
                                            </Box>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <PreSelectProduct
                open={showPreSelectionWindow}
                onClose={() => setSelectedProduct(null)}
                product={selectedProduct}
                onSubmitProduct={(value) => addProductToOrderList(value)}
            />
        </>
    )
}

export default OrderEntry