import Autocomplete from "@component/Autocomplete"
import DateFilter from "@component/Date"
import { Grid } from "@component/UIComponents"
import { useExpensesMenus } from "../hooks/useExpensesMenus"
import dayjs from "dayjs"

const Filters = ({ state=null, setState=()=>null }) => {
    const { primaryOptions } = useExpensesMenus()
    const handleChangeDate = (val) => {
        if( Boolean(val) ){
            Object.keys(val).forEach((key) => {
                setState(key, val[key])
            })
        }
    }
    return(
        <>
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <DateFilter
                        size="small"
                        startDate={dayjs((state?.startDate??""))}
                        endDate={dayjs((state?.endDate??""))}
                        onChange={(date) => handleChangeDate(date)}
                    />
                </Grid>
                <Grid xs={12} >
                    <Autocomplete
                        size="small"
                        fullWidth
                        label="Organizar gastos por"
                        options={primaryOptions()}
                        queryValue={(state?.organizeBy??"")}
                        compare={(val) => (val?._id??"")}
                        onChange={(_, params) => setState("organizeBy", (params?._id??""))}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Filters