import { useFormik, yup } from '@hook/useFormik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthenticateUserMutation, useSendPasswordRecoveryEmailMutation, useSendValidationEmailMutation } from '@query/Authentication'
import { Toast } from '@util/swal'
import { usePanel } from '@hook/usePanel'
import { useLoginController } from './useLoginController'

export const useAuthenticateUser = () => {
    const { userName } = usePanel()
    const { t } = useTranslation()
    const [authenticateUserMutation, {isLoading:isLoadingAuthenticateUserMutation}] = useAuthenticateUserMutation()
    const { storeQuickAccessLogin } = useLoginController()

    const authenticateUser = async (payload) => {
        try {
            const resp = await authenticateUserMutation(payload).unwrap()
            
            if( resp.status === 1 ){
                const r = (resp?.payload??{})
                const token = (r?.token??"")
                const { firstNameAndLastName } = userName((r?.name??""), (r?.lastName??""))
                Toast.fire({
                    icon: 'success',
                    text: `Bienvenido ${firstNameAndLastName}`
                })
                storeQuickAccessLogin({...r, email: (payload?.email??"")})
                localStorage.setItem("access_token", token)
                localStorage.setItem("redirectByDefault", (r?.homePage??"/"))
                setTimeout(() => {
                    window.location.reload(true)
                }, 2000)
            }else{
                Toast.fire({
                    icon: 'error',
                    text: `${t((resp.payload??""))}`
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        authenticateUser,
        isLoadingAuthenticateUserMutation
    }
}

export const useSendPasswordRecoveryEmail = () => {
    const { t } = useTranslation()
    const [sendPasswordRecoveryEmailMutation, {isLoading:isLoadingSendPasswordRecoveryEmailMutation}] = useSendPasswordRecoveryEmailMutation()

    const sendPasswordRecoveryEmail = async (payload) => {
        try {
            const resp = await sendPasswordRecoveryEmailMutation(payload).unwrap()
            
            if( resp.status === 1 ){
                Toast.fire({
                    icon: 'success',
                    text: `Te hemos enviado un correo electrónico para recuperar tu contraseña.`
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    text: `${t((resp.payload??""))}`
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        sendPasswordRecoveryEmail,
        isLoadingSendPasswordRecoveryEmailMutation
    }
}

export const useSendValidationEmail = () => {
    const { t } = useTranslation()
    const [sendValidationEmailMutation, {isLoading:isLoadingSendValidationEmailMutation}] = useSendValidationEmailMutation()

    const sendValidationEmail = async (payload) => {
        try {
            const resp = await sendValidationEmailMutation(payload).unwrap()
            
            if( resp.status === 1 ){
                Toast.fire({
                    icon: 'success',
                    text: `Te hemos enviado nuevamente el correo de verificación.`
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    text: `${t((resp.payload??""))}`
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        sendValidationEmail,
        isLoadingSendValidationEmailMutation
    }
}


/**
 * @typedef {Object} Props
 * @property {object} defaultValues
 * @property {undefined} onSubmit
 */

/**
 * @param {Props} params
 */

export const useLoginFormValues = (params={}) => {
    const { t } = useTranslation()
    const { defaultValues={}, onSubmit } = params
    const initialValues = React.useMemo(() => {
        let val =  {
            email: "",
            password: "",
        }
        if( Boolean(defaultValues?._id) ){
            val = {...defaultValues}
        }
        return val
    },[defaultValues])
    const { errors, touched, setFieldValue, handleSubmit, values, resetForm } = useFormik({
        onSubmit,
        initialValues,
        schema: {
            email: yup.string().email(t('emailIsRequired')).required(t('emailIsRequired')),
            password: yup.string().required(t('passwordRequired')),
        }
    })

    return { errors, touched, setFieldValue, handleSubmit, values, resetForm }
}

/**
 * @typedef {Object} Props
 * @property {object} defaultValues
 * @property {undefined} onSubmit
 */

/**
 * @param {Props} params
 */
export const useRecoveryFormValues = (params={}) => {
    const { t } = useTranslation()
    const { defaultValues={}, onSubmit } = params
    const initialValues = React.useMemo(() => {
        let val =  {
            email: "",
        }
        if( Boolean(defaultValues?._id) ){
            val = {...defaultValues}
        }
        return val
    },[defaultValues])
    const { errors, touched, setFieldValue, handleSubmit, values, resetForm } = useFormik({
        onSubmit,
        initialValues,
        schema: {
            email: yup.string().email(t('emailIsRequired')).required(t('emailIsRequired')),
        }
    })

    return { errors, touched, setFieldValue, handleSubmit, values, resetForm }
}