import useQueryParams from "@hook/useQueryParams"
import { useGetCloseOfDayQuery, useSaveClosingOfTheDayMutation } from "@query/Business"
import { Toast } from "@util/swal"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import _ from 'lodash'

export const useClosingOfTheDay = () => {
    const [ saveClosingOfTheDayMutation, { isLoading:isLoadingSaveClosingOfTheDayMutation } ] = useSaveClosingOfTheDayMutation()
    const { getQueryParams, setQueryParams } = useQueryParams()
    const closingFilter = useMemo(() => getQueryParams(["closingDate"]), [getQueryParams])
    const { closingDate=null } = (closingFilter??{})
    const { data } = useGetCloseOfDayQuery({ startDate: closingDate, endDate: closingDate })

    const closingOfTheDay = useSelector(state => state.ClosingOfTheDay)
    const { ClosingSummary={} } = (closingOfTheDay??{})
    const { PreviousClosing=undefined } = (ClosingSummary??{})

    const closingData = useMemo(() => {
        if( _.isArray(PreviousClosing) ){
            return PreviousClosing
        }else{
            return (data?.payload??[])
        }
    }, [data, PreviousClosing])

    const CashierDaySale = useMemo(() => {
        const resp = (closingData??[]).filter((item) => (item?.saleOfTheDay?.total??0) > 0 )
        return resp
    }, [closingData])

    const ClosingOfTheDay = useMemo(() => {
        const saleOfTheDay = (CashierDaySale??[]).reduce((acc, item) => {
            const { cashOfTheDay={}, creditOfTheDay={}, dailyConsignmentToBanks=[], total=0 } = (item?.saleOfTheDay??{})

            acc.cashOfTheDay = { //EFECTIVO DEL DIA
                ...(acc?.cashOfTheDay??{}),
                amount: ((acc?.cashOfTheDay?.amount??0)+(cashOfTheDay?.amount??0)),
                results: [...(acc?.cashOfTheDay?.results??[]), ...(cashOfTheDay?.results??0)]
            }

            acc.creditOfTheDay = { //CRÉDITOS DEL DIA
                ...(acc?.creditOfTheDay??{}),
                amount: ((acc?.creditOfTheDay?.amount??0)+(creditOfTheDay?.amount??0)),
                results: [...(acc?.creditOfTheDay?.results??[]), ...(creditOfTheDay?.results??0)]
            }

            for( let dailyConsignmentToBank of dailyConsignmentToBanks ){ // BANCOS DEL DIA
                const index = (acc?.dailyConsignmentToBanks??[]).findIndex((bank) => ((bank?.name??"") === (dailyConsignmentToBank?.name??"")) )
                if(index === -1){
                    acc.dailyConsignmentToBanks = [...(acc?.dailyConsignmentToBanks??[]), dailyConsignmentToBank]
                }else{
                    acc.dailyConsignmentToBanks[index] = {
                        ...acc.dailyConsignmentToBanks[index],
                        amount: ((acc?.dailyConsignmentToBanks?.[index]?.amount??0)+(dailyConsignmentToBank?.amount??0)),
                        results: [...(acc?.dailyConsignmentToBanks?.[index]?.results??[]), ...(dailyConsignmentToBank?.results??[])]
                    }
                }
            }
            acc.total = ((acc?.total??0)+total)
            return acc
        }, { cashOfTheDay: {}, creditOfTheDay: {}, dailyConsignmentToBanks: [], total: 0 })
        return saleOfTheDay
    }, [CashierDaySale])

    const GeneralCashCollections = useMemo(() => {

        const outflows = (CashierDaySale??[]).reduce((accOutflows, item) => {
            let cashOutflows = (item?.cashOutflows??{})
            cashOutflows = Object.entries(cashOutflows).reduce((acc, [key, value]) => {
                const omitKeys = ["cashPaymentsForPurchases", "cashPaymentsOnCreditPurchases"]
                const total = omitKeys.some((ky) => (ky === key) ) ? (acc+0) : (acc+(value?.amount??0))
                return total
            }, 0)
            return (accOutflows+cashOutflows)
        }, 0)

        const saleOfTheDay = (CashierDaySale??[]).reduce((acc, item) => {
            const { paymentToCreditsFromPreviousDays={}, cashOfTheDay={}, boxTransfers={}, boxFromThePreviousDay=0 } = (item?.cashCollections??{})

            acc.paymentToCreditsFromPreviousDays = { //RECAUDO CRÉDITOS
                ...(acc?.paymentToCreditsFromPreviousDays??{}),
                amount: ((acc?.paymentToCreditsFromPreviousDays?.amount??0)+(paymentToCreditsFromPreviousDays?.amount??0)),
                results: [...(acc?.paymentToCreditsFromPreviousDays?.results??[]), ...(paymentToCreditsFromPreviousDays?.results??0)]
            }

            acc.cashOfTheDay = { //EFECTIVO DEL DIA
                ...(acc?.cashOfTheDay??{}),
                amount: ((acc?.cashOfTheDay?.amount??0)+(cashOfTheDay?.amount??0)),
                results: [...(acc?.cashOfTheDay?.results??[]), ...(cashOfTheDay?.results??0)]
            }

            acc.boxFromThePreviousDay += boxFromThePreviousDay // CAJA ANTERIOR

            acc.boxTransfers = { //TRASLADOS DE CAJA
                ...(acc?.boxTransfers??{}),
                amount: ((acc?.boxTransfers?.amount??0)+(boxTransfers?.amount??0)),
                results: [...(acc?.boxTransfers?.results??[]), ...(boxTransfers?.results??0)]
            }
            const cashTotal = (acc?.paymentToCreditsFromPreviousDays?.amount??0)+(acc?.cashOfTheDay?.amount??0)+(acc?.boxFromThePreviousDay??0)
            const outflowsTotal = (outflows+(acc?.boxTransfers?.amount??0))
            // acc.total = (((acc?.paymentToCreditsFromPreviousDays?.amount??0)+(acc?.cashOfTheDay?.amount??0)+(acc?.boxFromThePreviousDay??0))-(cashOutflows+(acc?.boxTransfers?.amount??0)))
            acc.total = (cashTotal-outflowsTotal)
            return acc
        }, { paymentToCreditsFromPreviousDays: {}, cashOfTheDay: {}, boxTransfers: {}, boxFromThePreviousDay: 0, total: 0 })
        return saleOfTheDay
    }, [CashierDaySale])
    
    const GeneralCashOutflows = useMemo(() => {
        const cashOutflows = (CashierDaySale??[]).reduce((acc, item) => {
            const { variableExpenses={}, fixedExpenses={}, cashPaymentsOnCreditPurchases={}, cashPaymentsForPurchases={}, deliveries={} } = (item?.cashOutflows??{})

            acc.variableExpenses = { //GASTOS VARIABLES
                ...(acc?.variableExpenses??{}),
                amount: ((acc?.variableExpenses?.amount??0)+(variableExpenses?.amount??0)),
                results: [...(acc?.variableExpenses?.results??[]), ...(variableExpenses?.results??0)]
            }

            acc.fixedExpenses = { //GASTOS FIJOS
                ...(acc?.fixedExpenses??{}),
                amount: ((acc?.fixedExpenses?.amount??0)+(fixedExpenses?.amount??0)),
                results: [...(acc?.fixedExpenses?.results??[]), ...(fixedExpenses?.results??0)]
            }

            acc.shopping = { //ABONOS A COMPRAS
                ...(acc?.shopping??{}),
                amount: (((acc?.shopping?.amount??0)+(cashPaymentsOnCreditPurchases?.amount??0))+((acc?.shopping?.amount??0)+(cashPaymentsForPurchases?.amount??0))),
                results: [...(acc?.shopping?.results??[]), ...(cashPaymentsOnCreditPurchases?.results??0), ...(cashPaymentsForPurchases?.results??0)]
            }
            
            acc.deliveries = { //DOMICILIOS
                ...(acc?.deliveries??{}),
                amount: ((acc?.deliveries?.amount??0)+(deliveries?.amount??0)),
                results: [...(acc?.deliveries?.results??[]), ...(deliveries?.results??[])]
            }

            acc.total = (((acc?.variableExpenses?.amount??0)+(acc?.fixedExpenses?.amount??0)+(acc?.deliveries?.amount??0)))
            return acc
        }, { variableExpenses: {}, fixedExpenses: {}, shopping: {}, deliveries: {}, total: 0 })
        return cashOutflows
    }, [CashierDaySale])


    const applyDefaultFilter = useCallback(() => {
        const isDate = dayjs(closingDate).isValid()
        if( isDate === false ){
            setQueryParams({ closingDate: dayjs().format("MM-DD-YYYY") })
        }
    }, [closingDate, setQueryParams])

    const onSaveClosingOfTheDayMutation = useCallback(async () => {
        try {
            const _ = (await import("lodash")).default
            let resp = await saveClosingOfTheDayMutation().unwrap()
            resp = (resp?.payload??[])
            if( !_.isArray(resp) ) throw new Error("Error, respuesta inesperada")
            Toast.fire({
                icon: "success",
                text: "El cierre del dia se grabo satisfactoriamente.",
            })
        } catch (error) {
            console.log(error)
        }
    }, [saveClosingOfTheDayMutation])

    useEffect(() => {
        applyDefaultFilter()
    }, [applyDefaultFilter])

    return {
        CashierDaySale,
        ClosingOfTheDay,
        GeneralCashCollections,
        GeneralCashOutflows,
        closingDate,
        onSaveClosingOfTheDayMutation,
        isLoadingSaveClosingOfTheDayMutation
    }
}