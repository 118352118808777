import { AddCircleOutlineIconRounded, ArchiveRoundedIcon, CurrencyExchangeRoundedIcon, EditIconRounded, ReplayRoundedIcon, RestoreRoundedIcon } from "@component/UIComponents"
import { preventAction } from "@util/helpers"
import { useCallback } from "react"
import AdvancePaymentHistory from "../components/AdvancePaymentHistory"


export const useProviderListMenu = () => {
    const primaryActions = useCallback((props={}, config={}) => {
        const { createProvider=()=>null } = config
        return [
            {
                title: "Crear proveedor",
                onClick: () => createProvider(),
                icon: <AddCircleOutlineIconRounded />
            },
        ]
    }, [])
    
    const secondaryActions = useCallback((props={}, config={}) => {
        const { onEdit=()=>null, onArchived=()=>null, onAdvance=()=>null, onReturnAdvance=()=>null }  = config
        const DismissButton = (props?.isArchived??false)
        return [
            ...(DismissButton ? [] : [{
                title: "Editar",
                onClick: () => onEdit(props),
                icon: <EditIconRounded />,
            }]),
            {
                title: (props?.isArchived??false) ? "Recuperar" : "Archivar",
                onClick: () => preventAction({
                    text: (props?.isArchived??false) ? `Desea recuperar el proveedor, ${(props?.name??"")}` : `Desea archivar el proveedor, ${(props?.name??"")}`,
                    onSubmit: () => onArchived(props)
                }),
                icon: <ArchiveRoundedIcon />
            },
            ...(DismissButton ? [] : [{
                title: "Generar anticipo",
                onClick: () => onAdvance(props),
                icon: <CurrencyExchangeRoundedIcon />,
            }]),
            {
                title: "Historial de anticipos",
                onClick: () => null,
                icon: <RestoreRoundedIcon />,
                variantClick: "popover",
                popoverContainer: <AdvancePaymentHistory {...props} />
            },
            ...(DismissButton ? [] : [{
                title: "Retornar anticipo",
                onClick: () => onReturnAdvance(props),
                icon: <ReplayRoundedIcon />,
            }]),
        ]
    }, [])

    return {
        primaryActions,
        secondaryActions
    }
}
