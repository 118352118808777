import { useShareSaleAsPurchaseMutation } from "@query/Sales"
import { asynchronousResponseHandler } from "@util/helpers"

export const useShareSaleAsPurchase = () => {
    const [shareSaleAsPurchaseMutation, { isLoading }] = useShareSaleAsPurchaseMutation()

    const shareSaleAsPurchase = async (payload={}) => {
        if( isLoading === false ){
            const resp = await shareSaleAsPurchaseMutation(payload).unwrap()
            asynchronousResponseHandler(resp, {
                successMessage: "La venta se compartió exitosamente con el cliente internamente.",
            })
        }
    }

    return {
        shareSaleAsPurchase,
        isLoading
    }
}