import { IconButton, InputAdornment, RemoveRedEyeIconRounded, TextField as TextFieldMUI, VisibilityOffRoundedIcon, styled } from '@component/UIComponents'
import { forwardRef } from 'react'

const CustomTextField = styled(TextFieldMUI,{
    shouldForwardProp: (prop) => prop !== "InputFontWeight"
})(({ InputFontWeight }) => ({
    ...(Boolean(InputFontWeight) ? {
        '& .MuiInputBase-input' : {
            fontWeight: InputFontWeight
        }
    } : {})
}))

const TextField = forwardRef(({showPassword=false, type="text", onViewPassword=undefined, ...rest}, ref) => {
    const handleViewPassword = () => {
        if( onViewPassword !== undefined ){
            onViewPassword()
        }
    }
    return(
        <CustomTextField
            type={type}
            InputProps={{
                endAdornment: <InputAdornment position='end' >
                    {showPassword &&
                        <IconButton onClick={handleViewPassword} >
                            {type === "password" ? <RemoveRedEyeIconRounded /> : <VisibilityOffRoundedIcon />} 
                        </IconButton>
                    }
                </InputAdornment>
            }}
            ref={ref}
            {...rest}
        />
    )
})

export default TextField