import { useUpdateInvoiceDueDateMutation } from "@query/Sales"
import { asynchronousResponseHandler } from "@util/helpers"

export const useUpdateInvoiceDueDate = () => {
    const [ updateInvoiceDueDateMutation, { isLoading } ] = useUpdateInvoiceDueDateMutation()
    const updateInvoiceDueDate = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await updateInvoiceDueDateMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "La fecha de vencimiento del crédito se actualizo exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        updateInvoiceDueDate,
        isLoadingUpdateInvoiceDueDate: isLoading
    }
}