import { useGetUserSessionInformation } from "@feature/Users"
import { currencyFormat } from "@util/currencyFormat"
import { useCallback } from "react"

export const useDeliveryInformationController = () => {
    const { fullName, balance } = useGetUserSessionInformation()

    const headerInformationAboutPayment = useCallback((paymentMethod="") => {
        if( paymentMethod === "inBox" ){
            return `${fullName} tu saldo disponible en caja para realizar pagos es de ${currencyFormat(balance)}.`
        }else if( paymentMethod === "uponDelivery" ) {
            return `El domicilio sera cobrado en el punto de entrega.`
        }else{
            return ""
        }
    }, [balance, fullName])

    const amountInputConfig = useCallback((paymentMethod="") => {
        if( paymentMethod === "inBox" ){
            return {
                amountHelperText: `El pago del domicilio no puede superar ${currencyFormat(balance)}`,
                maxAmount: balance,
            }
        }else{
            return {
                amountHelperText: undefined,
                maxAmount: undefined,
            }
        }
    }, [balance])

    return {
        headerInformationAboutPayment,
        amountInputConfig
    }
}