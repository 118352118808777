import { Badge, Box, Divider, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, NotificationsActiveRoundedIcon, Typography, styled } from '@component/UIComponents'
import { Fragment, useCallback, useState } from 'react'
import Menu from './Menu';
import { Trans } from 'react-i18next';
import { currencyFormat } from '@util/currencyFormat';
import { useGetNotifications } from '@feature/Business/hooks/useBusiness';
import { usePanel } from '@hook/usePanel';
import { useGetUserSessionInformation } from '@feature/Users';
import { useSocketIO } from '@hook/useSocketIO' 
import { useGetBusinessInformation } from '@feature/Settings/BusinessInformation/hooks/useBusiness';

const HighlightTextNotification = styled(Box)(({theme}) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    }
}))

const MoneyUsageNotification = styled(Box)(({theme}) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.main,
}))

const NotificationsMenu = () => {
    const { updateNotificationDisplay } = useSocketIO()
    const { userData, isFetching } = useGetUserSessionInformation()
    const { business, isLoading } = useGetBusinessInformation()
    const ready = (!isFetching && !isLoading)

    const { getDateFormats } = usePanel()
    const { notifications, unreadNotifications } = useGetNotifications()

    const [ anchorEl, setAnchorEl ] = useState(null)
    const handleClose = () => setAnchorEl(null);

    const handleOpenNotificationsMenu = (target) => {
        setAnchorEl(target)
        updateNotificationDisplay({
            userId: (userData?._id??null),
            businessId: (business?._id??null),
            unreadNotifications,
        })
    }

    const NoNotifications = () => {
        return(
            <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}  >
                <Box textAlign={'center'} >
                    <NotificationsActiveRoundedIcon sx={{ fontSize: "3rem", color: (theme) => theme.palette.grey[400] }} />
                    <Typography fontSize={'1rem'} fontWeight={'500'} sx={{ color: (theme) => theme.palette.grey[400] }} > No tienes notificaciones </Typography>
                </Box>
            </Box>
        )
    }

    const SeeAllNotifications = () => {
        return(
            <Box position={'absolute'} bottom={0} width={'100%'} textAlign={'center'} >
                <Link variant='body2' href='#' onClick={() => null} > Ver todas las notificaciones </Link>
            </Box>
        )
    }

    const notificationTextConstructor = useCallback((constructor={}) => {
        const { user={}, variant="", secondReference={}, firstReference="", amountNotification=0 } = constructor
        const text = <Trans
            i18nKey={variant}
            values={{
                userName: `${(user?.name??"")} ${(user?.lastName??"")}`,
                refItemNotification: firstReference,
                thirdNotification: (secondReference?.name??""),
                amount: currencyFormat(amountNotification),
            }}
            components={[<HighlightTextNotification component={'span'} />, <MoneyUsageNotification component={'span'} />]}
        />
        return <Typography fontSize={'.800rem'} lineHeight={'1.2'} fontWeight={'500'} >
            {text}
        </Typography>
    }, [])

    return(
        <>
            <IconButton disabled={!ready} onClick={({ currentTarget }) => handleOpenNotificationsMenu(currentTarget)} >
                <Badge color='error' badgeContent={(unreadNotifications?.length??0)} >
                    <NotificationsActiveRoundedIcon className='iconNotificationSize' sx={{ color: (theme) => theme.palette.primary.contrastText }} />
                </Badge>
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} positionArrow='right' >
                <Box position={'relative'} width={'100vw'} maxWidth={350} height={'100vh'} maxHeight={400} >
                    {notifications.length >= 1 ?
                        <Box>
                            <List sx={{ maxHeight: 375, overflow: 'scroll' }} >
                                {notifications.map((notify, index) => {
                                    return(
                                        <Fragment key={index} >
                                            {Boolean((notify?.createdAt??undefined)) &&
                                                <Typography fontSize={'.700rem'} fontWeight={500} textAlign={'center'} lineHeight={1} position={'relative'} top={4} >
                                                    {getDateFormats((notify?.createdAt??undefined)).fullDate} {getDateFormats((notify?.createdAt??undefined)).hhmmss}
                                                </Typography>
                                            }
                                            <ListItem>
                                                <ListItemIcon sx={{ minWidth: 50 }} >
                                                    <NotificationsActiveRoundedIcon sx={{ fontSize: '2.2rem' }} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={{ margin: 0 }}
                                                    primary={notificationTextConstructor(notify)}
                                                />
                                            </ListItem>
                                            
                                            <Divider />
                                        </Fragment>
                                    )
                                })}
                            </List>
                        </Box>
                        :
                        <NoNotifications />
                    }
                    <SeeAllNotifications />
                </Box>
            </Menu>
        </>
    )
}

export default NotificationsMenu