import { currencyFormat } from "@util/currencyFormat"
import { validateRealNumber } from "@util/helpers"

export const generateQuickSummary = (data=[]) => {
    let currentData = [...data]

    let inventoryCost = { primary: 0, secondary: "Costo total del inventario" }
    let simpleInventoriedProducts = { primary: 0, secondary: "Producto simple inventariado" }
    let inventoriedCompositeProducts = { primary: 0, secondary: "Producto compuesto inventariado" }

    for( let product of currentData ){
        const cost = (product?.cost??0)
        const quantity = (product?.stock??0)
        const totalCost = (cost*quantity)
        if( Boolean(product?.compound) === false && Boolean(product?.productParent?._id) === false ){
            inventoryCost.primary += totalCost
            simpleInventoriedProducts.primary += 1
        }else{
            inventoriedCompositeProducts.primary += 1
        }
    }

    inventoryCost.primary = currencyFormat(inventoryCost.primary)
    simpleInventoriedProducts.primary = currencyFormat(simpleInventoriedProducts.primary, { applyCurrencyFormat: false })
    inventoriedCompositeProducts.primary = currencyFormat(inventoriedCompositeProducts.primary, { applyCurrencyFormat: false })

    let resp = [
        inventoryCost,
        simpleInventoriedProducts,
        inventoriedCompositeProducts
    ]

    return resp
}

export const calculateTotalInventory = (product=null) => {
    let r = 0
    if( Boolean(product?._id) ){
        const cost = validateRealNumber((product?.cost??0)) ? (product?.cost??0) : 0
        // const packaging = validateRealNumber((product?.packaging?.quantity??0)) ? (product?.packaging?.quantity??0) : 0
        const stock = validateRealNumber((product?.stock??0)) ? (product?.stock??0) : 0
        if( (product?.compound??false) === false ){
            r = (cost*stock)
        }else{
            r = (cost*stock)
        }
    }
    return r
}