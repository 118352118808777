import AutocompleteCreable from "@component/AutocompleteCreable"
import { useGetDeliveries } from "../hooks/useDeliveries"

const DeliveryFinder = ({ inputRef=undefined, sx=undefined, fullWidth=undefined, disabled=undefined, size=undefined, onChange=undefined, value=null, filters={} }) => {
    const { deliveries, isFetching } = useGetDeliveries(filters)
    return(
        <>
            <AutocompleteCreable
                inputRef={inputRef}
                sx={sx}
                disabled={disabled}
                onChange={onChange}
                value={value}
                fullWidth={fullWidth}
                size={size}
                label="Domiciliario"
                options={deliveries}
                loading={isFetching}
            />
        </>
    )
}

export default DeliveryFinder