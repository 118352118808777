import { useURLParams } from "@hook/useURLParams"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useCallback } from "react"
import { useState, useMemo } from "react"

export const useFiltersOrderList = () => {
    const [dates, setDates] = useState({ startDate: dayjs(), endDate: dayjs() })
    const [supplier, setSupplier] = useState(null)
    const { getAllQueries } = useURLParams()
    const queriesActive = useMemo(() => {
        return (getAllQueries()??{})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filters = useMemo(() => {
        return {
            ...dates,
            startDate: dayjs(dates.startDate).format("MM-DD-YYYY"),
            endDate: dayjs(dates.endDate).format("MM-DD-YYYY"),
            providers: supplier
        }
    }, [dates, supplier])

    const loadQueryValue = useCallback(() => {
        const keyFilters = Object.keys(filters)
        for( let key of keyFilters ){
            if( ["startDate", "endDate"].some((n) => n === key) ){
                if( Boolean(queriesActive[key]) ){
                    setDates(prevState => ({...prevState, [key]: queriesActive[key] }))
                }
            }

            if(key === "providers"){
                if( Boolean(queriesActive[key]) ){
                    setSupplier(queriesActive[key])
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        loadQueryValue()
    }, [loadQueryValue])

    return {
        filters,
        setDates,
        setSupplier
    }
}