import { Box, Typography } from '@component/UIComponents'
import { useGetProvider } from '@feature/Providers/hooks/useProviders'
import { useTranslation } from 'react-i18next'

const SupplierInformationRow = ({ providerName, _id }) => {
    const { t } = useTranslation()
    const { provider } = useGetProvider({ refId: _id }, { skip: !Boolean(_id), refetchOnMountOrArgChange: true })
    const { typeOfBankAccount, bankAccountNumber, bank="", bankAgreementNumber } = (provider??{})
    return(
        <>
        <Box width={'100%'} pr={7} >
            <Typography lineHeight={1} fontSize={'.750rem'} fontWeight={'bold'} > {providerName} </Typography>
            <Box fontSize={'.750rem'} fontWeight={'bold'} lineHeight={1} display={'flex'} justifyContent={'space-between'} width={'100%'} >
                <Box> BANCO: { Boolean(bank) ? bank : "N/A" } </Box>
                <Box> CTA: { Boolean(typeOfBankAccount) ? t(typeOfBankAccount).toLocaleUpperCase() : "N/A" } </Box>
                <Box> N° CTA: { Boolean(bankAccountNumber) ? bankAccountNumber : "N/A" } </Box>
                <Box> CONVENIO: { Boolean(bankAgreementNumber) ? bankAgreementNumber : "N/A" } </Box>
                {/* {providerName} */}
            </Box>
        </Box>
        </>
    )
}

export default SupplierInformationRow