import { Box, useMediaQuery, useTheme } from "@mui/material"
import Button from "@mui/lab/LoadingButton"
import styled from "@emotion/styled"
import { ButtonGroup } from "@mui/material"
import { usePointOfSaleActions } from "./hooks/usePointOfSaleActions"
import dayjs from "dayjs"
import { useCallback } from "react"

const ButtonStyled = styled(Button)(({ theme }) => ({
    width: "100%",
    height: '6.5vh',
    borderRadius: 0,
    fontSize: '.800rem',
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
        '& .keyboard_shortcut': {
            display: "none"
        }
    }
}))

const PointOfSaleActions = ({ invoiceType = "order", onRegisterSale = () => null, onCleanBilling = () => null, onPayBill = () => null, customer = null, products = [], total = 0, totalProfitCurrency = 0, totalProfitPercentage = 0, _id = null, user = null, WaitingForSavedSale = false }) => {
    const { permissions = [] } = (user?.rol ?? {})
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const hasPermission = useCallback((permission = "") => {
        if (user?.typeUser === "admin") return true
        return permissions.includes(permission)
    }, [permissions, user])
    const { saveForLater, cleanBilling, registerOrder, PrintMyLastBill, editOrder, isEditable, WaitingForSavedOrder } = usePointOfSaleActions({
        onPayBill,
        onCleanBilling,
        customer,
        products,
        total,
        totalProfitCurrency,
        totalProfitPercentage,
        user,
        _id,
    })

    const text = useCallback((key) => {
        const sss = {
            updateOrder: isMobile ? "Actualizar" : "Actualizar pedido",
            saveOrder: isMobile ? "Guardar" : "Registrar pedido",
            updateSale: isMobile ? "Actualizar" : "Actualizar venta",
            saveSale: isMobile ? "Guardar" : "Registrar venta",
            saveOrderAndPrint: isMobile ? "Guardar Imp" : "Registrar pedido e Imprimir",
            updateOrderAndPrint: isMobile ? "Actualizar Imp" : "Actualizar pedido e Imprimir",
            saveSaleAndPrint: isMobile ? "Guardar Imp" : "Registrar venta e Imprimir",
            updateSaleAndPrint: isMobile ? "Actualizar Imp" : "Actualizar venta e Imprimir",
            quotation: isMobile ? "Pedido" : "Guardar como cotización",
        }
        return sss[key]
    }, [isMobile])

    return (
        <ButtonGroup fullWidth variant="outlined" sx={{
            position: "fixed",
            bottom: 0,
        }} >
            {(!isMobile && hasPermission("temporarily_save_the_sale")) &&
                <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="saveInvoice" onClick={() => saveForLater({ customer, productList: products, total })} >
                    <Box className="truncate-text" >
                        Guardar para después
                    </Box>
                    <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                        (F12)
                    </Box>
                </ButtonStyled>
            }
            {(!isMobile && hasPermission("clean_billing_area")) &&
                <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="clearBilling" onClick={() => cleanBilling()} >
                    <Box className="truncate-text" >
                        Limpiar
                    </Box>
                    <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                        (SUPR)
                    </Box>
                </ButtonStyled>
            }
            {(hasPermission("register_sale")) &&
                <>
                    {invoiceType === "order" ?
                        <>
                            <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="recordSale" onClick={() => isEditable ? editOrder() : registerOrder()} >
                                <Box className="truncate-text">
                                    {isEditable ? text("updateOrder") : text("saveOrder")}
                                </Box>
                                <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                                    (F4)
                                </Box>
                            </ButtonStyled>
                            <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="recordSaleAndPrint" onClick={() => isEditable ? editOrder({ print: true }) : registerOrder({ print: true })} >
                                <Box className="truncate-text">
                                    {isEditable ? text("updateOrderAndPrint") : text("saveOrderAndPrint")}
                                </Box>
                                <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                                    (F6)
                                </Box>
                            </ButtonStyled>
                        </>
                        :
                        <>
                            <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="recordSale" onClick={onRegisterSale} >
                                <Box className="truncate-text">
                                    {isEditable ? text("updateSale") : text("saveSale")}
                                </Box>
                                <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                                    (F4)
                                </Box>
                            </ButtonStyled>
                            <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="recordSaleAndPrint" onClick={() => onRegisterSale({ print: true })} >
                                <Box className="truncate-text">
                                    {isEditable ? text("updateSaleAndPrint") : text("saveSaleAndPrint")}
                                </Box>
                                <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                                    (F6)
                                </Box>
                            </ButtonStyled>
                        </>
                    }
                </>
            }
            {/* { (((showBillingMode === "InvoiceSale") && hasPermission("register_sale")) )  &&
                <>
                    <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="recordSale" onClick={onRegisterSale} >
                        <Box className="truncate-text">
                            { isEditable ? text("updateSale") : text("saveSale") }
                        </Box>
                        <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                            (F4)
                        </Box>
                    </ButtonStyled>
                    <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="recordSaleAndPrint" onClick={() => onRegisterSale({print: true})} >
                        <Box className="truncate-text">
                            { isEditable ? text("updateSaleAndPrint") : text("saveSaleAndPrint") }
                        </Box>
                        <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                            (F6)
                        </Box>
                    </ButtonStyled>
                </>
            } */}
            {(!isEditable && hasPermission("register_quotation")) &&
                <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="recordQuote" onClick={() => onRegisterSale({ invoiceType: "price" })} >
                    <Box className="truncate-text">
                        {text("quotation")}
                    </Box>
                    <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                        (F5)
                    </Box>
                </ButtonStyled>
            }
            {!isMobile && hasPermission("print_last_sale_invoice") &&
                <ButtonStyled loading={(WaitingForSavedOrder || WaitingForSavedSale)} id="printLastInvoice" onClick={() => PrintMyLastBill({ InitDate: dayjs().format("MM-DD-YYYY"), EndDate: dayjs().format("MM-DD-YYYY") })} >
                    <Box className="truncate-text">
                        Imprimir ultima factura
                    </Box>
                    <Box className="keyboard_shortcut" fontSize={'.800rem'} ml={1} >
                        (HOME)
                    </Box>
                </ButtonStyled>
            }
        </ButtonGroup>
    )
}

export default PointOfSaleActions