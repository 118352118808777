import SingleDataGrid from "@component/SingleDataGrid"
import { usePanel } from "@hook/usePanel"
import { currencyFormat } from "@util/currencyFormat"

const SupplierDailyShoppingList = ({ list=[], loading=false }) => {
    const { getDateFormats } = usePanel()
    const columns = [
        {minWidth: 120, maxWidth: 120, field: "user", headerName : "Informante", renderCell: ({ row }) => `${(row?.user?.name??"")} ${(row?.user?.lastName??"")}`},
        {minWidth: 250, maxWidth: 250, field: "provider", headerName : "Proveedor", renderCell: ({ row }) => `${(row?.provider?.name??"")}`},
        {minWidth: 100, maxWidth: 100, field: "consecutive", align: "center", headerName : "Consecutivo", renderCell: ({row}) => `C#${(row?.consecutive??0)}`},
        {minWidth: 150, maxWidth: 150, field: "invoiceNumber", headerName : "Numero de factura", renderCell: ({row}) => `FAC#${(row?.invoiceNumber??"")}`},
        {minWidth: 150, maxWidth: 150, field: "subTotal", headerName : "Sub total", renderCell: ({row}) => ` ${currencyFormat((row?.subTotal??0))} `},
        {minWidth: 150, maxWidth: 150, field: "tax", headerName : "IVA", renderCell: ({row}) => ` ${currencyFormat((row?.tax??0))} `},
        {minWidth: 160, maxWidth: 160, field: "total", headerName : "Total", renderCell: ({row}) => ` ${currencyFormat((row?.total??0))} `},
        {minWidth: 160, maxWidth: 160, field: "createdAt", headerName : "Fecha de registro", renderCell: ({row}) => ` ${getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm} `},
    ]

    return(
        <>
            <SingleDataGrid
                localeText={{
                    noRowsLabel: "No se han registrado compras el dia de hoy"
                }}
                columns={columns}
                rows={list}
                isFetching={loading}
            />
        </>
    )
}

export default SupplierDailyShoppingList