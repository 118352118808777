import { useReturnCreditNoteMoneyInCashMutation } from "@query/Sales"
import { Toast } from "@util/swal"
import { useCallback } from "react"

export const useCreditNoteActions = () => {
    const [ ReturnCreditNoteMoneyInCashMutation ] = useReturnCreditNoteMoneyInCashMutation()
    
    const ReturnCreditNoteMoneyInCash = useCallback(async (CreditNoteID=null) => {
        try {
            let resp = await ReturnCreditNoteMoneyInCashMutation({ CreditNoteID }).unwrap()
            resp = (resp?.payload?._id??null)
            if( Boolean(resp) ) {
                Toast.fire({
                    icon: "success",
                    text: "El saldo de la nota crédito se devolvió correctamente."
                })
            }
        } catch (error) {
            
        }
    }, [ReturnCreditNoteMoneyInCashMutation])

    return {
        ReturnCreditNoteMoneyInCash,
    }
}