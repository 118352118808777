import { api, mutationConfig } from "../splitApi";
import { DeliveryTag, SaleTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerHomeDeliveryInvoice: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/home/delivery/invoice", body };
            },
            invalidatesTags: [...SaleTag, ...DeliveryTag]
        }),
        removeHomeDeliveryFromTheInvoice: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/remove/home/delivery/from/the/invoice", body };
            },
            invalidatesTags: [...SaleTag, ...DeliveryTag]
        }),
        getHomeDelivery: build.query({
            query: (params=undefined) => ({
                url: `/get/home/delivery`,
                params
            }),
            providesTags: DeliveryTag
        }),
        getDeliveries: build.query({
            query: (params=undefined) => ({
                url: `/get/deliveries`,
                params
            }),
            providesTags: DeliveryTag
        }),
        getDeliveriesRealized: build.query({
            query: (params=undefined) => ({
                url: `/get/deliveries/realized`,
                params
            }),
            providesTags: DeliveryTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetDeliveriesQuery,
    useGetDeliveriesRealizedQuery,
    useRegisterHomeDeliveryInvoiceMutation,
    useGetHomeDeliveryQuery,
    useRemoveHomeDeliveryFromTheInvoiceMutation,
} = Endpoints;