import { api, mutationConfig } from "../splitApi";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        IncludeOrdersInSalesReports: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/setting/include/orders/in/sales/reports", body };
            },
            invalidatesTags: ["Sale", "BusinessSetting"]
        }),
        GetBusinessAdjustments: build.query({
            query: (params=undefined) => ({
                url: `/setting/get/business/adjustments`,
                params
            }),
            providesTags: ["BusinessSetting"]
        }),
    }),
    overrideExisting: false,
});

export const {
    useIncludeOrdersInSalesReportsMutation,
    useGetBusinessAdjustmentsQuery,
} = Endpoints;