import NumericFormat from '@component/NumericFormat'
import { AddShoppingCartRoundedIcon, Avatar, Box, Button, CameraAltIconRounded, CloseRoundedIcon, Dialog, DialogContent, Grid, IconButton, Stack, Typography, styled, useTheme, useMediaQuery } from '@component/UIComponents'
import { currencyFormat, calculateProfitMargin } from '@util/currencyFormat'
import { useEffect, useMemo } from 'react'
import { useProductPOSFormik } from '../hooks/usePOS'
import Form from '@component/Form'
import { Toast } from '@util/swal'
import { useProductPreSelection } from '../hooks/useProductPreSelection'
import { elementFocus } from '@util/helpers'
import { useGetProducts } from '@feature/Inventory/Products/hooks/useProducts'
import { TextField } from '@mui/material'
import { useGetUserSessionInformation } from '@feature/Users'

const TypographyStyled = styled(Typography, {
    shouldForwardProp: (props) => props !== "bold"
})(({ bold }) => ({
    lineHeight: 1.2,
    ...( Boolean(bold) ? {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        WebkitTextStroke: ".5px"
    } : {
        fontSize: '.875rem',
        fontWeight: 'bold',
    })
}))

const TypographyDetailsPrice = styled(Typography,{
    shouldForwardProp: (prop) => prop !== "typeText"
})(({ typeText }) => ({
    lineHeight: 1.1,
    ...(typeText === "titlePrice" ? {
        fontSize: '.875rem',
        textTransform: "uppercase",
    } : {}),
    ...(typeText === "price" ? {
        fontSize: '1rem',
        fontWeight: "bold"
    } : {}),
    ...(typeText === "stock" ? {
        textTransform: "uppercase",
        fontSize: '.875rem',
    } : {}),
}))

const BoxStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== "active"
})(({theme, active}) => ({
    background: theme.palette.grey[300],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(.8),
    width: "100%",
    cursor: 'pointer',
    ...(Boolean(active) ? {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    } : {}),
    '& .priceName': {
        lineHeight: 1,
        fontSize: '.775rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    '& .priceValue': {
        lineHeight: 1,
        fontWeight: 'bold',
        textAlign: 'center',
    },
}))

const StackStyled = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "editable"
})(({ theme, editable }) => ({
    padding: theme.spacing(.5),
    ...(editable ? {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.error.light,
        '& .MuiSvgIcon-root' : {
            color: theme.palette.background.paper
        },
    } : {})
}))

const ProductPreSelection = ({ variant="sale", maxQuantity=undefined, customer=undefined, product=null, onClose=undefined, onChange=()=>null, value=undefined, editable=false, getProduct=null }) => {
    const { results:productsPOSList } = useGetProducts({ variant: 'pos' }, { skip: !Boolean(getProduct) })
    const { rolInternalName="" } = useGetUserSessionInformation()

    const currentProduct = useMemo(() => {
        let r = null
        if( (productsPOSList?.length??0) >= 1 && Boolean(getProduct) ){
            r = productsPOSList.find((n) => (n?._id??"") === getProduct)
        }else{
            r = product
        }
        return r
    }, [product, getProduct, productsPOSList])

    const handleSubmitData = (params) => {
        if( Boolean(onChange) ){
            if( variant === "creditNote" ){
                const quantityForCreditNote = ((value?.quantity??0)-(params?.quantity??0))
                params.quantity = quantityForCreditNote
            }
            onChange(params)
        }
    }
    const { values, setFieldValue, handleSubmit, errors, touched, resetForm } = useProductPOSFormik({ onSubmit: handleSubmitData, defaultValues: value })
    const { costPrice, name, quantity, salePrice, typePrice, pricePosition, observation="" } = values
    const open = useMemo(() => Boolean(currentProduct?._id), [currentProduct])

    const { handleChangePrice, handleChangeQuantity, loadProductData, setTriggerEraser } = useProductPreSelection({ product: currentProduct, setFieldValue, defaultValue: value, formValues: values })
    useEffect(() => {
        loadProductData()
    }, [loadProductData])

    const preSubmit = () => {
        let touchedsKey = Object.keys(touched)
        let errorsKey = Object.keys(errors)
        let isError = errorsKey.map((n) => touchedsKey.some(b => b === n))

        if( isError.some((n) => n === true) ){
            for( let n of errorsKey ){
                Toast.fire({
                    icon: "warning",
                    text: errors[n]
                })
            }
        }
        handleSubmit()
    }

    useEffect(() => {
        if( open ){
            setTimeout(() => {
                elementFocus("quantityProduct")
            }, 100)
        }else{
            resetForm()
            elementFocus("searchBarPOS")
        }
    }, [open,resetForm])

    const theme = useTheme()
    const isMobile = useMediaQuery( theme.breakpoints.down("sm") )

    return(
        <>
            <Dialog
            PaperProps={{
                elevation: 0,
                style: {
                    zIndex: 1301,
                },
            }}
            sx={ isMobile ? { '& .MuiDialog-paperScrollPaper' : { top: '-22vh' } } : {}} PaperProps={{ elevation: 0 }} fullWidth maxWidth="xs" transitionDuration={0} open={open} onClose={onClose} >
                <DialogContent>
                    <Form onSubmit={() => preSubmit()} >
                        <Grid container spacing={1} >
                            <Grid xs={12} >
                                <StackStyled editable={editable} direction={'row'} justifyContent={'space-between'} >
                                    <Box>
                                        <IconButton size="small" color='success' type='submit' onClick={() => preSubmit()} >
                                            <AddShoppingCartRoundedIcon />
                                        </IconButton>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'} >
                                        <Avatar sx={{ width: 80, height: 80 }} src={(currentProduct?.images??[])[0]??undefined} alt={(currentProduct?.images??[])[0]??undefined} >
                                            <CameraAltIconRounded />
                                        </Avatar>
                                        <Box textAlign={'center'} >
                                            <TypographyStyled bold > {(name??"")} </TypographyStyled>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <IconButton size="small" color='error' onClick={onClose} >
                                            <CloseRoundedIcon />
                                        </IconButton>
                                    </Box>
                                </StackStyled>
                            </Grid>
                            <Grid xs={12} >
                                <Stack direction={'row'} justifyContent={'space-between'} >
                                    <Box>
                                        <TypographyDetailsPrice typeText="titlePrice" >
                                            Costo: { (rolInternalName === "seller2") ? "****" : currencyFormat((costPrice??0)) }
                                        </TypographyDetailsPrice>
                                        <TypographyDetailsPrice typeText="price" >
                                            Ganancia: {" "}
                                            {/* { currencyFormat(salePrice) } - {" "} */}
                                            { (rolInternalName === "seller2") ? "****" : <>
                                                { typePrice === "manual" ? calculateProfitMargin((salePrice*quantity), ((salePrice*quantity)-(costPrice*quantity))) : currencyFormat(((currentProduct?.prices??[])[pricePosition]?.percentageProfit??0), { applyCurrencyFormat: false })}%
                                            </> }
                                        </TypographyDetailsPrice>
                                        <TypographyDetailsPrice typeText="stock" > stock: {currencyFormat((currentProduct?.stock??0), { applyCurrencyFormat: false })} {Boolean(currentProduct?.compound) ? (currentProduct?.packaging?.name??"") : "UND"} </TypographyDetailsPrice>
                                    </Box>
                                    <Box>
                                        <NumericFormat
                                            label="Cantidad"
                                            maxVal={maxQuantity}
                                            onBlur={() => setTriggerEraser(true)}
                                            id={'quantityProduct'}
                                            onChange={handleChangeQuantity}
                                            prefix=''
                                            value={quantity}
                                            style={{
                                                maxWidth: 100,
                                                textAlign: "center",
                                                fontWeight: "bold",
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>

                            {variant === "sale" &&
                                <>
                                    <Grid xs={12} >
                                        <Stack direction={'row'} justifyContent={'space-between'} gap={1} >
                                            {currentProduct?.prices?.map((item, index) => {
                                                return(
                                                    <BoxStyled key={index} active={ ((typePrice !== "manual") && (index === pricePosition)) } onClick={() => handleChangePrice({ pricePosition: index })} >
                                                        { index === 0 && <Typography className='priceName' > De tal </Typography> }
                                                        { index === 1 && <Typography className='priceName' > Mayor </Typography> }
                                                        { index === 2 && <Typography className='priceName' > Distribuidor </Typography> }
                                                        <Typography className='priceValue' > {currencyFormat((item?.sellPrice??0))} </Typography>
                                                    </BoxStyled>
                                                )
                                            })}
                                        </Stack>
                                    </Grid>
                                    <Grid xs={12} >
                                        <Box mt={1} >
                                            <NumericFormat
                                                id="manualPrice"
                                                onBlur={() => setTriggerEraser(true)}
                                                onChange={(values, event) => handleChangePrice({ typePrice: "manual", values, event })}
                                                size={'small'}
                                                fullWidth
                                                label={'Precio manual'}
                                                value={(typePrice === "manual") ? salePrice : ""}
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            }
                            <Grid xs={12} >
                                <TextField
                                    size='small'
                                    fullWidth
                                    placeholder='Observación'
                                    multiline
                                    value={observation}
                                    onChange={({ target }) => setFieldValue("observation", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <Box mt={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                    <Typography fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} > Total: { currencyFormat((quantity*salePrice)) } </Typography>
                                    
                                    <Button onClick={() => preSubmit()} >
                                        Continuar
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ProductPreSelection