import { ArrowBackIosNewRoundedIcon, RemoveRedEyeIconRounded } from "@component/UIComponents"
import { useCallback } from "react"

export const useSellerReportOptions = ({ dataGrid="seller_list" }) => {
    const primaryMenu = useCallback(({goBack=()=>null}) => {
        return [
            ...((dataGrid === "sales_list") ? [
                { title: "Volver", onClick: () => goBack(), icon: <ArrowBackIosNewRoundedIcon /> }
            ] : []),
        ]
    }, [dataGrid])

    const secondaryMenu = useCallback(({ onViewInvoice }) => {
        return [
            { title: "Ver factura", onClick: () => onViewInvoice(), icon: <RemoveRedEyeIconRounded sx={{ width: 23, height: 23 }} /> }

        ]
    }, [])

    return {
        primaryMenu,
        secondaryMenu
    }
}