import { api, mutationConfig } from "../splitApi";
import { BoxTag, DeliveryTag, SaleTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        recordSale: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/record/sale", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag, ...["PendingInvoices", "GetOrder", "MyLastBill"]]
        }),
        recordInvoiceInformationAndPayments: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/record/invoice/information/and/payments", body };
            },
            invalidatesTags: [...SaleTag, ...DeliveryTag, ...BoxTag]
        }),
        getSales: build.query({
            query: (params=undefined) => ({
                url: `/get/sales`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getPaymentsToSalesInvoice: build.query({
            query: (params=undefined) => ({
                url: `/get/payments/to/sales/invoice`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getSale: build.query({
            query: (params=undefined) => ({
                url: `/get/sale`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getPurchaseHistoryOfAProduct: build.query({
            query: (params=undefined) => ({
                url: `/get/purchase/history/of/a/product`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getSalesSummary: build.query({
            query: (params=undefined) => ({
                url: `/get/sales/summary`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getSaleOfTheDay: build.query({
            query: (params=undefined) => ({
                url: `/get/sale/of/the/day`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getCreditsFromPreviousDays: build.query({
            query: (params=undefined) => ({
                url: `/get/credits/from/previous/days`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getCopiesOfTheInvoice: build.query({
            query: (params=undefined) => ({
                url: `/get/copies/of/the/invoice`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getCreditNotes: build.query({
            query: (params=undefined) => ({
                url: `/get/credit/notes`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        getReceivedSalesInvoices: build.query({
            query: (params=undefined) => ({
                url: `/get/received/sales/invoices`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        retrieveReceivedSaleInvoice: build.query({
            query: (params=undefined) => ({
                url: `/retrieve/received/sale/invoice`,
                params
            }),
            providesTags: [...SaleTag]
        }),
        registerNewPaymentForSale: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/new/payment/for/sale", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
        updateInvoiceInformation: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/update/invoice/information", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
        updateInvoiceDueDate: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/update/invoice/due/date", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
        archiveSalesInvoice: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/sales/invoice", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
        removeCreditFromTheSale: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/remove/credit/from/the/sale", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
        shareSaleAsPurchase: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/share/sale/as/purchase", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
        registerCreditNote: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/credit/note", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
        receiveSaleFromAnotherBusiness: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/receive/sale/from/another/business", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
        returnCreditNoteMoneyInCash: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/return/credit/note/money/in/cash", body };
            },
            invalidatesTags: [...SaleTag, ...BoxTag]
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSalesQuery,
    useLazyGetSalesQuery,
    useGetSaleQuery,
    useLazyGetSaleQuery,
    useGetPurchaseHistoryOfAProductQuery,
    useRecordSaleMutation,
    useRecordInvoiceInformationAndPaymentsMutation,
    useGetSalesSummaryQuery,
    useRegisterNewPaymentForSaleMutation,
    useUpdateInvoiceInformationMutation,
    useGetCopiesOfTheInvoiceQuery,
    useGetPaymentsToSalesInvoiceQuery,
    useGetSaleOfTheDayQuery,
    useGetCreditsFromPreviousDaysQuery,
    useUpdateInvoiceDueDateMutation,
    useArchiveSalesInvoiceMutation,
    useRemoveCreditFromTheSaleMutation,
    useGetCreditNotesQuery,
    useRegisterCreditNoteMutation,
    useGetReceivedSalesInvoicesQuery,
    useLazyRetrieveReceivedSaleInvoiceQuery,
    useShareSaleAsPurchaseMutation,
    useReceiveSaleFromAnotherBusinessMutation,
    useReturnCreditNoteMoneyInCashMutation
} = Endpoints;
