import { useGetExpensesQuery } from "@query/VariableExpenses"
import { useMemo } from "react"

export const useGetExpenses = (filters={}, config={}) => {
    const { data, isFetching } = useGetExpensesQuery(filters, config)
    const expenses = useMemo(() => (data?.payload??[]), [data])

    return {
        isFetching,
        expenses,
    }
}