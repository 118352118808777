export const totalPaymentsToSalesInvoice = (totalSale=0, payments=[]) => {
    payments = Array.isArray(payments) ? payments : []
    const totalPayments = payments?.reduce((acc, val) => {
        const typePayment = (val?.type??"entry")
        if(typePayment === "entry"){
            return (acc+(val?.amount??0))
        }else if( typePayment === "exit" ){
            return (acc-(val?.amount??0))
        }else{
            return (acc+0)
        }
    }, 0)

    return {
        payments,
        totalPayments,
        balance: (totalSale-totalPayments)
    }
}