import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

const useQueryParams = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const setQueryParams = useCallback((newParams) => {
        const searchParams = new URLSearchParams(location.search);

        Object.entries(newParams).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                searchParams.set(key, value);
            }
        });

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }, [location, navigate]);

    const deleteQueryParams = useCallback((keys) => {
        const searchParams = new URLSearchParams(location.search);

        keys.forEach((key) => {
            searchParams.delete(key);
        });

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }, [location, navigate]);

    const getQueryParams = useCallback((keys) => {
        const searchParams = new URLSearchParams(location.search);
        const result = {};

        keys.forEach((key) => {
            result[key] = searchParams.get(key) || null;
        });

        return result;
    }, [location]);

    return { setQueryParams, deleteQueryParams, getQueryParams };
};

export default useQueryParams;