import { useLazyGetProductTurnoverRateQuery } from "@query/Products"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import _ from 'lodash'
import useQueryParams from "@hook/useQueryParams"

export const useCompareProductsByDates = () => {
    const { getQueryParams } = useQueryParams()
    const { ProductID=null } = useMemo(() => getQueryParams(["ProductID"]), [getQueryParams])
    const ShowComparison = useMemo(() => Boolean(ProductID), [ProductID])
    const [rows, setRows] = useState([{
        index: "001",
        "__compare__": {
            InitialDate: dayjs().format("MM-DD-YYYY"),
            FinalDate: dayjs().format("MM-DD-YYYY")
        }
    }])
    const [GetProductTurnoverRateQuery, { isLoading }] = useLazyGetProductTurnoverRateQuery()

    const GetProductTurnoverRate = useCallback( async () => {
        try {
            let result = []
            for( let row of rows ){
                const { InitialDate, FinalDate } = (row?.__compare__??{})
                const data = Boolean(ProductID) ? await GetProductTurnoverRateQuery({ InitialDate, FinalDate, ProductID }).unwrap() : null
                const { payload=[] } = (data??{})
                let item = (payload??[]).map((item) => ({
                    ...(item??{}),
                    "__compare__": (row?.__compare__??{}),
                    index: (result.length+1)
                }) )
                item = item.length === 1 ? item[0] : null
                if( Boolean(item) ){
                    result.push(item)
                }else{
                    result.push({
                        index: (result.length+1),
                        name: "NO SE ENCONTRÓ INFORMACIÓN",
                        "__compare__": {
                            InitialDate: dayjs().format("MM-DD-YYYY"),
                            FinalDate: dayjs().format("MM-DD-YYYY")
                        }
                    })
                }
            }
            const isEquals = _.isEqual(rows, result)
            if( !isEquals ) setRows(result)
        } catch (error) {
            console.log(error)
        }
    }, [GetProductTurnoverRateQuery, rows, ProductID])
    
    const UpdateComparisonDate = useCallback((field="", date=null, index=null,) => {
        if( !["InitialDate", "FinalDate"].some((validField) => (field === validField)) ){
            alert("El nombre del campo a actualizar no es correcto")
            return
        }
        if( !dayjs(date).isValid() ){
            alert(`${field}: El formato de la fecha no es correcto`)
            return
        }
        let currentRows = [...(rows??[])]
        index = index >= 1 ? (index-1) : index
        currentRows[index].__compare__[field] = date
        setRows(currentRows)
    }, [rows])

    const AddNewRow = useCallback(() => {
        let currentRows = [...(rows??[])]
        currentRows.push({
            index: (currentRows.length+1),
            "__compare__": {
                InitialDate: dayjs().format("MM-DD-YYYY"),
                FinalDate: dayjs().format("MM-DD-YYYY")
            }
        })
        setRows(currentRows)
    }, [rows])

    const RemoveRow = useCallback((index=null) => {
        let currentRows = [...(rows??[])]
        currentRows.splice(index, 1)
        setRows(currentRows)
    }, [rows])

    useEffect(() => {
        GetProductTurnoverRate()
    },[GetProductTurnoverRate])

    return {
        isLoading,
        rows,
        UpdateComparisonDate,
        AddNewRow,
        RemoveRow,
        ShowComparison,
    }
}