import { Box, Typography } from "@mui/material"

const HeaderDefault = ({ name = "", phone = "", address = "", nit = "", variant="" }) => {
    return (
        <Box>
            {variant === "creditNote" &&
                <Typography textTransform={'uppercase'} className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} > Nota crédito </Typography>
            }
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} >{name ?? ""}</Typography>
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > Tel. {phone ?? ""} </Typography>
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > {address ?? ""} </Typography>
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > {nit ? nit : ""} </Typography>
        </Box>
    )
}

const HeaderOrder = ({ name = "", consecutive=-1 }) => {
    return (
        <Box>
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} >{name ?? ""}</Typography>
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > PEDIDO </Typography>
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > P#{consecutive} </Typography>
        </Box>
    )
}

const HeaderPrice = ({ name = "", consecutive=-1 }) => {
    return (
        <Box>
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.5rem"} textAlign={'center'} textTransform={'uppercase'} >Orden de pedido</Typography>
            <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} > UNIKO </Typography>
        </Box>
    )
}

const Header = ({ invoiceType = "", business = null, orderNumber=-1, variant="" }) => {
    if (invoiceType === "sale") {
        return (
            <HeaderDefault {...(business??{})} variant={variant} />
        )
    }else if( invoiceType === "order" ){
        return(
            <HeaderOrder {...(business??{})} consecutive={orderNumber} />
        )
    }else if( invoiceType === "price" ){
        return(
            <HeaderPrice />
        )
    }
    // return (
    //     <>
    //         {invoiceType === "price" ?
    //             <Box>
    //                 <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.5rem"} textAlign={'center'} textTransform={'uppercase'} >Orden de pedido</Typography>
    //                 <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} > UNIKO </Typography>
    //             </Box>
    //             :
    //             <Box>
    //                 {variant === "creditNote" &&
    //                     <Typography textTransform={'uppercase'} className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} > Nota crédito </Typography>
    //                 }
    //                 <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} >{business?.name ?? ""}</Typography>
    //                 <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > Tel. {business?.phone ?? ""} </Typography>
    //                 <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > {business?.address ?? ""} </Typography>
    //                 <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > {business?.nit ? business?.nit : ""} </Typography>
    //             </Box>
    //         }
    //     </>
    // )
}

export default Header