import AutocompleteCreable from "@component/AutocompleteCreable"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"
import { useGetCustomerTypes } from "../hooks/useGetCustomerTypes"

const CustomerTypeFinder = ({ size=undefined, value=null, onChange=undefined, multiple=undefined, queryValue=undefined, compare=undefined }) => {
    const { customerTypes, isFetchingGetCustomerTypes } = useGetCustomerTypes()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: customerTypes, queryValue, compare })
    const val = getAutocompleteValue()

    return(
        <>
            <AutocompleteCreable
                size={size}
                multiple={multiple}
                value={val}
                onChange={onChange}
                loading={isFetchingGetCustomerTypes}
                label="Tipo de cliente"
                options={customerTypes}
            />
        </>
    )
}

export default CustomerTypeFinder