import { Text } from '@component/Title'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, Grid, TextField } from '@component/UIComponents'
import FinderRoles from '@feature/Settings/RolesAndPermissions/components/FinderRoles'
import { useEmployeeOptions, useEmployeesSettingFormData, useGetEmployee, useUpdateEmployeeSettings } from '../hooks/useEmployees'
import Form from '@component/Form'
import Autocomplete from '@component/Autocomplete'
import EmployeeBillingSettings from './EmployeeBillingSettings/EmployeeBillingSettings'

const EmployeeSettings = ({ employeeId, open=false, onClose=()=>null }) => {
    const { employee } = useGetEmployee({ employeeId })
    const { values, setFieldValue, validateValues, resetValues } = useEmployeesSettingFormData({ defaultValues: employee })
    const { cashier=false, rol=null, status="locked", password="", homePage="", billingMode="" } = values
    const { updateEmployeeSettings } = useUpdateEmployeeSettings()
    const { homePages } = useEmployeeOptions()

    const handleSubmit = () => {
        let currentValues = {...(values??{})}
        currentValues.billingMode = (currentValues?.billingMode?._id??"")
        const { type } = validateValues(currentValues)
        if( type === "errors" ) return
        updateEmployeeSettings(currentValues, {
            clearForm: () => {
                resetValues()
                onClose()
            }
        })
    }

    return(
        <>
            <Dialog maxWidth="xs" fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={12} >
                                <Text variant='form-title' > Sesión del usuario </Text>
                                <Divider />
                            </Grid>
                            <Grid xs={12} >
                                <FormControlLabel
                                    label="Permitir inicio de sesión"
                                    control={<Checkbox
                                        checked={status === "locked" ? false : true}
                                        onChange={({target}) => setFieldValue("status", ((target?.checked === true) ? "active" : "locked"))}
                                    />}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <TextField
                                    value={password}
                                    fullWidth
                                    label="Cambiar contraseña"
                                    onChange={({target}) => setFieldValue("password", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <Autocomplete
                                    value={(homePages().find((n) => ((n?.value??"") === homePage)) || null)}
                                    label="Pagina de inicio"
                                    options={homePages()}
                                    onChange={(_, value) => setFieldValue("homePage", (value?.value??""))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <FinderRoles
                                    value={rol}
                                    fullWidth
                                    label="Privilegio"
                                    onChange={(_, value) => setFieldValue("rol", (value??null))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <Text variant='form-title' > Facturación </Text>
                                <Divider />
                            </Grid>
                            <Grid xs={12} >
                                <FormControlLabel
                                    label="Permitir funciones de caja"
                                    control={<Checkbox
                                        checked={cashier}
                                        onChange={({target}) => setFieldValue("cashier", (target?.checked??false))}
                                    />}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <EmployeeBillingSettings
                                    billingMode={billingMode}
                                    onChange={setFieldValue}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default EmployeeSettings