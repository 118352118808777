import { DeleteIconRounded, SaveRoundedIcon } from "@component/UIComponents"
import { useCallback } from "react"

export const useOrderListMenu = () => {
    const primaryMenu = useCallback((props={}, config={}) => {
        const { onRemoveProductsFromList=()=>null } = config
        return [
            { title: "Quitar producto", onClick: () => onRemoveProductsFromList(), icon: <DeleteIconRounded />, buttonProps: { color: 'error' } },
        ]
    }, [])

    const secondaryMenu = useCallback((props={}, config={}) => {
        const { loadingSaveOrder=false } = props
        const { onSaveOrder=()=>null } = config
        return [
            { title: "Guardar pedido", onClick: () => onSaveOrder(), icon: <SaveRoundedIcon />, buttonProps: { loading: loadingSaveOrder } },
        ]
    }, [])

    return {
        primaryMenu,
        secondaryMenu
    }
}