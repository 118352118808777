import { Box, CircularProgress, InputAdornment, RestoreRoundedIcon, SearchIconRounded, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@component/UIComponents'
import { useGetPurchaseHistoryOfAProduct } from '@feature/Reports/Sales/hooks/useSales'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'
import { useState } from 'react'
import { useCallback } from 'react'
import { Fragment } from 'react'

const CustomerPurchaseHistory = ({ customerId }) => {
    const [search, setSearch] = useState("")
    let { history, isFetching } = useGetPurchaseHistoryOfAProduct({ limit: 5, customerId, variant: "customerPurchaseTracking" }, { skip: !Boolean(customerId) })
    const { getDateFormats } = usePanel()
    history = Boolean(customerId) ? history : []

    const productsList = useCallback((products=[]) => {
        let list = [...products]
        list = list.filter((item) => (item?.name??"").toUpperCase().includes(search.toUpperCase()))
        return list
    }, [search])

    if( isFetching ){
        return(
            <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={200} >
                <Box textAlign={'center'} >
                    <CircularProgress />
                    <Typography fontSize={'.875rem'} >
                        Cargando historial de compra del cliente...
                    </Typography>
                </Box>
            </Box>
        )
    }else{
        return(
            <>
                <Box mb={1} >
                    <TextField
                        value={search}
                        InputProps={{
                            startAdornment: <InputAdornment position='start' >
                                <SearchIconRounded />
                            </InputAdornment>
                        }}
                        fullWidth
                        placeholder='Buscar producto...'
                        variant='standard'
                        onChange={({target}) => setSearch((target?.value??""))}
                    />
                </Box>
                <Box display={'flex'} flexDirection={'column'} maxHeight={'78vh'} overflow={'scroll'} px={1} >
                    {history.length <= 0 ?
                        <>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                                <RestoreRoundedIcon sx={{ fontSize: '4rem', color: (theme) => theme.palette.grey[400] }} />
                                <Typography fontWeight={'500'} sx={{ color: (theme) => theme.palette.grey[400] }} >
                                    No se encontraron facturas de ventas...
                                </Typography>
                            </Box>
                        </>
                        :
                        <>
                            {history.map((item, index) => {
                                let productsL = productsList((item?.products??[]))
                                if( (productsL.length >= 1) ){
                                    return(
                                        <Box key={index} border={1} p={1} mb={1} borderRadius={1} sx={{ borderColor: (theme) => theme.palette.grey[400] }} >
                                            <Typography fontSize={'.775rem'} fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }} >{getDateFormats((item?.createdAt??"")).fullDate} - {getDateFormats((item?.createdAt??"")).hhmmss}</Typography>
                                            <Box display={'flex'} justifyContent={'space-between'} >
                                                <Typography fontWeight={'bold'} >{`#FAC${(item?.consecutive??0)}`}</Typography>
                                                <Typography fontWeight={'bold'} >{currencyFormat((item?.total??0))}</Typography>
                                            </Box>
                                            <Table size='small' >
                                                <TableBody>
                                                    {productsList((item?.products??[])).map((product, indexP) => {
                                                        return(
                                                            <TableRow key={indexP} sx={{
                                                                '& .MuiTableCell-root': {
                                                                    padding: .5,
                                                                }
                                                            }} >
                                                                <TableCell>
                                                                    <Typography fontSize={'.875rem'} sx={{
                                                                        width: 200,
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                    }} >
                                                                        {(product?.name??"")}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography fontSize={'.875rem'}>
                                                                        x{(product?.quantity??"")}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography fontSize={'.875rem'}>
                                                                        {currencyFormat((product?.salePrice??0))}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography fontSize={'.875rem'}>
                                                                        {currencyFormat(((product?.quantity??"")*(product?.salePrice??0)))}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    )
                                }else{
                                    return <Fragment key={index} ></Fragment>
                                }
                            })}
                        </>    
                    }
                </Box>
            </>
        )
    }
}

export default CustomerPurchaseHistory