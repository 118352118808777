import { Box, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, SearchIconRounded, TextField, styled } from '@component/UIComponents'
import { useCallback, useMemo, useState } from 'react'
import EmptyList from './EmptyList'

const ListStyled = styled(List)(({ theme }) => ({
    maxHeight: "80vh",
    overflow: "scroll",
    '& .MuiButtonBase-root':{
        paddingTop: 2,
        paddingBottom: 2,
    },
    '& .MuiListItemIcon-root': {
        minWidth: "40px",
        color: theme.palette.primary.main
    },
    '& .MuiListItemText-primary': {
        fontSize: "1rem",
        fontWeight: "bold",
    }
}))

const VerticalCustomList = ({ list=[], getPrimaryLabel=null, listItemIcon=true, itemIcon=<></>, emptyListText="", onClick=()=>null }) => {
    const [filter, setFilter] = useState("")

    const label = useCallback((item) => {
        return Boolean(getPrimaryLabel) ? getPrimaryLabel(item) : (item?.name??"")
    }, [getPrimaryLabel])

    const currentList = useMemo(() => {
        let cList = [...list]
        if( Boolean(filter) ){
            cList = cList.filter((item) => label((item??"")).toUpperCase().includes(filter.toUpperCase()))
        }
        return cList
    }, [list, filter, label])

    return(
        <>
            <Box px={1} >
                <TextField
                    helperText={`${currentList.length} resultados`}
                    onChange={({target}) => setFilter((target?.value??""))}
                    fullWidth
                    variant='standard'
                    placeholder="Buscador..."
                    InputProps={{
                        startAdornment: <InputAdornment position='start' >
                            <SearchIconRounded />
                        </InputAdornment>
                    }}
                />
            </Box>
            {currentList.length <= 0 ?
                <EmptyList icon={itemIcon} text={emptyListText} />
                :
                <ListStyled>
                    { currentList.map((item, index) => {
                        return(
                            <ListItemButton key={index} onClick={() => onClick(item)} >
                                { listItemIcon &&
                                    <ListItemIcon>
                                        {itemIcon}
                                    </ListItemIcon>
                                }
                                <ListItemText
                                    primary={label(item)}
                                />
                            </ListItemButton>
                        )
                    }) }
                </ListStyled>
            }
        </>
    )
}

export default VerticalCustomList