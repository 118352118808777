import PatternFormat from "@component/PatternFormat"
import { Box, Button, Grid, TextField } from "@component/UIComponents"
import { useBusinessFormData, useGetBusinessInformation, useUpdateBusinessInformation } from "./hooks/useBusiness"
import Form from "@component/Form"

const BusinessInformation = () => {
    const { business, isLoading } = useGetBusinessInformation()
    const { updateBusinessInformation, isLoading:isLoadingMutation } = useUpdateBusinessInformation()
    const { values, setFieldValue, validateValues } = useBusinessFormData({ defaultValues: business })
    const { address="", email="", name="", nit="", phone="" } = values
    const handleSubmit = () => {
        const { type } = validateValues(values)
        if(type === "errors") return
        updateBusinessInformation(values)
    }

    return(
        <>
            <Form onSubmit={handleSubmit} >
                <Grid container >
                    <Grid xs={12} >
                        <TextField
                            label="Nombre del negocio"
                            fullWidth
                            margin="dense"
                            value={name}
                            onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <TextField
                            label="Correo electrónico"
                            fullWidth
                            margin="dense"
                            type="email"
                            value={email}
                            onChange={({ target }) => setFieldValue("email", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <PatternFormat
                            format="+57 ### ### ## ##"
                            label="Numero de teléfono"
                            fullWidth
                            margin="dense"
                            value={phone}
                            onChange={(value) => setFieldValue("phone", (value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <TextField
                            label="NIT"
                            fullWidth
                            margin="dense"
                            value={nit}
                            onChange={({ target }) => setFieldValue("nit", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <TextField
                            multiline
                            rows={3}
                            label="Dirección"
                            fullWidth
                            margin="dense"
                            value={address}
                            onChange={({ target }) => setFieldValue("address", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <Box display={'flex'} justifyContent={'end'} >
                            <Button type="submit" loading={isLoading || isLoadingMutation } >Guardar</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Form>
        </>
    )
}

export default BusinessInformation