export const Business = ["Business"]
export const FixedExpenseTag = ["FixedExpense"]
export const ProviderTag = ["Providers"]
export const PurchaseTag = ["Purchase"]
export const BoxTag = ["Box"]
export const BusinessTypeTag = ["BusinessType"]
export const BranchTag = ["BranchTag"]
export const CityTag = ["City"]
export const NeighborhoodTag = ["Neighborhood"]
export const ZoneTag = ["Zone"]
export const CustomerTag = ["Customer"]
export const CustomerAdvanceTag = ["CustomerAdvance"]
export const SaleTag = ["Sale"]
export const DeliveryTag = ["Delivery"]
export const HaulierTag = ["Haulier"]
export const RoleTag = ["Role"]
export const EmployeeTag = ["Employee"]
export const ProductTag = ["Product"]
export const NotificationsTag = ["Notifications"]
export const OrderPurchaseTag = ["OrderPurchase"]
export const VariableExpensesTag = ["VariableExpenses"]
export const CategoriesTag = ["CategoriesTag"]
export const PackagingTag = ["PackagingTag"]
export const FullCustomerTag = [...CustomerTag, ...CustomerAdvanceTag, ...BusinessTypeTag, ...BranchTag, ...CityTag, ...NeighborhoodTag, ...ZoneTag,]

export const Tags = [
    ...FixedExpenseTag,
    ...ProviderTag,
    ...PurchaseTag,
    ...BoxTag,
    ...BusinessTypeTag,
    ...BranchTag,
    ...CityTag,
    ...NeighborhoodTag,
    ...ZoneTag,
    ...CustomerTag,
    ...SaleTag,
    ...DeliveryTag,
    ...ProductTag,
    ...VariableExpensesTag,
    ...Business,
    ...NotificationsTag,
    ...CategoriesTag,
    ...PackagingTag,
]