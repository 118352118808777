import { createCookie, getCookie } from "@util/helpers"
import { Toast } from "@util/swal"
import dayjs from "dayjs"
import { useCallback } from "react"

export const useSaveForLater = ({ onCleanBilling=()=>null }) => {

    const saveForLater = useCallback(({ customer=null, productList=[], total=0, } ) => {
        if( productList.length <= 0 ){
            Toast.fire({ icon: "warning", text: "No es posible guardar una lista vacía." })
            return
        }
        // if( Boolean(invoiceCharged) ){
        //     Toast.fire({
        //         icon: "warning",
        //         text: "No es posible guardar una factura que ya se encuentra registrada."
        //     })
        //     return
        // }
        let currentProductList = [...productList]
        let savedProductList = (getCookie("savedProductList", { convertJSONstringify: true })??[])
        savedProductList.push({ customer, products: currentProductList, total, createdAt: dayjs().toDate() })
        savedProductList = savedProductList.map((n, index) => ({...n, _id: (index+1)}))
        createCookie({
            name: "savedProductList",
            value: savedProductList,
            convertJSONstringify: true
        })
        Toast.fire({ icon: "success", text: "La factura se guardo exitosamente." })
        onCleanBilling()
    }, [onCleanBilling])

    return {
        saveForLater
    }
}