import { Box, Button } from '@component/UIComponents'
import AuthContainerForm from "@component/AuthContainerForm"
import { routes } from '@util/routes'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import BackgroundPOS from './components/BackgroundPOS'
const { singIn } = routes

const Welcome = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const elem = document.getElementById("root")
        if( Boolean(elem) ){
            elem.style.backgroundColor = "#d8eaff"
        }
    }, [])
    return(
        <Box position={'relative'} width={'100vw'} height={'100vh'} >
            <BackgroundPOS />
            <AuthContainerForm title={""} sx={{ zIndex: "1000 !important" }} >
                <Box fontSize={'1.5rem'} textAlign={'center'} fontWeight={'bold'} textTransform={'uppercase'} sx={{ color: (theme) => theme.palette.primary.main }} >
                    ¡bienvenido vende facill!
                </Box>
                <Box fontSize={'1.2rem'} textAlign={'center'} fontWeight={'bold'} textTransform={'uppercase'} >
                    Registro Rapido
                </Box>
                <Box display={'flex'} justifyContent={'center'} mt={4} >
                    <Button onClick={() => navigate(singIn())} disableElevation sx={{ bgcolor: (theme) => theme.palette.success.light, '&:hover': { bgcolor: (theme) => theme.palette.success.light }, color: "#FFFFFF" }} >
                        Continuar
                    </Button>
                </Box>
            </AuthContainerForm>
        </Box>
    )
}

export default Welcome