import { api, mutationConfig } from "../splitApi";
import { BoxTag, HaulierTag, OrderPurchaseTag, PurchaseTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        archivingPaymentsPurchases: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archiving/payments/purchases", body };
            },
            invalidatesTags: [...PurchaseTag, ...BoxTag]
        }),
        archivePurchase: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/purchase", body };
            },
            invalidatesTags: [...PurchaseTag, ...BoxTag]
        }),
        recordPurchase: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/record/purchase", body };
            },
            invalidatesTags: [...PurchaseTag, ...BoxTag, ...HaulierTag, ...OrderPurchaseTag]
        }),
        payPurchases: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/pay/purchases", body };
            },
            invalidatesTags: [...PurchaseTag, ...BoxTag]
        }),
        updatePurchaseExpirationDate: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/update/purchase/expiration/date", body };
            },
            invalidatesTags: [...PurchaseTag, ...BoxTag]
        }),
        getPurchases: build.query({
            query: (params=undefined) => ({
                url: `/get/purchases`,
                params
            }),
            providesTags: [...PurchaseTag, ...BoxTag]
        }),
        getPurchase: build.query({
            query: (params=undefined) => ({
                url: `/get/purchase`,
                params
            }),
            providesTags: [...PurchaseTag, ...BoxTag]
        }),
    }),
    overrideExisting: false,
});

export const {
    useRecordPurchaseMutation,
    useGetPurchasesQuery,
    usePayPurchasesMutation,
    useGetPurchaseQuery,
    useLazyGetPurchaseQuery,
    useArchivePurchaseMutation,
    useArchivingPaymentsPurchasesMutation,
    useUpdatePurchaseExpirationDateMutation,
} = Endpoints;
