import { ArrowBackRoundedIcon, AssignmentRoundedIcon, BadgeRoundedIcon, CurrencyExchangeRoundedIcon, DashboardRoundedIcon, GroupsRoundedIcon, PaidRoundedIcon, PaymentsRoundedIcon, PersonIconRounded, PersonSearchRoundedIcon, PointOfSaleRoundedIcon, SettingsRoundedIcon, ShoppingCartRoundedIcon, StoreRoundedIcon, TrendingUpRoundedIcon } from "@component/UIComponents"
import { useValidatePermission } from "@hook/useValidatePermission"
import { routes } from "@util/routes"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ButtonCrearProducto from "./useCreateProductButton"
const { cashierReport, settingPointOfSale, updateUserInformation, updateBusinessInformation, customerTracking, sales, expenses, ordersToSuppliers, closingOfTheDay, hauliers, deliveries, employees, roles, settings, home, inventory, report, purchaseReport, masterBox, boxes, banks, enterPurchase, accountsPayable, customers, providers } = routes

export const useDashboard = () => {
    const { t } = useTranslation()
    const { validateArrayByPermissionOrder } = useValidatePermission()

    const settingList = useCallback(() => {
        return [
            {
                name: t('Volver'),
                pathName: home(),
                active: false,
                icon: <ArrowBackRoundedIcon />,
                children: []
            },
            {
                name: t('Información del usuario'),
                pathName: updateUserInformation(),
                active: false,
                icon: <PersonIconRounded />,
                children: []
            },
            {
                name: t('Información del negocio'),
                pathName: updateBusinessInformation(),
                active: false,
                icon: <StoreRoundedIcon />,
                children: []
            },
            {
                name: t('Punto de venta'),
                pathName: settingPointOfSale(),
                active: false,
                icon: <PointOfSaleRoundedIcon />,
                children: []
            },
            {
                name: t('Roles'),
                pathName: roles(),
                active: false,
                icon: <BadgeRoundedIcon />,
                children: []
            },
        ]
    }, [t])

    const drawerListDefault = useCallback(() => {
        let r = [
            {
                name: t('panel'),
                pathName: home(),
                active: false,
                icon: <DashboardRoundedIcon />,
                children: []
            },
            {
                
                name: t('purchases'),
                pathName: "",
                active: false,
                icon: <ShoppingCartRoundedIcon />,
                children: [
                    {
                        name: 'Pedidos',
                        pathName: ordersToSuppliers(" "),
                        active: false,
                        prefix: "orders",
                    },
                    {
                        name: 'Ingreso de compras',
                        pathName: enterPurchase(" "),
                        active: false,
                        prefix: "enter",
                    },
                    {
                        name: 'Reporte de compras',
                        pathName: purchaseReport("purchases"),
                        active: false,
                        prefix: "purchase",
                    },
                ]
            },
            {
                name: t('Cajas y bancos'),
                pathName: "",
                active: false,
                icon: <CurrencyExchangeRoundedIcon />,
                children: [
                    {
                        name: 'Caja principal',
                        pathName: masterBox(),
                        active: false,
                        prefix: "master",
                    },
                    {
                        name: 'Caja POS',
                        pathName: cashierReport(),
                        active: false,
                        prefix: "cashier",
                    },
                    {
                        name: 'Bolsillos',
                        pathName: boxes(),
                        active: false,
                    },
                    {
                        name: 'Bancos',
                        pathName: banks(),
                        active: false,
                    },
                ]
            },
            {
                name: t('third'),
                pathName: home(),
                active: false,
                icon: <GroupsRoundedIcon />,
                children: [
                    {
                        name: 'Clientes',
                        pathName: customers(),
                        active: false,
                        prefix: "customers",
                    },
                    {
                        name: 'Proveedores',
                        pathName: providers(),
                        active: false,
                        prefix: "providers",
                    },
                    {
                        name: 'Equipo',
                        pathName: employees(),
                        active: false,
                    },
                    {
                        name: t('Domiciliarios'),
                        pathName: deliveries(),
                        active: false,
                    },
                    {
                        name: t('Transportistas'),
                        pathName: hauliers(),
                        active: false,
                    },
                ]
            },
            {
                name: t('Gastos'),
                pathName: home(),
                active: false,
                icon: <PaymentsRoundedIcon />,
                children: [
                    {
                        name: t('Gastos fijos'),
                        pathName: accountsPayable("fixed-costs"),
                        active: false,
                        prefix: "accounts",
                    },
                    {
                        name: t('Gastos generales'),
                        pathName: expenses(),
                        active: false,
                        prefix: "expenses",
                    },
                ]
            },
            {
                name: t('Inventarios'),
                pathName: inventory("products"),
                active: false,
                icon: <AssignmentRoundedIcon />,
                children: [
                    {
                        name: <ButtonCrearProducto/>,
                    },
                    {
                        name: t('Inventarios'),
                        pathName: inventory("products"),
                        active: false,
                    },
                    {
                        name: t('Productos'),
                        pathName: inventory("products"),
                        active: false,
                    },
                    {
                        name: t('Categorías'),
                        pathName: inventory("categories"),
                        active: false,
                    },
                    {
                        name: t('Embalajes'),
                        pathName: inventory("packaging"),
                        active: false,
                    },
                    {
                        name: t('Mas Vendidos'),
                        pathName: inventory("bestSeller"),
                        active: false,
                    },
                    {
                        name: t('Tasa de rotacion'),
                        pathName: inventory("TurnoverRate"),
                        active: false,
                    }
                ]
            },
            {
                name: t('reports'),
                pathName: report("sales"),
                prefix: "report",
                active: false,
                icon: <TrendingUpRoundedIcon />,
                children: []
            },
            {
                name: t('Seguimiento de clientes'),
                pathName: customerTracking(),
                prefix: "customer",
                active: false,
                icon: <PersonSearchRoundedIcon />,
                children: []
            },
            {
                name: t('Cierre del dia'),
                pathName: closingOfTheDay(),
                active: false,
                icon: <PaidRoundedIcon />,
                children: []
            },
            {
                name: t('Vender'),
                pathName: sales({saleId: ""}),
                active: false,
                icon: <PointOfSaleRoundedIcon />,
                children: []
            },
            {
                name: t('Configurar negocio'),
                pathName: settings(),
                active: false,
                icon: <SettingsRoundedIcon />,
                // children: [
                //     {
                //         name: t('Roles'),
                //         pathName: roles(),
                //         active: false,
                //     },
                //     {
                //         name: t('Sucursales'),
                //         pathName: branches(),
                //         active: false,
                //     },
                // ]
                children: []
            },
        ]
        r = validateArrayByPermissionOrder(["show_panel_menu", "show_shopping_menu", "show_menu_of_boxes_and_banks", "show_third_party_menu", "show_expenses_menu", "show_inventory_and_products_menu", "show_report_menu", "show_closing_menu_of_the_day", "show_sales_menu", "show_panel_menu",], r)
        return r
    }, [t, validateArrayByPermissionOrder])


    return {
        drawerListDefault,
        settingList
    }
}

export const useAppBar = () => {
    const navigate = useNavigate()
    const appBarOptionMenu = useCallback(() => {
        return [
            {
                title: "Configuraciones del sistema",
                icon: <SettingsRoundedIcon />,
                onClick: () => navigate(settings())
            }
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [navigate])

    return {
        appBarOptionMenu
    }
}