import { useRegisterHomeDeliveryInvoiceMutation } from "@query/Deliveries"
import { asynchronousResponseHandler } from "@util/helpers"

export const useRegisterHomeDeliveryInvoice = () => {
    const [registerHomeDeliveryInvoiceMutation, { isLoading }] = useRegisterHomeDeliveryInvoiceMutation()

    const registerHomeDeliveryInvoice = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await registerHomeDeliveryInvoiceMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "El domicilio se registro satisfactoriamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerHomeDeliveryInvoice,
        isLoading,
    }
}