const Form = ({ children, onKeyDown=undefined, onSubmit, formikHandleChange, onKeyUp=undefined, id=undefined }) => {

    const handleSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const form = event.target
        const formData = new FormData(form)
        let values = {}

        for( let [index, value] of formData.entries() ){
            values[index] = value
        }

        if( onSubmit !== undefined ){
            onSubmit(values)
        }
    }

    const handleChange = (event) => {
        if( formikHandleChange !== undefined ){
            const { target } = event
            if( Boolean(target) ){
                let { name, value, type, checked, files, attributes } = target
                let inputMode = attributes?.inputmode?.value??null
                if( type === "checkbox" ) value = checked
                if( type === "file" ) value = files[0]
                if( inputMode === "numeric" ) {
                    value = value.split(" ")
                    if( Number.isNaN(parseInt(value[value.length-1])) ){
                        value.splice(value.length-1, 1)
                    }
                    value = Number.isNaN(parseInt(value)) ? "" : Number(value)
                }
                formikHandleChange(name, value)
            }
        }
    }

    const handleKeyUp = (event) => {
        if( Boolean(onKeyUp) ){
            onKeyUp(event)
        }
    }

    return(
        <form id={id} onKeyDown={onKeyDown} onKeyUp={handleKeyUp} onSubmit={handleSubmit} onChange={handleChange} >
            {children}
        </form>
    )
}

export default Form