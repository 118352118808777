import { Box } from "@mui/material"
import { ThumbUpRounded } from "@mui/icons-material"
import { useGetPendingInvoicesQuery } from "@query/billing"
import { useMemo, useState } from "react"
import { usePrintInvoice } from "@feature/Sales/hooks/usePrintInvoice"
import DialogInvoice from "@feature/Reports/Invoice/components/DialogInvoice"
import ListItems from "./components/ListItems"


const ListOfPendingInvoices = ({ isEditable = false, onSelectBill = () => null }) => {
    const { data } = useGetPendingInvoicesQuery()
    const pending = useMemo(() => (data?.payload ?? []), [data])
    const { PrintInvoice } = usePrintInvoice()
    const [showBill, setShowBill] = useState(null)
    const maxHeightContainerList = useMemo(() => {
        if (isEditable === true) {
            return `calc(100vh - 490px)`
        } else {
            return `calc(100vh - 370px)`
        }
    }, [isEditable])


    if (pending.length >= 1) {
        return (
            <>
                <DialogInvoice open={Boolean(showBill)} onClose={() => setShowBill(null)} saleRef={showBill} />
                <ListItems
                    maxHeightContainerList={maxHeightContainerList}
                    list={pending}
                    onSelectBill={onSelectBill}
                    onPrint={PrintInvoice}
                    onShow={setShowBill}
                />
            </>
        )
    } else {
        return (
            <Box sx={{ textAlign: "center", padding: 1, color: ({ palette }) => palette.grey[400] }} >
                <ThumbUpRounded sx={{ fontSize: "2.5rem" }} />
                <Box>
                    No hay facturas pendientes...
                </Box>
            </Box>
        )
    }
}

export default ListOfPendingInvoices