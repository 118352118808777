import PatternFormat from "@component/PatternFormat"
import { Box, Button, Grid, TextField } from "@component/UIComponents"
import Form from "@component/Form"
import { useUserInformationFormDataController } from "./hooks/useUserInformationController"
import { useGetUserSessionInformation } from "@feature/Users"
import { useMemo } from "react"
import { useUpdateUserInformation } from "./hooks/useUpdateUserInformation"

const UserInformation = () => {
    const { userData } = useGetUserSessionInformation()
    const defaultValues = useMemo(() => ({ name: (userData?.name??""), lastName: (userData?.lastName??""), email: (userData?.email??""), phone: (userData?.phone??""), new: true }), [userData])
    const { values, setFieldValue, validateValues } = useUserInformationFormDataController({ defaultValues })
    const { name, lastName, email, phone, password } = values
    const { updateUserInformation, updateUserInformationIsLoading } = useUpdateUserInformation()

    const handleSubmit = async () => {
        const { type } = validateValues(values)
        if(type === "errors") return
        await updateUserInformation(values)
    }

    return(
        <>
            <Form onSubmit={handleSubmit} >
                <Grid container spacing={1} >
                    <Grid xs={6} >
                        <TextField
                            label="Nombre"
                            fullWidth
                            value={name}
                            onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <TextField
                            label="Apellido"
                            fullWidth
                            value={lastName}
                            onChange={({ target }) => setFieldValue("lastName", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <TextField
                            label="Correo electrónico"
                            fullWidth
                            type="email"
                            value={email}
                            onChange={({ target }) => setFieldValue("email", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <PatternFormat
                            format="+57 ### ### ## ##"
                            label="Numero de teléfono"
                            fullWidth
                            value={phone}
                            onChange={(val) => setFieldValue("phone", (val??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <TextField
                            label="Nueva contraseña"
                            fullWidth
                            value={password}
                            onChange={({ target }) => setFieldValue("password", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <Box display={'flex'} justifyContent={'end'} >
                            <Button type="submit" loading={updateUserInformationIsLoading} >Guardar</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Form>
        </>
    )
}

export default UserInformation