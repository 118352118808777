import { store } from '@util/store'
import { AdapterDayjs, CssBaseline, LocalizationProvider, ThemeProvider } from "@component/UIComponents"
import theme from '@theme/main'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Provider } from "react-redux";

const AppProviders = ({ children }) => {
    console.log('theme', theme)
    return(
        <>
            <Provider store={store} >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={undefined} >
                    <ThemeProvider theme={theme} >
                        <CssBaseline />
                        {children}
                    </ThemeProvider>
                </LocalizationProvider>
            </Provider>
        </>
    )
}

export default AppProviders