import { SwitchDescription } from "@component/Switch"
import { useReportSetting } from "../hooks/useReportSetting"
import { useGetBusinessAdjustmentsQuery } from "@query/BusinessSetting"
import { useMemo } from "react"

const ShowOrdersInSalesReport = () => {
    const { onUpdateViewOrderInSalesReport, isLoadingViewOrderInSalesReport } = useReportSetting()
    const { data, isLoading } = useGetBusinessAdjustmentsQuery({ Adjustment: "reports" })
    const setting = useMemo(() => (data?.payload??{}), [data])
    const { showOrderInSalesReport=false } = (setting??{})
    return (
        <SwitchDescription
            label="Ver pedidos en reporte de ventas"
            description={<>
                Los pendidos y las ventas se mostraran en la tabla de <b>reporte de ventas</b>.
            </>}
            onChangeValue={(checked) => onUpdateViewOrderInSalesReport({ showOrderInSalesReport: checked })}
            loading={(isLoadingViewOrderInSalesReport || isLoading)}
            checked={showOrderInSalesReport}
        />
    )
}

export default ShowOrdersInSalesReport