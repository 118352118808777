import SuperDataGrid from "@component/SuperDataGrid"
import { useArchiveCustomer, useCustomersFilters, useGetCustomers } from "../hooks/useCustomers"
import { useMemo, useState } from "react"
import { usePanel } from "@hook/usePanel"
import ActionsList from "@component/ActionsList"
import { useCustomerListMenu } from "./hooks/useCustomerList"
import { Grid, Box } from "@component/UIComponents"
import Filters from "./components/Filters"
import CustomerForm from "../CustomerForm"
import { currencyFormat } from "@util/currencyFormat"
import GenerateCustomerAdvance from "../components/GenerateCustomerAdvance"
import ReturnAdvancePaymentFromCustomerToBox from "../components/ReturnAdvancePaymentFromCustomerToBox"
import ListRendering from "@component/ListRendering"

const CustomerList = () => {
    const [selection, setSelection] = useState({ openRegisterForm: false, customerToEdit: null, customerToAdvance: null, customerToReturnAdvance: null })
    const { archived, showOnly, groupBy } = useCustomersFilters()
    const { isFetching, groupCustomers, listRenderingOptions } = useGetCustomers({ archived, groupBy, showOnly })
    const { getDateFormats } = usePanel()
    const { primaryMenu, secondaryMenu } = useCustomerListMenu()
    const { archiveCustomer } = useArchiveCustomer()

    const Buttons = (props) => <ActionsList
        options={primaryMenu(props, {
            onEdit: () => setSelection((prevState) => ({...prevState, openRegisterForm: true, customerToEdit: props})),
            onArchived: () => archiveCustomer({ customerId: (props?._id??null), archived: !(props?.isArchived??false) }),
            onAdvance: () => setSelection((prevState) => ({...prevState, customerToAdvance: props})),
            onReturnAdvance: () => setSelection((prevState) => ({...prevState, customerToReturnAdvance: props})),
        })}
        variant="mobileMenu"
    />

    const BoxPortal = () =>(
        <Box id='portal'/>
    )
    const columns = useMemo(() => [
        {
            minWidth: 250,
            maxWidth: 250,
            field: "name",
            headerName: "Cliente",
            renderCell: ({ row }) => {
                return(
                    <>
                        <Buttons {...row} />
                        {row?.name??""}
                    </>
                )
            }
        },
        {minWidth: 140, maxWidth: 140, field: "balance", headerName: "Anticipo", renderCell: ({row}) => `${currencyFormat((row?.balance??0))}`},
        {flex: 1, minWidth: 250, field: "address", headerName: "Dirección"},
        {maxWidth: 200, minWidth: 200, field: "customerType", headerName: "Tipo de cliente", renderCell: ({row}) => `${(row?.customerType?.name??"")}`},
        {maxWidth: 200, minWidth: 200, field: "businessType", headerName: "Tipo de negocio", renderCell: ({row}) => `${(row?.businessType?.name??"")}`},
        {maxWidth: 200, minWidth: 200, field: "zone", headerName: "Zona", renderCell: ({row}) => `${(row?.zone?.name??"")}`},
        {minWidth: 250, maxWidth: 250, field: "businessName", headerName: "Razón social"},
        {minWidth: 150, maxWidth: 150, field: "identificationNumber", headerName: "N° de documento"},
        {minWidth: 100, maxWidth: 100, field: "phoneNumber", headerName: "Teléfono"},
        {minWidth: 160, maxWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({ row }) => `${getDateFormats(row?.createdAt??"").ddmmyyyyhhmm}`},
        {minWidth: 50, maxWidth: 50, field: " ", headerName: " ", renderCell: ({ row }) => <Buttons {...row} /> },
    ], [getDateFormats])

    return(
        <>
            <Grid container >
                <Grid xs={12} 
                container
                direction='row'
                justifyContent={'start'}
                alignItems={'center'}
                >
                    <ActionsList
                        filterButton={{
                            show: true,
                            component: () => <Filters />
                        }}
                        options={secondaryMenu({
                            onCreate: () => setSelection((prevState) => ({...prevState, openRegisterForm: true}))
                        })}
                    />
                    <BoxPortal/>
                </Grid>
                
                <Grid xs={12} >
                    <ListRendering
                        elements={listRenderingOptions}
                        component={(list, index) => {
                            return(
                                <SuperDataGrid
                                    title={Boolean(groupBy) ? (groupCustomers[index]?.groupName??"") : undefined}
                                    portalId={'portal'}
                                    downloadData
                                    fixedCells
                                    loading={isFetching}
                                    columns={columns}
                                    rows={list}
                                    filterBy={({ name="", identificationNumber="" }) => `${name} ${identificationNumber}`}
                                />
                            )
                        }}

                    />
                </Grid>
            </Grid>
            <CustomerForm open={Boolean(selection?.openRegisterForm)} defaultValues={(selection?.customerToEdit??null)} onClose={ () => setSelection((prevState) => ({...prevState, openRegisterForm: false, customerToEdit: null})) } />
            <GenerateCustomerAdvance customer={(selection?.customerToAdvance??null)} onClose={() => setSelection((prevState) => ({...prevState, customerToAdvance: null}))} />
            <ReturnAdvancePaymentFromCustomerToBox customer={(selection?.customerToReturnAdvance??null)} onClose={() => setSelection((prevState) => ({...prevState, customerToReturnAdvance: null}))} />
        </>
    )
}

export default CustomerList