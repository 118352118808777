import { CheckIconRounded, RemoveRedEyeIconRounded, ShoppingCartRoundedIcon } from "@component/UIComponents"
import { preventAction } from "@util/helpers"
import { useCallback } from "react"
import { useReceiveSaleFromAnotherBusiness } from "./useReceiveSaleFromAnotherBusiness"

export const useReceiptOfSalesInvoicesOptions = () => {
    const { receiveSaleFromAnotherBusiness } = useReceiveSaleFromAnotherBusiness()

    const pendingInvoiceOptionsMenu = useCallback((props={}) => {
        const { onPreviewSaleInvoice=()=>null, invoiceReceptionId=null } = props

        let r = [
            { title: "Ver factura", onClick: () => onPreviewSaleInvoice(), icon: <RemoveRedEyeIconRounded /> },
            {
                title: "Recibir factura",
                onClick: () => preventAction({
                    text: "¿Desea recibir la factura?, al recibir la factura se habilitaran mas opciones de uso.",
                    onSubmit: () => receiveSaleFromAnotherBusiness({ invoiceReceptionId })
                }),
                icon: <CheckIconRounded />
            },
        ].map((n, index) => ({...n, _id: index}))

        return r
    }, [receiveSaleFromAnotherBusiness])

    const optionsReceivedInvoice = useCallback((props={}) => {
        const { onPreviewSaleInvoice=()=>null, onEnterInvoicePurchase=()=>null } = props

        let r = [
            { title: "Ver factura", onClick: () => onPreviewSaleInvoice(), icon: <RemoveRedEyeIconRounded /> },
            {
                title: "Ingresar como compra",
                onClick: () => preventAction({
                    text: "¿Desea ingresa esta factura como una compra?",
                    onSubmit: () => onEnterInvoicePurchase()
                }),
                icon: <ShoppingCartRoundedIcon />
            },
        ].map((n, index) => ({...n, _id: index}))

        return r
    }, [])

    return {
        pendingInvoiceOptionsMenu,
        optionsReceivedInvoice
    }
}