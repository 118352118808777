import { Grid } from "@component/UIComponents"
import BoxesTemplate from "@feature/MasterBox/components/BoxesTemplate"
import { useGetMasterBox } from "./hooks/useMasterBox"
import dayjs from "dayjs"
import TabAppContext from "@component/TabAppContext"
import ComponentTitle from "@component/ComponentTitle"

const Boxes = () => {
    const { resp } = useGetMasterBox({ startDate: dayjs().format("MM-DD-YYYY") })

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Caja Principal" />
            <Grid container >
                <Grid xs={12} >
                    <TabAppContext
                        tab={"minorBox"}
                        tabs={[
                            {
                                label: "Caja principal",
                                value: "minorBox",
                                component: <>
                                    <BoxesTemplate
                                        variant="masterBox"
                                        boxName={"minorBox"}
                                        refId={(resp?._id??null)}
                                        boxBalance={(resp?.amount??0)}
                                    />
                                </>
                            }
                        ]}
                    />
                    
                </Grid>
            </Grid>
        </>
    )
}

export default Boxes