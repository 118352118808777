import { Box, Dialog, Typography } from '@component/UIComponents'
import { useGetProductPurchaseHistory } from "../Products/hooks/useProducts"
import { currencyFormat } from "@util/currencyFormat"
import ActionsList from '@component/ActionsList'
import { useProductsPurchaseHistoryMenu } from './hooks/useProductsSaleHistory'
import { useState } from 'react'
import Invoice from '@feature/PurchaseReport/Purchases/components/Invoice'
import SuperDataGrid from '@component/SuperDataGrid'
import { usePanel } from '@hook/usePanel'
import dayjs from 'dayjs'
import { useCallback } from 'react'

const ProductPurchaseHistory = ({ productId, open=false, onClose=() => null }) => {
    const [date, setDate] = useState({
        startDate: dayjs().startOf("week"),
        endDate: dayjs().endOf("day"),
    })
    const { getDateFormats } = usePanel()
    const [selection, setSelection] = useState({ viewInvoice: null })
    const { viewInvoice } = selection
    const { history, isFetching } = useGetProductPurchaseHistory({ ref: productId, ...date, })
    const { primaryMenu } = useProductsPurchaseHistoryMenu()
    const Buttons = (props={}) => <ActionsList variant='iconButton' options={primaryMenu(props, {
        onViewInvoice: (saleId) => setSelection((prevState) => ({...prevState, viewInvoice: saleId}))
    })} />
    const columns = [
        {minWidth: 140, maxWidth: 140, field: "_id", headerName: "Fecha", renderCell: ({ row }) => getDateFormats(row?.createdAt??undefined).ddmmyyyyhhmm},
        {flex: 1, minWidth: 200, field: "name", headerName: "Producto"},
        {flex: 1, minWidth: 150, field: "provider", headerName: "Proveedor", renderCell: ({row}) => `${(row?.provider?.name??"")}`},
        {minWidth: 60, maxWidth: 60, field: "quantity", headerName: "Cant.", renderCell: ({ row }) => `x${(row?.quantity??0)}`},
        {minWidth: 120, maxWidth: 120, field: "purchaseCost", headerName: "Costo", renderCell: ({ row }) => ` ${currencyFormat((row?.purchaseCost??0))} `},
        {minWidth: 60, maxWidth: 60, field: "consecutive", headerName: "#C", renderCell: ({ row }) => `${(row?.consecutive??0)}`},
        {minWidth: 50, maxWidth: 50, field: " ", headerName: " ", renderCell: ({row}) => <Buttons {...row} />},
    ]

    const handleChangeDateRange = (fields=[], values=[]) => {
        if( fields.length === 2 ){
            setDate((prevState) => ({...prevState,
                startDate: dayjs((values[0]??"")),
                endDate: dayjs((values[1]??"")),
            }))
            return
        }
        if( fields.length === 1 ){
            let field = (fields[0] === "sd") ? "startDate" : (fields[0] === "ed") ? "endDate" : undefined
            setDate((prevState) => ({...prevState,
                [field]: dayjs((values[0]??""))
            }))
            return
        }
    }
    const quickList = useCallback((list) => {
        let currentHistory = [...list]
        let quantityProductPurchased = currentHistory.reduce((acc, val) => ((val?.quantity??0)+acc),0)
        let totalProductPurchase = currentHistory.reduce((acc, val) => (((val?.purchaseCost??0)*(val?.quantity??0))+acc),0)

        return [
            {primary: `x${quantityProductPurchased}`, secondary: "Cantidad de producto comprado"},
            {primary: currencyFormat((totalProductPurchase)), secondary: "Total compra del producto"},
        ]
    }, [])
    return(
        <>
            <Dialog maxWidth="md" PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Box px={2} py={1}  >
                    <Typography fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} >Historial de compra del producto</Typography>
                </Box>
                <SuperDataGrid
                    onQuickList={quickList}
                    sd={date.startDate}
                    ed={date.endDate}
                    onDateRangeChange={handleChangeDateRange}
                    dateRange
                    loading={isFetching}
                    columns={columns}
                    rows={history}
                    filterBy={(param) => `${(param?.name??"")} ${(param?.consecutive??"")} ${(param?.provider?.name??"")}`}
                />
            </Dialog>
            <Invoice open={Boolean(viewInvoice)} purchaseId={viewInvoice} onClose={() => setSelection((prevState) => ({...prevState, viewInvoice: null}))} />
        </>
    )
}

export default ProductPurchaseHistory