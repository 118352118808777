import { getTheTimeOfDay } from "@util/date"
import dayjs from "dayjs"
import { useCallback } from "react"
import { useMemo } from "react"

export const useStatistics = ({ sales=[] }) => {
    const currentSales = useMemo(() => ([...sales]), [sales])
    const hoursOfTheDay = useCallback(() => getTheTimeOfDay(), [])
    const hoursWithTheHighestSales = useMemo(() => {
        const salesWithTimeFormat = currentSales.map((n) => ({
            ...n,
            hour: `${dayjs(n?.createdAt??"").format("HH")}:00`,
        }))
        return salesWithTimeFormat
    }, [currentSales])

    const salesGroupedByHours = useMemo(() => {
        let results = []
        for( let sale of hoursWithTheHighestSales ){
            let groupIndex = results.findIndex((n) => (n?.hour??0) === (sale?.hour??"") )
            if( groupIndex >= 0 ){
                results[groupIndex].total += (sale?.total??0)
                results[groupIndex].totalProfitCurrency += (sale?.totalProfitCurrency??0)
                results[groupIndex].results.push(sale)
            } else {
                results.push({
                    hour: (sale?.hour??""),
                    total: (sale?.total??0),
                    totalProfitCurrency: (sale?.totalProfitCurrency??0),
                    results: [sale],
                })
            }
        }
        return results
    }, [hoursWithTheHighestSales])

    return {
        hoursOfTheDay,
        salesGroupedByHours,
    }
}