import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@component/UIComponents'
import Form from './Form'
import { useRef, useState } from 'react'

const DetailsWindow = ({ title="", validateDetail=true, onSubmit=()=>null, onClose=()=>null, open=false }) => {
    const [detail, setDetail] = useState("")
    const buttonRef = useRef(null)

    const handleSubmit = () => {
        onClose()
        onSubmit(detail)
    }

    const handleKeyDown = (event) => {
        const { code="" } = event
        if( code === "Tab" ){
            event.preventDefault()
            event.stopPropagation()
            if( Boolean(buttonRef.current) ){
                buttonRef.current.focus()
            }
        }
    }
    return(
        <>
            <Dialog onKeyDown={handleKeyDown} maxWidth="xs" fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Typography mb={2} fontWeight={'bold'} fontSize={'.800rem'} textTransform={'uppercase'} >{title}</Typography>
                        <Grid container >
                            <Grid xs={12} >
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    label="Detalles"
                                    value={detail}
                                    onChange={({target}) => setDetail((target?.value??""))}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button ref={buttonRef} type='submit' disabled={((detail.trim() === "") && (validateDetail === true))} >
                            Continuar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default DetailsWindow