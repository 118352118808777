import { Box, Card, CardContent, Stack }  from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'

const TotalizeReport = ({ title="TOTAL", value=0 }) => {
    return(
        <Box maxWidth={300} >
            <Card variant='outlined' >
                <CardContent sx={{
                    padding: ({ spacing }) => `${spacing(1.5)} !important`,
                    lineHeight: 1.2,
                    fontWeight: 'bold',
                    WebkitTextStroke: .5,
                }} >
                    <Stack>
                        <Box>{title}</Box>
                        <Box color="primary.main" fontSize={'1.2rem'} > {currencyFormat(value)} </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}

export default TotalizeReport