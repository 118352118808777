import { Box } from '@component/UIComponents'
import { currencyFormat } from "@util/currencyFormat"
import { useCallback } from "react"

export const useMassiveProductEditionDataGridController = ({ list=[] }) => {

    const columns = useCallback(({ onCellClick=()=>null }) => {
        let r = []

        const handleClickCell = (field="", value=null) => onCellClick(field, value)

        r = [
            {
                minWidth: 250,
                field: "name",
                headerName: "Nombre",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("name", row)} >
                            {(row?.name??"")}
                        </Box>
                    )
                }
            },
            {
                maxWidth: 200,
                minWidth: 200,
                field: "category",
                headerName: "Categoría",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("category", row)} >
                            {(row?.category?.name??"N/A")}
                        </Box>
                    )
                }
            },
            {
                maxWidth: 150,
                minWidth: 150,
                field: "packaging",
                headerName: "Embalaje",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("packaging", row)} >
                            {(row?.packaging?.name??"N/A")}
                        </Box>
                    )
                }
            },
            {
                maxWidth: 200,
                minWidth: 200,
                field: "provider",
                headerName: "Proveedor",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("provider", row)} >
                            {(row?.provider?.name??"N/A")}
                        </Box>
                    )
                }
            },
            {
                maxWidth: 200,
                minWidth: 200,
                field: "productParent",
                headerName: "Producto que lo compone",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("productParent", row)} >
                            {(row?.productParent?.name??"N/A")}
                        </Box>
                    )
                }
            },
            {
                maxWidth: 120,
                minWidth: 120,
                field: "cost",
                headerName: "Costo",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("cost", row)} >
                            {currencyFormat((row?.cost??0))}
                        </Box>
                    )
                }
            },
            ...(Array.from({ length: 3 }, (_, arrIndex) => (arrIndex+1)).map((price, index) => {
                return {
                    maxWidth: 120,
                    minWidth: 120,
                    field: `price${price}`,
                    headerName: `Precio ${price}`,
                    renderCell: ({row}) => {
                        return(
                            <Box component={'span'} onClick={() => handleClickCell(`prices`, row)} >
                                {currencyFormat((row?.prices?.[index]?.sellPrice??0))}
                            </Box>
                        )
                    }
                }
            })),
            {
                maxWidth: 100,
                minWidth: 100,
                field: "stock",
                headerName: "Stock",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("stock", row)} >
                            {(currencyFormat((row?.stock??0), {applyCurrencyFormat: false}))}
                        </Box>
                    )
                }
            },
            {
                maxWidth: 120,
                minWidth: 120,
                field: "skuCode",
                headerName: "SKU",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("skuCode", row)} >
                            {(row?.skuCode??"N/A")}
                        </Box>
                    )
                }
            },
            {
                maxWidth: 120,
                minWidth: 120,
                field: "lowStockAlert",
                headerName: "Alerta bajo stock",
                renderCell: ({row}) => {
                    return(
                        <Box component={'span'} onClick={() => handleClickCell("lowStockAlert", row)} >
                            {(row?.lowStockAlert??0)}
                        </Box>
                    )
                }
            },
        ]
        return r
    }, [])

    const rows = useCallback(() => {
        let currentList = [...list]
        return currentList
    }, [list])

    return {
        columns,
        rows
    }
}