import { ArrowBackIosRoundedIcon, ArrowForwardIosRoundedIcon, Box, Dialog, DialogContent, Grid, IconButton } from '@component/UIComponents'
import { useMemo } from 'react'
import { useCreditNoteController } from '../PartialCreditNote/hooks/useCreditNoteController'
import Invoice from '../..'
import ActionsList from '@component/ActionsList'
import { useInvoiceMenu } from '../../hooks/useInvoice'
import { useRef } from 'react'
import { useState } from 'react'

const CreditNotes = ({ invoice=null, creditNotes=[], open=false, onClose=()=>null }) => {
    const [mergeInvoice, setMergeInvoice] = useState(false)
    const { mergeInvoiceWithCreditNote } = useCreditNoteController()
    const invoiceRef = useRef(null)
    const { invoiceMenu } = useInvoiceMenu({ invoice, invoiceRef })
    
    const currentInvoice = useMemo(() => {
        const creditNote = creditNotes[0]
        let { invoiceCreditNote={}, newInvoice={} } = mergeInvoiceWithCreditNote({ creditNoteProducts: (creditNote?.products??[]), invoice })
        const inv = mergeInvoice ? newInvoice : invoiceCreditNote
        inv.createdAt = (creditNote?.createdAt??"")
        inv.seller = (creditNote?.user??{})
        return (inv??{})
    }, [invoice, creditNotes, mergeInvoiceWithCreditNote, mergeInvoice])

    return(
        <>
            <Dialog fullWidth open={open} onClose={onClose} transitionDuration={0} PaperProps={{ elevation: 0 }} >
                <DialogContent>
                    <Grid container >
                        <Grid xs={12} >
                            <ActionsList
                                options={invoiceMenu({...currentInvoice, mergeInvoice}, {
                                    includingMenus: ["printInvoice", "mergeInvoice"],
                                    onMergeInvoice: () => setMergeInvoice((prevState) => !prevState)
                                } )}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <Box display={'flex'} alignItems={'center'} >
                                <Box>
                                    <IconButton >
                                        <ArrowBackIosRoundedIcon />
                                    </IconButton>
                                </Box>
                                <Invoice
                                    variant={mergeInvoice ? undefined : "creditNote"}
                                    ref={invoiceRef}
                                    {...currentInvoice}
                                />
                                <Box>
                                    <IconButton >
                                        <ArrowForwardIosRoundedIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreditNotes