import SuperDataGrid from "@component/SuperDataGrid"
import { Avatar, Box, Button, CameraAltIconRounded, EditNoteRoundedIcon, Grid, Portal, Stack, Typography } from "@component/UIComponents"
import { useGetProducts, useMassLoadingOfProducts, useProductMenu, useXLSXProducts } from "./hooks/useProducts"
import { useMemo, useRef, useState } from "react"
import { currencyFormat } from "@util/currencyFormat"
import ActionsList from "@component/ActionsList"
import ProductForm from "./ProductForm"
import ProductSalesHistory from "../ProductSalesHistory"
import ProductPurchaseHistory from "../ProductPurchaseHistory"
import { calculateTotalInventory, generateQuickSummary } from "./utils/helpersToProducts"
import Filters from "./components/Filters"
import ListRendering from "@component/ListRendering"
import DownloadProductUploadTemplate from "./components/DownloadProductUploadTemplate"
import { preventAction } from "@util/helpers"
import XLSXFileAlert from "./components/XLSXFileAlert"
import ComponentTitle from "@component/ComponentTitle"
import { useProductsController } from "./hooks/useProductsController"
import ShowColumns from "./components/ShowColumns"
import MassiveProductEdition from "./MassiveProductEdition"
import { useArchiveProduct } from "./hooks/useArchiveProduct"
import MassiveChangeHistory from "./components/MassiveChangeHistory"

const Products = () => {
    const [showBulkEdit, setShowBulkEdit] = useState(false)
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({})
    const { filters, handlerSetFilters, activeFilters } = useProductsController()
    const [downloadExcelTemplate, setDownloadExcelTemplate] = useState(false)
    const [productPurchaseHistory, setProductPurchaseHistory] = useState({ open: false, productId: null })
    const [productSaleHistory, setProductSaleHistory] = useState({ open: false, productId: null })
    const [productForm, setProductForm] = useState({ open: false, productId: null })
    const { results=[], productList } = useGetProducts({ ...activeFilters, variant: "inventory" })
    const { primaryMenu, secondaryMenu } = useProductMenu()
    const { downloadData, loadData } = useXLSXProducts()
    const { massLoadingOfProducts } = useMassLoadingOfProducts()
    const inputFileRef = useRef()
    const fileUploadHandler = (files) => loadData(files, inputFileRef, { onManageData: (data) => massLoadingOfProducts({ products: data }) })
    const { archiveProduct } = useArchiveProduct()

    const Buttons = (props={}) => <ActionsList
        title={<>
            <Box component={'span'} display={'flex'} alignItems={'center'} gap={1} >
                <Avatar src={props?.images[0]??undefined} sx={Boolean(props?.images[0]) ? { width: 60, height: 60 } : {}} >
                    <CameraAltIconRounded />
                </Avatar>
                {`${(props?.name??"")}`}
            </Box>
        </>}
        variant="mobileMenu"
        options={secondaryMenu(props, {
            onEdit: (productId) => setProductForm(prevState => ({...prevState, open: true, productId})),
            onSaleHistory: (productId) => setProductSaleHistory({open: true, productId}),
            onPurchaseHistory: (productId) => setProductPurchaseHistory({open: true, productId}),
            onArchived: (productId) => archiveProduct({ productId })
        })}
    />

    const columns = useMemo(() => {
        let r = [
            { flex: 1, minWidth: 420, field: "name", headerName: "Producto", renderCell: ({row}) => {
                return(
                    <>
                        <Box display={'flex'} alignItems={'center'} >
                            <Box>
                                <Buttons {...row} />
                            </Box>
                            <Typography fontSize={'.875rem'} >
                                {row?.name??""}
                            </Typography>
                        </Box>
                    </>
                )
            }},
            { maxWidth: 70, minWidth: 70, field: "stock", headerName: "Stock", renderCell: ({ row }) => `${currencyFormat((row?.stock??0), {applyCurrencyFormat: false})}`},
            { maxWidth: 100, minWidth: 100, field: "cost", headerName: "Costo", renderCell: ({ row }) => `${currencyFormat((row?.cost??0))}` },
            { maxWidth: 120, minWidth: 120, field: "totalStock", headerName: "Total Stock", renderCell: ({ row }) => `${currencyFormat(calculateTotalInventory(row))}`, valueGetter: ({ row }) => calculateTotalInventory(row)},
            {
                maxWidth: 50,
                minWidth: 50,
                field: "lowInventory",
                headerName: "I.B",
                renderCell: ({row}) => {
                    return(
                        <>
                            { Boolean((row?.lowStockAlert??0)) ?
                                <>
                                    { (row?.stock??0) <= (row?.lowStockAlert??0) ?
                                        <Typography sx={{ color: (theme) => theme.palette.warning.main }} fontWeight={'bold'} textAlign={'center'} > ESCASO </Typography>
                                        :
                                        <Typography sx={{ color: (theme) => theme.palette.success.main }} fontWeight={'bold'} textAlign={'center'} > DISPONIBLE </Typography>
                                    }
                                </>
                                :
                                <></>
                            }
                        </>
                    )
                },
                valueGetter: ({row}) => {
                    let val = Boolean((row?.lowStockAlert??0)) ?
                        (((row?.stock??0) <= (row?.lowStockAlert??0)) ?
                        "ESCASO"
                        :
                        "DISPONIBLE")
                    :
                    ""
                    return val
                },
            },
            { flex: 1, minWidth: 140, field: "category", headerName: "Categoría", renderCell: ({ row }) => `${(row?.category?.name??"")}` },
            { maxWidth: 100, minWidth: 100, field: "consecutive", headerName: "Consecutivo", renderCell: ({row}) => `N°${(row?.consecutive??0)}` },
            { flex: 1, minWidth: 250, field: "provider", headerName: "Proveedor", renderCell: ({ row }) => (row?.provider?.name??"") },
            { maxWidth: 110, minWidth: 110, field: "packaging", headerName: "Embalaje", renderCell: ({ row }) => Boolean(row?.packaging) ? `${(row?.packaging?.name??"")} - x${(row?.packaging?.quantity??0)}` : "" },
            { maxWidth: 100, minWidth: 100, align: "center", field: "lowStockAlert", headerName: "Alerta stock  bajo", renderCell: ({row}) => `${(row?.lowStockAlert??0)}` },
            { flex: 1, minWidth: 250, field: "productParent", headerName: "Producto padre", renderCell: ({row}) => Boolean(row?.productParent) ? `${row?.productParent?.name??""}` : "N/A" },
            { maxWidth: 80, minWidth: 80, field: "activePOS", headerName: "Mostrar en punto de venta", align: "center", renderCell: ({row}) => Boolean(row?.activePOS) ? "SI" : "NO"},
            { maxWidth: 80, minWidth: 80, field: "activePurchase", headerName: "Mostrar en compras", align: "center", renderCell: ({row}) => Boolean(row?.activePurchase) ? "SI" : "NO"},
            { maxWidth: 90, minWidth: 90, field: "tax", headerName: "Impuesto", renderCell: ({row}) => Boolean(row?.tax) ? `${row?.tax?.name??""} ${row?.tax?.tax??""}%` : "" },
            { maxWidth: 120, minWidth: 120, align: "center", field: "skuCode", headerName: "Código SKU" },
        ]
        const IBIndex = r.findIndex((n) => (n?.field??"") === "lowInventory")

        for( let i = 1; i <= 3; i++ ){
            if( IBIndex >= 0 ){
                let newRow = [
                    {
                        maxWidth: 150,
                        minWidth: 150,
                        field: `sellPrice${(i??0)}`,
                        headerName: `Precio de venta ${(i??0)}`,
                        renderCell: ({ row }) => {
                            const prices = (row?.prices??[])
                            const currentPrice = prices[i-1]
                            const currentSellPrice = (currentPrice?.sellPrice??0)
                            const currentPercentageProfit = (currentPrice?.percentageProfit??0)
                            return (
                                <Grid
                                container
                                direction="row"
                                gap={1}
                                >
                                    <Typography
                                    variant="body2"
                                    >
                                    {currencyFormat(currentSellPrice)} -
                                    </Typography>
                                    <Typography
                                    variant="body2"
                                    sx={{ color: '#3b41ff'}}
                                    >
                                    {currentPercentageProfit}%
                                    </Typography>
                                </Grid>
                            )
                        }
                    },
                ]
                const insertIndex = IBIndex + (i);
                r.splice(insertIndex, 0, ...newRow,)
            }
        }
        return r
    }, [])

    const columns2 = useMemo(() => {
        let r = [
            { flex: 1, minWidth: 300, field: "name", headerName: "Producto", renderCell: ({row}) => {
                return(
                    <>
                        <Box display={'flex'} gap={1} alignItems={'center'} >
                            <Typography fontSize={'.875rem'} >
                                {row?.name??""}
                            </Typography>
                        </Box>
                    </>
                )
            }},
            { maxWidth: 150, minWidth: 150, field: "packaging", headerName: "Embalaje", renderCell: ({ row }) => Boolean(row?.packaging) ? `${(row?.packaging?.name??"")} - x${(row?.packaging?.quantity??0)}` : "" },
            { maxWidth: 130, minWidth: 130, field: "stockPackaging", headerName: "Stock por embalaje", renderCell: ({ row }) => (row?.compound === true && Boolean(row?.productParent?._id)) ? (row?.stock??0) : ((row?.stock??0)/(row?.packaging?.quantity??0)) },
            { maxWidth: 130, minWidth: 130, field: "stock", headerName: "Stock unitario", renderCell: ({ row }) => (row?.compound === true && Boolean(row?.productParent?._id)) ? ((row?.stock??0)*(row?.packaging?.quantity??0)) : (row?.stock??0) },
            { maxWidth: 150, minWidth: 150, field: "cost", headerName: "Costo unitario", renderCell: ({row}) => currencyFormat((row?.cost??0))},
            { maxWidth: 150, minWidth: 150, field: "costStock", headerName: "Costo por embalaje", renderCell: ({row}) => (row?.compound === true && Boolean(row?.productParent?._id)) ? currencyFormat((row?.cost??0)) : currencyFormat(((row?.cost??0)*(row?.packaging?.quantity??0))) },
            { maxWidth: 180, minWidth: 180, field: "totalStockCost", headerName: "Costo total en inventario", renderCell: ({row}) => (row?.compound === true && Boolean(row?.productParent?._id)) ? currencyFormat(((row?.cost??0)*(row?.stock??0))) : currencyFormat(((row?.cost??0)*(row?.stock??0))) },
        ]
        return r
    }, [])

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Productos" />
            <input style={{ display: "none" }} ref={inputFileRef} type="file" accept=".xlsx, .xls" onChange={({target}) => fileUploadHandler([...target.files]) } />
            <Grid container >
                <Grid xs={12} >
                    <Box className="children-inline" >
                        <ActionsList
                            componentProps={{
                                filter: {
                                    active: true,
                                    props: {
                                        component: () => <Filters filters={{...filters}} setFiltersData={handlerSetFilters} />,
                                        filters,
                                    }
                                },
                                portal: { active: true }
                            }}
                            options={primaryMenu(null, {
                                onCreateProduct: () => setProductForm(prevState => ({...prevState, open: true})),
                                onDownloadXLSX: () => preventAction({
                                    component: <XLSXFileAlert />,
                                    onSubmit: () => setDownloadExcelTemplate(true),
                                    confirmButtonText: "Siguiente",
                                    cancelButtonText: "Cancelar",
                                }),
                                onLoadXLSX: () => preventAction({
                                    component: <XLSXFileAlert />,
                                    onSubmit: () => Boolean(inputFileRef.current) ? inputFileRef.current.click() : null,
                                    confirmButtonText: "Siguiente",
                                    cancelButtonText: "Cancelar",
                                }),
                            })}
                        />
                        <Box display={'flex'} alignItems={'center'} >
                            <MassiveChangeHistory />
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12} >
                    { !showBulkEdit &&
                        <Portal container={document.getElementById("portalAnchor")} >
                            <Stack direction={'row'} width={'100%'} >
                                <ShowColumns onChange={(columnVisibility) => setColumnVisibilityModel(columnVisibility)} />
                            </Stack>
                        </Portal>
                    }
                    <Portal container={document.getElementById("actionList-portal")} >
                        <Button startIcon={<EditNoteRoundedIcon />} onClick={() => setShowBulkEdit(prevState => !prevState)} >
                            Edición masiva
                        </Button>
                    </Portal>
                    { !showBulkEdit &&
                        <ListRendering
                            elements={[...productList].map((n) => (n?.results??[]))}
                            component={(list, index) => {
                                return(
                                    <SuperDataGrid
                                        sortOptions = {{field: 'name', sort: 'asc'}}
                                        autoHeight={([...productList].map((n) => (n?.results??[]))).length >= 2 ? true : false}
                                        portalId="actionList-portal"
                                        onDownloadBook={downloadData}
                                        downloadData
                                        title={(productList[index]?.groupName??undefined)}
                                        fixedCells
                                        columnBuffer={((results?.length??0)*2)}
                                        columns={((filters?.listType??"") === "inventory") ? columns2 : columns}
                                        rows={list}
                                        filterBy={(param) => `${(param?.name??"")} ${(param?.consecutive??"")} ${(param?.skuCode??"")}`}
                                        onQuickList={generateQuickSummary}
                                        columnVisibilityModel={columnVisibilityModel}
                                    />
                                )
                            }}
                        />
                    }
                </Grid>
                { showBulkEdit &&
                    <Grid xs={12} >
                        <MassiveProductEdition
                            data={(productList[0]?.results??[])}
                        />
                    </Grid>
                }
            </Grid>
            <ProductForm open={productForm.open} onClose={() => setProductForm(prevState => ({...prevState, open: false, productId: null}))} productId={(productForm?.productId??null)} />
            <ProductSalesHistory
                open={productSaleHistory.open}
                productId={productSaleHistory.productId}
                onClose={() => setProductSaleHistory(prevState => ({...prevState, open: false, productId: null}))}
            />
            <ProductPurchaseHistory
                open={productPurchaseHistory.open}
                productId={productPurchaseHistory.productId}
                onClose={() => setProductPurchaseHistory(prevState => ({...prevState, open: false, productId: null}))}
            />
            <DownloadProductUploadTemplate
                open={downloadExcelTemplate}
                onClose={() => setDownloadExcelTemplate(false)}
            />
        </>
    )
}

export default Products