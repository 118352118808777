import { Box, CircularProgress, ClearIconRounded, Divider, Typography, styled } from '@component/UIComponents'
import { useRemoveCreditFromTheSale } from '@feature/Reports/Invoice/hooks/useRemoveCreditFromTheSale'
import { totalPaymentsToSalesInvoice } from '@feature/Reports/Sales/utils/totalPaymentsToSalesInvoice'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'
import { preventAction } from '@util/helpers'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const PaymentBoxContainer = styled(Box)(() => ({
    '& .removePayment' : {
        display: "none"
    },
    '&:hover': {
        '& .removePayment' : {
            display: "flex"
        },
    }
}))

const ListPaymentsToInvoices = ({ payments=[], variant="saleInvoice", loading=false, children, refId, actionRemovePayments=true }) => {
    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const { removeCreditFromTheSale } = useRemoveCreditFromTheSale()

    const removePayment = useCallback((payload) => {
        if( variant === "saleInvoice" ){
            preventAction({
                text: "¿Desea remover el abono realizado a la venta?",
                onSubmit: () => removeCreditFromTheSale(payload)
            })
        }
    }, [variant, removeCreditFromTheSale])

    const totalPayments = useMemo(() => {
        if( variant === "saleInvoice" ){
            return (totalPaymentsToSalesInvoice(0, payments)?.totalPayments??0)
        }else{
            return 0
        }
    }, [variant, payments])
    if( loading ){
        return(
            <>
                <Box display={'flex'} justifyContent={'center'} my={8} >
                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'} >
                        <CircularProgress />
                        <Typography mt={1} > Cargando información de pagos... </Typography>
                    </Box>
                </Box>
            </>
        )
    }else{
        return(
            <>
                <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} >Pagos: </Typography>
                <Divider sx={{ my: 1 }} />
                {payments.length <= 0 ?
                    <> No se han realizado pagos </>
                    :
                    <>
                        {payments.map((payment, index) => {
                            return(
                                <PaymentBoxContainer mb={1} key={index} display={'flex'} gap={1} height={45} >
                                    {((payment?.type??"") !== "exit" && (payment?.state??"asset") !== "removed" && actionRemovePayments) && 
                                        <Box onClick={() => removePayment({ saleId: refId, paymentId: (payment?._id??"") })} className="removePayment" sx={{ bgcolor: (theme) => theme.palette.error.main, color: "#FFFFFF", cursor: "pointer" }} height={'100%'} display={'flex'} alignItems={'center'} >
                                            <ClearIconRounded />
                                        </Box>
                                    }
                                    <Box width={'100%'} >
                                        <Typography lineHeight={1.5} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                                            {t((payment?.wallet??""))} - {t((payment?.boxName??""))}
                                            <Box component={'span'} sx={{ color: (theme) => ((payment?.type??"entry") === "entry") ? theme.palette.primary.main : theme.palette.error.main }} >
                                                {currencyFormat(payment?.amount)}
                                            </Box>
                                        </Typography>
                                        <Typography lineHeight={1} fontWeight={'bold'} fontSize={'.775rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} > {getDateFormats((payment?.createdAt??"")).ddmmyyyyhhmm} <Box component={'span'} >{payment?.user?.name??""} {payment?.user?.lastName??""}</Box> </Typography>
                                        {(payment?.state??"asset") === "removed" &&
                                            <Typography textTransform={'uppercase'} lineHeight={1} fontWeight={'bold'} fontSize={'.775rem'} sx={{ color: (theme) => theme.palette.warning.main }} > Abono removido </Typography>
                                        }
                                        <Divider sx={{ my: 1 }} />
                                    </Box>
                                </PaymentBoxContainer>
                            )
                        })}
                        <Typography lineHeight={1} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} > Total pagos: <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} >{currencyFormat((totalPayments??0))}</Box> </Typography>
                        {children}
                    </>
                }
            </>
        )
    }
}

export default ListPaymentsToInvoices