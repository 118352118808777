import { Popover } from '@component/UIComponents'

const PopoverWindow = ({ anchorEl=null, content=<></> }) => {
    return(
        <>
            <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} variant="contained" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} transformOrigin={{ vertical: 'bottom', horizontal: 'center', }} >
                {content}
            </Popover>
        </>
    )
}

export default PopoverWindow