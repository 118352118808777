import EmptyList from '@component/EmptyList'
import { ArchiveRoundedIcon, Box, Divider, EditIconRounded, List, ListItem, ListItemIcon, ListItemText, LocalShippingIconRounded, MenuItem, RemoveRedEyeIconRounded, ShoppingCartRoundedIcon, Typography, styled } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'
import { useGetOrdersSuppliers } from '../OrderEntry/hooks/useGetOrdersSuppliers'
import LoadProgress from '@component/LoadProgress'
import { Fragment } from 'react'
import Menu from '@component/Menu'
import { useControllerOrderList } from './hooks/useControllerOrderList'
import OrderInvoice from '../OrderInvoice'
import { useURLParams } from '@hook/useURLParams'
import { rebuildArray } from '@util/helpers'

const ListStyled = styled(List)(({ theme }) => ({
    padding: "0px !important",
    '& .MuiListItem-root': {
        padding: 0,
        '& .MuiListItemIcon-root': {
            minWidth: 40
        },
        '& .MuiListItemText-root': {
            '&  .MuiListItemText-primary': {
                lineHeight: 1,
                fontWeight: "bold",
                fontSize: ".875rem",
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: "underline"
                }
            },
            '&  .MuiListItemText-secondary': {
                fontWeight: "bold",
                fontSize: ".775rem",
                color: theme.palette.grey[500],
                '& .orderTotal': {
                    fontWeight: "bold",
                    color: theme.palette.primary.main
                }
            },
        }
    }
}))

const PurchaseOrderList = () => {
    const { getAllQueries } = useURLParams()
    const validFilters = ["startDate", "endDate", "providers"]
    const allQueries = getAllQueries()
    const filters = rebuildArray(validFilters, allQueries)

    const { orders, isFetchingOrdersSuppliers, totalizeOrders } = useGetOrdersSuppliers(filters)
    const { menuAnchorEl, openMenu, closeMenu, selectedOrder, deselectOrderIdentifier, orderIdentifier, selectOrderIdentifier, editOrderOrder, convertOrderToPurchase } = useControllerOrderList()

    const { getDateFormats } = usePanel()

    if( isFetchingOrdersSuppliers ){
        return(
            <LoadProgress
                text='Cargando pedidos...'
            />
        )
    }else{
        if( orders.length <= 0 ){
            return(
                <>
                    <EmptyList icon={<LocalShippingIconRounded />} text='No hay pedidos para mostrar.' />
                </>
            )
        }else{
            return(
                <>
                    <Typography textAlign={'right'} fontWeight={'bold'} textTransform={'uppercase'} fontSize={'.875rem'} > Total: <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > { currencyFormat(totalizeOrders) } </Box> </Typography>
                    <ListStyled>
                        {orders.map((n, index) => {
                            return(
                                <Fragment key={index} >
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocalShippingIconRounded />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography className='MuiListItemText-primary' onClick={({ currentTarget }) => openMenu(currentTarget, n)} > {(n?.provider?.name??"")} </Typography>}
                                            secondary={<Typography className='MuiListItemText-secondary' >
                                                <Box component={'span'}>
                                                    {getDateFormats((n?.createdAt??undefined)).fullDate} - 
                                                    {getDateFormats((n?.createdAt??undefined)).hhmm}
                                                </Box>
                                                <br />
                                                <Box component={'span'} className='orderTotal' > {currencyFormat((n?.total??0))} </Box>
                                            </Typography>}
                                        />
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            )
                        })}
                        <Menu open={Boolean(menuAnchorEl)} anchorEl={menuAnchorEl} onClose={() => closeMenu()} >
                            <ListItem>
                                <Box>
                                    <Typography lineHeight={1} fontWeight={'bold'} textTransform={'uppercase'} fontSize={'.775rem'} > Pedido #{(selectedOrder?.consecutive??0)} </Typography>
                                    <Typography lineHeight={1} fontWeight={'bold'} textTransform={'uppercase'} fontSize={'.775rem'} > {(selectedOrder?.provider?.name??"")} </Typography>
                                </Box>
                            </ListItem>
                            <MenuItem onClick={() => selectOrderIdentifier()} >
                                <ListItemIcon>
                                    <RemoveRedEyeIconRounded />
                                </ListItemIcon>
                                Ver orden de pedido
                            </MenuItem>
                            <MenuItem disabled={Boolean((selectedOrder?.referenceToPurchase??null))} onClick={() => editOrderOrder()} >
                                <ListItemIcon>
                                    <EditIconRounded />
                                </ListItemIcon>
                                Editar
                            </MenuItem>
                            <MenuItem disabled={Boolean((selectedOrder?.referenceToPurchase??null))} onClick={() => Boolean((selectedOrder?.referenceToPurchase??null)) ? null : convertOrderToPurchase()} >
                                <ListItemIcon>
                                    <ShoppingCartRoundedIcon />
                                </ListItemIcon>
                                {Boolean((selectedOrder?.referenceToPurchase??null)) ? "Ya es una compra" : "Convertir a compra"}
                            </MenuItem>
                            <MenuItem>
                                <ListItemIcon>
                                    <ArchiveRoundedIcon />
                                </ListItemIcon>
                                Archivar
                            </MenuItem>
                        </Menu>
                    </ListStyled>
                    <OrderInvoice open={Boolean(orderIdentifier)} onClose={() => deselectOrderIdentifier()} purchaseOrderId={orderIdentifier} />
                </>
            )
        }
    }
}

export default PurchaseOrderList