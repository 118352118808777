import { ArrowBackIosRoundedIcon, Box, IconButton, List, ListItem, ListItemIcon, ListItemText, styled } from '@component/UIComponents'
import { useNavigate } from 'react-router-dom'
import { routes } from '@util/routes'
const { home } = routes


const ListStyled = styled(List)(({theme}) => ({
    padding: 0,
    '& .MuiListItem-root': {
        padding: theme.spacing(1),
    },
    '& .MuiListItemText-primary': {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase",
        fontSize: ".875rem",
        fontWeight: "bold",
        color: "#FFFFFF"
    },
}))

const Cashier = ({ refUser=null, name="Usuario", role="Sin definir", balance=0 }) => {
    const navigate = useNavigate()
    return(
        <>
            <ListStyled>
                <ListItem>
                    <ListItemIcon>
                        <IconButton onClick={() => navigate(home())} >
                            <ArrowBackIosRoundedIcon sx={{ color: "#FFFFFF" }} />
                        </IconButton>
                    </ListItemIcon>
                    <ListItemText
                        primary={<>
                            <Box>
                                <Box lineHeight={1} > {name} </Box>
                                <Box textTransform={'capitalize'} > {role} </Box>
                            </Box>
                        </>}
                    />
                </ListItem>
            </ListStyled>
        </>
    )
}

export default Cashier