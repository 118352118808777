import { useGetOrdersSuppliersQuery } from "@query/PurchasesOrder"
import { useMemo } from "react"

export const useGetOrdersSuppliers = (filters={}) => {
    const { data, isFetching } = useGetOrdersSuppliersQuery(filters)
    const orders = useMemo(() => (data?.payload??[]), [data])
    const totalizeOrders = orders.reduce((acc, val) => (acc+(val?.total??0)), 0)

    return {
        orders,
        isFetchingOrdersSuppliers: isFetching,
        totalizeOrders
    }
}