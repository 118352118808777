import { Alert, Box, Button, Dialog, DialogContent, Grid, ManageSearchRoundedIcon, Stack } from "@component/UIComponents"
import { useGetCachedInventoryBulkUpdateOperations } from "./hooks/useGetCachedInventoryBulkUpdateOperations"
import dayjs from "dayjs"
import SuperDataGrid from "@component/SuperDataGrid"
import { useCallback, useMemo, useState } from "react"
import { Toast } from "@util/swal"

const MassiveChangeHistory = () => {
    const [showLog, setShowLog] = useState(false)
    const { data } = useGetCachedInventoryBulkUpdateOperations()
    const { user = null, createdAt = null, updateOps = [] } = (data ?? {})

    const originalValues = useMemo(() => updateOps.map(item => item?.[0]), [updateOps])
    const updateValues = useMemo(() => updateOps.map((item, index) => ({ ...item?.[1], _id: (index + 1) })), [updateOps])
    const keys = useMemo(() => {
        const values = updateOps.map((item, index) => Object.keys(({ ...item?.[1] })))
        const largestArray = values.reduce((max, current) => {
            return current.length > max.length ? current : max;
        }, []);
        return largestArray
    }, [updateOps])

    const columns = useMemo(() => {
        let currentKeys = [...(keys??[])]
        let resp = []
        currentKeys.forEach((item) => {
            resp.push({
                field: item,
                minWidth: 200
            })
        })
        return resp
    }, [keys])

    const handleOpenLog = useCallback(() => {
        if( (updateOps??[]).length >= 1 ){
            setShowLog(true)
        }else{
            Toast.fire({
                icon: "warning",
                text: "No se encontraron cambios recientes para mostrar."
            })
        }
    }, [updateOps])

    return (
        <>
            <Button startIcon={<ManageSearchRoundedIcon />} onClick={handleOpenLog} >
                Cambios masivos
            </Button>
            <Dialog maxWidth="lg" fullWidth open={showLog} onClose={() => setShowLog(false)} >
                <DialogContent>
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <Alert severity="info" >
                                Recuerde que este historial tiene un periodo de duración hasta las { dayjs().endOf("day").format("HH:mm A") } del dia de hoy, este historial representa los cambios realizado en la carga del ultimo archivo.
                            </Alert>
                        </Grid>
                        <Grid xs={12} >
                            <Stack lineHeight={1.2} fontWeight={'bold'} textTransform={'uppercase'} >
                                <Box>
                                    Operación realizada por: <Box component={'span'} sx={{ color: ({ palette }) => palette.primary.main  }} > {(user?.name ?? "")} {(user?.lastName ?? "")} </Box>
                                </Box>
                                <Box>
                                    El: <Box component={'span'} sx={{ color: ({ palette }) => palette.primary.main  }} > {dayjs(createdAt).format("DD-MM-YYYY HH:mm A")} </Box>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid xs={6} >
                            <SuperDataGrid
                                title={'Antes'}
                                useSearchBar={false}
                                columns={columns}
                                rows={originalValues}
                            />
                        </Grid>
                        <Grid xs={6} >
                            <SuperDataGrid
                                title={'Después'}
                                useSearchBar={false}
                                columns={columns}
                                rows={updateValues}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )


}

export default MassiveChangeHistory