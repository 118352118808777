import { usePurchaseOptions } from "../hooks/usePurchase"
import { Grid } from "@component/UIComponents"
import DateFilter from "@component/Date"
import Autocomplete from "@component/Autocomplete"
import NumericFormat from "@component/NumericFormat"
import ProviderFinder from "@feature/Providers/components/ProviderFinder"
import dayjs from "dayjs"

const Filters = ({ setPurchaseReportFilters=()=>null, see="", endDate=dayjs(), invoiceNumber="", groupBy=null, omitDateRange, paymentTypes, purchaseStatus, startDate=dayjs(), useDueDate, viewArchived }) => {
    const { groupPurchases, purchaseStatus:purchaseStatusList,  } = usePurchaseOptions()
    return(
        <Grid container spacing={1.5} >
            <Grid xs={12} >
                <DateFilter
                    stDisableFuture={!(useDueDate === "useDueDate")}
                    edDisableFuture={!(useDueDate === "useDueDate")}
                    size="small"
                    startDate={dayjs(startDate)}
                    endDate={dayjs(endDate)}
                    onChange={(date) => {
                        if( Boolean(date?.startDate) ) setPurchaseReportFilters("startDate", date.startDate)
                        if( Boolean(date?.endDate) ) setPurchaseReportFilters("endDate", date.endDate)
                    }}
                />
            </Grid>
            <Grid xs={12} >
                <NumericFormat
                    size="small"
                    label="Numero de factura"
                    fullWidth
                    prefix="#FAC "
                    value={invoiceNumber}
                    onChange={({ floatValue }) => setPurchaseReportFilters("invoiceNumber", floatValue)}
                />
            </Grid>
            <Grid xs={12} >
                <Autocomplete
                    queryValue={groupBy}
                    size="small"
                    label="Agrupar por"
                    options={groupPurchases}
                    onChange={(_, param)=>setPurchaseReportFilters("groupBy", (param?.value??""))}
                />
            </Grid>
            <Grid xs={12} >
                <ProviderFinder
                    queryValue={see}
                    size="small"
                    multiple
                    onChange={(_, param) => setPurchaseReportFilters("see", (param??[]).map((n) => n._id).join(","))}
                    compare={(n) => (n?._id??"")}
                />
            </Grid>
            <Grid xs={12} >
                <Autocomplete
                    queryValue={purchaseStatus}
                    size="small"
                    label="Estado de pago"
                    options={purchaseStatusList}
                    onChange={(_, param) => setPurchaseReportFilters("purchaseStatus", (param?.value??""))}
                />
            </Grid>
            <Grid xs={12} >
                <Autocomplete
                    queryValue={paymentTypes}
                    multiple
                    size="small"
                    label="Tipo de factura"
                    options={[
                        {name: "Crédito", value: "credit" },
                        {name: "Parcial", value: "partial" },
                    ].map((n, index) => ({...n, _id: index}))}
                    onChange={(_, param) => {
                        if( param.length <= 0 ){
                            setPurchaseReportFilters("paymentTypes", "credit")
                        }else{
                            setPurchaseReportFilters("paymentTypes", (param??[]).map((n) => n.value).join(","))
                        }
                    }}
                />
            </Grid>
            <Grid xs={12} >
                <Autocomplete
                    queryValue={omitDateRange || useDueDate}
                    size="small"
                    label="Fecha"
                    options={[
                        {name: "Filtrar por fecha de vencimiento", value: "useDueDate" },
                        {name: "Omitir rango de fecha", value: "omitDateRange" },
                    ].map((n, index) => ({...n, _id: index}))}
                    onChange={(_, param) => {
                        if( (param?.value??"") === "omitDateRange" ){
                            setPurchaseReportFilters("useDueDate", "null")
                            setPurchaseReportFilters("omitDateRange", "omitDateRange")
                        }else if( (param?.value??"") === "useDueDate" ){
                            setPurchaseReportFilters("useDueDate", "useDueDate")
                            setPurchaseReportFilters("omitDateRange", "null")
                        }else{
                            setPurchaseReportFilters("useDueDate", "null")
                            setPurchaseReportFilters("omitDateRange", "null")
                        }
                    }}
                />
            </Grid>
            <Grid xs={12} >
                <Autocomplete
                    queryValue={(viewArchived === true) ? "viewArchived" : "viewAvailable" }
                    size="small"
                    label="Estado de la factura"
                    options={[
                        { name: "Ver disponibles", value: "viewAvailable" },
                        { name: "Ver archivados", value: "viewArchived" },
                    ].map((n, index) => ({...n, _id: index}))}
                    onChange={(_, param) => {
                        if( (param?.value??"") === "viewArchived" ){
                            setPurchaseReportFilters("viewArchived", true)
                        }else{
                            setPurchaseReportFilters("viewArchived", "")
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default Filters