import { api, mutationConfig } from "../splitApi";
import { BranchTag, BusinessTypeTag, CustomerTag, FullCustomerTag, NeighborhoodTag, ZoneTag, CustomerAdvanceTag, BoxTag, } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerAdvanceToCustomer: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/advance/to/customer", body };
            },
            invalidatesTags: [...CustomerTag, ...CustomerAdvanceTag, ...BoxTag,]
        }),
        registerCustomer: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/customer", body };
            },
            invalidatesTags: FullCustomerTag
        }),
        archiveCustomer: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/customer", body };
            },
            invalidatesTags: CustomerTag
        }),
        getCustomers: build.query({
            query: (params=undefined) => ({
                url: `/get/customers`,
                params
            }),
            providesTags: CustomerTag
        }),
        getCustomer: build.query({
            query: (params=undefined) => ({
                url: `/get/customer`,
                params
            }),
            providesTags: CustomerTag
        }),
        getNeighborhoods: build.query({
            query: (params=undefined) => ({
                url: `/get/neighborhoods`,
                params
            }),
            providesTags: NeighborhoodTag
        }),
        getBusinessesType: build.query({
            query: (params=undefined) => ({
                url: `/get/businesses/type`,
                params
            }),
            providesTags: BusinessTypeTag
        }),
        getBranches: build.query({
            query: (params=undefined) => ({
                url: `/get/branches`,
                params
            }),
            providesTags: BranchTag
        }),
        getZones: build.query({
            query: (params=undefined) => ({
                url: `/get/zones`,
                params
            }),
            providesTags: ZoneTag
        }),
        getCustomerAdvanceHistory: build.query({
            query: (params=undefined) => ({
                url: `/get/customer/advance/history`,
                params
            }),
            providesTags: CustomerAdvanceTag
        }),
        getCustomerDebts: build.query({
            query: (params=undefined) => ({
                url: `/get/customer/debts`,
                params
            }),
            providesTags: CustomerAdvanceTag
        }),
        getCustomerPurchaseTracking: build.query({
            query: (params=undefined) => ({
                url: `/get/customer/purchase/tracking`,
                params
            }),
            providesTags: CustomerTag
        }),
        getCustomerTypes: build.query({
            query: (params=undefined) => ({
                url: `/get/customer/types`,
                params
            }),
            providesTags: CustomerTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCustomersQuery,
    useGetNeighborhoodsQuery,
    useGetBusinessesTypeQuery,
    useGetBranchesQuery,
    useGetZonesQuery,
    useRegisterCustomerMutation,
    useArchiveCustomerMutation,
    useRegisterAdvanceToCustomerMutation,
    useGetCustomerAdvanceHistoryQuery,
    useGetCustomerQuery,
    useLazyGetCustomerQuery,
    useGetCustomerDebtsQuery,
    useGetCustomerPurchaseTrackingQuery,
    useLazyGetCustomerPurchaseTrackingQuery,
    useGetCustomerTypesQuery
} = Endpoints;
